import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import isEmpty from 'lodash/isEmpty';
import Fuse from "fuse.js";
import {toast} from "../../../store/toaster/actions";
import styled from "styled-components";
import {getAgencies} from "./store/actions";
import {InputGroup, NonIdealState, Spinner, Button} from "@blueprintjs/core";
import {AutoSizer, Column, Table} from "react-virtualized";
import Editor from "./editor";
import {toggleAgencyEditor} from './store/actions';


const StyledList = styled.div`
  height: 100%;
  width: 100%;
  padding: 8px 16px;
`;

const FUSE_OPTIONS = {
  threshold: 0.5,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    {name: 'name', weight: 0.4},
    {name: 'address_1', weight: 0.2},
    {name: 'address_2', weight: 0.1},
    {name: 'address_3', weight: 0.1},
    {name: 'phone', weight: 0.1},
    {name: 'vat_number', weight: 0.1},
  ]
};

class SettingsAgencies extends Component {
  state = {
    search: '',
    record: parseInt(this.props.match.params._id),
  }

  componentDidMount() {
    this.props.getAgencies();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!isEmpty(nextProps.data)) {
      nextProps.getAgencies();
    }
  }

  render() {
    const { records, loading, toggleAgencyEditor } = this.props;
    const fuse = new Fuse(records, FUSE_OPTIONS);
    const filteredRecords = this.state.search.length ? fuse.search(this.state.search) : records;


    return (
      <React.Fragment>
        {loading ? (
          <Spinner className="mt50" />
        ) : (
          <React.Fragment>
            <div className="tools">
              <Button large onClick={() => toggleAgencyEditor({})} icon="add" intent="success" text="Add New" />
              <div className="flex">
                <InputGroup
                  leftIcon="search"
                  onChange={(e) => {
                    this.setState({
                      search: e.currentTarget.value
                    })
                  }}
                  large
                  placeholder="Search"
                />
              </div>
            </div>
            {filteredRecords.length > 0 ? (
              <StyledList>
                <AutoSizer>
                  {({width, height}) => (
                    <Table
                      ref="Table"
                      headerHeight={30}
                      noRowsRenderer={this._noRowsRenderer}
                      overscanRowCount={10}
                      rowClassName={this._rowClassName}
                      rowHeight={30}
                      rowCount={filteredRecords.length}
                      rowGetter={({ index }) => filteredRecords[index]}
                      height={height}
                      onRowClick={(event) => toggleAgencyEditor(event.rowData)}
                      width={width}>
                      <Column
                        label='Name'
                        dataKey='name'
                        width={width * 0.2}
                      />
                      <Column
                        label='Address 1'
                        dataKey='address_1'
                        width={width * 0.2}
                      />
                      <Column
                        label='Address 2'
                        dataKey='address_2'
                        width={width * 0.2}
                      />
                      <Column
                        label='Address 3'
                        dataKey='address_3'
                        width={width * 0.2}
                      />
                      <Column
                        label='Phone'
                        dataKey='phone'
                        width={width * 0.2}
                      />
                      <Column
                        label='Vat Number'
                        dataKey='vat_number'
                        width={width * 0.2}
                      />
                      <Column
                        label='Number of contractors'
                        dataKey='number_of_contractors'
                        width={width * 0.2}
                      />
                    </Table>
                  )}
                </AutoSizer>
              </StyledList>
            ) : (
              <NonIdealState
                icon="search"
                title="No agencies found"
              />
            )}
          </React.Fragment>
        )}
        <Editor />
      </React.Fragment>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    getAgencies,
    toggleAgencyEditor,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    loading: state.settingsAgencies.loading,
    records: state.settingsAgencies.data,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(SettingsAgencies));
