import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {FormGroup, InputGroup, Button, Intent, Dialog, Classes, Checkbox, Card} from '@blueprintjs/core';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {toast} from "../../../store/toaster/actions";
import {toggleCurrencyEditor, insertCurrency, updateCurrency, deleteCurrency} from "./store/actions";

import SlidingPanel from '../../../components/slidingpanel';
import {Box, Flex} from "@rebass/grid";
import Icon from "../../../components/icon";
import Select from "react-select";

const StyledEditor = styled(SlidingPanel)`
  .bp3-card {
    margin: 0 5px 16px 0;
    
    h4 {
      font-size: 16px;
      margin: 0 0 10px;
    }
  }
`;


class CurrencyEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      record: {
        name: '',
        code: '',
        rate: '',
      },
      deleteDialog: false,
      errors: {},
      sent: false,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.record && nextProps.record.id) {
      this.setState({
        record: {
          id: nextProps.record.id ? nextProps.record.id : '',
          name: nextProps.record.name ? nextProps.record.name : '',
          code: nextProps.record.code ? nextProps.record.code : '',
          rate: nextProps.record.rate ? nextProps.record.rate : '',         
        }
      });
    } else {
      this.setState({
        record: {
          name: '',
          code: '',
          rate: '',         
        }
      });
    }
  }

  handleDelete()
  {
    this.setState({deleteDialog: false});
    this.props.deleteCurrency(this.props.match.params._id, this.props.record.id, () => {
      this.props.toast({message: 'Currency was successfully deleted', intent: 'SUCCESS'});
      this.props.toggleCurrencyEditor();
    });
  }

  handleSave(e) {
    e.preventDefault();
    this.setState({sent: true});
    if(this.handleValidation()) {
      const { insertCurrency, updateCurrency, toggleCurrencyEditor, toast, record } = this.props;
      if (record.id){
        updateCurrency(record.company_id, record.id, this.state.record, status => {
          if (status === 'saved'){
            toast({message: 'Currency was successfully updated', intent: 'SUCCESS'});
            toggleCurrencyEditor();
          } else if (status === 'error') {
            toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      } else {
        insertCurrency(this.props.match.params._id, this.state.record, status => {
          if (status === 'saved'){
            toast({message: 'Currency was successfully added', intent: 'SUCCESS'});
            toggleCurrencyEditor();
          } else if (status === 'error') {
            toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      }
    } else {
      console.log('form error');
    }

  }

  handleValidation(){
    let record = this.state.record;
    let errors = {};
    let formIsValid = true;

    if(!record['name']){
      formIsValid = false;
      errors['name'] = "This field is required";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleChange(e){
    let record = this.state.record;
    record[e.currentTarget.name] = e.currentTarget.value;
    this.setState({record});
    if (this.state.sent){
      this.handleValidation();
    }
  }

  handleNotificationChange(type, value) {
    let val = null;
    if (!value) {
      val = null;
    } else if (value.hasOwnProperty('value') && value.value === false){
      val = false;
    } else {
      val = true;
    }
    this.setState({
      ...this.state,
      record: {
        ...this.state.record,
        [type]: val
      },
      values: {
        ...this.state.values,
        [type]: value,
      }
    },() => {
      if (this.state.sent){
        this.handleValidation();
      }
    });
  }

  render() {
    const {isEditorActive, toggleCurrencyEditor} = this.props;
    const {record, values} = this.state;
    return (
      <StyledEditor isOpen={isEditorActive} width="520px" title="Currency" onRequestClose={() => toggleCurrencyEditor()}>
        <React.Fragment>
          <form onSubmit={(e) => this.handleSave(e)}>
            <FormGroup
              intent={this.state.errors.name ? Intent.DANGER : Intent.SUCCESS}
              label="Name"
              labelFor="name"
              labelInfo="*"
              helperText={this.state.errors.name && this.state.errors.name}
            >
              <InputGroup
                intent={this.state.errors.name ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="name"
                id="name"
                large
                placeholder="Name"
                defaultValue={record.name}
              />
            </FormGroup>
            <FormGroup
              intent={this.state.errors.code ? Intent.DANGER : Intent.SUCCESS}
              label="Code"
              labelFor="code"
              helperText={this.state.errors.code && this.state.errors.code}
            >
              <InputGroup
                intent={this.state.errors.code ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="code"
                id="code"
                large
                placeholder="Code"
                value={record.code}
              />
            </FormGroup>
            <FormGroup
              intent={this.state.errors.rate ? Intent.DANGER : Intent.SUCCESS}
              label="Rate"
              labelFor="rate"
              helperText={this.state.errors.rate && this.state.errors.rate}
            >
              <InputGroup
                intent={this.state.errors.rate ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="rate"
                id="rate"
                large
                placeholder="Rate"
                value={record.rate}
              />
            </FormGroup>           
            <div className="button-bar">
              <Button large type="submit" icon="floppy-disk" intent="success" text="Save" />
              {record && record.id && <Button style={{marginLeft: 10}} large onClick={() => this.setState({deleteDialog: true})} icon={<Icon color="#fff" icon="trash" />} intent="danger" text="Delete" />}
            </div>
          </form>
          <Dialog
            icon="info-sign"
            onClose={() => this.setState({deleteDialog: false})}
            title="Currency Delete"
            isOpen={this.state.deleteDialog}>
            <div className={Classes.DIALOG_BODY}>
              <div>Are you sure?</div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => this.setState({deleteDialog: false})}>Cancel</Button>
                <Button intent="DANGER" onClick={() => this.handleDelete()}>Delete</Button>
              </div>
            </div>
          </Dialog>
        </React.Fragment>
      </StyledEditor>
    );
  }
}

function mapStateToProps(state){
  return {
    isEditorActive: state.settingsCurrencies.isEditorActive,
    record: state.settingsCurrencies.recordToEdit,
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    toast,
    toggleCurrencyEditor,
    updateCurrency,
    insertCurrency,
    deleteCurrency,
  }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withRouter(CurrencyEditor));
