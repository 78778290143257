import React, { Component } from 'react';
import Select from 'react-select';
import Scrollbar from 'react-scrollbars-custom';
import { Link } from 'react-router-dom';
import months from './MonthsArray';
import {bindActionCreators} from "redux";
import {toast} from "../../../store/toaster/actions";
import { getExpenses } from "./store/actions";
import {connect} from "react-redux";
import {Button, Intent, Popover, PopoverInteractionKind, Position, Spinner} from "@blueprintjs/core";


class Months extends Component {
  state = {
    records: null,
    years: [],
    selectedYear: undefined,
  }

  componentDidMount() {
    this.props.getExpenses();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const records = nextProps.expensesSummary;
    const years = Object.keys(records);
    years.sort(function(a, b){return b - a});
    let selectedYear = null;
    const _year = nextProps.match.params._year
    if (_year){
      const propYear = years.indexOf(_year);
      if (propYear > -1){
        selectedYear = years[propYear];
      } else {
        selectedYear = years[0];
      }
    } else {
      selectedYear = years[0];
    }
    if (!selectedYear) {
      const currentYear = new Date().getFullYear();
      years.push(currentYear);
      selectedYear = currentYear;
    }
    const formatedYears = [];
    years.map(y => {
      formatedYears.push({label: y, value: y});
    });

    const selectedRecords = records[selectedYear];
    this.setState({
      records: selectedRecords,
      years: formatedYears,
      selectedYear: {label: selectedYear, value: selectedYear}
    });
  }

  handleChangeYear(year){
    const { _id } = this.props.match.params;
    this.setState({
      selectedYear: year,
    }, () => {
      return this.props.history.push(`/expenses/${year.value}`);
    })
  }



  render() {
    const { selectedYear, years, records } = this.state;

    return (
      <React.Fragment>
        {this.props.expensesSummaryLoading ?
          <Spinner className="mt50" /> :
          <React.Fragment>
            <div className="tools">
              <div>
                <Popover
                  interactionKind={PopoverInteractionKind.CLICK}
                  popoverClassName="bp3-popover-content-sizing expenses-popover"
                  position={Position.BOTTOM_LEFT}
                >
                  <Button large intent={Intent.SUCCESS}>Add New</Button>
                  <div>
                    <div className=""><Button onClick={() => this.props.history.push('/expenses/add/business')} large className="business">Business Expense</Button></div>
                    <div className="mt5"><Button onClick={() => this.props.history.push('/expenses/add/travel')} large className="travel">Travel Expense</Button></div>
                  </div>
                </Popover>
              </div>
              <Select
                className="year-picker"
                options={this.state.years}
                onChange={option => this.handleChangeYear(option)}
                value={this.state.selectedYear}
              />
            </div>
            <Scrollbar noDefaultStyles>
              <div className="months list">
                  {[ ...Array(12).keys() ].map((m, month) => {
                    const record = records && records[m] ? records[m] : null;
                    return (
                      <Link to={`/expenses/${selectedYear && selectedYear.value}/${month+1}`}
                            key={month}
                            className={`${parseInt(this.props.match.params._month - 1) === month ? 'selected ' : ''}month`}>
                        <div className="header">
                          <p className="title">
                            {months[month]} {selectedYear && selectedYear.value}
                          </p>
                        </div>
                        <div className="content">{record && record.total ? `€${record.total.toFixed()}` : '€0'}</div>
                        <div className="footer">
                          <div className="approved">
                            <div className="bullet" />
                            <p className="number">{record && record.business ? `€${record.business.toFixed()}` : '€0'}</p>
                          </div>
                          <div className="waiting">
                            <div className="bullet" />
                            <p className="number">{record && record.travel ? `€${record.travel.toFixed()}` : '€0'}</p>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            </Scrollbar>
          </React.Fragment>
        }
      </React.Fragment>


    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    getExpenses
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    expensesSummaryLoading: state.expenses.expensesSummaryLoading,
    expensesSummary: state.expenses.expensesSummary,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(Months));
