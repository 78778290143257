import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

class SlidingPanel extends React.Component {
  constructor(props) {
    super(props);
    Modal.setAppElement('#root');
  }

  render() {

    const CLOSE_TIMEOUT = 500;
    const {
      isOpen,
      title,
      subtitle,
      onRequestClose,
      onAfterOpen,
      children,
      className,
      overlayClassName,
      from = 'right',
      width,
      hideHeader,
    } = this.props;
    const directionClass = `slide-panel_from_${from}`;
    return (
        <Modal
            className={`slide-panel ${directionClass} ${className || ''}`}
            style={{
              content: { width: width || '80%' }
            }}
            overlayClassName={`slide-panel__overlay ${overlayClassName || ''}`}
            closeTimeoutMS={CLOSE_TIMEOUT}
            isOpen={isOpen}
            onAfterOpen={onAfterOpen}
            onRequestClose={onRequestClose}
            contentLabel={`Modal "${title || ''}"`}
        >
          {!hideHeader &&
            <div className="slide-panel__header">
            <div className="slide-panel__close" onClick={onRequestClose}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129">
            <path
            fill="currentColor"
            fillRule="evenodd"
            d="m88.6,121.3c0.8,0.8 1.8,1.2 2.9,1.2s2.1-0.4 2.9-1.2c1.6-1.6 1.6-4.2 0-5.8l-51-51 51-51c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8,0l-54,53.9c-1.6,1.6-1.6,4.2 0,5.8l54,53.9z"
            />
            </svg>
            </div>
            <div className="slide-panel__title-wrapper">
            <h2 className="slide-panel__title">{title}</h2>
            <div className="slide-panel__subtitle">{subtitle}</div>
            </div>
            </div>
          }
          <div className="slide-panel__content">{children}</div>
        </Modal>
    );
  }
}

SlidingPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hideHeader: PropTypes.bool,
  title: PropTypes.any,
  subtitle: PropTypes.any,
  onRequestClose: PropTypes.func,
  onAfterOpen: PropTypes.func,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  from: PropTypes.oneOf([ 'left', 'right' ]),
  width: PropTypes.string
};

export default SlidingPanel;
