import React, {Component} from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Details from "./Details";
import Employees from "./Employees";

import BankAccounts from "./BankAccounts";
import BankStatements from "./BankStatements";
import Tabs from "./tabs";

class Detail extends Component {
  render() {
    return (
      <div className="right">
        <div className="tools">
          <Tabs match={this.props.match} history={this.props.history}/>
        </div>
          <Switch>
            <Route exact path="/companies/:_id/" component={Details} />
            <Route exact path="/companies/:_id/employees" selectedId={this.props.match.params._id} component={Employees} />
            <Route exact path="/companies/:_id/bank-statements" selectedId={this.props.match.params._id} component={BankStatements} />
            <Route exact path="/companies/:_id/bank-accounts" selectedId={this.props.match.params._id} component={BankAccounts} />
          </Switch>
      </div>
    );
  }
}

export default withRouter(Detail);
