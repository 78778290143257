import React, { useState } from 'react';
import { toast } from "../../../../store/toaster/actions";
import { toggleMaritalStatusAndSpouseDetailsEditor, updateRecord } from "../../../../store/contractors/actions";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, InputGroup, Button, Spinner } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import Select from 'react-select';
import { Formik, Field } from 'formik';
import { converDateForServer } from '../../../../utils/parse';
import moment from 'moment';

export default ({ formik }) => {
  const [loading, setLoading] = useState(false);
  const { record = {}, maritalStatuses = [], contractors = [] } = useSelector(store => ({
      record: store.contractors.recordToEdit,
      contractors: store.contractors.records,
      maritalStatuses: store.general.maritalStatuses
    })
  );
  const dispatch = useDispatch();
  const {
    marital_status_id,
    spouse_id,
    spouse_first_name,
    spouse_last_name,
    spouse_maiden_name,
    spouse_pps_number,
    date_of_marriage,
    date_of_separation
  } = record;

  const maritalOptions = maritalStatuses.map(c => ({ value: c.id, label: c.name }));
  const contractorsOptions = contractors.filter(c => c.id !== record.id)
    .map(c => ({ value: c.id, label: `${c.first_name} ${c.last_name}` }));

  const onSubmit = form => {
    setLoading(true);
    dispatch(updateRecord(
      record.id,
      { 
        ...form,
        date_of_marriage: converDateForServer(form.date_of_marriage),
        date_of_separation: converDateForServer(form.date_of_separation)
      },
      status => {
      if (status === 'saved') {
        dispatch(toggleMaritalStatusAndSpouseDetailsEditor());
        dispatch(toast({ message: 'Contractor was successfully updated', intent: 'SUCCESS' }));
      } else if (status === 'error') {
        dispatch(toast({ message: 'Server Error', intent: 'DANGER' }));
      }
      setLoading(false);
    }));
  }

  return (
    <Formik
      initialValues={{
        marital_status_id,
        spouse_id,
        spouse_first_name,
        spouse_last_name,
        spouse_maiden_name,
        spouse_pps_number,
        date_of_marriage: date_of_marriage ? moment(date_of_marriage, 'DD/MM/YYYY').toDate() : undefined,
        date_of_separation: date_of_separation ? moment(date_of_separation, 'DD/MM/YYYY').toDate() : undefined,
      }}
      onSubmit={onSubmit}>
      {subformik => {
        const { values, handleChange, handleBlur, handleSubmit, dirty, setFieldValue } = formik || subformik;

        let disableForSingle = values.marital_status_id === 1;
        let disableForMarried = values.marital_status_id === 2;
        let disableForOther = values.marital_status_id > 2;

        return (
          <form onSubmit={handleSubmit}>
            <FormGroup
              label="Marital Status"
              labelFor="marital_status_id">
              <Field>
                {({ field }) => {
                  return (
                    <Select
                      type="select"
                      id="marital_status_id"
                      value={maritalOptions.find(o => o.value === field.value.marital_status_id)}
                      options={maritalOptions}
                      onChange={option => {
                        setFieldValue('marital_status_id', option ? option.value : 0);
                        if (!option) return;
                        if (option.value !== 2) {
                          setFieldValue('spouse_id', null);
                          setFieldValue('spouse_first_name', null);
                          setFieldValue('spouse_last_name', null);
                          setFieldValue('spouse_pps_number', null);
                          setFieldValue('date_of_marriage', null);
                          if (option.value === 2) setFieldValue('date_of_separation', null);
                        }
                      }}
                      onBlur={handleBlur}
                      name="Marital Status" />
                  )
                }}
              </Field>
            </FormGroup>
            <FormGroup
              label="Spouse"
              labelFor="spouse_id"
              disabled={disableForSingle || disableForOther}>
              <Field>
                {({ field, form: { setFieldValue } }) => {
                  return (
                    <Select
                      isClearable
                      type="select"
                      id="spouse_id"
                      placeholder="Other"
                      value={contractorsOptions.find(o => o.value === field.value.spouse_id)}
                      options={contractorsOptions}
                      onChange={option => {
                        setFieldValue('spouse_id', option ? option.value : 0);
                        if (!option) return;
                        const c = contractors.find(c => c.id === option.value);
                        if (c) {
                          setFieldValue('spouse_first_name', c.first_name || '');
                          setFieldValue('spouse_last_name', c.last_name || '');
                          setFieldValue('spouse_pps_number', c.pps_number || '');
                        }
                      }}
                      onBlur={handleBlur}
                      name="Marital Status"
                      isDisabled={disableForSingle || disableForOther} />
                  )
                }}
              </Field>
            </FormGroup>
            <FormGroup
              label="First Name"
              labelFor="spouse_first_name"
              disabled={disableForSingle || disableForOther}>
              <InputGroup
                name="spouse_first_name"
                id="spouse_first_name"
                value={values.spouse_first_name || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disableForSingle || disableForOther} />
            </FormGroup>
            <FormGroup
              label="Last Name"
              labelFor="spouse_last_name"
              disabled={disableForSingle || disableForOther}>
              <InputGroup
                name="spouse_last_name"
                id="spouse_last_name"
                value={values.spouse_last_name || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disableForSingle || disableForOther} />
            </FormGroup>
            <FormGroup
              label="Maiden Name"
              labelFor="spouse_maiden_name"
              disabled={disableForSingle || disableForOther}>
              <InputGroup
                name="spouse_maiden_name"
                id="spouse_maiden_name"
                value={values.spouse_maiden_name || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disableForSingle || disableForOther} />
            </FormGroup>
            <FormGroup
              label="PPS Nunber"
              labelFor="spouse_pps_number"
              disabled={disableForSingle || disableForOther}>
              <InputGroup
                name="spouse_pps_number"
                id="spouse_pps_number"
                value={values.spouse_pps_number || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disableForSingle || disableForOther} />
            </FormGroup>
            <FormGroup
              label="Date of Marriage"
              labelFor="date_of_marriage"
              disabled={disableForSingle || disableForOther}>
              <Field>
                {({ field, form: { setFieldValue } }) => (
                  <DateInput
                    inputProps={{ style: { width: '100%' }, className: 'bp3-large', leftIcon: "calendar" }}
                    id="date_of_marriage"
                    className="bp3-large"
                    minDate={new Date('1900-01-01')}
                    formatDate={date => moment(date).format('DD/MM/YYYY')}
                    parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
                    onChange={date => {
                      setFieldValue('date_of_marriage', date);
                    }}
                    onBlur={handleBlur}
                    placeholder={"DD/MM/YYY"}
                    showActionsBar
                    disabled={disableForSingle || disableForOther}
                    value={values.date_of_marriage || undefined} />
                )}
              </Field>
            </FormGroup>
            <FormGroup
              label="Date of Separation"
              labelFor="date_of_separation"
              disabled={disableForSingle || disableForMarried}>
              <Field>
                {({ field, form: { setFieldValue } }) => {
                  return (
                  <DateInput
                    inputProps={{ style: { width: '100%' }, className: 'bp3-large', leftIcon: "calendar" }}
                    id="date_of_separation"
                    className="bp3-large"
                    minDate={new Date('1900-01-01')}
                    formatDate={date => moment(date).format('DD/MM/YYYY')}
                    parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
                    onChange={date => {
                      setFieldValue('date_of_separation', date);
                    }}
                    onBlur={handleBlur}
                    placeholder={"DD/MM/YYY"}
                    showActionsBar
                    disabled={disableForSingle || disableForMarried}
                    value={values.date_of_separation || undefined} />
                )}}
              </Field>
            </FormGroup>
            {!formik && <Button large type="submit" icon="floppy-disk" intent="success" text="Save" disabled={!dirty || loading} />}
            {!formik && loading && <Spinner />}
          </form>
        )
      }}
    </Formik>
  );
}
