export const types = [
  {value: 0, label: 'Less than 5 hours'},
  {value: 1, label: 'Between 5 and 10 hours'},
  {value: 2, label: 'Between 10 and 24 hours'},
  {value: 3, label: 'Overnight Allowance'}
];

export const days = [
  {value: 1, label: '1 day' },
  {value: 2, label: '2 days' },
  {value: 3, label: '3 days' },
  {value: 4, label: '4 days' },
  {value: 5, label: '5 days' },
  {value: 6, label: '6 days' },
  {value: 7, label: '7 days' },
  {value: 8, label: '8 days' },
  {value: 9, label: '9 days' },
  {value: 10, label: '10 days' },
  {value: 11, label: '11 days' },
  {value: 12, label: '12 days' },
  {value: 13, label: '13 days' },
  {value: 14, label: '14 days' },
  {value: 15, label: '15 days' },
  {value: 16, label: '16 days' },
  {value: 17, label: '17 days' },
  {value: 18, label: '18 days' },
  {value: 19, label: '19 days' },
  {value: 20, label: '20 days' },
  {value: 21, label: '21 days' },
  {value: 22, label: '22 days' },
  {value: 23, label: '23 days' }
];

export const engines = [
  {value: 1, label: '0 days' },
  {value: 2, label: '1 day' },
  {value: 3, label: '2 days' },
];