import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {DateInput, TimePrecision} from "@blueprintjs/datetime";
import Scrollbar from 'react-scrollbars-custom';
import {withRouter} from "react-router-dom";
import Select from 'react-select';
import { Flex, Box } from '@rebass/grid';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import moment from "moment";

import styled from 'styled-components';
import {
  FormGroup,
  InputGroup,
  Intent,
  Button,
  TextArea,
  Checkbox,
  Spinner,
  Callout,
  Dialog,
  Classes
} from "@blueprintjs/core";
import {toast} from "../../../../store/toaster/actions";
import BusinessExpenseUploadItem from "../../../accountant/contractors/Expenses/BusinessExpenseUploadItem";
import {connect} from "react-redux";

const StyledForm = styled.form`
  display: flex;
  flex-flow: column;
  min-height: 100%;
  
  .content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: ${props => props.hideHeader ? '0' : '72px'};
    
    .form-wrapper {
      padding: 10px;
    }
  }
  
  .card {
    margin-bottom: 10px;
    border-radius: 4px;
    
    .card-title {
      border-radius: 4px 4px 0 0;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #e4e5e9;
    }
    
    .card-body {
      padding: 10px 10px 0;
      border: 1px solid #e4e5e9;
    }
  }
  
  .status {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #edeef0;
    margin-bottom: 10px;
    padding: 5px 0;
    
    span {
      &.value {
        font-weight: bold;
      }
    }
  }
  
  .reject {
    margin-top: -10px;
  }
`;

const statuses = {
  status_1: 'Awaiting Approval',
  status_2: 'Approved',
  status_3: 'Rejected',
  status_4: 'Deleted',
  status_5: 'Paid',
};

class BusinessExpenseForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      deleteDialog: false,
      statusName: '',
      recordId: null,
      loading: true,
      categories: [],
      currencies: [],
      selectedCategory: null,
      selectedCurrency: null,
      isCategoryInvalid: '',
      isCurrencyInvalid: '',
      agree1: false,
      agree2: false,
      uploads: [],
      files: [],
      incurred: null,
      statusId: null,
      fields: {
        value: '',
        description: '',
        incurred: null,
        currency: null,
        category: null,
        eurValue: 0,
      },
      errors: {},
    }
  }

  componentDidMount() {
    const { expense } = this.props;
    axios.get(`/expenses/business/${expense ? expense.id : 0}`)
      .then(result => {
        const { record, categories, currencies, files } = result.data;
        const formattedCategories = this.formatCategories(categories);
        const formattedCurrencies = this.formatCurrencies(currencies);
        const description = record && record.description ? record.description : '';
        const incurred = record && record.incurred ? moment(record.incurred).toDate() : null;
        const category = record && record.type_id ? record.type_id : '';
        const currency = record && record.currency_id ? record.currency_id : '';
        const selectedCategory = category ? formattedCategories.find(el => el.value === category) : null;
        const selectedCurrency = currency ? formattedCurrencies.find(el => el.value === currency) : null;
        const eurValue = record && record.net ? record.net : 0;

        const uploads = files.map(file => {
          file.uploaded = true;
          return file;
        });

        const agreements = record && record.id ? true : false;
        const value = record && record.net ? record.net : '';
        this.setState({
          recordId: record && record.id,
          rejectedReason: record && record.rejected_reason ? record.rejected_reason : '',
          statusName: record && record.status_name,
          statusId: record && record.status_id,
          loading: false,
          incurred,
          status: record && record.status_id ? statuses[`status_${record.status_id}`] : '',
          fields: {
            ...this.state.fields,
            incurred: moment(incurred).format('YYYY-MM-DD HH:mm:ss'),
            description,
            value,
            category,
            currency,
            eurValue,
          },
          files,
          uploads,
          categories: formattedCategories,
          currencies: formattedCurrencies,
          selectedCategory,
          selectedCurrency,
          agree1: agreements,
          agree2: agreements,
        });
      }).catch(error => {
        console.log(error);
    });
  }

  formatCategories(categories) {
    const formattedCategories = [];
    categories.map(category => {
      formattedCategories.push({label: category.name, value: category.id});
      return category;
    });
    return formattedCategories;
  }

  formatCurrencies(currencies) {
    const formattedCurrencies = [];
    currencies.map(currency => {
      formattedCurrencies.push({label: currency.name, value: currency.id, rate: currency.rate});
      return currency;
    });
    return formattedCurrencies;
  }

  onIncurredChanged(e) {
      const incurredDate = e;
      if ( !incurredDate ){
        this.setState({
          incurred: null,
          fields: { ...this.state.fields, incurred: null},
          errors: { ...this.state.errors, incurred: 'This Field is required' }
        });
      } else {
        this.setState({
          incurred: incurredDate,
          fields: { ...this.state.fields, incurred: moment(incurredDate).format('YYYY-MM-DD HH:mm:ss')},
          errors: { ...this.state.errors, incurred: undefined }
        });
      }
  }

  onFocusChanged(fieldName) {
    if(this.props.onFocusChanged) {
      this.props.onFocusChanged(fieldName);
    }
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;

    let eurValue = this.state.fields.eurValue;
    if (name === 'value'){
      if (value && this.state.selectedCurrency && this.state.selectedCurrency.rate) {
        eurValue = parseFloat(parseFloat(value) * this.state.selectedCurrency.rate).toFixed(2);
      }
    }


    if (!value.length) {
      this.setState({
        fields: {...this.state.fields, eurValue, [name]: value},
        errors: {...this.state.errors, [name]: 'This Field is required.'}
      });
    } else {
      this.setState({
        fields: {...this.state.fields, eurValue, [name]: value},
        errors: {...this.state.errors, [name]: undefined}
      });
    }
  }

  handleDrop(acceptedFiles){
    const uploads = acceptedFiles.map(file => {
       if (this.state.uploads.find(el => el.name === file.name)){
         return;
       }
      if (file.type === 'application/pdf' || file.type === 'image/png' || file.type === 'image/jpeg') {
        const formData = new FormData();
        if (this.state.recordId) {
          formData.append('expenseId', this.state.recordId);
        }
        formData.append("file", file);
        this.setState({
          uploads: [...this.state.uploads, file]
        },() => {
          return axios.post("/expenses/upload", formData).then(response => {
            const originalName = response.data.originalName;
            const fileId = response.data.id;
            const fileName = response.data.name;

            this.setState({
              uploads: this.state.uploads.map(el => {
                if (el.name === originalName){
                  el.id = fileId;
                  el.originalName = fileName;
                  el.uploaded = true;
                }
                return el;
              })
            }, () => {
              console.log(this.state.uploads);
            });
          });
        });
      }
    });

    // Once all the files are uploaded
    /*
    axios.all(uploads).then(() => {
      this.setState({
        //files: [],
        uploading: false,
      }, () => {
        // this.props.toast({message: "Successfully uploaded.", intent: "success"});
      });
    });
    */

  }

  changeCategory(option) {
    this.setState({
      selectedCategory: option,
      fields: {
        ...this.state.fields,
        category: option.value,
      }
    });
  }

  changeCurrency(option) {

    const {value} = this.state.fields;
    let eurValue = 0;
    if (value && option.rate) {
      eurValue = parseInt(value) * option.rate;
    }

    this.setState({
      selectedCurrency: option,
      fields: {
        ...this.state.fields,
        eurValue: eurValue ? parseFloat(eurValue).toFixed(2) : 0,
        currency: option.value,
      }
    }, () => {
      if (this.state.sent) {
        if (this.state.errors.currency){
          this.setState({
            ...this.state,
            errors: {
              ...this.state.errors,
              currency: undefined,
            }
          })
        }
      }
    });
  }

  validate() {
    let hasError = false;

    const errors = {
      incurred: null,
      category: null,
      description: null,
      currency: null,
      value: null,
      files: null,
      agree1: null,
      agree2: null,
    };


    if (moment(this.state.fields.incurred).isValid()){
      errors.incurred = null;
    } else {
      errors.incurred = 'This Field is required.';
      hasError = true;
    }

    if (this.state.fields.category){
      errors.category = null;
    } else {
      errors.category = 'This Field is required.';
      hasError = true;
    }

    if (this.state.fields.description.length){
      errors.description = null;
    } else {
      errors.description = 'This Field is required.';
      hasError = true;
    }

    if (this.state.fields.currency){
      errors.currency = null;
    } else {
      errors.currency = 'This Field is required.';
      hasError = true;
    }

    if (this.state.fields.value){
      errors.value = null;
    } else {
      errors.value = 'This Field is required.';
      hasError = true;
    }

    if (this.state.agree1){
      errors.agree1 = null;
    } else {
      errors.agree1 = 'This Field is required.';
      hasError = true;
    }

    if (this.state.agree2){
      errors.agree2 = null;
    } else {
      errors.agree2 = 'This Field is required.';
      hasError = true;
    }

    this.setState({
      sent: true,
      errors,
    });

    return !hasError;
  }

  handleCheckbox(target, value) {
    this.setState({
       [target]: value
    });
  }

  deleteFile (id) {
    const data = {fileId: id};
    this.setState({
      uploads: this.state.uploads.map(upload => {
        if (upload.id === id){
          upload.uploaded = false;
        }
        return upload;
      }),
    })
    return axios.delete("/expenses/upload", { data: { id } }).then(response => {
      this.setState({
        uploads: this.state.uploads.filter(upload => upload.id !== id),
      }, () => {
        this.props.toast({message: 'Receipt deleted', intent: 'success'});
      })
    }).catch(error => {

    });
  }

  viewFile (upload) {
    console.log(upload);
    const { userId } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/expenses/business/${this.state.recordId}/receipt/${upload.internal}?auth=${window.localStorage.getItem('ammotio-token')}&session=${window.localStorage.getItem('ammotio-session')}`;
    window.open(url, "_blank");
  }

  handleDelete () {
    return axios.delete(`/expenses/business/${this.state.recordId}`).then(response => {
      this.setState({
        deleteDialog: false,
      }, () => {
        this.props.onBusinessEdited();
        this.props.toast({message: 'Business Expense deleted', intent: 'success'});
      })
    }).catch(error => {

    });
  }

  handleSubmit (event) {
    event.preventDefault();
    if (this.validate()){
      const data = this.state.fields;
      data.files = this.state.uploads.map((el) => {
        return el.id
      });
      if (this.state.recordId) {
        axios.post(`/expenses/business/${this.state.recordId}`, data).then(result => {
          this.props.onBusinessEdited();
        }).catch(error => {
          console.log(error);
        });
      } else {
        axios.post('/expenses/business', data).then(result => {
          const {month, year} = result.data;
          this.props.history.push(`/expenses/${parseInt(year)}/${parseInt(month)}`);
        });
      }
    }
  }

  render() {
    const {loading, statusName, statusId, recordId} = this.state;
    const canEdit = (this.state.statusName === 'Submitted' || !this.state.recordId);

    return (
      <StyledForm hideHeader={this.props.hideHeader} noValidate onSubmit={ event => this.handleSubmit(event) }>
        {loading ? <Spinner /> : <React.Fragment>
            {!this.props.hideHeader ?
              <div className="tools">
                <Button large onClick={(event) => this.handleSubmit(event)} icon="add" intent="success" text="Add New" />
              </div> : ''}
            <div className="content">
              <Scrollbar noDefaultStyles>
                <div className="form-wrapper">
                  {statusName &&
                    <React.Fragment>
                      <Flex className="row">
                        <Box width={1/1}>
                          <div className="status">
                            <span>Status</span>
                            <span className="value">{statusName}</span>
                          </div>
                        </Box>
                      </Flex>
                      {statusId === 3 &&
                        <Flex className="row reject">
                          <Box width={1 / 1}>
                            <div className="status">
                              <Callout intent="danger" icon="info-sign">
                                {this.state.rejectedReason}
                              </Callout>
                            </div>
                          </Box>
                        </Flex>
                      }
                    </React.Fragment>
                  }
                  <Flex className="row">
                    <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                      <FormGroup
                        style={{width: '100%'}}
                        intent={this.state.errors.incurred ? Intent.DANGER : Intent.SUCCESS}
                        label="Incurred"
                        labelFor="incurred"
                        labelInfo="*"
                        helperText={this.state.errors.incurred ? this.state.errors.incurred : ''}
                      >
                        <DateInput
                          disabled={!canEdit}
                          inputProps={{style: {width: '100%'}, className: 'bp3-large'}}
                          id="incurred"
                          formatDate={date => moment(date).locale('ie').format('DD/MM/YYYY HH:mm')}
                          parseDate={str => moment(str, 'DD/MM/YYYY HH:mm')}
                          onChange={(dt) => this.onIncurredChanged(dt)}
                          placeholder={"DD/MM/YYYY HH:MM"}
                          value={this.state.incurred}
                          timePrecision={TimePrecision.MINUTE}
                          showActionsBar
                          intent={this.state.errors.incurred ? Intent.DANGER : Intent.NONE}
                        />
                      </FormGroup>
                    </Box>
                    <Box width={['100%','50%','50%']} pl={['0', '5px']}>
                      <FormGroup
                        intent={this.state.errors.category ? Intent.DANGER : Intent.SUCCESS}
                        label="Category"
                        labelFor="category"
                        labelInfo="*"
                        helperText={this.state.errors.category ? this.state.errors.category : ''}
                      >
                        <Select
                          isDisabled={!canEdit}
                          id="category"
                          name="category"
                          multi={false}
                          options={this.state.categories}
                          onChange={option => this.changeCategory(option)}
                          value={this.state.selectedCategory}
                          onFocus={() => this.onFocusChanged('category')}
                          onBlur={() => this.onFocusChanged(null)}
                        />
                      </FormGroup>
                    </Box>
                  </Flex>
                  <Flex className="row">
                    <Box width={1/1}>
                      <FormGroup
                        intent={this.state.errors.description ? Intent.DANGER : Intent.SUCCESS}
                        label="Description"
                        labelFor="description"
                        labelInfo="*"
                        helperText={this.state.errors.description ? this.state.errors.description : ''}
                      >
                        <TextArea
                          disabled={!canEdit}
                          id="description"
                          name="description"
                          growVertically
                          large
                          fill
                          value={this.state.fields.description}
                          onChange={(e) => this.handleUserInput(e)}
                          onFocus={() => this.onFocusChanged('description')}
                          onBlur={() => this.onFocusChanged(null)}
                        />

                      </FormGroup>
                    </Box>
                  </Flex>
                  {canEdit &&
                  <Flex>
                    <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                      <FormGroup
                        intent={this.state.errors.currency ? Intent.DANGER : Intent.SUCCESS}
                        label="Currency (of entered value)"
                        labelFor="currency"
                        labelInfo="*"
                        helperText={this.state.errors.currency ? this.state.errors.currency : ''}
                      >
                        <Select
                          isDisabled={!canEdit}
                          id="currency"
                          name="currency"
                          multi={false}
                          options={this.state.currencies}
                          onChange={option => this.changeCurrency(option)}
                          value={this.state.selectedCurrency}
                          onFocus={() => this.onFocusChanged('currency')}
                          onBlur={() => this.onFocusChanged(null)}
                        />
                      </FormGroup>
                    </Box>
                    <Box width={['100%','50%','50%']} pl={['0', '5px']}>
                      <FormGroup
                        intent={this.state.errors.value ? Intent.DANGER : Intent.SUCCESS}
                        label="Value"
                        labelFor="value"
                        labelInfo="*"
                        helperText={this.state.errors.value ? this.state.errors.value : ''}
                      >
                        <InputGroup
                          disabled={!canEdit}
                          name="value"
                          id="value"
                          large
                          onChange={(event) => this.handleUserInput(event)}
                          onFocus={() => this.onFocusChanged('value')}
                          onBlur={() => this.onFocusChanged(null)}
                          value={this.state.fields.value}
                        />
                      </FormGroup>

                    </Box>
                  </Flex>
                  }
                  <Callout className="mb20">
                    <Flex>
                      <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                        EUR Value
                      </Box>
                      <Box className="align-right" width={['100%','50%','50%']} pl={['5px', '5px']}>
                        <strong className="pl10 right">€{this.state.fields.eurValue}</strong>
                      </Box>
                    </Flex>
                  </Callout>

                  {(!recordId || this.state.uploads.length > 0) &&
                    <Flex>
                      <Box width={1 / 1}>
                        <FormGroup
                          intent={this.state.errors.files ? Intent.DANGER : Intent.SUCCESS}
                          label="Receipts"
                          helperText={this.state.errors.files ? this.state.errors.files : ''}
                        >
                          <Dropzone disabled={!canEdit} onDrop={acceptedFiles => this.handleDrop(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                              <div {...getRootProps()} className="dd-area" style={{marginLeft: -4, marginRight: -4}}>
                                <input {...getInputProps()} />
                                {canEdit &&
                                <React.Fragment>
                                  <p>click to select or drag 'n' drop copy of your receipt</p>
                                  <p>you can upload jpg, png of pdf format</p>
                                </React.Fragment>
                                }
                                <table>
                                  <tbody>
                                  {this.state.uploads.map((upload, index) => (
                                    <BusinessExpenseUploadItem canDelete={!canEdit}
                                                               onViewClick={(id) => this.viewFile(id)}
                                                               onDeleteClick={(id) => this.deleteFile(id)} key={index}
                                                               upload={upload}/>
                                  ))}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </Dropzone>
                        </FormGroup>
                      </Box>
                    </Flex>
                  }


                  {canEdit &&
                    <React.Fragment>
                      <Flex>
                        <Box width={1/1}>
                          <FormGroup
                            intent={this.state.errors.agree1 ? Intent.DANGER : Intent.SUCCESS}
                            helperText={this.state.errors.agree1 ? this.state.errors.agree1 : ''}
                          >
                            <Checkbox large checked={this.state.agree1} label="I confirm that this expense was incurred by me personally on the company's behalf. I also confirm that this expense was incurred wholly, exclusively and necessarily in the performance of my duties." onChange={(event) => this.handleCheckbox('agree1', event.currentTarget.checked)} />
                          </FormGroup>
                        </Box>
                      </Flex>
                      <Flex>
                        <Box width={1/1}>
                          <FormGroup
                            intent={this.state.errors.agree2 ? Intent.DANGER : Intent.SUCCESS}
                            helperText={this.state.errors.agree2 ? this.state.errors.agree2 : ''}
                          >
                            <Checkbox large checked={this.state.agree2} label="I confirm that I have kept the original receipt on file. Receipts may be requested by Revenue for verification." onChange={(event) => this.handleCheckbox('agree2', event.currentTarget.checked)} />
                          </FormGroup>
                        </Box>
                      </Flex>

                      <Flex>
                        <Box className="pt20 space-between" width={1 / 1}>
                          <Button large onClick={(event) => this.handleSubmit(event)} icon="add" intent="success"
                                  text={this.state.recordId ? 'Save' : 'Add New'}/>
                        </Box>
                      </Flex>
                    </React.Fragment>
                  }
                  {this.state.recordId && this.state.statusId === 1 &&
                  <Button large onClick={(event) => this.setState({deleteDialog: true})} icon="trash" intent="danger"
                          text={'Delete'}/>}
                </div>
              </Scrollbar>
            </div>
            <Dialog
              icon="info-sign"
              onClose={() => this.setState({deleteDialog: false})}
              title="Delete Business Expense"
              isOpen={this.state.deleteDialog}>
              <div className={Classes.DIALOG_BODY}>
                Are you sure?
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                  <Button onClick={() => this.setState({deleteDialog: false})}>Cancel</Button>
                  <Button intent="DANGER" onClick={() => this.handleDelete()}>Delete</Button>
                </div>
              </div>
            </Dialog>
          </React.Fragment>}
      </StyledForm>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
  }, dispatch);
}

export default (connect(null, matchDispatchToProps)(withRouter(BusinessExpenseForm)));
