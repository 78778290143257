import { companiesConstants } from '../constants';
const initialState = {
  loading: false,
  records: {},
  isEditorActive: false,
  isBankAccountEditorActive: false,
  isListActive: false,
  selectedRecord: null,
  recordToEdit: null,
  recordSaving: false,
  bankAccountsLoading: false,
  bankAccounts: [],
  bankStatementsLoading: false,
  bankStatements: [],
  employees: [],
  searchPhrase: localStorage.getItem('ammotio-companies-search'),
  filters: localStorage.getItem('ammotio-companies-filter') ? JSON.parse(localStorage.getItem('ammotio-companies-filter')) : []
}

export function companies(state = initialState, action, x) {
  switch (action.type) {
    case companiesConstants.COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
        records: {}
      };
    case companiesConstants.COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload,
      };
    case companiesConstants.COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        records: {}
      };
    case companiesConstants.COMPANY_REQUEST:
      return {
        ...state,
        recordLoading: true,
        selectedRecord: null
      };
    case companiesConstants.COMPANY_SUCCESS:
      return {
        ...state,
        recordLoading: false,
        selectedRecord: action.payload
      };
    case companiesConstants.COMPANY_ADD_REQUEST:
      return {
        ...state,
        recordSaving: true,
        records: [...state.records, action.payload]
      };
    case companiesConstants.COMPANY_ADD_SUCCESS:
      return {
        ...state,
        recordSaving: false,
        records: [...state.records, action.payload]
      };
    case companiesConstants.COMPANY_EDIT_REQUEST:
      return {
        ...state,
        recordSaving: true,
      };
    case companiesConstants.COMPANY_EDIT_SUCCESS:

      console.log(action.payload);
      const updatedRecords = state.records.map(record => action.payload.id === record.id ? action.payload : record);

      return {
        ...state,
        recordSaving: false,
        records: updatedRecords,
        selectedRecord: {
          ...state.selectedRecord,
          record: action.payload
        }
      };
    case companiesConstants.COMPANY_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        recordDeleting: true,
      };
    case companiesConstants.COMPANY_DELETE_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        recordDeleting: false,
        records: state.records.filter(record => action.payload !== record.id),
      };

    case companiesConstants.EMPLOYEES_REQUEST:
      return {
        ...state,
        employeesLoading: true,
        employees: []
      };
    case companiesConstants.EMPLOYEES_SUCCESS:
      return {
        ...state,
        employeesLoading: false,
        employees: action.payload
      };
    case companiesConstants.EMPLOYEES_FAILURE:
      return {
        ...state,
        error: action.error,
        employeesLoading: false,
        employees: []
      };

    case companiesConstants.BANK_ACCOUNTS_REQUEST:
      return {
        ...state,
        bankAccountsLoading: true,
        bankAccounts: []
      };
    case companiesConstants.BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        bankAccountsLoading: false,
        bankAccounts: action.payload
      };
    case companiesConstants.BANK_ACCOUNTS_FAILURE:
      return {
        ...state,
        error: action.error,
        bankAccountsLoading: false,
        bankAccounts: []
      };


    case companiesConstants.BANK_ACCOUNTS_ADD_REQUEST:
      return {
        ...state,
      };
    case companiesConstants.BANK_ACCOUNTS_ADD_SUCCESS:
      console.log(action);
      return {
        ...state,
        bankAccounts: [...state.bankAccounts, action.payload]
      };
    case companiesConstants.BANK_ACCOUNTS_ADD_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case companiesConstants.BANK_ACCOUNTS_EDIT_REQUEST:
      return {
        ...state,
      };
    case companiesConstants.BANK_ACCOUNTS_EDIT_SUCCESS:

      console.log(action.payload);

      return {
        ...state,
        bankAccounts: state.bankAccounts.map(record => action.payload.id === record.id ? action.payload : record)
      };
    case companiesConstants.BANK_ACCOUNTS_EDIT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case companiesConstants.BANK_ACCOUNTS_DELETE_REQUEST:
      return {
        ...state,
      };
    case companiesConstants.BANK_ACCOUNTS_DELETE_SUCCESS:
      console.log(action);

      return {
        ...state,
        bankAccounts: state.bankAccounts.filter(account=>account.id !== action.payload)
      };
    case companiesConstants.BANK_ACCOUNTS_DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case companiesConstants.BANK_STATEMENTS_REQUEST:
      return {
        ...state,
        bankStatementsLoading: true,
        bankStatements: []
      };
    case companiesConstants.BANK_STATEMENTS_SUCCESS:
      return {
        ...state,
        bankStatementsLoading: false,
        bankStatements: action.payload
      };
    case companiesConstants.BANK_STATEMENTS_FAILURE:
      return {
        ...state,
        bankStatementsLoading: false,
        error: action.error,
        bankStatements: []
      };

    case companiesConstants.COMPANIES_TOGGLE_EDITOR:
      return {
        ...state,
        isEditorActive: !state.isEditorActive,
        recordToEdit: action.payload
      };

    case companiesConstants.TOGGLE_BANK_ACCOUNT_EDITOR:
      return {
        ...state,
        isBankAccountEditorActive: !state.isBankAccountEditorActive,
        recordToEdit: action.payload
      };

    case companiesConstants.COMPANIES_TOGGLE_LIST:
      return {
        ...state,
        isListActive: !state.isListActive
      };

    case companiesConstants.SET_FILTER:
      if (action.value){
        localStorage.setItem('ammotio-companies-filter', JSON.stringify([
          ...state.filters,
          {name: action.name, operator: action.operator, value: action.value}
        ]));
        return {
          ...state,
          filters: [
            ...state.filters,
            {name: action.name, operator: action.operator, value: action.value}
          ]
        };
      } else {
        localStorage.setItem('ammotio-companies-filter', JSON.stringify(
          state.filters.filter(filter=>filter.name !== action.name)
        ));
        return {
          ...state,
          filters: state.filters.filter(filter=>filter.name !== action.name)
        };
      }
    case companiesConstants.SEARCH:
      localStorage.setItem('ammotio-companies-search', action.phrase);
      return {
        ...state,
        searchPhrase: action.phrase,
      };
    default:
      return state
  }
}
