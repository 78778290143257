import { TOAST } from '../';
import axios from "axios";

export function toast(message){
  return function(dispatch){

    dispatch({
      type: TOAST,
      payload: message,
    });
  }
}

export function socketToast(message){
  return function(dispatch){
    axios.post(`/toast`, message).then(result => {
      console.log(result);
    }).catch(error => {
      console.log(error);
    });
  }
}

