import React, {Component} from 'react';
import axios from 'axios';
import {Button, Classes, Dialog, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import {toast} from "../../../store/toaster/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

class ChangePassword extends Component {
  state = {
    errors: {},
    currentPassword: '',
    newPassword: '',
    newPassword2: '',
  }

  handlePasswordInput(event) {
    const {name, value} = event.currentTarget;
    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  validate() {
    let isValid = true;
    let errors = {};

    const {currentPassword, newPassword, newPassword2} = this.state;

    if (currentPassword.length === 0) {
      errors.currentPassword = 'This fields is required';
      isValid = false;
    } else {
      errors.currentPassword = undefined;
    }

    if (newPassword.length === 0) {
      errors.newPassword = 'This fields is required';
      isValid = false;
    } else {
      errors.newPassword = undefined;

      if (newPassword.length < 6) {
        errors.newPassword = 'Your password must contain at least 6 characters';
        isValid = false;
      } else {
        errors.newPassword = undefined;
      }
    }

    if (newPassword2.length === 0) {
      errors.newPassword2 = 'This fields is required';
      isValid = false;
    } else {
      errors.newPassword2 = undefined;

      if (newPassword !== newPassword2) {
        errors.newPassword2 = 'Fields mismatch';
        isValid = false;
      } else {
        errors.newPassword2 = undefined;
      }
    }


    this.setState({
      errors
    });

    return isValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      sent: true,
    }, () => {
      if (this.validate()) {
        const data = {
          current_password: this.state.currentPassword,
          new_password: this.state.newPassword
        }
        axios.post('profile/password', data).then(result => {
          this.setState({
            currentPassword: '',
            newPassword: '',
            newPassword2: '',
          }, () => {
            this.props.toast({message: 'Your password has been changed', intent: 'success'});
            this.props.onClose();
          });
        }).catch(error => {
          console.log(error.response);
          if (error.response.status === 422){
            this.setState({
              errors: {
                ...this.state.errors,
                currentPassword: error.response.data.errors[0],
              }
            });
          }
        });
      }
    })
  }

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <Dialog
        width={320}
        icon="info-sign"
        onClose={() => onClose()}
        title="Change Password"
        isOpen={isOpen}>
        <form onSubmit={(event) => this.handleSubmit(event)}>
          <div className={Classes.DIALOG_BODY}>
              <FormGroup
                intent={this.state.errors.currentPassword ? Intent.DANGER : Intent.NONE}
                className="mb5"
                label="Current Password"
                labelFor="currentPassword"
                labelInfo="*"
                helperText={this.state.errors.currentPassword && this.state.errors.currentPassword}
              >
                <InputGroup
                  type="password"
                  intent={this.state.errors.currentPassword ? Intent.DANGER : Intent.NONE}
                  name="currentPassword"
                  id="currentPassword"
                  large
                  onChange={(event) => this.handlePasswordInput(event)}
                  value={this.state.currentPassword}
                />
              </FormGroup>
              <FormGroup
                intent={this.state.errors.newPassword ? Intent.DANGER : Intent.NONE}
                className="mb5"
                label="New Password"
                labelFor="newPassword"
                labelInfo="*"
                helperText={this.state.errors.newPassword && this.state.errors.newPassword}
              >
                <InputGroup
                  type="password"
                  intent={this.state.errors.newPassword ? Intent.DANGER : Intent.NONE}
                  name="newPassword"
                  id="newPassword"
                  large
                  onChange={(event) => this.handlePasswordInput(event)}
                  value={this.state.newPassword}
                />
              </FormGroup>
              <FormGroup
                intent={this.state.errors.newPassword2 ? Intent.DANGER : Intent.NONE}
                className="mb5"
                label="Confirm New Password"
                labelFor="newPassword2"
                labelInfo="*"                   ß
                helperText={this.state.errors.newPassword2 && this.state.errors.newPassword2}
              >
                <InputGroup
                  type="password"
                  intent={this.state.errors.newPassword2 ? Intent.DANGER : Intent.NONE}
                  name="newPassword2"
                  id="newPassword2"
                  large
                  onChange={(event) => this.handlePasswordInput(event)}
                  value={this.state.newPassword2}
                />
              </FormGroup>

          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => onClose()}>Cancel</Button>
              <Button type="submit" intent="SUCCESS" icon="floppy-disk">Save</Button>
            </div>
          </div>
      </form>
      </Dialog>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
  }, dispatch);
}


export default (connect(null, matchDispatchToProps)(ChangePassword));
