import React, {Component} from 'react';
import SlidingPanel from "../../../components/slidingpanel";
import {Button, Classes, Dialog, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import axios from "axios";

class AddressEditor extends Component {
  state = {
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    country: '',
    errors: {},
    saving: false,
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {record} = nextProps;
    if (record){
      this.setState({
        address1: record.address_1,
        address2: record.address_2,
        address3: record.address_3,
        address4: record.address_4,
      });
    }
  }

  handleChange(event){
    const {name, value} = event.currentTarget;
    this.setState({
      ...this.state,
      [name]: value,
    });

  }

  handleSubmit(event){
    event.preventDefault();

    this.setState({
      saving: true,
    })

    const data = {
      address_1: this.state.address1,
      address_2: this.state.address2,
      address_3: this.state.address3,
      address_4: this.state.address4
    }

    axios.post('profile/address', data).then(result => {
      this.setState({
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        saving: false,
      }, () => {
        this.props.onUpdate();
        this.props.onClose();
      });
    }).catch(error => {
      this.setState({
        saving: false,
      })
    });
  }


  render() {
    const {isOpen, onClose} = this.props;
    return (
      <SlidingPanel width="520px" isOpen={isOpen} title='Address' onRequestClose={() => onClose()}>
        <form onSubmit={(event) => this.handleSubmit(event)}>
            <FormGroup
              intent={this.state.errors.address1 ? Intent.DANGER : Intent.NONE}
              className="mb5"
              label="Address 1"
              labelFor="address1"
              labelInfo="*"
              helperText={this.state.errors.address1 && this.state.errors.address1}
            >
              <InputGroup
                name="address1"
                id="address1"
                large
                onChange={(event) => this.handleChange(event)}
                value={this.state.address1}
              />
            </FormGroup>
            <FormGroup
              className="mb5"
              label="Address 2"
              labelFor="address2"
              labelInfo="*"
              helperText={this.state.errors.address2 && this.state.errors.address2}
            >
              <InputGroup
                name="address2"
                id="address2"
                large
                onChange={(event) => this.handleChange(event)}
                value={this.state.address2}
              />
            </FormGroup>
            <FormGroup
              className="mb5"
              label="Address 3"
              labelFor="address3"
              labelInfo="*"
              helperText={this.state.errors.address3 && this.state.errors.address3}
            >
              <InputGroup
                name="address3"
                id="address3"
                large
                onChange={(event) => this.handleChange(event)}
                value={this.state.address3}
              />
            </FormGroup>
            <FormGroup
              className="mb5"
              label="Address 4"
              labelFor="address4"
              labelInfo="*"
              helperText={this.state.errors.address4 && this.state.errors.address4}
            >
              <InputGroup
                name="address4"
                id="address4"
                large
                onChange={(event) => this.handleChange(event)}
                value={this.state.address4}
              />
            </FormGroup>
          <Button loading={this.state.saving} large className="mt20" type="submit" intent="SUCCESS" icon="floppy-disk">Save</Button>

        </form>
      </SlidingPanel>
    );
  }
}

export default AddressEditor;
