import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const StyledWidget = styled.div`
  display: flex;
  width: 25%;
  padding: 5px;
  
  @media screen and (max-width: 800px) {
    width: 50%;
  }
  
    @media screen and (max-width: 500px) {
    width: 100%;
  }
  
  &>div {
    padding: 0px;
    flex: 1;
    min-height: 180px;
    border-radius: 4px;
    display: flex;
    flex-flow: column;
    align-content: center;
    justify-content: center;
    background-color: #edeef0;
    color: #000;
    position: relative;
    
    .value {
      left: 0;
      top: 50px;
      position: absolute;
      text-align: center;
      font-size: 28px;
      width: 100%;
      font-weight: 700;
    }
    
    .date {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 70px;
      text-align: center;
    }
    
    .title {
      left: 0;
      top: 20px;
      position: absolute;
      width: 100%;
      text-align: center;
      font-weight: 500;
    }
    
    .footer {
      text-align: center;
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 30px;
      margin: 10px -10px;
      padding: 10px;
      border-top: 1px solid #fff;  
    }
  }
`

class Widget extends Component {
  render() {
    const {title, value, date, footer, symbolBefore, symbolAfter} = this.props;
    return (
      <StyledWidget>
        <div>
          <div className="title">{title}</div>
          <div className="value">{symbolBefore}{value}{symbolAfter}</div>
          <div className="date">{date}</div>
          <div className="footer">
            <Link to={footer.link}>{footer.title}</Link>
          </div>
        </div>
      </StyledWidget>
    );
  }
}

export default Widget;
