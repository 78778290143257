import React, {Component} from 'react';
import Months from './Months';
import ExpensesList from "./ExpensesList";
import {connect} from "react-redux";
import SlidingPanel from "../../../../components/slidingpanel";
import BusinessExpenseForm from "./BusinessExpenseForm";
import TravelExpenseForm from "./TravelExpenseForm";
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {getExpenses, getExpensesSummary} from "../../../../store/contractors/actions";

class Expenses extends Component {
  state = {
    expense: null,
    travelEditor: false,
    businessEditor: false,
  }

  componentDidMount() {
    const { _expense_type } = this.props.match.params;

    this.setState({
      travelEditor: _expense_type === 'travel',
      businessEditor: _expense_type === 'business'
    })
  }

  showExpense (expense) {
    this.setState({
      expense,
      businessEditor: expense.type === "business",
      travelEditor: expense.type === "travel"
    });
  }

  addExpense(type) {
    this.setState({
      businessEditor: type === "business",
      travelEditor: type === "travel",
    });
  }

  onEdited(keepEditorOpen, type) {
    const { getExpensesSummary, getExpenses, match } = this.props;

    this.setState({
      expense: null,
      businessEditor: type === "business" && !!keepEditorOpen,
      travelEditor: type === "travel" && !!keepEditorOpen
    }, () => {
      getExpensesSummary(match.params._id, r => {});
      getExpenses(
        match.params._id,
        match.params._year,
        match.params._month,
        r => {}
      );
    });
  }

  render() {
    const { _id, _year, _month } = this.props.match.params;
    const { history } = this.props;
    const {
      businessEditor,
      travelEditor,
      expense
    } = this.state;

    return (
      <React.Fragment>
          <div className="split">
            <div className="left">
              <Months
                {...this.props}
                addExpense={type => this.addExpense(type)} />
            </div>
            <div className="right">
              <ExpensesList
                {...this.props}
                showExpense={expense => this.showExpense(expense)}/>
            </div>
          </div>
          <SlidingPanel
            width="520px"
            isOpen={businessEditor}
            title="Business Expense"
            onRequestClose={() => { 
              this.setState({
                expense: null,
                businessEditor: false
              });
              history.push(
                _year && _month
                  ? `/contractors/${_id}/expenses/${_year}/${_month}`
                  : `/contractors/${_id}/expenses/`
              );
            }}>
            <BusinessExpenseForm
              match={this.props.match}
              closeBusinessEditor={() =>
                this.setState({
                  expense: null,
                  businessEditor: false
                })
              }
              onBusinessEdited={status =>
                this.onEdited(status)
              }
              userId={_id}
              expense={expense} />
          </SlidingPanel>
          <SlidingPanel 
            width="520px"
            isOpen={travelEditor}
            title="Travel Expense"
            onRequestClose={() => {
              this.setState({
                expense: null,
                travelEditor: false
              })
              history.push(
                _year && _month
                  ? `/contractors/${_id}/expenses/${_year}/${_month}`
                  : `/contractors/${_id}/expenses/`
              );
            }}>
            <TravelExpenseForm
              match={this.props.match}
              userId={_id}
              expense={expense}
              onEdited={status =>
                this.onEdited(status)
              }/>
          </SlidingPanel>

      </React.Fragment>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    getExpenses,
    getExpensesSummary,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    expensesSummaryLoading: state.contractors.expensesSummaryLoading,
    expensesLoading: state.contractors.expensesLoading,
    expenses: state.contractors.expenses,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(Expenses));
