import { TOAST } from '../';

export function toaster(state = {}, action) {
  switch (action.type) {
    case TOAST:
      return {
        toast:action.payload
      };
    default:
      return state
  }
}
