import React, {Component} from 'react';
import Switch from "react-switch";
import {Collapse} from "react-collapse";
import {Box, Flex} from "@rebass/grid";
import {FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import Select from "react-select";

class MileageForm extends Component {

  constructor(props) {
    super(props);

    const {expense} = this.props;

    this.state = {
      carUsed: expense && expense.use_car ? true : false,
      engineSizes: [],
      engineSize: expense && expense.engineSize ? expense.engineSize : null,
      engineSizeInvalid: '',
      mileageToDate: 0,
      distanceTraveled: expense && expense.distanceTraveled ? expense.distanceTraveled : '',
      distanceTraveledInvalid: '',
      carBegin: expense && expense.carBegin ? expense.carBegin : '',
      carBeginInvalid: '',
      carEnd: expense && expense.carEnd ? expense.carEnd : '',
      carEndInvalid: '',
      carModel: expense && expense.carModel ? expense.carModel : '',
      carModelInvalid: '',
      generalError: '',
      mileageBreakdown: false,
      errors: {

      },
      fields: {
        use_car: 0,
        engine_size_id: null,
        motor_distance: '',
        motor_start_location: '',
        motor_end_location: '',
        car_make_model: '',
      }
    }
  }

  componentDidMount() {
    const {engineSizes} = this.props;


    const formattedEngineSizes = this.formatEngineSizes(engineSizes);

    this.setState({
      engineSizes: formattedEngineSizes,
    })

  }

  formatEngineSizes(engineSizes) {
    const formattedEngineSizes = [];
    engineSizes.map(engineSize => {
      const eSize = engineSize;
      eSize.label = engineSize.name;
      eSize.value = engineSize.id;
      formattedEngineSizes.push(eSize);
      return engineSize;
    });
    return formattedEngineSizes;
  }

  onCarUsedChanged(checked) {
    this.setState({
      carUsed: checked,
      fields: checked ? {...this.state.fields, use_car: 1} : {
        ...this.state.fields,
        use_car: 0,
        motor_distance: '',
        motor_start_location: '',
        motor_end_location: '',
        car_make_model: '',
      },
    }, () => {
      this.state.sent && this.validate();
    });
  }

  changeEngineSize(engineSize) {
    console.log(engineSize);
    this.setState({
      engineSize,
      fields: { ...this.state.fields, engine_size_id: engineSize.value},
      errors: { ...this.state.errors, engine_size_id: undefined },
    }, () => this.calculateMileage());
  }


  onFocusChanged(fieldName) {
    if (this.props.onFocusChanged) {
      this.props.onFocusChanged(fieldName);
    }
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (!value.length) {
      this.setState({
        fields: {...this.state.fields, [name]: value},
        errors: {...this.state.errors, [name]: 'This Field is required.'}
      });
    } else {
      this.setState({
        fields: {...this.state.fields, [name]: value},
        errors: {...this.state.errors, [name]: undefined}
      });
    }
  }

  onDistanceChanged(event) {
    const value = event.target.value;
    if (value.length === 0) {
      this.setState({
        fields: { ...this.state.fields, motor_distance: 0},
        errors: { ...this.state.errors, motor_distance: 'This Field is required' },
      },() => this.calculateMileage());
    } else if (isNaN(value)) {
      this.setState({
        errors: { ...this.state.errors, motor_distance: 'Only numbers allowed' },
      }, () => this.calculateMileage());
    } else if (value === '0') {
      this.setState({
        errors: { ...this.state.errors, motor_distance: 'Zero is not allowed' },
      }, () => this.calculateMileage());
    } else {
      this.setState({
        fields: { ...this.state.fields, motor_distance: value},
        errors: { ...this.state.errors, motor_distance: undefined },
      }, () => this.calculateMileage());
    }
  }

  calculateMileage() {
    if (this.state.engineSize && this.state.distanceTraveled.length) {
      const mileageToDate = this.state.mileageToDate;
      const distance = parseInt(this.state.distanceTraveled);
      const total = mileageToDate + distance;
      let deduction = 0;
      let finalValue = 0;

      for (let i = 1; i < 5; i++) {
        const limit = this.state.engineSize[`band${i}limit`];
        const rate = this.state.engineSize[`band${i}rate`];
        if (mileageToDate < limit) {
          if (total < limit) {
            finalValue += (distance - deduction) * rate;
            deduction += (distance - deduction);
          } else {
            const kmUsed = limit - mileageToDate - deduction;
            if (kmUsed < 0) {
              finalValue += (kmUsed * rate);
              deduction += (limit - deduction);
            } else {
              finalValue += (kmUsed * rate);
              deduction += kmUsed;
            }
          }
        }
      }
      return finalValue;
    }
  }


  render() {
    const {editable} = this.props;

    return (
      <div className="card">
        <div className="card-heading">
          <div className="card-title" onClick={() => {
            console.log('click');
          }}>
            <div>Private car used for this business</div>
            <Switch
              disabled={!editable}
              onColor="#5db8f1"
              offColor="#2c3c59"
              checkedIcon={false}
              uncheckedIcon={false}
              width={48}
              height={24}
              onChange={(checked) => this.onCarUsedChanged(checked)}
              checked={this.state.carUsed}
              id="normal-switch"
            />
          </div>
        </div>
        <Collapse isOpened={this.state.carUsed}>
          <div className="card-body">
            <Flex flexWrap={'wrap'}>
              <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                <FormGroup
                  intent={this.state.errors.engine_size_id ? Intent.DANGER : Intent.SUCCESS}
                  label="Engine Size"
                  labelFor="engineSize"
                  labelInfo="*"
                  helperText={this.state.errors.engine_size_id && this.state.errors.engine_size_id}
                >
                  <Select
                    isDisabled={!editable}
                    id="engineSize"
                    name="engineSize"
                    multi={false}
                    options={this.state.engineSizes}
                    onChange={option => this.changeEngineSize(option)}
                    value={this.state.engineSize}
                    onFocus={(event) => this.onFocusChanged(event)}
                    onBlur={() => this.onFocusChanged(null)}
                  />
                </FormGroup>
              </Box>
              <Box width={['100%', '50%', '50%']} pl={['0', '5px']}>
                <FormGroup
                  intent={this.state.errors.motor_distance ? Intent.DANGER : Intent.SUCCESS}
                  label="Distance traveled(km)"
                  labelFor="motor_distance"
                  labelInfo="*"
                  helperText={this.state.errors.motor_distance && this.state.errors.motor_distance}
                >
                  <InputGroup
                    disabled={!editable}
                    name="motor_distance"
                    id="motor_distance"
                    large
                    onChange={(event) => this.onDistanceChanged(event)}
                    onFocus={(event) => this.onFocusChanged(event)}
                    onBlur={() => this.onFocusChanged(null)}
                    value={this.state.fields.motor_distance}
                  />
                </FormGroup>
              </Box>
            </Flex>
            <Flex flexWrap={'wrap'}>
              <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                <FormGroup
                  intent={this.state.errors.motor_start_location ? Intent.DANGER : Intent.SUCCESS}
                  label="Where the car journey begin?"
                  labelFor="motor_start_location"
                  labelInfo="*"
                  helperText={this.state.errors.motor_start_location && this.state.errors.motor_start_location}
                >
                  <InputGroup
                    disabled={!editable}
                    name="motor_start_location"
                    id="motor_start_location"
                    large
                    onChange={(event) => this.handleUserInput(event)}
                    onFocus={(event) => this.onFocusChanged(event)}
                    onBlur={() => this.onFocusChanged(null)}
                    value={this.state.fields.motor_start_location}
                  />
                </FormGroup>
              </Box>
              <Box width={['100%', '50%', '50%']} pl={['0', '5px']}>
                <FormGroup
                  intent={this.state.errors.motor_end_location ? Intent.DANGER : Intent.SUCCESS}
                  label="Where the car journey end?"
                  labelFor="motor_end_location"
                  labelInfo="*"
                  helperText={this.state.errors.motor_end_location && this.state.errors.motor_end_location}
                >
                  <InputGroup
                    disabled={!editable}
                    name="motor_end_location"
                    id="motor_end_location"
                    large
                    onChange={(event) => this.handleUserInput(event)}
                    onFocus={(event) => this.onFocusChanged(event)}
                    onBlur={() => this.onFocusChanged(null)}
                    value={this.state.fields.motor_end_location}
                  />
                </FormGroup>
              </Box>
            </Flex>
            <Flex flexWrap={'wrap'}>
              <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                <FormGroup
                  intent={this.state.errors.car_make_model ? Intent.DANGER : Intent.SUCCESS}
                  label="Make & Model of the vehicle"
                  labelFor="car_make_model"
                  labelInfo="*"
                  helperText={this.state.errors.car_make_model && this.state.errors.car_make_model}
                >
                  <InputGroup
                    disabled={!editable}
                    name="car_make_model"
                    id="car_make_model"
                    large
                    onChange={(event) => this.handleUserInput(event)}
                    onFocus={(event) => this.onFocusChanged(event)}
                    onBlur={() => this.onFocusChanged(null)}
                    value={this.state.fields.car_make_model}
                  />
                </FormGroup>
              </Box>
            </Flex>
          </div>
          <div className="card-footer">
            <Flex flexWrap={'wrap'}>
              <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                Mileage value:
              </Box>
              <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                Calculation Breakdown:<br/>
                Mileage to date: {this.state.mileageToDate}<br/>
                Mileage entered: {this.state.distanceTraveled}
              </Box>
            </Flex>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default MileageForm;
