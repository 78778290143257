import React, { Component } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { DateInput, TimePrecision } from "@blueprintjs/datetime";
import Switch from 'react-switch';
import moment from "moment";
import { FormGroup, InputGroup, Intent, Button, TextArea, Checkbox, NumericInput, Dialog, Classes, Tag } from '@blueprintjs/core';
import Select from 'react-select';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { Collapse } from 'react-collapse';
import axios from 'axios';
import { types, days } from './data';

import { bindActionCreators } from "redux";
import { toast } from "../../../../store/toaster/actions";
import { connect } from "react-redux";

const StyledExpensesForm = styled.form`
  height: 100%;
`;

const statuses = {
  status_1: 'Awaiting Approval',
  status_2: 'Approved',
  status_3: 'Rejected',
  status_4: 'Deleted',
  status_5: 'Paid',
};

class TravelExpenseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {

      subsistenceClaim: props.record && props.record.subsistenceClaim ? true : false,
      subsistenceRate: null,
      subsistenceRateValue: 0,
      startDate: props.record && props.record.startDate ? props.record.startDate : null,
      startDateInvalid: '',
      endDate: props.record && props.record.endDate ? props.record.endDate : null,
      endDateInvalid: '',
      durationDays: 0,
      durationHours: 0,
      serverOperation: null,

      location: props.record && props.record.location ? props.record.location : null,
      locationInvalid: '',
      additionalAccommodation: props.record && props.record.additionalAccommodation ? props.record.additionalAccommodation : '',
      carUsed: props.record && props.record.carUsed ? true : false,
      tripBegin: props.record && props.record.tripBegin ? props.record.tripBegin : '',
      tripBeginInvalid: '',
      tripEnd: props.record && props.record.tripEnd ? props.record.tripEnd : '',
      tripEndInvalid: '',


      engineSizeInvalid: '',
      mileageToDate: 0,
      distanceTraveled: props.record && props.record.distanceTraveled ? props.record.distanceTraveled : '',
      distanceTraveledInvalid: '',
      carBegin: props.record && props.record.carBegin ? props.record.carBegin : '',
      carBeginInvalid: '',
      carEnd: props.record && props.record.carEnd ? props.record.carEnd : '',
      carEndInvalid: '',
      carModel: props.record && props.record.carModel ? props.record.carModel : '',
      carModelInvalid: '',

      subsistenceBreakdown: true,
      mileageBreakdown: false,
      currency: props.record && props.record.currency ? props.record.currency : null,




      statusName: '',
      recordId: null,
      loading: true,

      locations: [],
      engineSizes: [],

      sent: false,
      statusId: null,
      deleteDialog: false,
      rejectDialog: false,
      rejection_reason: '',
      rejectionSent: false,
      eurValue: 0,
      fields: {
        incurred: null,
        start_date: null,
        end_date: null,
        subsistenceClaim: false,
        location: null,
        additionalAccommodation: null,
        start_location: null,
        tripEnd: null,
        carUsed: false,
        engineSize: null,
        distanceTraveled: 0,
        carBegin: null,
        carEnd: null,
        carModel: null,
        userId: null,
        nights: 1
      },
      errors: {},
      generalError: '',
    };
  }

  componentDidMount() {
    const { expense, userId } = this.props;
    let expenseId = expense && expense.id ? expense.id : 0;

    if (this.props.match.params._expense_id > 0) {
      expenseId = this.props.match.params._expense_id;
    }

    axios.get(`contractors/${userId}/expenses/travel/${expenseId}`)
      .then(result => {
        const { record, locations, engineSizes } = result.data;

        const formattedLocations = this.formatLocations(locations);
        const formattedEngineSizes = this.formatEngineSizes(engineSizes);
        const startDate = record && record.start_time ? moment(record.start_time).toDate() : null;
        const endDate = record && record.end_time ? moment(record.end_time).toDate() : null;
        const location = record ? formattedLocations.find(l => l.value == record.location) : null;
        const engineSize = record && record.engine_size_id && record.engine_size_id > 0 ? record.engine_size_id : 0;
        const selectedEngine = engineSize ? formattedEngineSizes.find(el => el.value === engineSize) : null;

        this.setState({
          loading: false,
          statusName: record && record.status_id ? statuses[`status_${record.status_id}`] : '',
          locations: formattedLocations,
          engineSizes: formattedEngineSizes,

          recordId: expenseId != 0 ? record.id : undefined,
          statusId: expenseId != 0 ? record.status_id : undefined,
          startDate,
          endDate,
          tripBegin: record && record.start_location ? record.start_location : '',
          rejection_reason: record && record.rejected_reason ? record.rejected_reason : '',
          tripEnd: record && record.end_location ? record.end_location : '',
          subsistenceClaim: record && record.location,
          location,
          selectedEngine,
          currency: record && record.currency ? record.currency : 'EUR',
          carUsed: record && record.use_car && record.use_car > 0,
          engineSize: selectedEngine,
          distanceTraveled: record.motor_distance,
          carBegin: record.motor_start_location,
          carEnd: record.motor_end_location,
          carModel: record.car_make_model,
          record,
          fields: {
            ...record,
            location_id: record && record.location_id ? record.location_id : null,
            location: record && record.location ? record.location : '',
            start_date: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
            end_date: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
            start_location: record && record.start_location ? record.start_location : '',
            end_location: record && record.end_location ? record.end_location : '',
          },
          mileageToDate: result.data.mileageToDate,
        }, () => {
          if (record) {
            this.recalculate()
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  formatLocations = locations =>
    locations
      ? locations.map(l => ({
        label: `${l.country} (${l.name})`,
        value: l.id,
        over5HoursRate: l.hours_5,
        over10HoursRate: l.hours_10,
        over10To24HoursRate: l.overnight_2,
        overnightRate: l.overnight,
        additionalAccommodation: l.additional_accommodation,
        additionalOvernightSubsistence: l.additionalOvernightSubsistence,
        additionalOvernightSubsistenceCurrency: l.additionalOvernightSubsistenceCurrency,
        additionalOvernightSubsistenceValue: l.currency_2_id,
      }))
      : []

  formatEngineSizes(engineSizes) {
    const formattedEngineSizes = [];
    engineSizes.map(engineSize => {
      const eSize = engineSize;
      eSize.label = engineSize.name;
      eSize.value = engineSize.id;
      formattedEngineSizes.push(eSize);
      return engineSize;
    });
    return formattedEngineSizes;
  }

  isClaim() {
    const { sent, subsistenceClaim, carUsed } = this.state;
    if (sent) {
      this.setState({
        generalError: !subsistenceClaim && !carUsed
          ? 'Select At least one option bellow'
          : ''
      });
    }
  }

  onSubsistenceClaimChanged(checked) {
    this.setState({
      subsistenceClaim: checked,
    }, () => this.isClaim());
  }

  onCarUsedChanged(checked) {
    this.setState({
      carUsed: checked,
    }, () => this.isClaim());
  }

  handleTypeChange = value => {
    const { location, fields } = this.state;
    const subsistence = location ? this.getBaseAmount(location, value) : 0;

    this.setState({
      fields: {
        ...fields,
        type_id: value,
        subsistence
      }
    });
  }

  changeLocation(location) {
    const { fields } = this.state;
    const subsistence = this.getBaseAmount(location, fields.type_id);

    this.setState({
      location,
      locationInvalid: '',
      fields: {
        ...fields,
        subsistence,
        location: location.value
      }
    }, () => this.recalculate());
  }

  getBaseAmount = (location, type) => {
    if (!location || typeof type === 'undefined') return undefined;

    switch (type) {
      case 0:
        return location.over5HoursRate ? location.over5HoursRate : 0;
      case 1:
        return location.over10HoursRate ? location.over10HoursRate : 0;
      case 2:
        return location.over10To24HoursRate ? location.over10To24HoursRate : 0;
      default:
        return location.overnightRate ? location.overnightRate : 0;
    }
  }

  recalculate() {
    const { fields } = this.state;

    this.setState({
      subsistenceRate: types[fields.type_id] && types[fields.type_id].label,
      subsistenceRateValue: fields.subsistence || 0,
      fields: {
        ...fields,
        total: fields.nights && fields.subsistence ? (fields.subsistence * fields.nights).toFixed(2) : 0
      }
    });
  }

  onStartChanged(e) {
    try {
      const startDate = e;
      this.setState({
        startDateInvalid: '',
        startDate
      });
    } catch (exception) {
      this.setState({
        startDateInvalid: 'This Field is required'
      });
      return;
    }
  }

  onEndChanged(e) {
    try {
      const endDate = e;
      this.setState({
        endDateInvalid: '',
        endDate
      });
    } catch (exception) {
      this.setState({
        endDateInvalid: 'This Field is required'
      });
      return;
    }
  }

  onFocusChanged(fieldName) {
    if (this.props.onFocusChanged) {
      this.props.onFocusChanged(fieldName);
    }
  }

  // onAdditionalAccommodationChanged(event) {
  //   const value = event.target.value;
  //   if (isNaN(value)) {
  //     this.setState({
  //       errors: {
  //         ...this.state.errors,
  //         additionalAccommodationInvalid: 'Only numbers allowed',
  //       }

  //     }, () => this.recalculate());
  //   } else {
  //     this.setState({
  //       errors: {
  //         ...this.state.errors,
  //         additionalAccommodationInvalid: null,
  //       }
  //     }, () => this.recalculate());
  //   }
  // }

  handleInputChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: (!value || !value.length) && 'This Field is required.'
      }
    });
  }

  handleChange = (key, value) => {
    this.setState({ fields: { ...this.state.fields, [key]: value } }, this.recalculate);
  }

  changeEngineSize = engineSize => {
    this.setState({
      engineSize,
      errors: { ...this.state.errors, engineSize: undefined }
    }, () => this.calculateMileage());
  }

  calculateMileage() {
    if (this.state.engineSize && this.state.distanceTraveled.length) {

      const mileageToDate = this.state.mileageToDate;
      const distance = parseInt(this.state.distanceTraveled);
      const total = mileageToDate + distance;
      let deduction = 0;
      let finalValue = 0;

      for (let i = 1; i < 5; i++) {
        const limit = this.state.engineSize[`band${i}limit`];
        const rate = this.state.engineSize[`band${i}rate`];

        if (mileageToDate < limit) {
          if (total < limit) {
            finalValue += (distance - deduction) * rate;
            deduction += (distance - deduction);
          } else {
            const kmUsed = limit - mileageToDate - deduction;
            if (kmUsed < 0) {
              finalValue += (kmUsed * rate);
              deduction += (limit - deduction);
            } else {
              finalValue += (kmUsed * rate);
              deduction += kmUsed;
            }
          }
        }
      }
    }
  }

  onDistanceChanged(event) {
    const value = event.target.value;
    if (value.length === 0) {
      this.setState({
        distanceTraveled: '',
        distanceTraveledInvalid: 'This field is required',
      }, () => this.calculateMileage());
    } else if (isNaN(value)) {
      this.setState({
        distanceTraveledInvalid: 'Only numbers allowed',
      }, () => this.calculateMileage());
    } else if (value === '0') {
      this.setState({
        distanceTraveledInvalid: 'Zero is not allowed',
      }, () => this.calculateMileage());
    } else {
      this.setState({
        distanceTraveled: value,
        distanceTraveledInvalid: '',
      }, () => this.calculateMileage());
    }
  }

  submitForm(event) {
    event.preventDefault();
    this.setState({
      sent: true,
    }, () => this.handleSubmit());
  }

  handleSubmit() {
    const { history } = this.props;
    const { fields } = this.state;
    let hasErrors = false;

    const errors = {
      startTime: null,
      endTime: null,
      description: null,
      currency: null,
      value: null,
      files: null,
      agree1: null,
      agree2: null,
    };

    if (moment(this.state.fields.start_time).isValid()) {
      errors.startTime = null;
    } else {
      errors.startTime = 'This field is required.'
      hasErrors = true;
    }

    if (moment(this.state.fields.end_time).isValid()) {
      errors.endTime = null;
    } else {
      errors.endTime = 'This field is required.'
      hasErrors = true;
    }

    if (!this.state.subsistenceClaim && !this.state.carUsed) {
      errors.generalError = 'Select At least one option bellow'
      hasErrors = true;
    } else {
      errors.generalError = null;
    }

    if (this.state.subsistenceClaim) {
      if (!this.state.location) {
        this.setState({
          locationInvalid: 'This field is required.'
        });
        hasErrors = true;
      }

      if (!fields.nights) {
        errors.nights = 'This field is required.';
        console.log('1');
        hasErrors = true;
      }

      // if (!fields.subsistence || fields.subsistence == 0) {
      //   errors.subsistence = 'This field is required.';
      //   console.log('2');
      //   hasErrors = true;
      // }

      if (!this.state.tripBegin || this.state.tripBegin.length === 0) {
        this.setState({
          tripBeginInvalid: 'This field is required.'
        });
        hasErrors = true;
      }

      if (!this.state.tripBegin || this.state.tripBegin.length === 0) {
        this.setState({
          tripBeginInvalid: 'This field is required.'
        });
        hasErrors = true;
      }

      if (!this.state.tripEnd || !this.state.tripEnd.length) {
        this.setState({
          tripEndInvalid: 'This field is required.'
        });
        hasErrors = true;
      }
    }

    if (this.state.carUsed) {
      if (!this.state.engineSize) {
        hasErrors = true;
        this.setState({ engineSizeInvalid: 'This field is required.' });
      }
      if (this.state.distanceTraveled === '' && this.state.distanceTraveled <= 0) {
        hasErrors = true;
        this.setState({ distanceTraveledInvalid: 'This field is required.' });
      }
      if (!this.state.carBegin || !this.state.carBegin.length) {
        hasErrors = true;
        this.setState({ carBeginInvalid: 'This field is required.' });
      }
      if (!this.state.carEnd || !this.state.carEnd.length) {
        hasErrors = true;
        this.setState({ carEndInvalid: 'This field is required.' });
      }
      if (!this.state.carModel || !this.state.carModel.length) {
        hasErrors = true;
        this.setState({ carModelInvalid: 'This field is required.' });
      }
    }

    if (hasErrors) {
      this.setState({ errors });
      return;
    }

    this.setState({
      locationInvalid: '',
      tripBeginInvalid: '',
      tripEndInvalid: '',
      engineSizeInvalid: '',
      distanceTraveledInvalid: '',
      carBeginInvalid: '',
      carEndInvalid: '',
      carModelInvalid: '',
      errors: {}
    });

    const record = {
      ...fields,
      incurred: this.state.startDate,
      start_time: this.state.startDate,
      location: this.state.location ? this.state.location.value : null,
      additionalAccommodation: this.state.additionalAccommodation && this.state.additionalAccommodation.length ? this.state.additionalAccommodation : null,
      start_location: this.state.tripBegin && this.state.tripBegin.length ? this.state.tripBegin : null,
      end_location: this.state.tripEnd && this.state.tripEnd.length ? this.state.tripEnd : null,
      use_car: this.state.carUsed,
      engine_size_id: this.state.engineSize && this.state.engineSize.id,
      motor_distance: this.state.distanceTraveled && this.state.distanceTraveled > 0 ? parseInt(this.state.distanceTraveled, 10) : 0,
      motor_start_location: this.state.carBegin && this.state.carBegin.length ? this.state.carBegin : null,
      motor_end_location: this.state.carEnd && this.state.carEnd.length ? this.state.carEnd : null,
      car_make_model: this.state.carModel && this.state.carModel.length ? this.state.carModel : null,
      userId: this.props.userId ? this.props.userId : null,
    };

    if (fields.id) {
      record.id = fields.id;
      const { userId } = this.props;
      const { record: r } = this.state;
      console.log(r);
      axios.post(`/contractors/${userId}/expenses/travel/${record.id}`, { ...r, ...record })
        .then(result => {
          this.props.onEdited();
          this.props.toast({ message: 'Travel expense as been successfully updated', intent: 'success' })
        })
        .catch(e => this.props.toast({ message: 'Something went wrong', intent: 'danger' }));
    } else {
      axios.post('/expenses/travel', record)
        .then(result => {
          this.props.onEdited();
          this.props.toast({ message: 'Travel expense as been successfully added', intent: 'success' })
        })
        .catch(e => this.props.toast({ message: 'Something went wrong', intent: 'danger' }));
    }
  }

  handleDelete() {
    const { userId } = this.props;
    axios.delete(`/contractors/${userId}/expenses/travel/${this.state.recordId}`)
      .then(result => {
        this.props.onEdited();
        this.props.toast({
          message: 'Business expense as been successfully deleted',
          intent: 'success'
        });
      })
      .catch(e => this.props.toast({ message: 'Something went wrong', intent: 'danger' }));
  }

  validateRejection() {
    let hasError = false;

    const errors = {
      rejection_reason: null,
    };

    if (this.state.rejection_reason.length) {
      errors.rejection_reason = null;
    } else {
      errors.rejection_reason = 'This Field is required.';
      hasError = true;
    }

    this.setState({
      errors
    })

    return !hasError;
  }

  handleReject() {
    if (this.validateRejection()) {
      this.setStatus(3, { rejected_reason: this.state.rejection_reason });
    }
  }

  setStatus(status, data = {}) {
    this.setState({
      serverOperation: status,
    });

    const { userId, expense } = this.props;
    const { _expense_id } = this.props.match.params;
    const { record } = this.state;

    axios.post(`/contractors/${userId}/expenses/travel/${expense ? expense.id : _expense_id}`, { ...record, status_id: status, ...data })
      .then(result => {
        this.setState({
          serverOperation: null,
          statusId: parseInt(status),
          statusName: statuses[`status_${status}`],
          rejectDialog: false
        }, () => {
          this.props.toast({ message: 'Travel expense status has been successfully updated', intent: 'success' })
        });
      })
      .catch(e => this.props.toast({ message: 'Something went wrong', intent: 'danger' }))
      .finally(() => {
        this.setState({ serverOperation: null });
      });
  }

  render() {
    const overNightRate = this.state.location && this.state.location.overnightRate ? this.state.location.overnightRate : 0;
    const overNightTotal = overNightRate * this.state.durationDays;
    const additionalOvernightSubsistence = this.state.location && this.state.location.additionalOvernightSubsistence ? this.state.location.additionalOvernightSubsistenceValue * this.state.durationDays : 0;
    const additionalAccommodation = (this.state.location && this.state.location.additionalAccommodation && this.state.additionalAccommodation.length) ? parseFloat(this.state.additionalAccommodation) : 0;
    const subsistenceTotal = "€" + (overNightTotal + this.state.subsistenceRateValue + additionalOvernightSubsistence + additionalAccommodation);

    const {
      statusId,
      startDate,
      serverOperation,
      statusName,
      rejection_reason,
      endDate,
      errors,
      recordId,
      deleteDialog,
      fields
    } = this.state;
    let canEdit = (!recordId || parseInt(statusId) === 1);

    console.log(this.state.fields.type_id);

    return (
      <React.Fragment>
        {statusName &&
          <Flex className="row ml20 mr20 mt20">
            <Box width={1 / 1}>
              <div className="status" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>{statusName} {(statusId === 3) && <><br />Reason - {rejection_reason}</>}</span>
                <span className="value">
                  {statusId == 1 &&
                    <React.Fragment>
                      <Button
                        loading={serverOperation == 2}
                        className="mr5"
                        large
                        intent="success"
                        onClick={() => this.setStatus(2)}
                        text="Approve">
                      </Button>
                      <Button
                        large
                        intent="danger"
                        onClick={() => this.setState({ rejectDialog: true })}
                        text="Reject">
                      </Button>
                    </React.Fragment>
                  }
                  {statusId == 2 &&
                    <Button
                      loading={serverOperation == 5}
                      className="mr5"
                      large
                      intent="success"
                      onClick={() => this.setStatus(5)}
                      text="Pay">
                    </Button>
                  }
                  {(statusId == 5 || statusId == 2 || statusId == 3) &&
                    <Button
                      loading={serverOperation == 1}
                      large
                      intent="danger"
                      onClick={() => this.setStatus(1)}
                      text="Set as Submitted">
                    </Button>
                  }
                </span>
              </div>
            </Box>
          </Flex>
        }
        <StyledExpensesForm
          className="content"
          noValidate
          onSubmit={event => this.submitForm(event)}>
          <Scrollbar noDefaultStyles>
            <div className="content-wrapper">
              <Flex flexWrap={'wrap'}>
                <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                  <FormGroup
                    style={{ width: '100%' }}
                    intent={errors.startTime ? Intent.DANGER : Intent.SUCCESS}
                    label="Travel start date and time"
                    labelFor="startDate"
                    labelInfo="*"
                    helperText={errors.startTime && errors.startTime}
                  >
                    <DateInput
                      inputProps={{
                        style: { width: '100%' },
                        className: 'bp3-large'
                      }}
                      id="startDate"
                      formatDate={date =>
                        moment(date)
                          .locale('ie')
                          .format('DD/MM/YYYY HH:mm')
                      }
                      parseDate={str =>
                        moment(str, 'DD/MM/YYYY HH:mm')
                          .locale('ie')
                          .toDate()
                      }
                      onChange={(dt) => this.onStartChanged(dt)}
                      placeholder={"DD/MM/YYYY HH:MM"}
                      value={startDate}
                      timePrecision={TimePrecision.MINUTE}
                      showActionsBar
                      disabled={!canEdit}
                      intent={startDate ? Intent.DANGER : Intent.NONE}
                    />
                  </FormGroup>
                </Box>
                {/* <Box width={['100%', '50%', '50%']} pl={['0', '5px']}>
                  <FormGroup
                    intent={errors.endTime ? Intent.DANGER : Intent.SUCCESS}
                    label="Travel end date and time"
                    labelFor="endDate"
                    labelInfo="*"
                    helperText={errors.endTime && errors.endTime}
                  >
                    <DateInput
                      inputProps={{
                        style: { width: '100%' },
                        className: 'bp3-large'
                      }}
                      id="endDate"
                      className="bp3-large"
                      formatDate={date => moment(date).locale('ie').format('DD/MM/YYYY HH:mm')}
                      parseDate={str => moment(str, 'DD/MM/YYYY HH:mm').locale('ie').toDate()}
                      onChange={(dt) => this.onEndChanged(dt)}
                      placeholder={"DD/MM/YYYY HH:MM"}
                      value={endDate}
                      timePrecision={TimePrecision.MINUTE}
                      showActionsBar
                      disabled={!canEdit}
                      intent={errors.endTime ? Intent.DANGER : Intent.NONE}
                    />
                  </FormGroup>
                </Box> */}
                <FormGroup
                  intent={Intent.DANGER}
                  label="Duration away from base"
                  labelFor="nights"
                  labelInfo="*"
                  helperText={errors.nights && errors.nights}>
                  <Select
                    options={days}
                    id="nights"
                    name="nights"
                    className="select"
                    multi={false}
                    isDisabled={!canEdit}
                    value={
                      fields.nights
                        ? days.find(day => day.value === fields.nights)
                        : days[0]
                    }
                    onChange={value => this.handleChange('nights', value.value)}
                  />
                </FormGroup>
                <FormGroup
                  intent={Intent.DANGER}
                  label="Please select the type of subsistence claim"
                  labelFor="type_id"
                  labelInfo="*"
                  helperText={errors.type_id && errors.type_id}>
                  <Select
                    options={types}
                    id="type_id"
                    name="type_id"
                    className="select"
                    multi={false}
                    isDisabled={!canEdit}
                    onChange={value => this.handleTypeChange(value.value)}
                    value={
                      fields.type_id
                        ? types.find(t => t.value === fields.type_id)
                        : types[0]
                    }
                  />
                </FormGroup>
              </Flex>
              {this.state.generalError.length ?
                <div className="row">
                  <div className="general-error">{this.state.generalError}</div></div> : ''}
              <Flex>
                <Box width={'100%'} pl={'0'}>
                  <div className="card" id="subsitence-claim-panel"
                    onToggle={(checked) => this.onSubsistenceClaimChanged(checked)}>
                    <div className="card-title" onClick={() => { console.log('click'); }}>
                      Subsistence claim
                      <Switch
                        onColor="#5db8f1"
                        offColor="#2c3c59"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        width={48}
                        height={24}
                        onChange={(checked) => this.onSubsistenceClaimChanged(checked)}
                        checked={!!this.state.subsistenceClaim}
                        disabled={!canEdit}
                        id="normal-switch"
                      />
                    </div>
                    <Collapse isOpened={!!this.state.subsistenceClaim}>
                      <div className="card-body">
                        <Flex flexWrap={'wrap'}>
                          <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                            <FormGroup
                              intent={this.state.locationInvalid.length ? Intent.DANGER : Intent.SUCCESS}
                              label="Location"
                              labelFor="location"
                              labelInfo="*"
                              helperText={this.state.locationInvalid.length ? this.state.locationInvalid : ''}
                            >
                              <Select
                                name="location"
                                multi={false}
                                options={this.state.locations}
                                onChange={option => this.changeLocation(option)}
                                value={this.state.location}
                                isDisabled={!canEdit}
                                onFocus={() => this.onFocusChanged('location')}
                                onBlur={() => this.onFocusChanged(null)}
                              />
                            </FormGroup>
                          </Box>
                          <Box width={['100%', '50%', '50%']} pl={['0', '5px']}>
                            {/* <FormGroup
                              intent={errors.startDate ? Intent.DANGER : Intent.SUCCESS}
                              label="Additional Accommodation"
                              labelFor="additionalAccommodation"
                              helperText={errors.startDate && errors.startDate}
                              disabled={(this.state.location &&
                                !this.state.location.additionalAccommodation) ||
                                !this.state.location || !canEdit
                              }
                            >
                              <InputGroup
                                disabled={(this.state.location &&
                                  !this.state.location.additionalAccommodation) ||
                                  !this.state.location || !canEdit
                                }
                                large
                                value={this.state.additionalAccommodation}
                                onChange={(event) => this.onAdditionalAccommodationChanged(event)}
                                onFocus={() => this.onFocusChanged('additional-accommodation')}
                                onBlur={() => this.onFocusChanged(null)}
                              />
                            </FormGroup> */}
                            <FormGroup
                              intent={Intent.DANGER}
                              label="Net subsistence per day/night"
                              labelFor="subsistence"
                              labelInfo="*"
                              helperText={errors.subsistence && errors.subsistence}>
                              <NumericInput
                                large
                                fill
                                name="subsistence"
                                id="subsistence"
                                value={fields.subsistence}
                                disabled={!canEdit}
                                onValueChange={value => this.handleChange('subsistence', value)} />
                            </FormGroup>
                          </Box>
                        </Flex>
                        <Flex flexWrap={'wrap'}>
                          <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                            <FormGroup
                              intent={this.state.tripBeginInvalid.length ? Intent.DANGER : Intent.SUCCESS}
                              label="Where did your trip begin?"
                              labelFor="tripBegin"
                              labelInfo="*"
                              helperText={this.state.tripBeginInvalid.length ? this.state.tripBeginInvalid : ''}
                            >
                              <InputGroup
                                name="tripBegin"
                                id="tripBegin"
                                large
                                onChange={e => this.setState({ tripBegin: e.target.value })}
                                onFocus={() => this.onFocusChanged('trip-begin')}
                                onBlur={() => this.onFocusChanged(null)}
                                value={this.state.tripBegin}
                                disabled={!canEdit}
                              />
                            </FormGroup>
                          </Box>
                          <Box width={['100%', '50%', '50%']} pl={['0', '5px']}>
                            <FormGroup
                              intent={this.state.tripEndInvalid.length ? Intent.DANGER : Intent.SUCCESS}
                              label="Where did your trip end?"
                              labelFor="tripEnd"
                              labelInfo="*"
                              helperText={this.state.tripEndInvalid.length ? this.state.tripEndInvalid : ''}
                            >
                              <InputGroup
                                name="tripEnd"
                                id="tripEnd"
                                large
                                onChange={e => this.setState({ tripEnd: e.target.value })}
                                onFocus={() => this.onFocusChanged('trip-end')}
                                onBlur={() => this.onFocusChanged(null)}
                                disabled={!canEdit}
                                value={this.state.tripEnd}
                              />
                            </FormGroup>
                          </Box>
                        </Flex>
                      </div>
                      {this.state.location ?
                        <div className="card" id="subsitence-breakdown-panel"
                          onToggle={(checked) => this.onSubsistenceClaimChanged(checked)}>
                          <div className="card-title" onClick={() => {
                            console.log('click');
                          }}>
                            Subsistence Calculation Breakdown
                            <Switch
                              onColor="#5db8f1"
                              offColor="#2c3c59"
                              checkedIcon={false}
                              uncheckedIcon={false}
                              width={32}
                              height={18}
                              onChange={(checked) => this.setState({
                                subsistenceBreakdown: checked
                              })}
                              checked={this.state.subsistenceBreakdown}
                              id="normal-switch"
                              disabled={!canEdit}
                            />
                          </div>
                          <Collapse isOpened={!!this.state.subsistenceBreakdown}>

                            <Flex pl={10} pr={10}>
                              <Box width={['100%', '65%', '65%']}><b>Duration Away From Base</b>
                              </Box>
                              <Box width={['100%', '35%', '35%']}>
                                {fields.nights}
                                {fields.nights && fields.nights > 1 ? ' Days' : ' Day'}
                              </Box>
                            </Flex>
                            <Flex pl={10} pr={10}>
                              <Box width={['100%', '65%', '65%']}><b>1 x {this.state.subsistenceRate}</b>
                              </Box>
                              <Box width={['100%', '35%', '35%']}> €{this.state.subsistenceRateValue}</Box>
                            </Flex>
                            <Flex pl={10} pr={10}>
                              <Box width={['100%', '65%', '65%']}>
                                <b>Total</b>
                              </Box>
                              <Box width={['100%', '35%', '35%']}>
                                €{fields.total}
                              </Box>
                            </Flex>
                          </Collapse>
                        </div>
                        : ''}

                    </Collapse>
                  </div>
                </Box>
              </Flex>
              <Flex>
                <Box width={'100%'}>
                  <div className="card"
                    onToggle={(checked) => this.onCarUsedChanged(checked)}
                  >
                    <div className="card-heading">
                      <div className="card-title" onClick={() => { console.log('click'); }}>
                        <div>Private car used for this business</div>
                        <Switch
                          onColor="#5db8f1"
                          offColor="#2c3c59"
                          checkedIcon={false}
                          uncheckedIcon={false}
                          width={48}
                          height={24}
                          onChange={(checked) => this.onCarUsedChanged(checked)}
                          checked={!!this.state.carUsed}
                          disabled={!canEdit}
                          id="normal-switch"
                        />
                      </div>
                    </div>
                    <Collapse isOpened={!!this.state.carUsed}>
                      <div className="card-body">
                        <Flex flexWrap={'wrap'}>
                          <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                            <FormGroup
                              intent={this.state.engineSizeInvalid.length ? Intent.DANGER : Intent.SUCCESS}
                              label="Engine Size"
                              labelFor="engineSize"
                              labelInfo="*"
                              helperText={this.state.engineSizeInvalid.length ? this.state.engineSizeInvalid : ''}
                            >
                              <Select
                                name="engineSize"
                                multi={false}
                                options={this.state.engineSizes}
                                onChange={this.changeEngineSize}
                                value={this.state.engineSize}
                                onFocus={() => this.onFocusChanged('engine-size')}
                                onBlur={() => this.onFocusChanged(null)}
                                disabled={!canEdit}
                              />
                            </FormGroup>
                          </Box>
                          <Box width={['100%', '50%', '50%']} pl={['0', '5px']}>
                            <FormGroup
                              intent={errors.distanceTraveled ? Intent.DANGER : Intent.SUCCESS}
                              label="Distance traveled(km)"
                              labelFor="distanceTraveled"
                              labelInfo="*"
                              helperText={errors.distanceTraveled ? this.state.errors.distanceTraveled : ''}
                            >
                              <InputGroup
                                name="distanceTraveled"
                                id="distanceTraveled"
                                type="number"
                                min="1"
                                large
                                onChange={this.handleInputChange}
                                onFocus={() => this.onFocusChanged('distanceTraveled')}
                                onBlur={() => this.onFocusChanged(null)}
                                value={this.state.distanceTraveled}
                                disabled={!canEdit}
                              />
                            </FormGroup>
                          </Box>
                        </Flex>
                        <Flex flexWrap={'wrap'}>
                          <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                            <FormGroup
                              intent={this.state.carBeginInvalid.length ? Intent.DANGER : Intent.SUCCESS}
                              label="Where the car journey begin?"
                              labelFor="carBegin"
                              labelInfo="*"
                              helperText={this.state.carBeginInvalid.length ? this.state.carBeginInvalid : ''}
                            >
                              <InputGroup
                                name="carBegin"
                                id="carBegin"
                                large
                                onChange={this.handleInputChange}
                                onFocus={() => this.onFocusChanged('carBegin')}
                                onBlur={() => this.onFocusChanged(null)}
                                value={this.state.carBegin}
                                disabled={!canEdit}
                              />
                            </FormGroup>
                          </Box>
                          <Box width={['100%', '50%', '50%']} pl={['0', '5px']}>
                            <FormGroup
                              intent={this.state.carEndInvalid.length ? Intent.DANGER : Intent.SUCCESS}
                              label="Where the car journey end?"
                              labelFor="carEnd"
                              labelInfo="*"
                              helperText={this.state.carEndInvalid.length ? this.state.carEndInvalid : ''}
                            >
                              <InputGroup
                                name="carEnd"
                                id="carEnd"
                                large
                                onChange={this.handleInputChange}
                                onFocus={() => this.onFocusChanged('car-end')}
                                onBlur={() => this.onFocusChanged(null)}
                                value={this.state.carEnd}
                                disabled={!canEdit}
                              />
                            </FormGroup>
                          </Box>
                        </Flex>
                        {/* <Flex flexWrap={'wrap'}>
                          <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                            <FormGroup
                              intent={this.state.carModelInvalid.length ? Intent.DANGER : Intent.SUCCESS}
                              label="Round Trip?"
                              labelFor="RoundTrip"
                              helperText={this.state.carModelInvalid.length ? this.state.carModelInvalid : ''}
                            >
                              <Checkbox
                                name="round_trip"
                                id="round_trip"
                                large

                                onChange={this.handleInputChange}
                                onFocus={() => this.onFocusChanged('car-model')}
                                onBlur={() => this.onFocusChanged(null)}
                                value={this.state.fields.car_make_model}
                                disabled={!canEdit}
                              />
                              <InputGroup
                                name="round_trip_location"
                                id="round_trip_location"
                                placeholder="First Stop"
                                large
                                onChange={this.handleInputChange}
                                onFocus={() => this.onFocusChanged('car-model')}
                                onBlur={() => this.onFocusChanged(null)}
                                value={this.state.fields.car_make_model}
                                disabled={!canEdit}
                              />
                            </FormGroup>
                          </Box>
                        </Flex> */}
                        <Flex flexWrap={'wrap'}>
                          <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                            <FormGroup
                              intent={this.state.carModelInvalid.length ? Intent.DANGER : Intent.SUCCESS}
                              label="Make & Model of the vehicle"
                              labelFor="carModel"
                              labelInfo="*"
                              helperText={this.state.carModelInvalid.length ? this.state.carModelInvalid : ''}
                            >
                              <InputGroup
                                name="carModel"
                                id="carModel"
                                large
                                onChange={this.handleInputChange}
                                onFocus={() => this.onFocusChanged('car-model')}
                                onBlur={() => this.onFocusChanged(null)}
                                value={this.state.carModel}
                                disabled={!canEdit}
                              />
                            </FormGroup>
                          </Box>
                        </Flex>
                      </div>
                      <div className="card-footer">
                        <Flex flexWrap={'wrap'}>
                          <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                            Mileage value:
                          </Box>
                          <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                            Calculation Breakdown:<br />
                            Mileage to date: {this.state.mileageToDate}<br />
                            Mileage entered: {this.state.distanceTraveled}
                          </Box>
                        </Flex>
                      </div>
                    </Collapse>
                  </div>
                </Box>
              </Flex>
              {canEdit &&
                <Flex>
                  <Box
                    className="pt20 space-between"
                    width={1 / 1}>
                    <Button
                      large
                      onClick={event =>
                        this.handleSubmit(event)
                      }
                      // icon={this.state.recordId
                      //   ? "file"
                      //   : 'add'
                      // }
                      intent="success"
                      text={this.state.recordId
                        ? 'Save'
                        : 'Add New'
                      } />
                    {this.state.recordId &&
                      <Button
                        large
                        onClick={event => this.setState({ deleteDialog: true })}
                        icon="trash"
                        intent="danger"
                        text='Delete' />
                    }
                  </Box>
                </Flex>
              }
            </div>
          </Scrollbar>
          <Dialog
            icon="info-sign"
            onClose={() => this.setState({ rejectDialog: false })}
            title="Reject Travel Expense"
            isOpen={this.state.rejectDialog}>
            <div className={Classes.DIALOG_BODY}>
              <FormGroup
                intent={this.state.errors.rejection_reason ? Intent.DANGER : Intent.SUCCESS}
                label="Describe your reason for rejection"
                labelFor="rejection_reason"
                labelInfo="*"
                helperText={this.state.errors.rejection_reason ? this.state.errors.rejection_reason : ''}
              >
                <TextArea
                  id="rejection_reason"
                  name="rejection_reason"
                  growVertically
                  large
                  fill
                  value={this.state.rejection_reason}
                  onChange={e => this.setState({ rejection_reason: e.target.value })}
                />
              </FormGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => this.setState({ rejectDialog: false })}>Cancel</Button>
                <Button intent="DANGER" onClick={() => this.handleReject()}>Reject</Button>
              </div>
            </div>
          </Dialog>
          <Dialog
            icon="info-sign"
            onClose={() => this.setState({ eleteDialog: false })}
            title="Delete Business Expense"
            isOpen={this.state.deleteDialog}>
            <div className={Classes.DIALOG_BODY}>
              <div>Are you sure?</div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  onClick={() =>
                    this.setState({ deleteDialog: false })
                  }>
                  Cancel
                </Button>
                <Button
                  intent="DANGER"
                  onClick={() =>
                    this.handleDelete()
                  }>
                  Delete
                </Button>
              </div>
            </div>
          </Dialog>
        </StyledExpensesForm>
      </React.Fragment>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
  }, dispatch);
}

export default connect(null, matchDispatchToProps)(TravelExpenseForm);
