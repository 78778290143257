import React, {Component} from 'react';
import {Box, Flex} from '@rebass/grid';
import {Spinner, Button} from "@blueprintjs/core";
import isEmpty from 'lodash/isEmpty';

import { connect } from 'react-redux';

import Icon from '../../../components/icon';
import {bindActionCreators} from "redux";

import { getProfile } from "../../../store/profile/actions";
import { toast } from "../../../store/toaster/actions";
import moment from "moment";
import ReactResizeDetector from "react-resize-detector";
import ChangePassword from "./changePassword";
import AddressEditor from "./address";
import Editor from "./editor";
import SpouseEditor from "./spouse";


class SettingsProfile extends Component {
  state = {
    editor: false,
    changePasswordDialog: false,
    addressEditor: false,
    spouseEditor: false,
    record: null,
  }

  componentDidMount() {
    this.props.getProfile();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!isEmpty(nextProps.data)) {
      nextProps.getProfile();
    }
  }

  toggleAddressEditor() {
    this.setState({
      addressEditor: true,
      record: this.props.record,
    });
  }

  toggleEditor() {
    this.setState({
      editor: true,
      record: this.props.record,
    });
  }

  toggleSpouseEditor() {
    this.setState({
      spouseEditor: true,
      record: this.props.record,
    });
  }

  render() {
    const { record, getProfile } = this.props;
    if (record) {
      return (
        <React.Fragment>
          <Flex flexWrap={'wrap'} p={'16px'}>
            <Box width={['100%','100%','100%','100%','45%', '35%']} pr={['0','0','0','0','5px']}>
              <div className="panel">
                <div className="panel-head">
                  <div className="tools">
                    <div className="icon" onClick={() => this.toggleEditor()}>
                      <Icon icon="inclined-pencil" color="#fff"/>
                    </div>
                  </div>
                  <h2>{record.first_name} {record.last_name}</h2>
                  <div className="data-item-container blue">
                    <div>Email</div>
                    <div>{record.email}</div>
                  </div>
                  <div className="data-item-container blue">
                    <div>Phone</div>
                    <div>{record.phone}</div>
                  </div>
                </div>
                <div className="panel-body">
                  <div className="data-item-container white">
                    <div>PPS Number</div>
                    <div>{record.pps_number}</div>
                  </div>
                  <div className="data-item-container white">
                    <div>National Insurance Number</div>
                    <div>{record.national_insurance_number}</div>
                  </div>
                  <div className="data-item-container white">
                    <div>Crew Code</div>
                    <div>{record.crew_code && record.crew_code}</div>
                  </div>
                  <div className="data-item-container white">
                    <div>Nationality</div>
                    <div>{record.nationality && record.nationality.name && record.nationality.name}</div>
                  </div>
                  <div className="data-item-container white">
                    <div>Date Of Birth</div>
                    <div>{moment(record.date_of_birth).isValid() ? moment(record.date_of_birth).format('DD/MM/YYYY') : ''}</div>
                  </div>
                  <div className="data-item-container white">
                    <div>Gender</div>
                    <div>{record.gender && record.gender.name && record.gender.name}</div>
                  </div>
                  <div className="data-item-container white">
                    <div>Marital Status</div>
                    <div>{record.marital_status && record.marital_status.name && record.marital_status.name}</div>
                  </div>
                </div>
              </div>
            </Box>
            <Box width={['100%','100%','100%','100%','55%', '65%']} pl={['0','0','0','0','5px']}>

              <ReactResizeDetector handleWidth handleHeight>
                {({width, height}) =>
                  <div className={width > 620 ? 'double' : 'single'}>
                    <div className="cols">
                      <div className="card">
                        <h5 className="card-title">Address
                          <div className="icon" onClick={() => this.toggleAddressEditor(record)}><Icon icon="inclined-pencil" color="#000"/>
                          </div></h5>
                        <div className="card-body">
                          {record.address_1 && <div>{record.address_1}</div>}
                          {record.address_2 && <div>{record.address_2}</div>}
                          {record.address_3 && <div>{record.address_3}</div>}
                          {record.address_4 && <div>{record.address_4}</div>}
                          {record.country && <div>{record.country}</div>}
                        </div>
                      </div>
                      <div className="card">
                        <h5 className="card-title">Portal Access</h5>
                        <div className="card-body right">

                          <table className="info-table">
                            <tbody>
                            <tr>
                              <td>Username</td>
                              <td>{record.username}</td>
                            </tr>
                            <tr>
                              <td colSpan={2} className="center">
                                <Button onClick={() => this.setState({changePasswordDialog: true})} intent="success" text="Change Password" />
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <h5 className="card-title">Company Details</h5>
                      <div className="card-body cols">
                        <table className="info-table gray">
                          <tbody>
                          <tr>
                            <td>Name</td>
                            <td>{record.company && record.company.name && record.company.name}</td>
                          </tr>
                          <tr>
                            <td>Cro Number</td>
                            <td>{record.company && record.company.cro_number && record.company.cro_number}</td>
                          </tr>
                          <tr>
                            <td>Tax Registration</td>
                            <td>{record.company && record.company.vat_number && record.company.vat_number}</td>
                          </tr>
                          <tr>
                            <td>Financial Year End</td>
                            <td>{moment(record.financial_year_end).isValid() ? moment(record.financial_year_end).format('DD/MM') : ''}</td>
                          </tr>
                          <tr>
                            <td>Date Of Inc.</td>
                            <td>{moment(record.date_of_incorporation).isValid() ? moment(record.date_of_incorporation).format('DD/MM/YYYY') : ''}</td>
                          </tr>
                          </tbody>
                        </table>
                        <table className="info-table">
                          <tbody>
                          <tr>
                            <td>Address 1</td>
                            <td>{record.company && record.company.address_1 && record.company.address_1}</td>
                          </tr>
                          <tr>
                            <td>Address 2</td>
                            <td>{record.company && record.company.address_2 && record.company.address_2}</td>
                          </tr>
                          <tr>
                            <td>Address 3</td>
                            <td>{record.company && record.company.address_3 && record.company.address_3}</td>
                          </tr>
                          <tr>
                            <td>Address 4</td>
                            <td>{record.company && record.company.address_4 && record.company.address_4}</td>
                          </tr>
                          <tr>
                            <td>Country</td>
                            <td>{record.company && record.company.country && record.company.country.name && record.company.country.name}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="card">
                      <h5 className="card-title">Spouse
                        <div className="icon" onClick={() => this.toggleSpouseEditor(record)}><Icon icon="inclined-pencil" color="#000"/>
                      </div></h5>
                      <div className="card-body cols">
                        <table className="info-table gray">
                          <tbody>
                          <tr>
                            <td>First Name</td>
                            <td>{record.spouse_first_name && record.spouse_first_name}</td>
                          </tr>
                          <tr>
                            <td>Last Name</td>
                            <td>{record.spouse_last_name && record.spouse_last_name}</td>
                          </tr>
                          <tr>
                            <td>Maiden Name</td>
                            <td>{record.spouse_maiden_name && record.spouse_maiden_name}</td>
                          </tr>
                          <tr>
                            <td>PPS Number</td>
                            <td>{record.spouse_pps_number && record.spouse_pps_number}</td>
                          </tr>
                          </tbody>
                        </table>
                        <table className="info-table">
                          <tbody>
                          <tr>
                            <td>Date of Marriage</td>
                            <td>{moment(record.date_of_marriage).isValid() ? moment(record.date_of_marriage).format('DD/MM/YYYY') : ''}</td>
                          </tr>
                          <tr>
                            <td>Date of Separation</td>
                            <td>{moment(record.date_of_separation).isValid() ? moment(record.date_of_separation).format('DD/MM/YYYY') : ''}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                }
              </ReactResizeDetector>
            </Box>
          </Flex>
          <ChangePassword isOpen={this.state.changePasswordDialog} onClose={() => this.setState({changePasswordDialog: false})} />
          <AddressEditor record={this.state.record} isOpen={this.state.addressEditor} onClose={() => this.setState({addressEditor: false})} onUpdate={() => getProfile()} />
          <Editor record={this.state.record} isOpen={this.state.editor} onClose={() => this.setState({editor: false})} onUpdate={() => getProfile()} />
          <SpouseEditor record={this.state.record} isOpen={this.state.spouseEditor} onClose={() => this.setState({spouseEditor: false})} onUpdate={() => getProfile()} />
        </React.Fragment>
      );
    } else {
      return (
        <Spinner className="mt50" />
      )
    }
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    getProfile,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    record: state.profile.data,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(SettingsProfile));
