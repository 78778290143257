import Echo from 'laravel-echo';
import io from 'socket.io-client';
import axios from "axios";

const echoMiddleware = store => next => {
  let echo;
  return action => {
    if(action.type === 'SOCKET_CONNECT') {
      /*
      echo = new Echo({
        auth: {
          headers: {
            "Authorization": localStorage.getItem('ammotio-token')
          },
        },
        broadcaster: 'socket.io',
        host: process.env.REACT_APP_SOCKET_URL,
        client: io
      });


      echo.connector.socket.on('connect', function(){
        console.log('connected', echo.socketId());
      });
      */

      //echo.private(action.payload.channel).listen(action.payload.action, action.payload.callback);
    }
    return next(action);
  }
};

export default echoMiddleware;
