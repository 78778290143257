import axios from 'axios';
import moment from 'moment';
import {companiesConstants} from '../constants';


export function getCompanies(){
  return function(dispatch){
    dispatch({type:companiesConstants.COMPANIES_REQUEST});
    axios.get('/companies').then(result => {
      dispatch({type:companiesConstants.COMPANIES_SUCCESS, payload: result.data});
    }).catch(error => {
      dispatch({type:companiesConstants.COMPANIES_FAILURE, error});
    });
  }
}

export function getCompany(companyId){
  return function(dispatch){
    axios.get(`'/companies/'${companyId}`).then(result => {
      dispatch({type:"GET_COMPANY", payload: result.data});
    }).catch(error => {
    });
  }
}

export function getDetails(companyId){
  return function(dispatch){
    dispatch({type:companiesConstants.COMPANY_REQUEST, id: companyId});
    axios.get(`/companies/${companyId}`).then(result => {
      dispatch({type:companiesConstants.COMPANY_SUCCESS, payload: result.data});
    }).catch(error => {
      dispatch({type:companiesConstants.COMPANY_FAILURE, error});
    });
  }
}

export function selectRecord( record ){
  return function(dispatch){
    dispatch({ type:companiesConstants.COMPANIES_SELECT_RECORD, payload: record });
  }
}

export function toggleEditor( record ){
  return function(dispatch){
      dispatch({ type:companiesConstants.COMPANIES_TOGGLE_EDITOR, payload: record });
  }
}

export function toggleList(){
  return function(dispatch){
    dispatch({ type:companiesConstants.COMPANIES_TOGGLE_LIST });
  }
}

export function insertRecord( record, cb ) {
  return function(dispatch){
    dispatch({type:companiesConstants.COMPANY_ADD_REQUEST});
    axios.post(`/companies`, record).then(result => {
      cb('saved');
      dispatch({type:companiesConstants.COMPANY_ADD_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:companiesConstants.COMPANY_ADD_FAILURE, error});
    });
  }
}

export function updateRecord( id, record, cb ) {
  return function(dispatch){
    dispatch({type:companiesConstants.COMPANY_EDIT_REQUEST});
    axios.post(`/companies/${id}`, record).then(result => {
      cb('saved');
      dispatch({type:companiesConstants.COMPANY_EDIT_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:companiesConstants.COMPANY_EDIT_FAILURE, error});
    });
  }
}

export function deleteRecord( id, cb ) {
  return function(dispatch){
    dispatch({type:companiesConstants.COMPANY_DELETE_REQUEST});
    axios.delete(`/companies/${id}`).then(result => {
      cb('saved');
      dispatch({type:companiesConstants.COMPANY_DELETE_SUCCESS, payload: parseInt(id)});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:companiesConstants.COMPANY_DELETE_FAILURE, error});
    });
  }
}

export function getEmployees( id, cb ) {
  return function(dispatch){
    dispatch({type:companiesConstants.EMPLOYEES_REQUEST});
    axios.get(`/companies/${id}/employees`).then(result => {
      cb('saved');
      dispatch({type:companiesConstants.EMPLOYEES_SUCCESS, payload: result.data});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:companiesConstants.EMPLOYEES_FAILURE, error});
    });
  }
}

export function getBankAccounts( id, cb ) {
  return function(dispatch){
    dispatch({type:companiesConstants.BANK_ACCOUNTS_REQUEST});
    axios.get(`/companies/${id}/bank-accounts`).then(result => {
      cb('saved');
      dispatch({type:companiesConstants.BANK_ACCOUNTS_SUCCESS, payload: result.data});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:companiesConstants.BANK_ACCOUNTS_FAILURE, error});
    });
  }
}

export function getBankStatements( id, cb ) {
  return function(dispatch){
    dispatch({type:companiesConstants.BANK_STATEMENTS_REQUEST});
    axios.get(`/companies/${id}/bank-statements`).then(result => {
      cb('saved');
      const data = result.data.map(record => {
        return {
          ...record,
          date: moment(record.date).format('DD/MM/Y')
        }
      });
      dispatch({type:companiesConstants.BANK_STATEMENTS_SUCCESS, payload: data});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:companiesConstants.BANK_STATEMENTS_FAILURE, error});
    });
  }
}

export function toggleBankAccountEditor( record ){
  return function(dispatch){
    dispatch({ type:companiesConstants.TOGGLE_BANK_ACCOUNT_EDITOR, payload: record });
  }
}

export function insertBankAccount( companyId, record, cb ) {
  return function(dispatch){
    dispatch({type:companiesConstants.BANK_ACCOUNTS_ADD_REQUEST});
    axios.post(`/companies/${companyId}/bank-accounts`, record).then(result => {
      cb('saved');
      dispatch({type:companiesConstants.BANK_ACCOUNTS_ADD_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:companiesConstants.BANK_ACCOUNTS_ADD_FAILURE, error});
    });
  }
}

export function updateBankAccount( companyId, id, record, cb ) {
  return function(dispatch){
    dispatch({type:companiesConstants.BANK_ACCOUNTS_EDIT_REQUEST});
    axios.post(`/companies/${companyId}/bank-accounts/${id}`, record).then(result => {
      cb('saved');
      dispatch({type:companiesConstants.BANK_ACCOUNTS_EDIT_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:companiesConstants.BANK_ACCOUNTS_EDIT_FAILURE, error});
    });
  }
}

export function deleteBankAccount( companyId, id, cb ) {
  return function(dispatch){
    dispatch({type:companiesConstants.BANK_ACCOUNTS_DELETE_REQUEST});
    axios.delete(`/companies/${companyId}/bank-accounts/${id}`).then(result => {
      cb('saved');
      dispatch({type:companiesConstants.BANK_ACCOUNTS_DELETE_SUCCESS, payload: parseInt(id)});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:companiesConstants.BANK_ACCOUNTS_DELETE_FAILURE, error});
    });
  }
}

export function setFilter(name, operator, value) {
  return function(dispatch){
    dispatch({ type:companiesConstants.SET_FILTER, name, operator, value });
  }
}

export function removeFilters() {
  return function(dispatch){
    dispatch({ type:companiesConstants.REMOVE_FILTERS });
  }
}

export function search(phrase) {
  return function(dispatch){
    dispatch({ type:companiesConstants.SEARCH, phrase });
  }
}
