import {expensesConstants} from "./contants";
import axios from 'axios';

export function getExpenses() {
  return function(dispatch){
    dispatch({type:expensesConstants.GET_EXPENSES_REQUEST});
    axios.get(`/expenses`).then(result => {
      dispatch({type:expensesConstants.GET_EXPENSES_SUCCESS, payload: result.data});
    }).catch(error => {
      dispatch({type:expensesConstants.GET_EXPENSES_FAILURE, error});
    });
  }
}

export function getExpensesList(year, month) {
  return function(dispatch){
    dispatch({type:expensesConstants.GET_EXPENSES_LIST_REQUEST});
    axios.get(`/expenses/${year}/${month}`).then(result => {
      dispatch({type:expensesConstants.GET_EXPENSES_LIST_SUCCESS, payload: result.data});
    }).catch(error => {
      dispatch({type:expensesConstants.GET_EXPENSES_LIST_FAILURE, error});
    });
  }
}

