import React, {Component} from 'react';
import moment from "moment";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getWorkingHours} from "./store/actions";
import {InputGroup, NonIdealState, Spinner} from "@blueprintjs/core";
import {CSVLink} from "react-csv";
import Icon from "../../../components/icon";
import {AutoSizer, Column, Table} from "react-virtualized";
import styled from "styled-components";

const StyledList = styled.div`
  height: 100%;
  width: 100%;
  padding: 8px 16px;
`;

const FUSE_OPTIONS = {
  threshold: 0.5,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    {name: 'period', weight: 0.4},
    {name: 'hours', weight: 0.2},
    {name: 'paid', weight: 0.2},
  ]
};

const EXCEL_HEADERS = [
  { label: 'Period', key: 'period' },
  { label: 'Hours', key: 'hours' },
  { label: 'Paid', key: 'paid' },
];

class WorkingHours extends Component {

  state = {
    records: [],
  }

  componentDidMount() {
    this.props.getWorkingHours();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let formatedRecords = [];
    nextProps.records.map(record => {
      record = {
        ...record,
        net: record.net ? record.net.toFixed(2) : parseInt('0').toFixed(2),
        paid: record.paid ? record.paid.toFixed(2) : parseInt('0').toFixed(2),
        hours: record.hours ? record.hours.toFixed(2) : parseInt('0').toFixed(2),
        hours_away: record.hours_away ? record.hours_away.toFixed(2) : parseInt('0').toFixed(2),
        vat_rate: `${record.vat_rate}%`,
        period: moment(record.period).isValid() ? moment(record.period).format('MM/YYYY') : ''
      };
      return formatedRecords.push(record);
    });

    this.setState({
      records: formatedRecords
    });
  }


  render() {
    const { loading } = this.props;
    const { records } = this.state;

    return (
      <div className="right">
        {loading ? (
          <Spinner className="mt50" />
        ) : (
          <React.Fragment>
            <div className="tools">
              <div></div>
              <div className="flex">
                {records.length > 0 &&
                <CSVLink
                  headers={EXCEL_HEADERS}
                  data={records}
                  filename={"working_hours.csv"}
                  className="mr5"
                  target="_blank"
                >
                  <Icon icon="csv" size={38}/>
                </CSVLink>
                }
                <InputGroup
                  leftIcon="search"
                  onChange={(e) => {
                    this.setState({
                      search: e.currentTarget.value
                    })
                  }}
                  name="vat_number"
                  id="vat_number"
                  large
                  placeholder="Search"
                />
              </div>
            </div>
            {records.length > 0 ? (
              <StyledList>
                <AutoSizer>
                  {({width, height}) => (
                    <Table
                      ref="Table"
                      headerHeight={30}
                      noRowsRenderer={this._noRowsRenderer}
                      overscanRowCount={10}
                      rowClassName={this._rowClassName}
                      rowHeight={30}
                      rowCount={records.length}
                      rowGetter={({ index }) => records[index]}
                      height={height}
                      onRowClick={(event) => console.log(event.rowData)}
                      width={width}>
                      <Column
                        label="Period"
                        dataKey="period"
                        width={width * 0.1}
                      />
                      <Column
                        width={width * 0.1}
                        disableSort
                        label="Hours"
                        dataKey="hours"
                        flexGrow={1}
                      />
                      <Column
                        width={width * 0.1}
                        disableSort
                        label="Paid"
                        dataKey="paid"
                        flexGrow={1}
                      />
                    </Table>
                  )}
                </AutoSizer>
              </StyledList>
            ) : (
              <NonIdealState
                icon="search"
                title="No working hours found"
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    getWorkingHours
  }, dispatch);
}

function mapStateToProps(state){
  return {
    loading: state.workingHours.loading,
    records: state.workingHours.records,
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(WorkingHours);
