import React, {Component} from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Intent } from '@blueprintjs/core';
import MediaQuery from 'react-responsive';

import List from './list';
import Detail from './detail';
import SlidingPanel from "../../../components/slidingpanel";
import ContractorEditor from "./Editors/Contractor";
import NewContractor from './Editors/NewContractor';
import PortalAccess from './Editors/PortalAccess';

import {
  getContractors,
  toggleAddressEditor,
  toggleEditor,
  toggleNotificationsEditor,
  toggleList,
  toggleCompanyEditor,
  toggleMaritalStatusAndSpouseDetailsEditor,
  togglePortalAccessEditor
} from '../../../store/contractors/actions';
import AddressEditor from "./Editors/Address";
import NotificationsEditor from "./Editors/Notifications";
import CompanyEditor from "./Editors/Company";
import MaritalStatusAndSpouseDetailsEditor from "./Editors/MaritalStatusAndSpouseDetails";


class Contractors extends Component {
  constructor(props){
    super(props);

    this.state = {
      contractorEditorOpen: false,
      records: [],
      isListOpen: false,
    };

    this.addRecord = this.addRecord.bind(this);
    this.onContractorSaved = this.onContractorSaved.bind(this);
  }

  componentDidMount() {
    this.props.getContractors();
  }

  addRecord(record){
    console.log(this);
    this.props.toast({message: 'Record Added', intent: Intent.SUCCESS})

    this.setState({
      records: [...this.state.records, record]
    });
  }

  onContractorSaved(record){
    this.setState({
      contractorEditorOpen: false
    });
    this.addRecord(record);
  }

  render() {
    const {
      isEditorActive,
      isAddressEditorActive,
      toggleEditor,
      toggleAddressEditor,
      toggleNotificationsEditor,
      toggleCompanyEditor,
      toggleMaritalStatusAndSpouseDetailsEditor,
      togglePortalAccessEditor,
      isListActive,
      toggleList,
      recordToEdit,
      isNotificationsEditorActive,
      isCompanyEditorActive,
      isMaritalStatusAndSpouseDetailsEditorActive,
      isPortalAccessEditorActive
    } = this.props;

    return (
      <div className="split">
        <MediaQuery minWidth={769}>
          <List />
        </MediaQuery>
        <MediaQuery maxWidth={769}>
          <SlidingPanel onAfterOpen={()=> console.log('opened')} onRequestClose={() => toggleList()} width="380px" isOpen={isListActive} from="left" title="Companies">
            <List onSelect={() => toggleList()} />
          </SlidingPanel>
        </MediaQuery>

        {this.props.match.params._id && <Detail {...this.props} addRecord={this.addRecord} />}
        <SlidingPanel
          width="520px"
          isOpen={isEditorActive}
          title={recordToEdit && recordToEdit.id
            ? `${recordToEdit.first_name} ${recordToEdit.last_name}`
            : 'New Contractor'
          }
          onRequestClose={() => toggleEditor()}>
          {
            recordToEdit && recordToEdit.id
            ? <ContractorEditor onSave={this.onContractorSaved}/>
            : <NewContractor onSave={this.onContractorSaved} />
          }
        </SlidingPanel>
        <SlidingPanel
          width="520px"
          isOpen={isAddressEditorActive}
          title='Home Address'
          onRequestClose={() => toggleAddressEditor()}>
          <AddressEditor onSave={this.onContractorSaved}/>
        </SlidingPanel>
        <SlidingPanel
          width="520px"
          isOpen={isNotificationsEditorActive}
          title='Notifications'
          onRequestClose={() => toggleNotificationsEditor()}>
          <NotificationsEditor onSave={this.onContractorSaved}/>
        </SlidingPanel>
        <SlidingPanel
          width="520px"
          isOpen={isCompanyEditorActive}
          title='Company'
          onRequestClose={() => toggleCompanyEditor()}>
          <CompanyEditor onSave={this.onContractorSaved}/>
        </SlidingPanel>
        <SlidingPanel
          width="520px"
          isOpen={isMaritalStatusAndSpouseDetailsEditorActive}
          title='Marital Status & Spouse Details Editor'
          onRequestClose={() => toggleMaritalStatusAndSpouseDetailsEditor()}>
          <MaritalStatusAndSpouseDetailsEditor onSave={this.onContractorSaved}/>
        </SlidingPanel>
        <SlidingPanel
          width="520px"
          isOpen={isPortalAccessEditorActive}
          title='Portal Access'
          onRequestClose={() => togglePortalAccessEditor()}>
          <PortalAccess />
        </SlidingPanel>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return{
    isEditorActive: state.contractors.isEditorActive,
    isAddressEditorActive: state.contractors.isAddressEditorActive,
    isNotificationsEditorActive: state.contractors.isNotificationsEditorActive,
    isCompanyEditorActive: state.contractors.isCompanyEditorActive,
    isListActive: state.contractors.isListActive,
    isMaritalStatusAndSpouseDetailsEditorActive: state.contractors.isMaritalStatusAndSpouseDetailsEditorActive,
    isPortalAccessEditorActive: state.contractors.isPortalAccessEditorActive,
    recordToEdit: state.contractors.recordToEdit,
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    getContractors,
    toggleEditor,
    toggleAddressEditor,
    toggleNotificationsEditor,
    toggleCompanyEditor,
    toggleList,
    toggleMaritalStatusAndSpouseDetailsEditor,
    togglePortalAccessEditor
  }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Contractors);

