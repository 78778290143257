import React, { useState } from 'react';
import { toast } from "../../../../store/toaster/actions";
import { toggleAddressEditor, updateRecord } from "../../../../store/contractors/actions";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup, InputGroup, Button, Spinner } from "@blueprintjs/core";
import Select from 'react-select';
import { Formik, Field } from 'formik';

export default ({ formik }) => {
  const [loading, setLoading] = useState(false);
  const { record = {}, countries = [] } = useSelector(store => ({
    record: store.contractors.recordToEdit,
    countries: store.general.countries
  }));
  const dispatch = useDispatch();
  const {
    address_1,
    address_2,
    address_3,
    address_4,
    country_id
  } = record;
  const countriesOptions = countries.map(c => ({ value: c.id, label: c.name }));

  const onSubmit = form => {
    setLoading(true);
    dispatch(updateRecord(record.id, form, status => {
      if (status === 'saved') {
        dispatch(toggleAddressEditor());
        dispatch(toast({ message: 'Contractor was successfully updated', intent: 'SUCCESS' }));
      } else if (status === 'error') {
        dispatch(toast({message: 'Server Error', intent: 'DANGER'}));
      }
      setLoading(false);
    }));
  }

  return (
    <Formik
      initialValues={{
        address_1: address_1 || '',
        address_2: address_2 || '',
        address_3: address_3 || '',
        address_4: address_4 || '',
        country_id: country_id || '',
      }}
      onSubmit={onSubmit}>
      {subformik => {
        const { values, handleChange, handleBlur, handleSubmit, dirty, setFieldValue } = formik || subformik;
        return (
          <form onSubmit={handleSubmit}>
            <FormGroup
              label="Address 1"
              labelFor="address_1">
              <InputGroup
                name="address_1"
                id="address_1"
                value={values.address_1 || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              label="Address 2"
              labelFor="address_2">
              <InputGroup
                name="address_2"
                id="address_2"
                value={values.address_2 || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              label="Address 3"
              labelFor="address_3">
              <InputGroup
                name="address_3"
                id="address_3"
                value={values.address_3 || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              label="Address 4"
              labelFor="address_4">
              <InputGroup
                name="address_4"
                id="address_4"
                value={values.address_4 || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              label="Country"
              labelFor="country">
              <Field>
                {({ field }) => {
                  return (
                    <Select
                      isClearable
                      type="select"
                      id="country"
                      value={countriesOptions.find(o => o.value === field.value.country_id)}
                      options={countriesOptions}
                      onChange={option => {
                        setFieldValue('country_id', option ? option.value : 0);
                      }}
                      onBlur={handleBlur}
                      name="Country" />
                )}}
              </Field>
            </FormGroup>
            { !formik && <Button large type="submit" icon="floppy-disk" intent="success" text="Save" disabled={!dirty || loading} /> }
            { !formik && loading && <Spinner /> } 
          </form>
        )
      }}
    </Formik>
  );
}
