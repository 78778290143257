import Fuse from "fuse.js";
import isEmpty from 'lodash/isEmpty';

function filter(data, filters, searchPhrase, fuseOptions){
  return new Promise((resolve, reject) => {

    if (isEmpty(filters) && isEmpty(searchPhrase)){
      resolve(data);
    }

    let filteredData = data;

    if(filters && filters.length){
      filters.forEach(filter => {
        switch (filter.operator) {
          case 'isEmpty': // is null or ''
            filteredData = filteredData.filter(record => record[filter.name] === null || record[filter.name] === '');
            break;
          default:
        };
      });
    }

    if(searchPhrase && searchPhrase.length && fuseOptions) {
      const fuse = new Fuse(filteredData, fuseOptions);

      resolve(fuse.search(searchPhrase).map(({ item }) => item));
    }
    resolve(filteredData);
    return filteredData;
  });
}

export { filter };
