import React, {Component} from 'react';

class Messages extends Component {
  render() {
    return (
      <div>Messages</div>
    );
  }
}

export default Messages;
