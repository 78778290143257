import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {bindActionCreators} from "redux";
import {logoutUser} from "../../store/user/actions";
import {connect} from "react-redux";

class Logout extends Component {


  componentDidMount() {

    this.props.logoutUser();
  }

  render() {
    return (
      <div className="account logout">
        <div className="left">
          <a href="/"><img className="branding" alt="ammotio" src="/images/ammotio.png" /></a>
          <div className="apps-wrapper">
            <h2>do you want to login again?</h2>
            <div className="apps-holder">
              <Link to="/login">Back to login</Link>
            </div>
          </div>
        </div>
        <div className="right">
          <h3>Log Out</h3>
          <p>You've been successfully logged out.</p>
          <p>Stay safe out there!</p>
        </div>
      </div>
    );
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    logoutUser: logoutUser,
  }, dispatch);
}

export default connect(null, matchDispatchToProps)(Logout);
