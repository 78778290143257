import React, {Component} from 'react';
import axios from 'axios';
import { Flex, Box } from '@rebass/grid';
import Widget from './widget';
import moment from 'moment';
import styled from 'styled-components';
import {Spinner} from "@blueprintjs/core";

const StyledDashboard = styled.div`
  width: 100%;
  padding: 10px;
 
  .grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
`


class Dashboard extends Component {

  state = {
    payslip: {
      total: 0,
      date: null,
    },
    working_hours: {
      total: 0,
      date: null,
    },
    invoice: {
      total: 0,
      date: null,
    },
    expenses: {
      total: 0,
      business: 0,
      travel: 0,
    },
    loading: true,
  };

  componentDidMount() {
    this.setState({
      loading: true,
    })

    axios.get('summary').then(result => {
      this.setState({
        loading: false,
        payslip: result.data.payslip,
        working_hours: result.data.working_hours,
        invoice: result.data.invoice,
        expenses: {
          total: result.data.expenses.value,
          business: result.data.expenses.business,
          travel: result.data.expenses.travel,
        },
      });
    }).catch(error => {

    });
  }

  render() {
    const { payslip, working_hours, invoice, expenses, loading } = this.state;
    return (
      <StyledDashboard>
        {loading ? (
          <Spinner className="mt50" />
        ) : (
          <React.Fragment>
            <div className="grid">
              <Widget
                title="Last Payslip"
                symbolBefore="€"
                value={payslip && payslip.value ? payslip.value : 0}
                date={payslip && moment(payslip.date).isValid() ? moment(payslip.date).format('DD/MM/YYYY') : ''}
                footer={{title: 'View All Payslips', link: '/documents/payslips'}}
              />
              <Widget
                title="Last Working Hours"
                value={working_hours && working_hours.value ? working_hours.value : 0}
                symbolAfter=" Hours"
                date={working_hours && moment(working_hours.date).isValid() ? moment(working_hours.date).format('MM/YYYY') : ''}
                footer={{title: 'View All Working Hours', link: '/working-hours'}}
              />
              <Widget
                title="Last Invoice"
                symbolBefore="€"
                value={invoice && invoice.value ? invoice.value : 0}
                date={invoice && invoice.date && moment(invoice.date).isValid() ? moment(invoice.date).format('DD/MM/YYYY') : ''}
                footer={{title: 'View All Invoices', link: '/invoices'}}
              />
              <Widget
                title="Total Expenses This Year"
                symbolBefore="€"
                value={expenses.total ? expenses.total : 0}
                footer={{title: 'View All Expenses', link: '/expenses'}}
              />
            </div>
          </React.Fragment>
          )}
      </StyledDashboard>
    );
  }
}

export default Dashboard;
