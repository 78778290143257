import React, {Component} from 'react';
import SlidingPanel from "../../../components/slidingpanel";
import {Button, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import axios from "axios";
import {DateInput} from "@blueprintjs/datetime";
import moment from "moment";

class SpouseEditor extends Component {
  state = {
    spouse_first_name: '',
    spouse_last_name: '',
    spouse_maiden_name: '',
    spouse_pps_number: '',
    date_of_marriage: null,
    dom: null,
    date_of_separation: null,
    dos: null,
    errors: {},
    saving: false,
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {record} = nextProps;
    if (record){
      const date_of_marriage = record.date_of_marriage && moment(record.date_of_marriage).toDate();
      const date_of_separation = record.date_of_separation && moment(record.date_of_separation).toDate();
      this.setState({
        spouse_first_name: record.spouse_first_name,
        spouse_last_name: record.spouse_last_name,
        spouse_maiden_name: record.spouse_maiden_name,
        spouse_pps_number: record.spouse_pps_number,
        date_of_marriage,
        dom: record.date_of_marriage,
        date_of_separation,
        dos: record.date_of_separation,
      });
    }
  }

  handleChange(event){
    const {name, value} = event.currentTarget;
    this.setState({
      ...this.state,
      [name]: value,
    });

  }

  handleSubmit(event){
    event.preventDefault();

    this.setState({
      saving: true,
    })

    const data = {
      spouse_first_name: this.state.spouse_first_name,
      spouse_last_name: this.state.spouse_last_name,
      spouse_maiden_name: this.state.spouse_maiden_name,
      spouse_pps_number: this.state.spouse_pps_number,
      date_of_marriage: this.state.dom,
      date_of_separation: this.state.dos,
    }

    axios.post('profile/spouse', data).then(result => {
      this.setState({
        spouse_first_name: '',
        spouse_last_name: '',
        spouse_maiden_name: '',
        spouse_pps_number: '',
        date_of_separation: null,
        dos: null,
        date_of_marriage: null,
        dom: null,
        saving: false,
      }, () => {
        this.props.onUpdate();
        this.props.onClose();
      });
    }).catch(error => {
      this.setState({
        saving: false,
      })
    });
  }


  render() {
    const {isOpen, onClose} = this.props;
    return (
      <SlidingPanel width="520px" isOpen={isOpen} title='Spouse' onRequestClose={() => onClose()}>
        <form onSubmit={(event) => this.handleSubmit(event)}>
          <FormGroup
            intent={this.state.errors.spouse_first_name ? Intent.DANGER : Intent.NONE}
            className="mb5"
            label="First Name"
            labelFor="spouse_first_name"
            helperText={this.state.errors.spouse_first_name && this.state.errors.spouse_first_name}
          >
            <InputGroup
              name="spouse_first_name"
              id="spouse_first_name"
              large
              onChange={(event) => this.handleChange(event)}
              value={this.state.spouse_first_name}
            />
          </FormGroup>
          <FormGroup
            className="mb5"
            label="Last Name"
            labelFor="spouse_last_name"
            helperText={this.state.errors.spouse_last_name && this.state.errors.spouse_last_name}
          >
            <InputGroup
              name="spouse_last_name"
              id="spouse_last_name"
              large
              onChange={(event) => this.handleChange(event)}
              value={this.state.spouse_last_name}
            />
          </FormGroup>
          <FormGroup
            className="mb5"
            label="Maiden Name"
            labelFor="spouse_maiden_name"
            helperText={this.state.errors.spouse_maiden_name && this.state.errors.spouse_maiden_name}
          >
            <InputGroup
              name="spouse_maiden_name"
              id="spouse_maiden_name"
              large
              onChange={(event) => this.handleChange(event)}
              value={this.state.spouse_maiden_name}
            />
          </FormGroup>
          <FormGroup
            className="mb5"
            label="PPS Number"
            labelFor="spouse_pps_number"
            helperText={this.state.errors.spouse_pps_number && this.state.errors.spouse_pps_number}
          >
            <InputGroup
              name="spouse_pps_number"
              id="spouse_pps_number"
              large
              onChange={(event) => this.handleChange(event)}
              value={this.state.spouse_pps_number}
            />
          </FormGroup>
          <FormGroup
            className="mb5"
            label="Date of Marriage"
            labelFor="date_of_marriage"
            helperText={this.state.errors.date_of_marriage && this.state.errors.date_of_marriage}
          >
            <DateInput
              inputProps={{style: {width: '100%'}, className: 'bp3-large', leftIcon: "calendar"}}
              id="date_of_marriage"
              className="bp3-large"
              minDate={new Date('1900-01-01')}
              formatDate={date => moment(date).format('DD/MM/YYYY')}
              parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
              onChange={(dt) => this.setState({
                date_of_marriage: dt ? new Date(dt) : null,
                dom: dt ? moment(dt).format('YYYY-MM-DD') : null,
              })}
              placeholder={"DD/MM/YYYY"}
              showActionsBar
              intent={this.state.date_of_marriage ? Intent.DANGER : Intent.NONE}
              value={this.state.date_of_marriage}
            />
          </FormGroup>
          <FormGroup
            className="mb5"
            label="Date of Separation"
            labelFor="date_of_separation"
            helperText={this.state.errors.date_of_separation && this.state.errors.date_of_separation}
          >
            <DateInput
              inputProps={{style: {width: '100%'}, className: 'bp3-large', leftIcon: "calendar"}}
              id="date_of_separation"
              className="bp3-large"
              minDate={new Date('1900-01-01')}
              formatDate={date => moment(date).format('DD/MM/YYYY')}
              parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
              onChange={(dt) => this.setState({
                date_of_separation: dt ? new Date(dt) : null,
                dos: dt ? moment(dt).format('YYYY-MM-DD') : null,
              })}
              placeholder={"DD/MM/YYYY"}
              showActionsBar
              intent={this.state.date_of_separation ? Intent.DANGER : Intent.NONE}
              value={this.state.date_of_separation}
            />
          </FormGroup>
          <Button loading={this.state.saving} large className="mt20" type="submit" intent="SUCCESS" icon="floppy-disk">Save</Button>
        </form>
      </SlidingPanel>
    );
  }
}

export default SpouseEditor;
