const layout=(
  //initial state
  state = {
    menu: true
  },
  action)=>{
  if(action.type === "TOGGLE_MENU"){
    state = {...state, menu: !state.menu}
  }
  return state;
};

export default layout;
