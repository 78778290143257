import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import SlidingPanel from "../slidingpanel";
import LeftMenu from "./menu";
import {bindActionCreators} from "redux";
import {toggleMenu} from "../../store/layout/actions";
import {connect} from "react-redux";

class Menu extends Component {
    state = {isEditorOpen: true}
    render() {
        const { menu, user, toggleMenu, modules } = this.props;
        if (user.data && user.data.id) {
          return (
            <div>
              <MediaQuery minWidth={991}>
                <LeftMenu isVisible={menu} isMobile={false} modules={modules}/>
              </MediaQuery>
              <MediaQuery maxWidth={991}>
                <SlidingPanel width="280px"
                       hideHeader={true}
                       from="left"
                       isOpen={ !menu }
                       onRequestClose={() => toggleMenu()}
                >
                  <LeftMenu isVisible={ true } isMobile={true} toggleMenu={toggleMenu} modules={modules}/>
                </SlidingPanel>
              </MediaQuery>
            </div>
          );
        } else {
          return (
            <React.Fragment/>
          )
        }
    }
}

function mapStateToProps(state){
  return{
    menu: state.layout.menu,
    user: state.user,
    modules: state.user.modules,
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    toggleMenu: toggleMenu,
  }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Menu);
