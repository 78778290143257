import axios from 'axios';
import {profileConstants} from '../constants';

export function getProfile(){
  return function(dispatch){
    dispatch({type:profileConstants.GET_PROFILE_REQUEST});
    axios.get('/profile')
      .then((result)=>{
        dispatch({type: profileConstants.GET_PROFILE_SUCCESS, payload: result.data});
      })
      .catch((err)=>{
        dispatch({type:profileConstants.GET_PROFILE_FAILURE, error: err});
      })
  }
}
