import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {FormGroup, InputGroup, Button, Intent, Dialog, Classes} from '@blueprintjs/core';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {toggleBankAccountEditor, insertBankAccount, updateBankAccount, deleteBankAccount} from "../../../../store/companies/actions";

import {Box, Flex} from "@rebass/grid";
import Icon from "../../../../components/icon";

class BankAccountEditor extends Component {

  constructor(props) {
    super(props);

    this.state = {
      record: {
        iban: null,
        name: null,
        sort_code: null,
        account_number: null,
      },
      deleteDialog: false,
      errors: {},
      sent: false,
    };
  }

  componentDidMount() {
    this.loadRecord()
  }

  loadRecord() {
    if(this.props.record.id) {
      this.setState({
        record: {
          iban: this.props.record.iban,
          name: this.props.record.name,
          sort_code: this.props.record.sort_code,
          account_number: this.props.record.account_number,
        },
      })
    }
  }

  handleDelete()
  {
    this.setState({deleteDialog: false});
    this.props.deleteBankAccount(this.props.match.params._id, this.props.record.id, () => {
      this.props.toast({message: 'Bank Account was successfully deleted', intent: 'SUCCESS'});
      this.props.toggleBankAccountEditor();
    });
  }

  handleSave(e) {
    e.preventDefault();
    this.setState({sent: true});
    if(this.handleValidation()) {
      const { insertBankAccount, updateBankAccount, toggleBankAccountEditor, toast, record } = this.props;
      if (record.id){
        updateBankAccount(record.company_id, record.id, this.state.record, status => {
          if (status === 'saved'){
            toast({message: 'Bank Account was successfully updated', intent: 'SUCCESS'});
            toggleBankAccountEditor();
          } else if (status === 'error') {
            toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      } else {
        insertBankAccount(this.props.match.params._id, this.state.record, status => {
          if (status === 'saved'){
            toast({message: 'Bank Account was successfully added', intent: 'SUCCESS'});
            toggleBankAccountEditor();
          } else if (status === 'error') {
            toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      }
    } else {
      console.log('form error');
    }

  }

  handleValidation(){
    let record = this.state.record;
    let errors = {};
    let formIsValid = true;

    if(!record['iban']){
      formIsValid = false;
      errors['iban'] = "This field is required";
    }

    if(!record['name']){
      formIsValid = false;
      errors['name'] = "This field is required";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleChange(e){
    let record = this.state.record;
    record[e.currentTarget.name] = e.currentTarget.value;
    this.setState({record});
    if (this.state.sent){
      this.handleValidation();
    }
  }

  render() {
    const {record} = this.props;
    return (
      <React.Fragment>
        <form onSubmit={(e) => this.handleSave(e)}>
          <FormGroup
            intent={this.state.errors.iban ? Intent.DANGER : Intent.SUCCESS}
            label="IBAN"
            labelFor="iban"
            labelInfo="*"
            helperText={this.state.errors.iban && this.state.errors.iban}
          >
            <InputGroup
              intent={this.state.errors.iban ? Intent.DANGER : Intent.NONE}
              onChange={(e) => this.handleChange(e)}
              name="iban"
              id="iban"
              large
              placeholder="IBAN"
              defaultValue={this.state.record.iban}
            />
          </FormGroup>
          <FormGroup
            intent={this.state.errors.name ? Intent.DANGER : Intent.SUCCESS}
            label="Account Name"
            labelFor="name"
            helperText={this.state.errors.name && this.state.errors.name}
          >
            <InputGroup
              intent={this.state.errors.name ? Intent.DANGER : Intent.NONE}
              onChange={(e) => this.handleChange(e)}
              name="name"
              id="name"
              large
              placeholder="Account Name"
              defaultValue={this.state.record.name}
            />
          </FormGroup>
          <Flex flexWrap={'wrap'}>
            <Box width={['100%','50%','50%']} pr={['0', '5px']}>
              <FormGroup
                intent={this.state.errors.sort_code ? Intent.DANGER : Intent.SUCCESS}
                label="Sort Code"
                labelFor="sort_code"
                helperText={this.state.errors.sort_code && this.state.errors.sort_code}
              >
                <InputGroup
                  intent={this.state.errors.sort_code ? Intent.DANGER : Intent.NONE}
                  onChange={(e) => this.handleChange(e)}
                  name="sort_code"
                  id="sort_code"
                  large
                  placeholder="Sort Code"
                  defaultValue={this.state.record.sort_code}
                />
              </FormGroup>
            </Box>
            <Box width={['100%','50%','50%']} pr={['0', '5px']}>
              <FormGroup
                intent={this.state.errors.account_number ? Intent.DANGER : Intent.SUCCESS}
                label="Account Number"
                labelFor="account_number"
                helperText={this.state.errors.account_number && this.state.errors.account_number}
              >
                <InputGroup
                  intent={this.state.errors.account_number ? Intent.DANGER : Intent.NONE}
                  onChange={(e) => this.handleChange(e)}
                  name="account_number"
                  id="account_number"
                  large
                  placeholder="Account Number"
                  defaultValue={this.state.record.account_number}
                />
              </FormGroup>
            </Box>
          </Flex>
          <div className="button-bar">
            <Button large type="submit" icon="floppy-disk" intent="success" text="Save" />
            {record && record.id && <Button style={{marginLeft: 10}} large onClick={() => this.setState({deleteDialog: true})} icon={<Icon color="#fff" icon="trash" />} intent="danger" text="Delete" />}
          </div>
        </form>
        <Dialog
          icon="info-sign"
          onClose={() => this.setState({deleteDialog: false})}
          title="Bank Account Delete"
          isOpen={this.state.deleteDialog}>
            <div className={Classes.DIALOG_BODY}>
              <div>Are you sure?</div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => this.setState({deleteDialog: false})}>Cancel</Button>
                <Button intent="DANGER" onClick={() => this.handleDelete()}>Delete</Button>
              </div>
            </div>
      </Dialog>
    </React.Fragment>
    );
  }
}

function mapStateToProps(state){
  return {
    record: state.companies.recordToEdit,
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    toast,
    toggleBankAccountEditor,
    updateBankAccount,
    insertBankAccount,
    deleteBankAccount,
  }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withRouter(BankAccountEditor));
