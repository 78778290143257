import axios from 'axios';
import {expensesReportsConstants} from './constants';


export function getSummary(){
  return function(dispatch){
    dispatch({type:expensesReportsConstants.EXPENSES_SUMMARY_REQUEST});
    axios.get('/reports/expenses?year=2019&month=8&status=1').then(result => {
      dispatch({type:expensesReportsConstants.EXPENSES_SUMMARY_SUCCESS, payload: result.data});
    }).catch(error => {
      dispatch({type:expensesReportsConstants.EXPENSES_SUMMARY_FAILURE, error});
    });
  }
}
