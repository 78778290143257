import { expensesReportsConstants } from './constants';
const initialState = {
  loading: false,
  records: [],
  error: {},
  searchPhrase: localStorage.getItem('ammotio-reports-expenses-search'),
  filters: localStorage.getItem('ammotio-reports-expenses-filter') ? JSON.parse(localStorage.getItem('ammotio-reports-expenses-filter')) : []
}

export function reportsExpenses(state = initialState, action, x) {
  switch (action.type) {
    case expensesReportsConstants.EXPENSES_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
        records: []
      };
    case expensesReportsConstants.EXPENSES_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload.data,
      };
    case expensesReportsConstants.EXPENSES_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        records: []
      };
    default:
      return state
  }
};
