import moment from 'moment';

export const baseFormatForDate = date =>
  date && moment(date).isValid()
    ? moment(date).format('DD/MM/YYYY').toString()
    : '';

export const converDateForServer = date => 
  date && moment(date).isValid()
    ? `${moment(date).format('YYYY-MM-DD')} 00:00:00`
    : null;