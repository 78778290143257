import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import Months from "./Months";
import ExpensesList from "./ExpensesList";

class Overview extends Component {
  render() {
    return (
      <div className="split">
        <div className="left w-320px">
          <Months {...this.props}/>
        </div>
        <div className="right">
          <ExpensesList onExpenseExited={() => this.onExpenseEdited()} {...this.props}/>
        </div>
      </div>
    );
  }
}

export default withRouter(Overview);
