import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';

const StyledMenu = styled.div`

  background-color: #374561;
  width: 280px;
  height: 100%;
  display: flex;
  z-index: 4;
  overflow: hidden;
  min-width: 280px;
  flex-direction: column;
  transition: margin-left .4s cubic-bezier(0.86, 0, 0.07, 1);
  
  
  &.hidden {
    margin-left: -280px;  
  }

  .brand {
    width: 280px;
    background-color: #2f3c56;
    height: 64px;
    position: relative;
    cursor: pointer;
    display: flex;
    padding: 0 15px;

    img {
      align-self: center;
    }

    .burger {
      position: absolute;
      right: 5px;
      top: 12px;
      width: 40px;
      height: 40px;
      padding: 10px;
      cursor: pointer;

      span {
        float: left;
        display: block;
        position: relative;
        margin: 9px 0;
        width: 20px;
        height: 2px;
        background: #fff;
        transition: width .2s ease-in-out;

        &::before,
        &::after {
          position: absolute;
          background: #fff;
          right: 0;
          height: 2px;
          content: " ";
        }

        &::before {
          top: 6px;
          width: 20px;
          transition: width .2s ease-in-out;
        }

        &::after {
          top: -6px;
          width: 15px;
          transition: width .2s ease-in-out;
        }
      }
    }

    &:hover {
      .burger {
        span {
          &::after {
            width: 20px;
          }
        }
      }
    }
  }

  .menu-wrap {
    position: relative;
    overflow-x: hidden;
    height: 100%;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 280px;
    transition: 0.6s;

    li {
      ul {
        svg {
          height: 42px;
          max-width: 42px;
          margin: 30px 47px;
          padding: 7px;
          border-radius: 5px;
          background-color: #74b7ec;
          fill: #fff;
        }

        .arrow-down {
          position: absolute;
          top: 71px;
          right: 67px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #74b7ec;
        }
      }
    }

    &.has-children {
      width: 140px;

      li {
        a {
          padding: 24px 16px 20px;
          transition: 0.6s;

          svg {
            margin-left: 27px;
            margin-top: -11px;
            height: 18px;
            max-width: 18px;
            transition: 0.6s;
            fill: #5db8f1;
          }

          p {
            font-size: 11px;
            text-align: center;
            width: 100%;
            margin: 11px 0 -5px;
            transition: 0.6s;
          }

          .bullet {
            opacity: 0;
            width: 10px;
            height: 10px;
            top: 37px;
            transition: 0.6s;
          }
        }

        &.active {

          ul {
            left: 140px;
            transition: 0.6s;

            li {
              padding: 1px;
              border-bottom: 1px solid #374561;
              border-right: 3px solid #74b7ec;

              a {
                width: 100%;
                border-radius: 0;
              }

              &:first-child {
                border: 0;
              }

              &:nth-child(2) {
                border-top: 1px solid #374561;
              }

              &.active {
                background-color: #74b7ec;

                a {
                  background-color: #74b7ec;
                }
              }
            }
          }
        }
      }
    }


    li {
      height: 72px;
      padding: 8px 16px 0;

      ul {
        background-color: #313c54;
        width: 140px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 280px;

        li {
          height: inherit;
        }
      }

      a {
        letter-spacing: 0.8px;
        display: block;
        padding: 20px 16px 16px;
        text-align: left;
        font-size: 12px;
        font-weight: 100;
        color: #fff;
        background-color: #324361;
        border-radius: 4px;
        width: 100%;
        position: relative;
        transition: 0.6s;

        svg {
          position: absolute;
          height: 24px;
          max-width: 24px;
          text-align: center;
          fill: #5db8f1;
          transition: 0.6s;
        }

        p {
          margin: 4px 0 6px 40px;
          transition: 0.6s;
        }

        .bullet {
          border: 1px solid #3a587b;
          border-radius: 7px;
          display: block;
          width: 14px;
          height: 14px;
          position: absolute;
          right: 16px;
          top: 24px;
          opacity: 1;
          transition: opacity 0.2s, right 0.6s;
          transition-delay: 0.4s;
        }
      }

      &.active {
        a {
          background-color: #2c3c59;

          .bullet {
            background-color: #22d1a2;
          }
        }
      }
    }
  }

`

class LeftMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarCollapsed: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ sidebarCollapsed: nextProps.sidebarCollapsed });
    }

    handleClick(event) {
        const { isMobile } = this.props;
        if (isMobile){

        }
    }

    render() {
        const { modules, isVisible } = this.props;
        let hasChildren = false;
        const path = `/${this.props.location.pathname.split("/")[1]}`;
        modules.map(({link, children}) => {
            if (link === path && children.length) {
                hasChildren = true
            }
            return 1;
        });

        return (
          <StyledMenu className={isVisible ? '' : 'hidden'}>
              <MediaQuery maxWidth={768}>
                  <div className="brand" onClick={e => this.props.toggleMenu()}>
                      <img className="symbol" src="/images/ammotio_symbol.png" alt="AMMOTIO"/>
                      <img className="logo" src="/images/ammotio_logo.png" alt="AMMOTIO"/>
                      <div className="burger"><span/></div>
                  </div>
              </MediaQuery>
              <div className="menu-wrap">
                  <ul className={hasChildren ? 'has-children' : ''}>
                      {modules.map(({
                                        id, name, link, icon, children
                                    }) => {
                          return (
                            <div key={id} data-to={link}>
                                <li className={path === link ? 'active' : ''}>
                                    <Link to={link} className="left-nav-button"
                                          onClick={(e) => this.handleClick(e)}>
                                        <svg className="icon">
                                            <use xlinkHref={`/ammotio-SVG-sprite.svg#${icon}`}></use>
                                        </svg>
                                        <p>{name}</p>
                                        <span className="bullet"/>
                                    </Link>
                                    {children.length ? <ul>
                                        <li>
                                            <svg className="icon">
                                                <use xlinkHref={icon}></use>
                                            </svg>
                                            <div className="arrow-down"></div>
                                        </li>
                                        {children.map((item) => {
                                            return (
                                              <li key={item.id}>
                                                  <Link to={item.link} className="left-nav-button"
                                                        onClick={(e) => this.handleClick(e)}>
                                                      {item.name}
                                                  </Link>
                                              </li>);
                                        })}
                                    </ul> : ''}
                                </li>
                            </div>
                          );
                      })}
                  </ul>
              </div>

          </StyledMenu>
        );
    }
}

export default withRouter(LeftMenu);
