import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {toggleNotificationsEditor, updateRecord} from "../../../../store/contractors/actions";
import {connect} from "react-redux";
import Select from 'react-select';
import {Button, Card, FormGroup} from "@blueprintjs/core";
import Icon from "../../../../components/icon";
import styled from "styled-components";

const StyledEditor = styled.div`
  .bp3-card {
    margin: 0 5px 16px 0;
    
    h4 {
      font-size: 16px;
      margin: 0 0 10px;
    }
  }
`;


class NotificationsEditor extends Component {
  state = {
    record: {
      notifications_payslips: null,
      notifications_working_hours: null,
    },
    values: {
      notifications_payslips: null,
      notifications_working_hours: null,
    },
    errors: {},
    options: [
      {label: 'Active', value: true},
      {label: 'Inactive', value: false},
    ]
  };

  componentDidMount() {
    if(this.props.record && this.props.record.id) {
      this.setState({
        record: {
          notifications_payslips: this.props.record.notifications_payslips === 0 ? false : this.props.record.notifications_payslips === 1 ? true : null,
          notifications_working_hours: this.props.record.notifications_working_hours === 0 ? false : this.props.record.notifications_working_hours === 1 ? true : null,
        },
        values: {
          notifications_payslips: this.props.record.notifications_payslips === 0 ? {value: false, label: 'Inactive'} : this.props.record.notifications_payslips === 1 ? {value: true, label: 'Active'} : null,
          notifications_working_hours: this.props.record.notifications_working_hours === 0 ? {value: false, label: 'Inactive'} : this.props.record.notifications_working_hours === 1 ? {value: true, label: 'Active'} : null,
        }
      })
    }
  }

  handleSave(event) {
    event.preventDefault();
    this.props.updateRecord(this.props.record.id, this.state.record, () => {});
  }

  handleNotificationChange(type, value) {
    let val = null;
    if (!value) {
      val = null;
    } else if (value.hasOwnProperty('value') && value.value === false){
      val = false;
    } else {
      val = true;
    }
    this.setState({
      ...this.state,
      record: {
        ...this.state.record,
        [type]: val
      },
      values: {
        ...this.state.values,
        [type]: value,
      }
    },() => {
      if (this.state.sent){
        this.handleValidation();
      }
    });
  }

  render() {
    const {record, values} = this.state;
    return (
      <StyledEditor>
        <form onSubmit={(e) => this.handleSave(e)}>
          <Card>
            <h4>Notifications</h4>
            <FormGroup
              label="Email notifications - Payslip"
              labelFor="notifications_payslips"
            >
              <Select
                isClearable
                placeholder="Not Set"
                id="notifications_payslips"
                options={this.state.options}
                name="notifications_payslips"
                onChange={option => this.handleNotificationChange('notifications_payslips', option)}
                value={values.notifications_payslips}
              />
            </FormGroup>

            <FormGroup
              label="Email notifications - Working Hours"
              labelFor="notifications_payslips"
            >
            <Select
              isClearable
              placeholder="Not Set"
              id="notifications_working_hours"
              options={this.state.options}
              name="notifications_working_hours"
              onChange={option => this.handleNotificationChange('notifications_working_hours', option)}
              value={values.notifications_working_hours}
            />
            </FormGroup>
          </Card>
          <div className="button-bar">
            <Button large type="submit" icon="floppy-disk" intent="success" text="Save" />
            {record && record.id && <Button style={{marginLeft: 10}} large onClick={() => this.setState({deleteDialog: true})} icon={<Icon color="#fff" icon="trash" />} intent="danger" text="Delete" />}
          </div>
        </form>

      </StyledEditor>
    );
  }
}

function mapStateToProps(state){
  return {
    record: state.contractors.recordToEdit,
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    toast,
    toggleNotificationsEditor,
    updateRecord,
  }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(NotificationsEditor);
