import React, {Component} from 'react';
import {Button, Checkbox, InputGroup, Popover, Position} from "@blueprintjs/core";
import { AutoSizer, List } from 'react-virtualized';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { Spinner } from "@blueprintjs/core";
import { filter } from '../../../utils/filter';

import { toggleEditor, insertRecord, setFilter, removeFilters, search } from "../../../store/companies/actions";

const FUSE_OPTIONS = {
  threshold: 0.2,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    {name: 'name', weight: 0.2},
    {name: 'address_1', weight: 0.2},
    {name: 'address_2', weight: 0.1},
    {name: 'address_3', weight: 0.1},
    {name: 'address_4', weight: 0.1},
    {name: 'country', weight: 0.1},
    {name: 'cro_number', weight: 0.1},
    {name: 'vat_number', weight: 0.1},
  ]
};

class DataList extends Component {
  constructor(props){
    super(props);
    this._rowRenderer = this._rowRenderer.bind(this);
    this._setRef = this._setRef.bind(this);

    this.state = {
      records: [],
      selectedId: null,
      filters: {
        cro_number: this.props.filters.some(filter => filter.name === 'cro_number'),
        vat_number: this.props.filters.some(filter => filter.name === 'vat_number'),
        date_of_incorporation: this.props.filters.some(filter => filter.name === 'date_of_incorporation'),
        financial_year_end: this.props.filters.some(filter => filter.name === 'financial_year_end'),
      }
    }
  }

  componentDidMount() {
    if (this.props.records.length) {
      console.log('sss');
      const { filters, searchPhrase, records } = this.props;
      const filteredData = filter(records, filters, searchPhrase, FUSE_OPTIONS);
      console.log(filteredData);
      const sortedRecords = this.props.records.sort((a, b) => a.name.localeCompare ? a.name.localeCompare(b.name) : 0);
      this.setState({
        records: sortedRecords
      });
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!nextProps.loading){
      const { filters, searchPhrase, records, match } = nextProps;
      filter(records, filters, searchPhrase, FUSE_OPTIONS).then(data => {
        const sortedRecords = data.sort((a, b) =>  a.name.localeCompare ? a.name.localeCompare(b.name) : 0);
        this.setState({
          records: sortedRecords,
          selectedId: match.params._id
        }, () => {
          this.list.forceUpdateGrid();
        });
      });
    }
  }

  handleFilter(name, operator, value) {
    const newFilters = this.state.filters;
    newFilters[name] = value;

    this.setState({
      filters: newFilters,
    });
    this.props.setFilter(name, operator, value);
  }

  handleFilterClear() {
    this.handleFilter('cro_number', 'isEmpty', false);
    this.handleFilter('vat_number', 'isEmpty', false);
    this.handleFilter('date_of_incorporation', 'isEmpty', false);
    this.handleFilter('financial_year_end', 'isEmpty', false);
  }

  render() {
    const { loading, filters, search, searchPhrase }  = this.props;
    return (
      <div className="left relative">
        <div className="absolute flex column">
          {!loading ? (
            <React.Fragment>
              <div className="tools">
                <Button large onClick={() => this.props.toggleEditor({})} icon="add" intent="success" text="Add New" />
              </div>
              <div className="tools">
                <InputGroup
                  leftIcon="search"
                  onChange={(e) => {search(e.currentTarget.value)}}
                  name="vat_number"
                  id="vat_number"
                  large
                  placeholder="Search"
                  defaultValue={searchPhrase}
                />
                <Popover popoverClassName="blue" content={
                  <div className="filters">
                    <div className="grid">
                      <Checkbox onChange={(el) => this.handleFilter('cro_number', 'isEmpty', el.currentTarget.checked)} inline large checked={this.state.filters.cro_number}>No CRO Number</Checkbox>
                      <Checkbox onChange={(el) => this.handleFilter('vat_number', 'isEmpty', el.currentTarget.checked)} inline large checked={this.state.filters.vat_number}>No VAT Number</Checkbox>
                      <Checkbox onChange={(el) => this.handleFilter('date_of_incorporation', 'isEmpty', el.currentTarget.checked)} inline large checked={this.state.filters.date_of_incorporation}>No Date of Inc.</Checkbox>
                      <Checkbox onChange={(el) => this.handleFilter('financial_year_end', 'isEmpty', el.currentTarget.checked)} inline large checked={this.state.filters.financial_year_end}>No Financial Year End</Checkbox>
                    </div>
                    <div className="footer">
                      <Button text="Clear" intent="primary" onClick={() => this.handleFilterClear()}/>
                    </div>
                  </div>
                } position={Position.BOTTOM}>
                  <Button className={Object.keys(filters).length && 'has-filters'} style={{marginLeft: '5px'}} large icon="filter-list" text="Filter">{Object.keys(filters).length > 0 && <div className="badge">{Object.keys(filters).length}</div>}</Button>
                </Popover>
              </div>
              <div className="stretch">
                <AutoSizer>
                  {({width, height}) => (
                    <div>
                      <List
                        className="list"
                        ref={(list) => {
                          this.list = list
                        }}
                        height={height}
                        rowCount={this.state.records.length}
                        rowHeight={105}
                        rowRenderer={this._rowRenderer}
                        width={width}
                      />
                    </div>
                  )}
                </AutoSizer>
              </div>
            </React.Fragment>
            ) : (<Spinner className="mt50" />)}
        </div>
      </div>
    );
  }

  _rowRenderer({index, isScrolling, key, style}) {
    const {showScrollingPlaceholder} = this.state;
    const record = this.state.records[index];
    if (showScrollingPlaceholder && isScrolling) {
      return (
        <div
          key={key}
          style={style}>
          Scrolling...
        </div>
      );
    }

    const cn = "row-wrapper" + ( parseInt(this.props.match.params._id) === record.id ? ' selected' : '');
    const subView = this.props.match.params._view ? `/${this.props.match.params._view}` : '';
    return (
      <div className={cn} key={key} style={style} onClick={() => this.props.onSelect && this.props.onSelect()}>
        <Link to={`/companies/${record.id}${subView}`} className="row">
          <h3>{record.name}</h3>
          <div className="body">
            <div><span><i className="divider blue1"/>CRO Number: </span><b>{record.cro_number}</b></div>
            <div><span><i className="divider blue2"/>TAX Number: </span><b>{record.vat_number}</b></div>
          </div>
        </Link>
      </div>
    );
  }

  _setRef (ref) {
    this.list = ref;
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    toggleEditor,
    insertRecord,
    setFilter,
    removeFilters,
    search
  }, dispatch);
}

function mapStateToProps(state){
  if (state.socket && state.socket.event === "TOAST"){
    return {
      toast: state.socket.data,
    }
  }

  return {
    filters: state.companies.filters,
    searchPhrase: state.companies.searchPhrase,
    loading: state.companies.loading,
    records: state.companies.records,
    selectedRecord: state.companies.selectedRecord,
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(DataList));
