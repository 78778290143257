import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {getWorkingHours} from "../../../../store/contractors/actions";
import {connect} from "react-redux";
import { AutoSizer, Table, Column } from 'react-virtualized';
import styled from "styled-components";
import { CSVLink } from "react-csv";

import 'react-virtualized/styles.css';
import {InputGroup, NonIdealState, Spinner} from "@blueprintjs/core";
import Fuse from "fuse.js";
import Icon from "../../../../components/icon";
import moment from "moment";

const StyledList = styled.div`
  height: 100%;
  width: 100%;
  padding: 8px 16px;
`;

const FUSE_OPTIONS = {
  threshold: 0.5,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    {name: 'period', weight: 0.4},
    {name: 'hours', weight: 0.2},
    {name: 'rate', weight: 0.2},
    {name: 'net', weight: 0.1},
    {name: 'gross', weight: 0.1},
  ]
};

const EXCEL_HEADERS = [
  { label: 'Period', key: 'period' },
  { label: 'Hours', key: 'hours' },
  { label: 'Rate', key: 'rate' },
  { label: 'Cheque Total', key: 'net' },
  { label: 'Paid', key: 'paid' },
];

class WorkingHours extends Component {

  state = {
    search: '',
    records: [],
    record: parseInt(this.props.match.params._id)
  }

  componentDidMount() {
    this.props.getWorkingHours(this.props.match.params._id, r => {});
  }

  componentWillReceiveProps(nextProps, nextContext) {

    if (this.state.record !== parseInt(nextProps.match.params._id)){
      this.setState({
        record: parseInt(nextProps.match.params._id),
      }, () => {
        this.props.getWorkingHours(this.props.match.params._id, r => {});
      });
    } else {
      if (nextProps.records.length > 0){
        let formatedRecords = [];
        nextProps.records.map(record => {
          record = {
            ...record,
            net: record.net ? record.net.toFixed(2) : parseInt('0').toFixed(2),
            paid: record.paid ? record.paid.toFixed(2) : parseInt('0').toFixed(2),
            hours: record.hours ? record.hours.toFixed(2) : parseInt('0').toFixed(2),
            hours_away: record.hours_away ? record.hours_away.toFixed(2) : parseInt('0').toFixed(2),
            vat_rate: `${record.vat_rate}%`,
            period: moment(record.period).isValid() ? moment(record.period).format('MM/YYYY') : ''
          };
          return formatedRecords.push(record);
        });
        this.setState({
          records: formatedRecords,
        });
      } else {
        this.setState({
          records: [],
        });
      }
    }
  }

  toExcel() {
    const fuse = new Fuse(this.props.records, FUSE_OPTIONS);
    const records = this.state.search.length ? fuse.search(this.state.search) : this.props.records;
    console.log(records);
  }

  render() {
    //const fuse = new Fuse(workingHours, FUSE_OPTIONS);

    const { loading } = this.props;
    const { records } = this.state;
    return (
      <React.Fragment>
        {loading ? (
          <Spinner className="mt50" />
        ) : (
          <React.Fragment>
            <div className="tools">
              <div></div>
              <div className="flex">
                {records.length > 0 &&
                  <CSVLink
                    headers={EXCEL_HEADERS}
                    data={records}
                    filename={"working_hours.csv"}
                    className="mr5"
                    target="_blank"
                  >
                    <Icon icon="csv" size={38}/>
                  </CSVLink>
                }
                <InputGroup
                  leftIcon="search"
                  onChange={(e) => {
                    this.setState({
                      search: e.currentTarget.value
                    })
                  }}
                  large
                  placeholder="Search"
                />
              </div>
            </div>
            {records.length ? (
              <StyledList>
              <AutoSizer>
                {({width, height}) => (
                  <Table
                    ref="Table"
                    headerHeight={30}
                    noRowsRenderer={this._noRowsRenderer}
                    overscanRowCount={10}
                    rowClassName={this._rowClassName}
                    rowHeight={30}
                    rowCount={records.length}
                    rowGetter={({ index }) => records[index]}
                    height={height}
                    width={width}>
                    <Column
                      label="Period"
                      dataKey="period"
                      width={width * 0.1}
                    />
                    <Column
                      width={width * 0.1}
                      disableSort
                      label="Hours"
                      dataKey="hours"
                      flexGrow={1}
                    />
                    <Column
                      width={width * 0.1}
                      disableSort
                      label="Rate"
                      dataKey="rate"
                      flexGrow={1}
                    />
                    <Column
                      width={width * 0.1}
                      disableSort
                      label="Cheque Total"
                      dataKey="net"
                      flexGrow={1}
                    />
                    <Column
                      width={width * 0.1}
                      disableSort
                      label="Paid"
                      dataKey="paid"
                      flexGrow={1}
                    />
                  </Table>
                )}
              </AutoSizer>
            </StyledList>
            ) : (
              <NonIdealState
                icon="search"
                title="No working hours found for this contractor"
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}


function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    getWorkingHours
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    loading: state.contractors.workingHoursLoading,
    records: state.contractors.workingHours,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(WorkingHours));
