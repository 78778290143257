export const companiesConstants = {
  COMPANIES_REQUEST: 'GET_COMPANIES_REQUEST',
  COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
  COMPANIES_FAILURE: 'GET_COMPANIES_FAILURE',

  COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
  COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
  COMPANY_FAILURE: 'GET_COMPANY_FAILURE',

  COMPANY_ADD_REQUEST: 'COMPANY_ADD_REQUEST',
  COMPANY_ADD_SUCCESS: 'COMPANY_ADD_SUCCESS',
  COMPANY_ADD_FAILURE: 'COMPANY_ADD_FAILURE',

  COMPANY_EDIT_REQUEST: 'COMPANY_EDIT_REQUEST',
  COMPANY_EDIT_SUCCESS: 'COMPANY_EDIT_SUCCESS',
  COMPANY_EDIT_FAILURE: 'COMPANY_EDIT_FAILURE',

  COMPANY_DELETE_REQUEST: 'COMPANY_DELETE_REQUEST',
  COMPANY_DELETE_SUCCESS: 'COMPANY_DELETE_SUCCESS',
  COMPANY_DELETE_FAILURE: 'COMPANY_DELETE_FAILURE',

  //BANK STATEMENTS
  EMPLOYEES_REQUEST: 'COMPANY_EMPLOYEES_REQUEST',
  EMPLOYEES_SUCCESS: 'COMPANY_EMPLOYEES_SUCCESS',
  EMPLOYEES_FAILURE: 'COMPANY_EMPLOYEES_FAILURE',

  //BANK STATEMENTS
  BANK_STATEMENTS_REQUEST: 'COMPANY_BANK_STATEMENTS_REQUEST',
  BANK_STATEMENTS_SUCCESS: 'COMPANY_BANK_STATEMENTS_SUCCESS',
  BANK_STATEMENTS_FAILURE: 'COMPANY_BANK_STATEMENTS_FAILURE',

  //BANK ACCOUNTS
  BANK_ACCOUNTS_REQUEST: 'COMPANY_BANK_ACCOUNTS_REQUEST',
  BANK_ACCOUNTS_SUCCESS: 'COMPANY_BANK_ACCOUNTS_SUCCESS',
  BANK_ACCOUNTS_FAILURE: 'COMPANY_BANK_ACCOUNTS_FAILURE',


  BANK_ACCOUNTS_ADD_REQUEST: 'COMPANY_BANK_ACCOUNTS_ADD_REQUEST',
  BANK_ACCOUNTS_ADD_SUCCESS: 'COMPANY_BANK_ACCOUNTS_ADD_SUCCESS',
  BANK_ACCOUNTS_ADD_FAILURE: 'COMPANY_BANK_ACCOUNTS_ADD_FAILURE',

  BANK_ACCOUNTS_EDIT_REQUEST: 'COMPANY_BANK_ACCOUNTS_EDIT_REQUEST',
  BANK_ACCOUNTS_EDIT_SUCCESS: 'COMPANY_BANK_ACCOUNTS_EDIT_SUCCESS',
  BANK_ACCOUNTS_EDIT_FAILURE: 'COMPANY_BANK_ACCOUNTS_EDIT_FAILURE',

  BANK_ACCOUNTS_DELETE_REQUEST: 'COMPANY_BANK_ACCOUNTS_DELETE_REQUEST',
  BANK_ACCOUNTS_DELETE_SUCCESS: 'COMPANY_BANK_ACCOUNTS_DELETE_SUCCESS',
  BANK_ACCOUNTS_DELETE_FAILURE: 'COMPANY_BANK_ACCOUNTS_DELETE_FAILURE',


  COMPANIES_TOGGLE_EDITOR: 'COMPANIES_TOGGLE_EDITOR',
  TOGGLE_BANK_ACCOUNT_EDITOR: 'COMPANIES_TOGGLE_BANK_ACCOUNT_EDITOR',
  COMPANIES_TOGGLE_LIST: 'COMPANIES_TOGGLE_LIST',

  SET_FILTER: 'COMPANIES_SET_FILTER',
  REMOVE_FILTERS: 'COMPANIES_REMOVE_FILTERS',
  SEARCH: 'COMPANIES_SEARCH',


  COMPANIES_SELECT_RECORD: 'COMPANIES_SELECT_RECORD'
};
