import React, { Component } from 'react';
import {Popover} from '@blueprintjs/core';
import ExpenseTypeFilter from './ExpenseTypeFilter';
import ExpenseStatusFilter from './ExpenseStatusFilter';
import styled from 'styled-components';

const StyledFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

class Filter extends Component {
  state = {
    businessCount: 0,
    travelCount: 0,
    business: false,
    travel: false,
    filters: this.props.filters ? this.props.filters : [],
    awaitingCount: 0,
    approvedCount: 0,
    paidCount: 0,
    rejectedCount: 0,
    deletedCount: 0
  }

  componentDidMount() {
    const { expenses } = this.props;
    this.setState({
      businessCount: expenses.filter(item => item.type === 'business').length,
      travelCount: expenses.filter(item => item.type === 'travel').length,
      awaitingCount: expenses.filter(item =>
        item.status && item.status.toLowerCase() === 'submitted'
      ).length,
      approvedCount: expenses.filter(item =>
        item.status && item.status.toLowerCase() === 'approved'
      ).length,
      paidCount: expenses.filter(item =>
        item.status && item.status.toLowerCase() === 'paid'
      ).length,
      rejectedCount: expenses.filter(item =>
        item.status && item.status.toLowerCase() === 'rejected'
      ).length,
      deletedCount: expenses.filter(item =>
        item.status && item.status.toLowerCase() === 'deleted'
      ).length
    })
  }

  setFilter(type, status) {
    let { filters } = this.state;
    if (status) {
      filters.push(type)
    } else {
      filters = filters.filter(item => item !== type);
    }

    this.setState({
      filters
    }, () => this.props.onChange(filters));
  }

  render() {
    const {
      businessCount,
      travelCount,
      awaitingCount,
      approvedCount,
      paidCount,
      rejectedCount,
      deletedCount
    } = this.state;

    return (
      <StyledFilter>
        <ExpenseTypeFilter
          label="Business Expenses"
          onChange={val => this.setFilter('business', val)}
          total={businessCount}
          color="#6c85d1" />
        <ExpenseTypeFilter
          label="Travel Expenses"
          onChange={val => this.setFilter('travel', val)}
          total={travelCount}
          color="#63b7ee" />
        <Popover
          content="Approved"
          minimal={true}
          interactionKind="hover">
          <ExpenseStatusFilter
            onChange={val => this.setFilter('Approved', val)}
            total={approvedCount}
            color="#39cfa3"
            icon="tick-white" />
        </Popover>
        <Popover
          content="Paid"
          minimal={true}
          interactionKind="hover">
          <ExpenseStatusFilter
            onChange={val => this.setFilter('Paid', val)}
            total={paidCount}
            color="#39cfa3"
            icon="tick-white" />
        </Popover>
        <Popover
          content="Awaiting Approval"
          minimal={true}
          interactionKind="hover">
          <ExpenseStatusFilter
            onChange={val => this.setFilter('Submitted', val)}
            total={awaitingCount}
            color="#fca22e"
            icon="clock-with-white-face" />
        </Popover>
        <Popover
          content="Rejected"
          minimal={true}
          interactionKind="hover">
          <ExpenseStatusFilter
            onChange={val => this.setFilter('Rejected', val)}
            total={rejectedCount}
            color="#f8224d"
            icon="close" />
        </Popover>
        <Popover
          content="Deleted"
          minimal={true}
          interactionKind="hover">
          <ExpenseStatusFilter
            onChange={val => this.setFilter('Deleted', val)}
            total={deletedCount}
            color="#f8224d"
            icon="trash" />
        </Popover>
      </StyledFilter>
    );
  }
}

export default Filter;
