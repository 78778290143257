import React from 'react';
import moment from 'moment';
import { Button } from "@blueprintjs/core";

export default ({ data, onClick }) => {
  const incurred = moment(data.incurred).format('DD/MM/YYYY');

  return (
    <React.Fragment>
      <div className={`expense-item ${data.type && data.type === 'travel' ? 'travel' : 'business'}`}>
        <div className="details">
          <div className="info">
            <p className="type">
              {
                data.type && data.type === "travel"
                  ? 'Travel Expense'
                  : 'Business Expense'
              } - {incurred}
            </p>
            <p className="date">{data.description}</p>
          </div>
          <div className="value">
            <div className="status awaiting_approval">
              <div
                className="icon sc-kpOJdX iOciBM"
                size="20"
                color="#fff">
              </div>
            </div>
            <div>{data.total && `€${data.total.toFixed(2)}`}</div>
          </div>
        </div>
        <div className="footer">
          <div className="status">
            Status:&nbsp;<b>{data.status}</b>
          </div>
          <div className="actions">
            <Button
              intent="primary"
              onClick={() => onClick && onClick(data)}>
              View
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
