import React, {Component} from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { Button, Popover, Menu, Position } from '@blueprintjs/core';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';

//actions
import { toggleMenu } from "../../store/layout/actions";
import { toast, socketToast } from "../../store/toaster/actions";

const StyledHeader = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  background-color: #edeef0;
  min-height: 64px;

  .burger {
    position: absolute;
    right: 5px;
    top: 12px;
    width: 40px;
    height: 40px;
    padding: 10px;
    cursor: pointer;

    span {
      float: left;
      display: block;
      position: relative;
      margin: 9px 0;
      width: 20px;
      height: 2px;
      background: #fff;
      transition: width .2s ease-in-out;

      &::before,
      &::after {
        position: absolute;
        background: #fff;
        right: 0;
        height: 2px;
        content: " ";
      }

      &::before {
        top: 6px;
        width: 20px;
        transition: width .2s ease-in-out;
      }

      &::after {
        top: -6px;
        width: 15px;
        transition: width .2s ease-in-out;
      }
    }
  }
  
  .brand {
    display: flex;
    width: 280px;
    background-color: #2f3c56;
    height: 64px;
    min-height: 64px;
    position: relative;
    cursor: pointer;
    align-items: center;
    padding: 0 15px;
  
    @media (max-width: 768px) {
      width: 128px;
    }
  
    .logo {
      position: relative;
      margin-left: 10px;
    }
  
    &:hover {
      .burger {
        span {
          &::after {
            width: 20px;
          }
        }
      }
    }
  }
  
`;

class Header extends Component {

  handleLogout() {
    this.props.history.push('/logout');
  }

  render() {
    const { toggleMenu, user } = this.props;

    if (user.data && user.data.id) {
      return (
        <StyledHeader>
          <div className="brand"  onClick={() => toggleMenu()}>
            <img className="symbol" src="/images/ammotio_symbol.png" alt="AMMOTIO" />
            <MediaQuery minWidth={769}>
              <img className="logo" src="/images/ammotio_logo.png" alt="AMMOTIO" />
            </MediaQuery>
            <div className="burger"><span /></div>
          </div>
          {/*<Button onClick={() => {socketToast({message: 'some message', intent: "danger"})}}>SOCKET TOAST</Button>*/}
          <Popover content={
            <Menu>
              <Menu.Item icon="settings" onClick={() => this.props.history.push('/settings/profile')} text="Settings" />
              <Menu.Divider />
              <Menu.Item onClick={()=> this.handleLogout()} text="Logout" icon="log-out" />
            </Menu>
          } position={Position.BOTTOM}>
            <Button icon="user" text={`${user.data.first_name} ${user.data.last_name}`} />
          </Popover>
        </StyledHeader>
      );
    } else {
      return (
        <React.Fragment/>
      )
    }
  }
}

function mapStateToProps(state){
  return{
    user: state.user,
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    toggleMenu,
    toast,
    socketToast
  }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Header));
