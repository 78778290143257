import { workingHoursConstants } from '../constants';

const initialState = {
  loading: false,
  records: []
}

export function workingHours(state = initialState, action) {
  switch (action.type) {
    case workingHoursConstants.GET_REQUEST:
      return {
        loading: true,
        records: [],
      };
    case workingHoursConstants.GET_SUCCESS:
      console.log(action.payload);
      return {
        loading: false,
        records: action.payload,
      };
    case workingHoursConstants.GET_FAILURE:
      return {
        loading: false,
        error: action.error,
        records: [],
      };
    default:
      return state
  }
}
