import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import Select from "react-select";
import {formattedMonths, formattedYears} from '../../../../utils/months';
import {toast} from "../../../../store/toaster/actions";
import {getSummary} from "./store/actions";
import {connect} from "react-redux";
import { AutoSizer, Table, Column } from 'react-virtualized';
import styled from "styled-components";
import { CSVLink } from "react-csv";
import axios from 'axios';
import 'react-virtualized/styles.css';
import {FormGroup, Button, TextArea, Dialog, Classes, NonIdealState, Spinner, Checkbox, AnchorButton} from "@blueprintjs/core";
import Fuse from "fuse.js";
import Icon from "../../../../components/icon";

const StyledList = styled.div`
  height: 100%;
  width: 100%;
  padding: 8px 16px;
`;

const StyledTools = styled.div`
  .filter-wrapper {
    display: flex;
    flex-flow: row;
    align-items: center;
    
    .months-select {
      width: 150px;
      margin-right: 10px;
    }   
    
    .years-select {
      width: 150px;
    }
    
  }
`;

const FUSE_OPTIONS = {
  threshold: 0.5,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    {name: 'date', weight: 0.4},
    {name: 'details', weight: 0.2},
    {name: 'credit', weight: 0.2},
    {name: 'debit', weight: 0.1},
    {name: 'balance', weight: 0.1},
  ]
};

const EXCEL_HEADERS = [
  { label: 'Date', key: 'submitted' },
  { label: 'Contractor', key: 'user_name' },
  { label: 'Company', key: 'company_name' },
  { label: 'Details', key: 'description' },
  { label: 'Balance', key: 'total' },
];

class Expenses extends Component {
  constructor(props){
    super(props);
    this.state = {
      search: '',
      record: parseInt(this.props.match.params._id),
      selectedMonth: formattedMonths().filter(obj => obj.value === new Date().getMonth() + 1),
      selectedYear: formattedYears().filter(obj => obj.value === new Date().getFullYear()),
      selectedCompany: null,
      selectedData: [],
      rejectDialog: false,
      rejection_reason: '',
      selectedStatus: null,
    }
  }
  
  
  componentDidMount() {  
    this.props.getSummary({company: null, month: new Date().getMonth() + 1, year: new Date().getFullYear()});
  }   
  
  setStatus() {
    if(this.state.selectedStatus) {      
      if(this.state.selectedStatus.value == 3 && !this.state.rejection_reason) {         
        this.setState({rejectDialog: true});
      } else {
        axios.post(`/reports/expenses/travelExpenses/status/update`,{
          expenses: [...new Set(this.state.selectedData)],
          newStatus:  this.state.selectedStatus.value,
          reason: this.state.rejection_reason,
        }).then(result => {
          this.props.getSummary({company: this.state.selectedCompany ? this.state.selectedCompany.value : null, month: this.state.selectedMonth.value ? this.state.selectedMonth.value : new Date().getMonth() + 1, year: this.state.selectedYear.value ? this.state.selectedYear.value : new Date().getFullYear()});
          this.props.toast({message: 'Status has been successfully updated', intent: 'success'});
          this.setState({
            selectedStatus: null,
            rejectDialog: false,
            rejection_reason: '',
          });
        })
      }
    }
  }

  render() {
    const { records, loading } = this.props;
    const fuse = new Fuse(records, FUSE_OPTIONS);
    const allRecords = this.state.search.length ? fuse.search(this.state.search) : records;
    
    let companyList = [{value: null, label: "All Company"}];
    allRecords.map(val=> companyList.push({value: val.company_id, label: val.company_name}));
    companyList = Array.from(new Set(companyList.map(JSON.stringify))).map(JSON.parse);

    const allCheckUnCheck = () => {
      let array = [...this.state.selectedData];
      if (document.getElementById('checkboxAll').checked) {
        allRecords.map(val=>{
          let id = document.getElementById('checkbox'+val.id);
          if(id !== null) {
            id.checked = true;
          }
          array.push(val.id);
        })
      } else {
        allRecords.map(val=>{
          let id = document.getElementById('checkbox'+val.id);
          if(id !== null) {
            id.checked = false;
          }
        })
        array = [];
      }
      this.setState({selectedData: [...new Set(array)]});
    } 

    const handelCheckbox = (data) => {
      let array = [...this.state.selectedData];
      if (document.getElementById('checkbox'+data.id).checked) {
        array.push(data.id);
      } else {
        let index = array.indexOf(data.id)
        if (index !== -1) {
          array.splice(index, 1);
        }
      }
      if(array.length == allRecords.length) {
        document.getElementById('checkboxAll').checked = true;
      } else {
        document.getElementById('checkboxAll').checked = false;
      }
      this.setState({selectedData: array});
    }  

    return (
      <React.Fragment>
        {loading ? (
          <Spinner className="mt50" />
        ) : (
          <React.Fragment>
            <StyledTools className="tools">
              <div className="filter-wrapper">
                <Select
                  className="months-select"
                  options={companyList}
                  placeholder="All Company"
                  multi={false}
                  onChange={option => {
                    this.setState({selectedCompany: option})
                    this.props.getSummary({company: option.value, month: this.state.selectedMonth.value ? this.state.selectedMonth.value : new Date().getMonth() + 1, year: this.state.selectedYear.value ? this.state.selectedYear.value : new Date().getFullYear()})
                  }}
                  value={this.state.selectedCompany}
                />
                <Select
                  className="months-select"
                  options={formattedMonths()}
                  placeholder="Month"
                  multi={false}
                  onChange={option => {
                    this.setState({selectedMonth: option})
                    this.props.getSummary({company: this.state.selectedCompany ? this.state.selectedCompany.value : null, month: option.value, year: this.state.selectedYear.value ? this.state.selectedYear.value : new Date().getFullYear()})
                  }}
                  value={this.state.selectedMonth}
                />
                <Select
                  className="years-select"
                  options={formattedYears()}
                  placeholder="Year"
                  multi={false}
                  onChange={option => {
                    this.setState({selectedYear: option})
                    this.props.getSummary({company: this.state.selectedCompany ? this.state.selectedCompany.value : null, month: this.state.selectedMonth.value ? this.state.selectedMonth.value : new Date().getMonth() + 1, year: option.value});
                  }}
                  value={this.state.selectedYear}
                />
                {this.state.selectedData.length != 0 && (<>
                <Select
                  className="years-select ml5"
                  options={[
                    {value: 2, label: 'Approve'},
                    {value: 5, label: 'Pay'},
                    {value: 3, label: 'Reject'},
                    {value: 4, label: 'Deleted'},
                    {value: 1, label: 'Set as Submitted'},
                  ]}
                  placeholder="Status"
                  multi={false}
                  onChange={option => {
                    this.setState({selectedStatus: option})
                  }}
                  value={this.state.selectedStatus}
                  />
                <Button
                  // loading={serverOperation === 2}
                  className="ml5"
                  large
                  intent="success"
                  onClick={() => this.setStatus()}>
                    Submit
                </Button>
                    </>)}
              </div>
              <div>
                {allRecords.length > 0 &&
                  <CSVLink
                    headers={EXCEL_HEADERS}
                    data={allRecords}
                    filename={"reports_expenses.csv"}
                    className="mr5"
                    target="_blank"
                  >
                    <Icon icon="csv" size={38}/>
                  </CSVLink>
                }
              </div>
            </StyledTools>
            {allRecords.length ? (
              <StyledList>
                <AutoSizer>
                  {({width, height}) => (
                    <Table
                      ref="Table"
                      headerHeight={30}
                      noRowsRenderer={this._noRowsRenderer}
                      overscanRowCount={10}
                      rowClassName={this._rowClassName}
                      rowHeight={30}
                      onRowClick={({ rowData })=> document.getElementById('checkbox'+rowData.id).click()}
                      rowCount={allRecords.length}
                      rowGetter={({ index }) => allRecords[index]}
                      height={height}
                      width={width}>
                      <Column
                        width={width * 0.05}
                        headerRenderer={()=> <Checkbox id={"checkboxAll"} style={{marginTop: '8px'}} onChange={()=> allCheckUnCheck()} />}
                        cellRenderer={({rowData})=> <Checkbox id={"checkbox"+rowData.id} checked={this.state.selectedData.indexOf(rowData.id) !== -1 ? true : false} style={{marginTop: '9px'}} onChange={() => handelCheckbox(rowData)} />}
                        flexGrow={1}
                      />
                      <Column
                        width={width * 0.3}
                        label="Contractor"
                        dataKey="user_name"
                        flexGrow={1}
                      />
                      <Column
                        width={width * 0.25}
                        disableSort
                        label="Company"
                        dataKey="company_name"
                        flexGrow={1}
                      />
                      <Column
                        width={width * 0.1}
                        disableSort
                        label="Status"
                        dataKey="status"
                        flexGrow={1}
                      />
                      <Column
                        width={width * 0.2}
                        disableSort
                        label="Date"
                        dataKey="submitted"
                        flexGrow={1}
                      />
                      <Column
                        width={width * 0.1}
                        disableSort
                        label="Total"
                        dataKey="total"
                        flexGrow={1}
                      />
                      <Column
                        width={width * 0.1}
                        cellRenderer={({rowData})=> <AnchorButton
                        href={`/contractors/${rowData.user_id}/expenses/${this.state.selectedYear ? this.state.selectedYear.value : new Date().getFullYear()}/${this.state.selectedMonth ? this.state.selectedMonth.value : new Date().getMonth() + 1}/travel/${rowData.id}`}
                        rightIcon="share"
                        target="_blank"
                        text={"View"}
                    />}
                      />
                    </Table>
                  )}
                </AutoSizer>
              </StyledList>
            ) : (
              <NonIdealState
                icon="search"
                title="No expenses found"
              />
            )}
          </React.Fragment>
        )}
        <Dialog
          icon="info-sign"
          onClose={() => this.setState({rejectDialog: false})}
          title="Reject Business Expense"
          isOpen={this.state.rejectDialog}>
          <div className={Classes.DIALOG_BODY}>
            <FormGroup
              label="Describe your reason for rejection"
              labelFor="rejection_reason"
              labelInfo="*"
            >
              <TextArea
                id="rejection_reason"
                name="rejection_reason"
                growVertically
                large
                fill
                value={this.state.rejection_reason}
                onChange={(e) => this.setState({rejection_reason: e.target.value})}
              />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => this.setState({rejectDialog: false})}>Cancel</Button>
              <Button intent="DANGER" onClick={() => this.setStatus()}>Reject</Button>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}


function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    getSummary
  }, dispatch);
}

function mapStateToProps(state) {
  console.log(state);

  return {
    loading: state.reportsExpenses.loading,
    records: state.reportsExpenses.records,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(Expenses));
