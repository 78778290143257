import axios from 'axios';
import {invoicesConstants} from '../constants';

export function getInvoices(){
  return function(dispatch){
    dispatch({type:invoicesConstants.GET_REQUEST});
    axios.get('/sales-invoices')
      .then((result)=>{
        dispatch({type: invoicesConstants.GET_SUCCESS, payload: result.data});
      })
      .catch((err)=>{
        dispatch({type:invoicesConstants.GET_FAILURE, error: err});
      })
  }
}
