import React, { useState } from 'react';
import { Formik } from 'formik';
import { Button, Card, Spinner } from "@blueprintjs/core";
import * as Yup from 'yup';
import {toggleEditor, insertRecord} from "../../../../store/contractors/actions";
import { toast } from "../../../../store/toaster/actions";

import Contractor from './Contractor';
import Company from './Company';
import Address from './Address';
import MaritalStatusAndSpouseDetails from './MaritalStatusAndSpouseDetails';
import PortalAccess from './PortalAccess';
import { useDispatch } from 'react-redux';

const FormSchema = Yup.object().shape({
  first_name: Yup.string().required("This field is required"),
  last_name: Yup.string().required("This field is required")
});

const defaultContractor = {
  first_name: '',
  last_name: '',
  date_of_birth: null,
  pps_number: null,
  national_insurance_number: null,
  crew_code: null,
  gender_id: null,
  nationality_id: null,
  email: null,
  phone: null,
  position_id: null,
  agency_id: null,
  profession_id: null,
  company_id: null,
  date_of_appointment: null,
  date_of_resignation: null,
  proprietary_director: 0,
  address_1: null,
  address_2: null,
  address_3: null,
  address_4: null,
  country_id: null,
  marital_status_id: null,
  spouse_id: null,
  spouse_first_name: null,
  spouse_last_name: null,
  spouse_maiden_name: null,
  spouse_pps_number: null,
  date_of_marriage: null,
  date_of_separation: null,
  username: null,
  password: null
}

export default ({ onSave }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (record) => {
    setLoading(true);
    dispatch(insertRecord(record, status => {
      if (status === 'saved'){
        dispatch(toast({message: 'Contractor was successfully added', intent: 'SUCCESS'}));
        dispatch(toggleEditor());
      } else if (status === 'error') {
        dispatch(toast({message: 'Server Error', intent: 'DANGER'}));
      }
      setLoading(false);
    }));
  }

  return (
    <div className="ml-5">
      <Formik
        initialValues={defaultContractor}
        validationSchema={FormSchema}
        onSubmit={handleSubmit}>
        {formik => {
          const { dirty, isValid } = formik;
          return (
            <>
              <Contractor formik={formik} />
              <h3>Company Details</h3>
              <Company formik={formik} />
              <h3>Home Address</h3>
              <Address formik={formik} />
              <h3>Marital Status & Spouse Details</h3>
              <MaritalStatusAndSpouseDetails formik={formik} />
              <h3>Portal Access</h3>
              <PortalAccess formik={formik} />
              {dirty && <Button large type="submit" intent="success" text="Save" onClick={formik.handleSubmit} disabled={loading || !isValid} />}
              {loading && <Spinner />}
            </>
          )
        }
      }
      </Formik>
    </div>
  );
};