import React, {Component} from 'react';
import { Route, Switch, withRouter, Link } from 'react-router-dom';

import SettingsProfile from "./profile";
import SettingsAgencies from "./agencies";
import SettingsCurrencies from "./currencies";
import SettingsMileage from "./mileage";
import SettingsSubsistence from "./subsistence";

class Settings extends Component {
  render() {
    const path = this.props.history.location.pathname.split("/");
    const currentPath = `${path[1]}/${path[2]}`;
    return (
      <div className="split">
      <div className="left">
        <ul className="submenu">
          <li className={currentPath === 'settings/profile' ? 'active' : ''}>
            <Link to="/settings/profile">Profile</Link>
          </li>
          <li className={currentPath === 'settings/agencies' ? 'active' : ''}>
            <Link to="/settings/agencies">Agencies</Link>
          </li>
          <li className={currentPath === 'settings/currencies' ? 'active' : ''}>
            <Link to="/settings/currencies">Currencies</Link>
          </li>
          <li className={currentPath === 'settings/mileage-rate' ? 'active' : ''}>
            <Link to="/settings/mileage-rate">Mileage Rate</Link>
          </li>
          <li className={currentPath === 'settings/subsistence-rate' ? 'active' : ''}>
            <Link to="/settings/subsistence-rate">Subsistence Rate</Link>
          </li>
        </ul>
      </div>
      <div className="right">
        <Switch>
          <Route exact path="/settings/profile" component={SettingsProfile} />
          <Route path="/settings/agencies" component={SettingsAgencies} />
          <Route path="/settings/currencies" component={SettingsCurrencies} />
          <Route path="/settings/mileage-rate" component={SettingsMileage} />
          <Route path="/settings/subsistence-rate" component={SettingsSubsistence} />
        </Switch>
      </div>
      </div>
    );
  }
}

export default withRouter(Settings);
