import React, {Component} from 'react';
import { Switch, Route} from "react-router-dom";
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";
import { Spinner } from '@blueprintjs/core';
import {bindActionCreators} from "redux";
import styled from 'styled-components';

import {checkUser} from './store/user/actions'

// Shared Screens
import Dashboard from './pages/dashboard';
import Login from './pages/account/login';
import Logout from './pages/account/logout';
import ForgotPassword from "./pages/account/forgotPassword";
import ResetPassword from "./pages/account/resetPassword";
import Settings from "./pages/settings";

// Accountant Screens
import Companies from "./pages/accountant/companies";
import Contractors from "./pages/accountant/contractors";
import Uploads from "./pages/accountant/uploads";
import Reports from "./pages/accountant/reports";

// Contractors Screens
import Expenses from "./pages/contractor/expenses";
import Invoices from "./pages/contractor/invoices";
import Documents from "./pages/contractor/documents";
import WorkingHours from "./pages/contractor/workingHours";
import Messages from "./pages/contractor/messages";
import BankStatements from "./pages/contractor/bankStatements";


const SyledLoader = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: start;
  padding-top: 200px;
  justify-content: center;
`


class Routes extends Component {
  componentDidMount() {
    this.checkUser();
  }

  checkUser() {
    if (!(this.props.location.pathname === "/login" ||
      this.props.location.pathname === "/logout" ||
      this.props.location.pathname === "/forgot-password")
    ) {
      this.props.checkUser();
    }
  }

  render() {
    const { user } = this.props;
    if (user.checkingIn) {
      return (
        <SyledLoader><Spinner size={50}/></SyledLoader>
      );
    } else {
      if (user.data && user.data.id) {
        return (
          <Switch>
            <Route exact path="/" component={Dashboard}/>
            <Route exact path="/logout" component={Logout}/>

            <Route path="/companies/:_id?/:_view?" component={Companies}/>
            <Route path="/contractors/:_id?/:_view?" component={Contractors}/>
            <Route exact path="/invoices/:_id?" component={Invoices}/>
            <Route exact path="/working-hours/:_id?" component={WorkingHours}/>
            <Route exact path="/bank-statements/:_id?" component={BankStatements}/>
            <Route exact path="/documents/:_id?" component={Documents}/>
            <Route exact path="/messages/:_id?" component={Messages}/>
            <Route path="/uploads" component={Uploads}/>
            <Route path="/reports" component={Reports}/>
            <Route path="/expenses" component={Expenses} />
            <Route path="/settings" component={Settings} />
          </Switch>
        )
      } else {
        return (
          <Switch>
            <Route exact path="/" component={Login}/>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/logout" component={Logout}/>
            <Route exact path="/forgot-password" component={ForgotPassword}/>
            <Route exact path="/reset-password/:_activationToken?" component={ResetPassword}/>
          </Switch>
        )
      }
    }
  }
};

function mapStateToProps(state){
  return{
    user: state.user,
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    checkUser,
  }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Routes));
