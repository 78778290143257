import React, {Component} from 'react';
import {FormGroup, InputGroup, Button, Intent} from '@blueprintjs/core';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import Select from 'react-select';
import {toast} from "../../../../store/toaster/actions";
import {toggleEditor, insertRecord, updateRecord} from "../../../../store/contractors/actions";

import {DateInput} from "@blueprintjs/datetime";
import moment from "moment";
import {Box, Flex} from "@rebass/grid";
import { LabelForEnum, Enums } from '../../../../AppConfig';
import CanIUse from '../../../../components/helpers/CanIUse';

const permissionGroupOptions = Object.keys(LabelForEnum.Role)
                                      .map(key => ({ value: key, label: LabelForEnum.Role[key] })); 

class ContractorEditor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      genders: [],
      record: {
        first_name: '',
        last_name: '',
        date_of_birth: null,
        pps_number: null,
        national_insurance_number: null,
        crew_code: null,
        gender_id: null,
        nationality_id: null,
        email: null,
        phone: null,
        position_id: null,
        agency_id: null,
        profession_id: null,
        permission_group_id: null
      },
      selectedGender: null,
      selectedNationality: null,
      selectedAgency: null,
      selectedProfession: null,
      selectedPosition: null,
      dateOfBirth: null,
      errors: {},
      sent: false,
    };
  }

  componentWillMount() {
    this.loadRecord()
  }

  loadRecord() {
    const { record } = this.props;
    let recordState = this.state.record;

    if(this.props.genders) {

      const genders = this.props.genders.map(gender => { return {value: gender.id, label: gender.name} })
      const countries = this.props.countries.map(country => { return {value: country.id, label: country.name} });
      const agencies = this.props.agencies.map(agency => { return {value: agency.id, label: agency.name} })
      const professions = this.props.professions.map(profession => { return {value: profession.id, label: profession.name} });
      const positions = this.props.positions.map(position => { return {value: position.id, label: position.name} })
      
      let selectedNationality = null;
      let selectedGender = null;
      let selectedAgency = null;
      let selectedProfession = null;
      let selectedPosition = null;

      if (record) {
        selectedNationality = countries.filter(country => country.value === record.country_id)[0];
        selectedGender = genders.filter(gender => gender.value === record.gender_id)[0];
        selectedAgency = agencies.filter(agency => agency.value === record.agency_id)[0];
        selectedProfession = professions.filter(profession => profession.value === record.profession_id)[0];
        selectedPosition = positions.filter(position => position.value === record.position_id)[0];

        recordState = {
          ...recordState,
          name: record.name,
          first_name: record.first_name,
          last_name: record.last_name,
          date_of_birth: record.date_of_birth && moment(this.props.record.date_of_birth, ['DD/MM/YYYY']).format('YYYY-MM-DD'),
          pps_number: record.pps_number,
          national_insurance_number: record.national_insurance_number,
          crew_code: record.crew_code,
          gender_id: record.gender_id,
          nationality_id: record.nationality_id,
          email: record.email,
          phone: record.phone,
          position_id: record.position_id,
          agency_id: record.agency_id,
          profession_id: record.profession_id,
          permission_group_id: record.permission_group_id
        }
      }

      this.setState({
        genders,
        countries,
        agencies,
        professions,
        positions,
        selectedNationality,
        selectedGender,
        selectedAgency,
        selectedProfession,
        selectedPosition,
        record: recordState,
        date_of_birth: record && record.date_of_birth
                        ? moment(record.date_of_birth, ['DD/MM/YYYY']).toDate()
                        : null
      })
    }
  }

  handleSave(e) {
    e.preventDefault();
    this.setState({sent: true});
    if(this.handleValidation()) {
      if (this.props.record.id){
        this.props.updateRecord(this.props.record.id, this.state.record, status => {
          if (status === 'saved'){
            this.props.toast({message: 'Contractor was successfully updated', intent: 'SUCCESS'});
            this.props.toggleEditor();
          } else if (status === 'error') {
            this.props.toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      } else {
        this.props.insertRecord(this.state.record, status => {
          if (status === 'saved'){
            this.props.toast({message: 'Contractor was successfully added', intent: 'SUCCESS'});
            this.props.toggleEditor();
          } else if (status === 'error') {
            this.props.toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      }
    } else {
      console.log('form error');
    }

  }

  handleValidation(){
    let record = this.state.record;
    let errors = {};
    let formIsValid = true;

    if(!record['first_name']){
      formIsValid = false;
      errors['first_name'] = "This field is required";
    }

    if(!record['last_name']){
      formIsValid = false;
      errors['last_name'] = "This field is required";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleChange(e) {
    const { name, value } = e.currentTarget;
    this.updateRecord(name, value);
    this.state.sent && this.handleValidation();
  }

  updateRecord(key, value) {
    let { record } = this.state;
    record[key] = value;
    this.setState({ record });
  }

  handleCountryChange(value)
  {
    this.setState({
      ...this.state,
      selectedNationality: value,
      record: {
        ...this.state.record,
        nationality_id: value && value.value ? value.value : null,
      }
    })
  }

  handleAgencyChange(value)
  {
    this.setState({
      ...this.state,
      selectedAgency: value,
      record: {
        ...this.state.record,
        agency_id: value && value.value ? value.value : null,
      }
    })
  }

  handleProfessionChange(value, name)
  {
    this.setState({
      ...this.state,
      selectedProfession: value,
      record: {
        ...this.state.record,
        profession_id: value && value.value ? value.value : null,
      }
    })
  }

  handlePositionChange(value, name)
  {
    this.setState({
      ...this.state,
      selectedPosition: value,
      record: {
        ...this.state.record,
        position_id: value && value.value ? value.value : null,
      }
    })
  }

  handleGenderChange(value)
  {
    this.setState({
      selectedGender: value,
      record: {
        ...this.state.record,
        gender_id: parseInt(value.value),
      }
    })
  }

  render() {
    const { record } = this.state;
    const { formik } = this.props;
    const handleChange = formik ? formik.handleChange : null;
    const setFieldValue = formik ? formik.setFieldValue : null;
    const errors = formik ? formik.errors : this.state.errors;

    return (
      <form onSubmit={(e) => this.handleSave(e)}>
        <Flex flexWrap={'wrap'}>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={errors.first_name ? Intent.DANGER : Intent.SUCCESS}
              label="First Name"
              labelFor="first_name"
              labelInfo="*"
              helperText={errors.first_name && errors.first_name}
            >
              <InputGroup
                intent={errors.first_name ? Intent.DANGER : Intent.NONE}
                onChange={(e) => {
                  this.handleChange(e);
                  handleChange && handleChange(e)
                }}
                name="first_name"
                id="first_name"
                large
                placeholder="First Name"
                defaultValue={record.first_name}
              />
            </FormGroup>
          </Box>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={errors.last_name ? Intent.DANGER : Intent.SUCCESS}
              label="Last Name"
              labelFor="name"
              labelInfo="*"
              helperText={errors.last_name && errors.last_name}
            >
              <InputGroup
                intent={errors.last_name ? Intent.DANGER : Intent.NONE}
                onChange={(e) => {
                  this.handleChange(e);
                  handleChange && handleChange(e);
                }}
                name="last_name"
                id="last_name"
                large
                placeholder="Last Name"
                defaultValue={record.last_name}
              />
            </FormGroup>
          </Box>
        </Flex>


        <Flex flexWrap={'wrap'}>

          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={errors.date_of_birth ? Intent.DANGER : Intent.SUCCESS}
              label="Date Of Birth"
              labelFor="date_of_birth"
              helperText={errors.date_of_birth && errors.date_of_birth}
            >
              <DateInput
                inputProps={{style: {width: '100%'}, className: 'bp3-large', leftIcon: "calendar"}}
                id="date_of_birth"
                className="bp3-large"
                minDate={new Date('1900-01-01')}
                formatDate={date => moment(date).format('DD/MM/YYYY')}
                parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
                onChange={(dt, f, t, r) => {
                  this.setState({
                    date_of_birth: dt ? new Date(dt) : null,
                    record: {
                      ...record,
                      date_of_birth: dt ? `${moment(dt).format('YYYY-MM-DD')} 00:00:00` : null,
                    }
                  }, () => {console.log(record.date_of_birth)});
                  setFieldValue && setFieldValue('date_of_birth', dt ? `${moment(dt).format('YYYY-MM-DD')} 00:00:00` : null);
                }}
                placeholder={"DD/MM/YYY"}
                showActionsBar
                intent={this.state.date_of_birth ? Intent.DANGER : Intent.NONE}
                value={this.state.date_of_birth}
              />
            </FormGroup>
          </Box>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={errors.pps_number ? Intent.DANGER : Intent.SUCCESS}
              label="PPS Number"
              labelFor="pps_number"
              helperText={errors.pps_number && errors.pps_number}
            >
              <InputGroup
                intent={errors.pps_number ? Intent.DANGER : Intent.NONE}
                onChange={(e) => {
                  this.handleChange(e) 
                  handleChange && handleChange(e);
                }}
                name="pps_number"
                id="pps_number"
                large
                placeholder="PPS Numberr"
                defaultValue={record.pps_number}
              />
            </FormGroup>
          </Box>

        </Flex>
        <Flex flexWrap={'wrap'}>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={errors.national_insurance_number ? Intent.DANGER : Intent.SUCCESS}
              label="National Insurance_Number"
              labelFor="national_insurance_number"
              helperText={errors.national_insurance_number && errors.national_insurance_number}
            >
              <InputGroup
                intent={errors.national_insurance_number ? Intent.DANGER : Intent.NONE}
                onChange={(e) => {
                  this.handleChange(e);
                  handleChange && handleChange(e);
                }}
                name="national_insurance_number"
                id="national_insurance_number"
                large
                placeholder="National Insurance Number"
                defaultValue={record.national_insurance_number}
              />
            </FormGroup>
          </Box>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={errors.crew_code ? Intent.DANGER : Intent.SUCCESS}
              label="CREW Code"
              labelFor="crew_code"
              helperText={errors.crew_code && errors.crew_code}
            >
              <InputGroup
                intent={errors.crew_code ? Intent.DANGER : Intent.NONE}
                onChange={(e) => {
                  this.handleChange(e);
                  handleChange && handleChange(e);
                }}
                name="crew_code"
                id="crew_code"
                large
                placeholder="CREW Code"
                defaultValue={record.crew_code}
              />
            </FormGroup>
          </Box>
        </Flex>

        <Flex flexWrap={'wrap'}>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={errors.gender_id ? Intent.DANGER : Intent.SUCCESS}
              label="Gender"
              labelFor="gender"
              helperText={errors.gender_id && errors.gender_id}
            >
              <Select
                id="gender"
                options={this.state.genders}
                onChange={option => {
                  this.handleGenderChange(option);
                  setFieldValue && setFieldValue('gender_id', option.value);
                }}
                value={this.state.selectedGender}
              />
            </FormGroup>
          </Box>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={errors.nationality_id ? Intent.DANGER : Intent.SUCCESS}
              label="Nationality"
              labelFor="nationality"
              helperText={errors.nationality_id && errors.nationality_id}
            >
              <Select
                id="nationality_id"
                options={this.state.countries}
                onChange={option => {
                  this.handleCountryChange(option);
                  setFieldValue && setFieldValue('nationality_id', option.value);
                }}
                value={this.state.selectedNationality}
                isClearable
              />
            </FormGroup>
          </Box>
        </Flex>

        <Flex flexWrap={'wrap'}>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={errors.email ? Intent.DANGER : Intent.SUCCESS}
              label="Email"
              labelFor="email"
              helperText={errors.email && errors.email}
            >
              <InputGroup
                intent={errors.email ? Intent.DANGER : Intent.NONE}
                onChange={(e) => {
                  this.handleChange(e);
                  handleChange && handleChange(e);
                }}
                name="email"
                id="email"
                large
                placeholder="Email"
                defaultValue={record.email}
              />
            </FormGroup>
          </Box>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={errors.phone ? Intent.DANGER : Intent.SUCCESS}
              label="Phone Number"
              labelFor="phone"
              helperText={errors.phone && errors.phone}
            >
              <InputGroup
                intent={errors.phone ? Intent.DANGER : Intent.NONE}
                onChange={(e) => {
                  this.handleChange(e);
                  handleChange && handleChange(e);
                }}
                name="phone"
                id="phone"
                large
                placeholder="Phone number"
                defaultValue={record.phone}
              />
            </FormGroup>
          </Box>
        </Flex>

        <Flex flexWrap={'wrap'}>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={errors.agency ? Intent.DANGER : Intent.SUCCESS}
              label="Agency"
              labelFor="agency"
              helperText={errors.agency && errors.agency}
            >
              <Select
                id="agency_id"
                options={this.state.agencies}
                onChange={option => {
                  this.handleAgencyChange(option);
                  setFieldValue && setFieldValue('agency_id', option.value);
                }}
                value={this.state.selectedAgency}
                isClearable
              />
            </FormGroup>
          </Box>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={errors.profession ? Intent.DANGER : Intent.SUCCESS}
              label="Profession"
              labelFor="profession"
              helperText={errors.profession && errors.profession}
            >
              <Select
                id="profession_id"
                options={this.state.professions}
                onChange={option => {
                  this.handleProfessionChange(option);
                  setFieldValue && setFieldValue('profession_id', option.value);
                }}
                value={this.state.selectedProfession}
                isClearable
              />
            </FormGroup>
          </Box>
        </Flex>
        <Flex flexWrap={'wrap'}>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={errors.position ? Intent.DANGER : Intent.SUCCESS}
              label="Position"
              labelFor="position"
              helperText={errors.position && errors.position}
            >
              <Select
                id="position"
                options={this.state.positions}
                onChange={option => {
                  this.handlePositionChange(option);
                  setFieldValue && setFieldValue('position_id', option.value);
                }}
                value={this.state.selectedPosition}
                isClearable
              />
            </FormGroup>
          </Box>
          <CanIUse action={Enums.UserActions.EDIT_CONTRACTOR_ROLE}>
            <Box width={['100%','50%','50%']} pr={['0', '5px']}>
              <FormGroup
                intent={errors.profession ? Intent.DANGER : Intent.SUCCESS}
                label="Permission Group"
                labelFor="permission_group"
                helperText={errors.profession && errors.profession}
              >
                <Select
                  id="permission_group_id"
                  options={permissionGroupOptions}
                  onChange={option => {
                    this.updateRecord(
                      'permission_group_id',
                      option ? parseInt(option.value) : null
                    );
                    setFieldValue && setFieldValue(
                      'permission_group_id',
                      option ? parseInt(option.value) : null
                    );
                  }}
                  defaultValue={record.permission_group_id &&
                    permissionGroupOptions.find(o =>
                      o.value == record.permission_group_id
                    )
                  }
                  isClearable
                />
              </FormGroup>
            </Box>
          </CanIUse>
        </Flex>

        { !formik && <Button large type="submit" icon="floppy-disk" intent="success" text="Save" /> }
      </form>
    );
  }
}

function mapStateToProps(state){
  return {
    record: state.contractors.recordToEdit,
    countries: state.general.countries,
    genders: state.general.genders,
    agencies: state.general.agencies,
    professions: state.general.professions,
    positions: state.general.positions,
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    toast,
    toggleEditor,
    updateRecord,
    insertRecord,
  }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(ContractorEditor);
