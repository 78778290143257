import axios from 'axios';
import {payslipsConstants} from '../constants';

export function getPayslips(){
  return function(dispatch){
    dispatch({type:payslipsConstants.GET_REQUEST});
    axios.get('/payslips')
      .then((result)=>{
        dispatch({type: payslipsConstants.GET_SUCCESS, payload: result.data});
      })
      .catch((err)=>{
        dispatch({type:payslipsConstants.GET_FAILURE, error: err});
      })
  }
}
