import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {FormGroup, InputGroup, Button, Intent, Dialog, Classes, Checkbox, Card} from '@blueprintjs/core';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {toast} from "../../../store/toaster/actions";
import {toggleAgencyEditor, insertAgency, updateAgency, deleteAgency} from "./store/actions";

import SlidingPanel from '../../../components/slidingpanel';
import {Box, Flex} from "@rebass/grid";
import Icon from "../../../components/icon";
import Select from "react-select";

const StyledEditor = styled(SlidingPanel)`
  .bp3-card {
    margin: 0 5px 16px 0;
    
    h4 {
      font-size: 16px;
      margin: 0 0 10px;
    }
  }
`;


class AgencyEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      record: {
        name: '',
        address_1: '',
        address_2: '',
        address_3: '',
        phone: '',
        vat_number: '',
        notifications_payslips: false,
        notifications_working_hours: false,
      },
      values: {
        notifications_payslips: null,
        notifications_working_hours: null,
      },
      options: [
        {label: 'Active', value: true},
        {label: 'Inactive', value: false},
      ],
      deleteDialog: false,
      errors: {},
      sent: false,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.record && nextProps.record.id) {
      this.setState({
        record: {
          name: nextProps.record.name ? nextProps.record.name : '',
          address_1: nextProps.record.address_1 ? nextProps.record.address_1 : '',
          address_2: nextProps.record.address_2 ? nextProps.record.address_2 : '',
          address_3: nextProps.record.address_3 ? nextProps.record.address_3 : '',
          phone: nextProps.record.phone ? nextProps.record.phone : '',
          vat_number: nextProps.record.vat_number ? nextProps.record.vat_number : '',
          notifications_payslips: nextProps.record.notifications_payslips === 0 ? false : nextProps.record.notifications_payslips === 1 ? true : null,
          notifications_working_hours: nextProps.record.notifications_working_hours === 0 ? false : nextProps.record.notifications_working_hours === 1 ? true : null,
        },
        values: {
          notifications_payslips: nextProps.record.notifications_payslips === 0 ? {value: false, label: 'Inactive'} : nextProps.record.notifications_payslips === 1 ? {value: true, label: 'Active'} : null,
          notifications_working_hours: nextProps.record.notifications_working_hours === 0 ? {value: false, label: 'Inactive'} : nextProps.record.notifications_working_hours === 1 ? {value: true, label: 'Active'} : null,
        }
      });
    }
  }


  handleDelete()
  {
    this.setState({deleteDialog: false});
    this.props.deleteAgency(this.props.match.params._id, this.props.record.id, () => {
      this.props.toast({message: 'Bank Account was successfully deleted', intent: 'SUCCESS'});
      this.props.toggleEditor();
    });
  }

  handleSave(e) {
    e.preventDefault();
    this.setState({sent: true});
    if(this.handleValidation()) {
      const { insertAgency, updateAgency, toggleAgencyEditor, toast, record } = this.props;
      if (record.id){
        updateAgency(record.company_id, record.id, this.state.record, status => {
          if (status === 'saved'){
            toast({message: 'Agency was successfully updated', intent: 'SUCCESS'});
            toggleAgencyEditor();
          } else if (status === 'error') {
            toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      } else {
        insertAgency(this.props.match.params._id, this.state.record, status => {
          if (status === 'saved'){
            toast({message: 'Agency was successfully added', intent: 'SUCCESS'});
            toggleAgencyEditor();
          } else if (status === 'error') {
            toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      }
    } else {
      console.log('form error');
    }

  }

  handleValidation(){
    let record = this.state.record;
    let errors = {};
    let formIsValid = true;

    if(!record['name']){
      formIsValid = false;
      errors['name'] = "This field is required";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleChange(e){
    let record = this.state.record;
    record[e.currentTarget.name] = e.currentTarget.value;
    this.setState({record});
    if (this.state.sent){
      this.handleValidation();
    }
  }

  handleNotificationChange(type, value) {
    let val = null;
    if (!value) {
      val = null;
    } else if (value.hasOwnProperty('value') && value.value === false){
      val = false;
    } else {
      val = true;
    }
    this.setState({
      ...this.state,
      record: {
        ...this.state.record,
        [type]: val
      },
      values: {
        ...this.state.values,
        [type]: value,
      }
    },() => {
      if (this.state.sent){
        this.handleValidation();
      }
    });
  }

  render() {
    const {isEditorActive, toggleAgencyEditor} = this.props;
    const {record, values} = this.state;
    return (
      <StyledEditor isOpen={isEditorActive} width="520px" title="Agency" onRequestClose={() => toggleAgencyEditor()}>
        <React.Fragment>
          <form onSubmit={(e) => this.handleSave(e)}>
            <FormGroup
              intent={this.state.errors.name ? Intent.DANGER : Intent.SUCCESS}
              label="Name"
              labelFor="name"
              labelInfo="*"
              helperText={this.state.errors.name && this.state.errors.name}
            >
              <InputGroup
                intent={this.state.errors.name ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="name"
                id="name"
                large
                placeholder="Name"
                defaultValue={record.name}
              />
            </FormGroup>
            <FormGroup
              intent={this.state.errors.address_1 ? Intent.DANGER : Intent.SUCCESS}
              label="Address 1"
              labelFor="address_1"
              helperText={this.state.errors.address_1 && this.state.errors.address_1}
            >
              <InputGroup
                intent={this.state.errors.address_1 ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="address_1"
                id="address_1"
                large
                placeholder="Address 1"
                value={record.address_1}
              />
            </FormGroup>
            <FormGroup
              intent={this.state.errors.address_2 ? Intent.DANGER : Intent.SUCCESS}
              label="Address 2"
              labelFor="address_2"
              helperText={this.state.errors.address_2 && this.state.errors.address_2}
            >
              <InputGroup
                intent={this.state.errors.address_2 ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="address_2"
                id="address_2"
                large
                placeholder="Address 2"
                value={record.address_2}
              />
            </FormGroup>
            <FormGroup
              intent={this.state.errors.address_3 ? Intent.DANGER : Intent.SUCCESS}
              label="Address 3"
              labelFor="address_3"
              helperText={this.state.errors.address_3 && this.state.errors.address_3}
            >
              <InputGroup
                intent={this.state.errors.address_3 ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="address_3"
                id="address_3"
                large
                placeholder="Address 3"
                value={record.address_3}
              />
            </FormGroup>
            <Flex flexWrap={'wrap'}>
              <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                <FormGroup
                  intent={this.state.errors.phone ? Intent.DANGER : Intent.SUCCESS}
                  label="Phone"
                  labelFor="phone"
                  helperText={this.state.errors.phone && this.state.errors.phone}
                >
                  <InputGroup
                    intent={this.state.errors.phone ? Intent.DANGER : Intent.NONE}
                    onChange={(e) => this.handleChange(e)}
                    name="phone"
                    id="phone"
                    large
                    placeholder="Phone"
                    value={record.phone}
                  />
                </FormGroup>
              </Box>
              <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                <FormGroup
                  intent={this.state.errors.vat_number ? Intent.DANGER : Intent.SUCCESS}
                  label="VAT Number"
                  labelFor="phone"
                  helperText={this.state.errors.vat_number && this.state.errors.vat_number}
                >
                  <InputGroup
                    intent={this.state.errors.vat_number ? Intent.DANGER : Intent.NONE}
                    onChange={(e) => this.handleChange(e)}
                    name="vat_number"
                    id="vat_number"
                    large
                    placeholder="VAT Number"
                    value={record.vat_number}
                  />
                </FormGroup>
              </Box>
            </Flex>
            <Card>
              <h4>Notifications</h4>
              <FormGroup
                label="Email notifications - Payslip"
                labelFor="notifications_payslips"
              >
                <Select
                  isClearable
                  placeholder="Not Set"
                  id="notifications_payslips"
                  options={this.state.options}
                  name="notifications_payslips"
                  onChange={option => this.handleNotificationChange('notifications_payslips', option)}
                  value={values.notifications_payslips}
                />
              </FormGroup>

              <FormGroup
                label="Email notifications - Working Hours"
                labelFor="notifications_payslips"
              >
                <Select
                  isClearable
                  placeholder="Not Set"
                  id="notifications_working_hours"
                  options={this.state.options}
                  name="notifications_working_hours"
                  onChange={option => this.handleNotificationChange('notifications_working_hours', option)}
                  value={values.notifications_working_hours}
                />
              </FormGroup>
            </Card>
            <Card>
              <h4>Business Expenses Settings</h4>
              <FormGroup
                label="Email notifications - Payslip"
                labelFor="notifications_payslips"
              >
                <Select
                  isClearable
                  placeholder="Not Set"
                  id="notifications_payslips"
                  options={this.state.options}
                  name="notifications_payslips"
                  onChange={option => this.handleNotificationChange('notifications_payslips', option)}
                  value={values.notifications_payslips}
                />
              </FormGroup>

              <FormGroup
                label="Email notifications - Working Hours"
                labelFor="notifications_payslips"
              >
                <Select
                  isClearable
                  placeholder="Not Set"
                  id="notifications_working_hours"
                  options={this.state.options}
                  name="notifications_working_hours"
                  onChange={option => this.handleNotificationChange('notifications_working_hours', option)}
                  value={values.notifications_working_hours}
                />
              </FormGroup>
            </Card>
            <div className="button-bar">
              <Button large type="submit" icon="floppy-disk" intent="success" text="Save" />
              {record && record.id && <Button style={{marginLeft: 10}} large onClick={() => this.setState({deleteDialog: true})} icon={<Icon color="#fff" icon="trash" />} intent="danger" text="Delete" />}
            </div>
          </form>
          <Dialog
            icon="info-sign"
            onClose={() => this.setState({deleteDialog: false})}
            title="Bank Account Delete"
            isOpen={this.state.deleteDialog}>
            <div className={Classes.DIALOG_BODY}>
              <div>Are you sure?</div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => this.setState({deleteDialog: false})}>Cancel</Button>
                <Button intent="DANGER" onClick={() => this.handleDelete()}>Delete</Button>
              </div>
            </div>
          </Dialog>
        </React.Fragment>
      </StyledEditor>
    );
  }
}

function mapStateToProps(state){
  return {
    isEditorActive: state.settingsAgencies.isEditorActive,
    record: state.settingsAgencies.recordToEdit,
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    toast,
    toggleAgencyEditor,
    updateAgency,
    insertAgency,
    deleteAgency,
  }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withRouter(AgencyEditor));
