import React, {Component} from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Details from "./Details";
import Tabs from "./tabs";
import BankAccounts from "./BankAccounts";
import BankStatements from "./BankStatements";
import WorkingHours from "./WorkingHours";
import Payslips from "./Payslips";
import Invoices from "./Invoices";
import Expenses from "./Expenses";

class Detail extends Component {
  render() {
    return (
      <div className="right">
        <div className="tools">
          <Tabs match={this.props.match} history={this.props.history}/>
        </div>
          <Switch>
            <Route exact path="/contractors/:_id" component={Details} />
            <Route exact path="/contractors/:_id/payslips" selectedId={this.props.match.params._id} component={Payslips} />
            <Route exact path="/contractors/:_id/invoices" selectedId={this.props.match.params._id} component={Invoices} />
            <Route exact path="/contractors/:_id/bank-statements" selectedId={this.props.match.params._id} component={BankStatements} />
            <Route exact path="/contractors/:_id/bank-accounts" selectedId={this.props.match.params._id} component={BankAccounts} />
            <Route exact path="/contractors/:_id/working-hours" selectedId={this.props.match.params._id} component={WorkingHours} />
            <Route exact path="/contractors/:_id/expenses/:_year?/:_month?/:_expense_type?/:_expense_id?" selectedId={this.props.match.params._id} component={Expenses} />
          </Switch>
      </div>
    );
  }
}

export default withRouter(Detail);
