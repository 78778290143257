import React, {Component} from 'react';
import styled from 'styled-components';
import Scrollbar from 'react-scrollbars-custom';
import { Button } from '@blueprintjs/core';
import Dropzone from 'react-dropzone'
import axios from 'axios'
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {connect} from "react-redux";

const StyledTable = styled.table`
  width: 100%;
  border: 1px solid #edeef0;
  text-align: left;
  
  th, td {
    text-align: left;
    border: 1px solid #edeef0;
    padding: 5px;
  }
  
  .f {
    width: 10%;
  }
`;

const StyledResult = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
`;

class BrightPayUploader extends Component {

  constructor(props){
    super(props);
    this.state = {
      files: [],
      result: [],
      uploading: false,
      totalAdded: 0,
      totalUpdated: 0,
      uploaded: false,
    }

    this.totalAdded = 0;
    this.totalUpdated = 0;
  }

  handleUpload = () => {
    this.totalAdded = 0;
    this.totalUpdated = 0;
    if (this.state.files.length <= 0){
      this.props.toast({message: "Select at least one file.", intent: "danger"});
      return;
    }

    this.setState({
      uploading: true,
      result: [],
      totalAdded: 0,
      totalUpdated: 0,
    }, () => {
      const uploaders = this.state.files.map(file => {
        const formData = new FormData();
        formData.append("file", file);

        return axios.post("/uploads/brightpay-ie", formData).then(response => {
          const data = response.data;

          const companyName = Object.keys(data)[0];
          const company = response.data[companyName];

          company.map(employee => {
            if (employee.payslips) {
              employee.payslips.map(payslip => {
                if(payslip.status && payslip.status === 'Added') {
                  this.totalAdded += 1;
                }
                if(payslip.status && payslip.status === 'Updated') {
                  this.totalUpdated += 1;
                }
                return payslip;
              })
            }
            return employee;
          });
          this.setState({
            result: [...this.state.result, data],
            totalAdded: this.totalAdded,
            totalUpdated: this.totalUpdated,
          });

        })
      });

      // Once all the files are uploaded
      axios.all(uploaders).then(() => {
        this.setState({
            //files: [],
            uploading: false,
        }, () => {
          this.props.toast({message: "Successfully uploaded.", intent: "success"});
        });
      });
    });
  }

  handleDrop(acceptedFiles){
    this.setState({files: acceptedFiles});
  }

  render() {
    return (
      <React.Fragment>
        <Scrollbar noDefaultStyles>
          <div className="center">
            <Dropzone onDrop={acceptedFiles => this.handleDrop(acceptedFiles)}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()} className="dd-area">
                    <input {...getInputProps()} />
                    {this.state.files.length ? <div>{this.state.files.length} files selected<div><Button intent="danger" text="Remove" onClick={(e) => {e.stopPropagation(); this.setState({files: []})}} icon="trash"/></div></div> : <p>Drag 'n' drop *.bpie* files here, or click to select files</p>}
                  </div>
                </section>
              )}
            </Dropzone>
            <StyledResult>
              <Button large="true" loading={this.state.uploading} icon="cloud-upload" onClick={() => this.handleUpload()} text="Upload" intent="success"/>
              {this.state.result.length > 0 && (
                <p className="p10">Added Payslips: {this.state.totalAdded} | Updated payslips: {this.state.totalUpdated}</p>
              )}
            </StyledResult>
              <div className="p10">
              {this.state.result.map((record, index)=> {

                console.log(record);

                const companyName = Object.keys(record)[0];
                const company = record[companyName];
                return (
                  <React.Fragment key={index}>
                    <StyledTable>
                      <thead>
                        <tr>
                          <th>{companyName}</th>
                        </tr>
                      </thead>
                    </StyledTable>
                    <StyledTable>
                      <thead>
                      <tr>
                        <th className="f">Last Name</th>
                        <th className="f">First Name</th>
                        <th className="f">Payslips Added</th>
                        <th className="f">Payslips Updated</th>
                      </tr>
                      </thead>
                      <tbody>
                      {company.map(employee => {
                        let payslipsAdded = 0;
                        let payslipsUpdated = 0;
                        employee.payslips.map(payslip => {
                          if (payslip.status && payslip.status === 'Added'){
                            payslipsAdded += 1;
                          }
                          if (payslip.status && payslip.status === 'Updated'){
                            payslipsUpdated += 1;
                          }
                          return payslip;
                        });

                        return (
                          <tr key={employee.id}>
                            <td className="f">{employee.last_name}</td>
                            <td className="f">{employee.first_name}</td>
                            <td className="f">{payslipsAdded}</td>
                            <td className="f">{payslipsUpdated}</td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </StyledTable>
                  </React.Fragment>
                )
              })}
              </div>

          </div>
        </Scrollbar>
      </React.Fragment>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
  }, dispatch);
}


export default (connect(null, matchDispatchToProps)(BrightPayUploader));
