import Actions from '../actions';

const initialState = {
  countries: [],
  genders: [],
  agencies: [],
  professions: [],
  positions: [],
  maritalStatuses: [{
    id: 1,
    sort: 1,
    name: "Single"
  }, { 
    id: 2,
    sort: 2,
    name: "Married"
  }, {
    id: 3,
    sort: 3,
    name: "Divorced"
  }, {
    id: 4,
    sort: 4,
    name: "Widower"
  }, {
    id: 5,
    sort: 5,
    name: "Seperated"
  }]
}

export function general(state = initialState, action) {
  switch (action.type) {
    case "SET_COUNTRIES":
      return {
        ...state,
        countries: action.payload,
      };
    case Actions.SET_MARITAL_STATUSES:
      return {
        ...state,
        maritalStatuses: action.payload,
      };
    case "SET_GENDERS":
      return {
        ...state,
        genders: action.payload,
      };
    case "SET_AGENCIES":
      return {
        ...state,
        agencies: action.payload,
      };
    case "SET_PROFESSIONS":
      return {
        ...state,
        professions: action.payload,
      };
    case "SET_POSITIONS":
      return {
        ...state,
        positions: action.payload,
      };
    default:
      return state
  }
}
