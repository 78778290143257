import axios from 'axios';
import {settingsSubsistenceRateConstants} from './constants';

export function getSubsistenceRate(){
  return function(dispatch){
    dispatch({type:settingsSubsistenceRateConstants.GET_SETTINGS_SUBSISTENCE_RATE_REQUEST});
    axios.get('/subsistence-rates')
      .then((result)=>{
        dispatch({type: settingsSubsistenceRateConstants.GET_SETTINGS_SUBSISTENCE_RATE_SUCCESS, payload: result.data});
      })
      .catch((err)=>{
        dispatch({type:settingsSubsistenceRateConstants.GET_SETTINGS_SUBSISTENCE_RATE_FAILURE, error: err});
      })
    }
  }  

export function getSubsistenceCurrency(){
  return function(dispatch){
    axios.get('/subsistence-rates/details')
      .then((result)=>{
        dispatch({type: settingsSubsistenceRateConstants.GET_SETTINGS_SUBSISTENCE_REQUEST_DATA, payload: result.data});
      })
  }
}


export function toggleSubsistenceRateEditor( record ){
  return function(dispatch){
    dispatch({ type:settingsSubsistenceRateConstants.TOGGLE_EDITOR, payload: record });
  }
}

export function insertSubsistenceRate( companyId, record, cb ) {
  return function(dispatch){
    dispatch({type:settingsSubsistenceRateConstants.SUBSISTENCE_RATE_ADD_REQUEST});
    axios.post(`/subsistence-rates`, record).then(result => {
      cb('saved');
      dispatch({type:settingsSubsistenceRateConstants.SUBSISTENCE_RATE_ADD_SUCCESS, payload: result.data});      
    }).catch(error => {
      cb('error');
      dispatch({type:settingsSubsistenceRateConstants.SUBSISTENCE_RATE_ADD_FAILURE, error});
    });
  }
}

export function updateSubsistenceRate( companyId, id, record, cb ) {
  return function(dispatch){
    dispatch({type:settingsSubsistenceRateConstants.SUBSISTENCE_RATE_EDIT_REQUEST});
    axios.post(`/subsistence-rates/${id}`, record).then(result => {
      cb('saved');
      dispatch({type:settingsSubsistenceRateConstants.SUBSISTENCE_RATE_EDIT_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:settingsSubsistenceRateConstants.SUBSISTENCE_RATE_EDIT_FAILURE, error});
    });
  }
}

export function deleteSubsistenceRate( companyId, id, cb ) {
  return function(dispatch){
    dispatch({type:settingsSubsistenceRateConstants.SUBSISTENCE_RATE_DELETE_REQUEST});
    axios.post(`/subsistence-rates/${id}/delete`).then(result => {
      cb('saved');
      dispatch({type:settingsSubsistenceRateConstants.SUBSISTENCE_RATE_DELETE_SUCCESS, payload: parseInt(id)});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:settingsSubsistenceRateConstants.SUBSISTENCE_RATE_DELETE_FAILURE, error});
    });
  }
}
