import React, {Component} from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Intent } from '@blueprintjs/core';
import MediaQuery from 'react-responsive';

import List from './list';
import Detail from './detail';
import SlidingPanel from "../../../components/slidingpanel";
import CompanyEditor from "./Editors/Company";

import { getCompanies, toggleEditor, toggleList } from '../../../store/companies/actions';


class Companies extends Component {
  constructor(props){
    super(props);

    this.state = {
      companyEditorOpen: false,
      records: [],
      isListOpen: false,
    };

    this.addRecord = this.addRecord.bind(this);
    this.onCompanySaved = this.onCompanySaved.bind(this);
  }

  componentDidMount() {
    this.props.getCompanies();
  }

  addRecord(record){
    console.log(this);
    this.props.toast({message: 'Record Added', intent: Intent.SUCCESS})

    this.setState({
      records: [...this.state.records, record]
    });
  }

  onCompanySaved(record){
    this.setState({
      companyEditorOpen: false
    });
    this.addRecord(record);
  }

  render() {
    const { isEditorActive, toggleEditor, isListActive, toggleList } = this.props;
    return (
      <div className="split">
        <MediaQuery minWidth={769}>
          <List />
        </MediaQuery>
        <MediaQuery maxWidth={769}>
          <SlidingPanel onAfterOpen={()=> console.log('opened')} onRequestClose={() => toggleList()} width="380px" isOpen={isListActive} from="left" title="Companies">
            <List onSelect={() => toggleList()} />
          </SlidingPanel>
        </MediaQuery>

        {this.props.match.params._id && <Detail {...this.props} addRecord={this.addRecord} />}
        <SlidingPanel width="520px" isOpen={isEditorActive} title="New Company" onRequestClose={() => toggleEditor()}>
          <CompanyEditor onSave={this.onCompanySaved}/>
        </SlidingPanel>
      </div>
    );
  }
}

function mapStateToProps(state){
  return{
    isEditorActive: state.companies.isEditorActive,
    isListActive: state.companies.isListActive,
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    getCompanies,
    toggleEditor,
    toggleList,
  }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Companies);

