import axios from 'axios';
import {bankStatementConstants} from '../constants';

export function getBankStatement(){
  return function(dispatch){
    dispatch({type:bankStatementConstants.GET_REQUEST});
    axios.get('/bank-statements')
      .then((result)=>{
        dispatch({type: bankStatementConstants.GET_SUCCESS, payload: result.data});
      })
      .catch((err)=>{
        dispatch({type:bankStatementConstants.GET_FAILURE, error: err});
      })
  }
}
