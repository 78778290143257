import React, {useState} from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

const StyledCheckbox = styled.div`
  position: relative;
  display: inline-flex;
  border-radius: 4px;
  background-color: ${props => props.color};
  border-color: ${props => darken(0.2, props.color)};
  padding: 10px;
  color: #fff;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  margin: 4px 20px 4px 0;
  
  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px;
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 4px;
    
    .check-mark {
      height: 10px;
      width: 5px;
      margin-bottom: 1px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: ${props => props.isActive ? 0.9 : 0}; 
    }
  }
  
  .count {
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -10px;
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${props => darken(0.2, props.color)};
  }
  

`

export default ({color, label, total, onChange}) => {
  const [checked, setChecked] = useState(false);

  const setVal = () => {
    setChecked(!checked);
    onChange(!checked);
  }

  return (
    <StyledCheckbox isActive={checked} onClick={setVal} color={color}>
      {label}
      <div className="checkbox"><div className="check-mark"></div></div>
      <div className="count">{total}</div>
    </StyledCheckbox>
  );
}