import axios from 'axios';
import {workingHoursConstants} from '../constants';

export function getWorkingHours(){
  return function(dispatch){
    dispatch({type:workingHoursConstants.GET_REQUEST});
    axios.get('/working-hours')
      .then((result)=>{
        dispatch({type: workingHoursConstants.GET_SUCCESS, payload: result.data});
      })
      .catch((err)=>{
        dispatch({type:workingHoursConstants.GET_FAILURE, error: err});
      })
  }
}
