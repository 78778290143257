import axios from 'axios';
import moment from 'moment';
import {contractorsConstants} from '../constants';


export function getContractors(){
  return function(dispatch){
    dispatch({type:contractorsConstants.CONTRACTORS_REQUEST});
    axios.get('/contractors').then(result => {


      dispatch({type:contractorsConstants.CONTRACTORS_SUCCESS, payload: result.data});


    }).catch(error => {
      dispatch({type:contractorsConstants.CONTRACTORS_FAILURE, error});
    });
  }
}

export function getDetails(contractorId){
  return function(dispatch){
    dispatch({type:contractorsConstants.CONTRACTOR_REQUEST, id: contractorId});
    axios.get(`/contractors/${contractorId}`).then(result => {
      dispatch({type:contractorsConstants.CONTRACTOR_SUCCESS, payload: result.data});
    }).catch(error => {
      dispatch({type:contractorsConstants.CONTRACTOR_FAILURE, error});
    });
  }
}

export function selectRecord( record ){
  return function(dispatch){
    dispatch({ type:contractorsConstants.CONTRACTORS_SELECT_RECORD, payload: record });
  }
}

export function toggleEditor( record ){
  return function(dispatch){
    dispatch({ type:contractorsConstants.CONTRACTORS_TOGGLE_EDITOR, payload: record });
  }
}

export function togglePortalAccessEditor( record ){
  return function(dispatch){
    dispatch({ type:contractorsConstants.CONTRACTORS_TOGGLE_PORTAL_ACCESS_EDITOR, payload: record });
  }
}

export function toggleAddressEditor( record ){
  return function(dispatch){
    dispatch({ type:contractorsConstants.CONTRACTORS_TOGGLE_ADDRESS_EDITOR, payload: record });
  }
}

export function toggleNotificationsEditor( record ){
  return function(dispatch){
    dispatch({ type:contractorsConstants.TOGGLE_NOTIFICATIONS_EDITOR, payload: record });
  }
}

export function toggleCompanyEditor( record ){
  return function(dispatch){
    dispatch({ type:contractorsConstants.TOGGLE_COMPANY_EDITOR, payload: record });
  }
}

export function toggleMaritalStatusAndSpouseDetailsEditor(record) {
  return function(dispatch) {
    dispatch({ type:contractorsConstants.MARITAL_STATUS_AND_SPOUSE_DETAILS_EDITOR, payload: record });
  }
}


export function toggleList(){
  return function(dispatch){
    dispatch({ type:contractorsConstants.CONTRACTORS_TOGGLE_LIST });
  }
}

export function insertRecord( record, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.CONTRACTOR_ADD_REQUEST});
    axios.post(`/contractors`, record).then(result => {
      cb('saved');
      dispatch({type:contractorsConstants.CONTRACTOR_ADD_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:contractorsConstants.CONTRACTOR_ADD_FAILURE, error});
    });
  }
}

export function updateRecord( id, record, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.CONTRACTOR_EDIT_REQUEST});
    axios.post(`/contractors/${id}`, record).then(result => {
      cb('saved');
     // getDetails(result.data);
      dispatch({type:contractorsConstants.CONTRACTOR_EDIT_SUCCESS, payload: record});
    }).catch(error => {
      cb('error');
      dispatch({type:contractorsConstants.CONTRACTOR_EDIT_FAILURE, error});
    });
  }
}

export function deleteRecord( id, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.CONTRACTOR_DELETE_REQUEST});
    axios.delete(`/contractors/${id}`).then(result => {
      cb('saved');
      dispatch({type:contractorsConstants.CONTRACTOR_DELETE_SUCCESS, payload: parseInt(id)});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:contractorsConstants.CONTRACTOR_DELETE_FAILURE, error});
    });
  }
}

export function getEmployees( id, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.EMPLOYEES_REQUEST});
    axios.get(`/contractors/${id}/employees`).then(result => {
      cb('saved');
      dispatch({type:contractorsConstants.EMPLOYEES_SUCCESS, payload: result.data});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:contractorsConstants.EMPLOYEES_FAILURE, error});
    });
  }
}


export function getPayslips( id, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.PAYSLIPS_REQUEST});
    axios.get(`/contractors/${id}/payslips`).then(result => {
      const data = result.data.map(record => {
        return {
          ...record,
          date: moment(record.date).format('DD/MM/Y')
        }
      });
      dispatch({type:contractorsConstants.PAYSLIPS_SUCCESS, payload: data});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:contractorsConstants.PAYSLIPS_FAILURE, error});
    });
  }
}


export function getInvoices( id, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.INVOICES_REQUEST});
    axios.get(`/contractors/${id}/invoices`).then(result => {
      const data = result.data.map(record => {
        return {
          ...record,
          date: moment(record.date).format('DD/MM/Y')
        }
      });
      dispatch({type:contractorsConstants.INVOICES_SUCCESS, payload: data});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:contractorsConstants.INVOICES_FAILURE, error});
    });
  }
}


export function getBankAccounts( id, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.BANK_ACCOUNTS_REQUEST});
    axios.get(`/contractors/${id}/bank-accounts`).then(result => {
      cb('saved');
      dispatch({type:contractorsConstants.BANK_ACCOUNTS_SUCCESS, payload: result.data});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:contractorsConstants.BANK_ACCOUNTS_FAILURE, error});
    });
  }
}

export function getExpensesSummary( id, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.EXPENSES_SUMMARY_REQUEST});
    axios.get(`/contractors/${id}/expenses`).then(result => {
      cb('saved');
      dispatch({type:contractorsConstants.EXPENSES_SUMMARY_SUCCESS, payload: result.data});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:contractorsConstants.EXPENSES_SUMMARY_FAILURE, error});
    });
  }
}

export function getExpenses( id, year, month ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.EXPENSES_REQUEST});
    axios.get(`/contractors/${id}/expenses/${year}/${month}`).then(result => {
      dispatch({type:contractorsConstants.EXPENSES_SUCCESS, payload: result.data});
    }).catch(error => {
      dispatch({type:contractorsConstants.EXPENSES_FAILURE, error});
    });
  }
}

export function getBankStatements( id, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.BANK_STATEMENTS_REQUEST});
    axios.get(`/contractors/${id}/bank-statements`).then(result => {
      cb('saved');
      const data = result.data.map(record => {
        return {
          ...record,
          date: moment(record.date).format('DD/MM/Y')
        }
      });
      dispatch({type:contractorsConstants.BANK_STATEMENTS_SUCCESS, payload: data});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:contractorsConstants.BANK_STATEMENTS_FAILURE, error});
    });
  }
}

export function getWorkingHours( id, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.WORKING_HOURS_REQUEST});
    axios.get(`/contractors/${id}/working-hours`).then(result => {
      cb('saved');
      dispatch({type:contractorsConstants.WORKING_HOURS_SUCCESS, payload: result.data});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:contractorsConstants.WORKING_HOURS_FAILURE, error});
    });
  }
}

export function toggleBankAccountEditor( record ){
  return function(dispatch){
    dispatch({ type:contractorsConstants.TOGGLE_BANK_ACCOUNT_EDITOR, payload: record });
  }
}

export function toggleBankStatementEditor( record ){
  return function(dispatch){
    dispatch({ type:contractorsConstants.TOGGLE_BANK_STATEMENT_EDITOR, payload: record });
  }
}

export function insertBankAccount( contractorId, record, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.BANK_ACCOUNTS_ADD_REQUEST});
    axios.post(`/contractors/${contractorId}/bank-accounts`, record).then(result => {
      cb('saved');
      dispatch({type:contractorsConstants.BANK_ACCOUNTS_ADD_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:contractorsConstants.BANK_ACCOUNTS_ADD_FAILURE, error});
    });
  }
}

export function updateBankAccount( contractorId, id, record, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.BANK_ACCOUNTS_EDIT_REQUEST});
    axios.post(`/contractors/${contractorId}/bank-accounts/${id}`, record).then(result => {
      cb('saved');
      dispatch({type:contractorsConstants.BANK_ACCOUNTS_EDIT_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:contractorsConstants.BANK_ACCOUNTS_EDIT_FAILURE, error});
    });
  }
}

export function deleteBankAccount( contractorId, id, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.BANK_ACCOUNTS_DELETE_REQUEST});
    axios.delete(`/contractors/${contractorId}/bank-accounts/${id}`).then(result => {
      cb('saved');
      dispatch({type:contractorsConstants.BANK_ACCOUNTS_DELETE_SUCCESS, payload: parseInt(id)});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:contractorsConstants.BANK_ACCOUNTS_DELETE_FAILURE, error});
    });
  }
}

export function insertBankStatement( contractorId, record, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.BANK_STATEMENTS_ADD_REQUEST});
    axios.post(`/contractors/${contractorId}/bank-statements`, record).then(result => {
      cb('saved');
      result.data.date = moment(result.data.date).format('DD/MM/Y');
      dispatch({type:contractorsConstants.BANK_STATEMENTS_ADD_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:contractorsConstants.BANK_STATEMENTS_ADD_FAILURE, error});
    });
  }
}

export function updateBankStatement( contractorId, id, record, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.BANK_STATEMENTS_EDIT_REQUEST});
    axios.post(`/contractors/${contractorId}/bank-statements/${id}`, record).then(result => {
      cb('saved');
      result.data.date = moment(result.data.date).format('DD/MM/Y');
      dispatch({type:contractorsConstants.BANK_STATEMENTS_EDIT_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:contractorsConstants.BANK_STATEMENTS_EDIT_FAILURE, error});
    });
  }
}

export function deleteBankStatement( contractorId, id, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.BANK_STATEMENTS_DELETE_REQUEST});
    axios.delete(`/contractors/${contractorId}/bank-statements/${id}`).then(result => {
      cb('saved');
      dispatch({type:contractorsConstants.BANK_STATEMENTS_DELETE_SUCCESS, payload: parseInt(id)});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:contractorsConstants.BANK_STATEMENTS_DELETE_FAILURE, error});
    });
  }
}

export function deletePayslip( contractorId, id, cb ) {
  return function(dispatch){
    dispatch({type:contractorsConstants.PAYSLIP_DELETE_REQUEST});
    axios.get(`/payslips/${id}/delete`).then(result => {
      cb('saved');
      dispatch({type:contractorsConstants.PAYSLIP_DELETE_SUCCESS, payload: parseInt(id)});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:contractorsConstants.PAYSLIP_DELETE_FAILURE, error});
    });
  }
}

export function setFilter(name, operator, value) {
  return function(dispatch){
    dispatch({ type:contractorsConstants.SET_FILTER, name, operator, value });
  }
}

export function removeFilters() {
  return function(dispatch){
    dispatch({ type:contractorsConstants.REMOVE_FILTERS });
  }
}

export function search(phrase) {
  return function(dispatch){
    dispatch({ type:contractorsConstants.SEARCH, phrase });
  }
}
