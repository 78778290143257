import React, {Component} from 'react';
import Switch from "react-switch";
import {Collapse} from "react-collapse";
import {Box, Flex} from "@rebass/grid";
import {FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import Select from "react-select";

class SubsistenceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
      location: null,
      locations: [],
      additionalAccommodation: 0,
      isSent: false,
      isValid: false,
      subsistenceBreakdown: false,
      errors: {
        location: null,
        start_location: null,
        end_location: null,
      },
      fields: {
        location: null,
        additional_accommodation_value: '',
        additional_accommodation_currency: null,
        additional_accommodation_rate: null,
        start_location: '',
        end_location: '',
      },
    }
  }

  componentDidMount() {
    const {locations, expense, isSent} = this.props;

    const formattedLocations = this.formatLocations(locations);
    const location = formattedLocations.find(l => parseInt(l.value) === parseInt(expense && expense.location));

    this.setState({
      locations: formattedLocations,
      location,
      isSent
    })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.isSent) {
      this.setState({
        isSent: nextProps.isSent,
      })
    }
  }

  formatLocations(locations) {
    const formattedLocations = [];
    locations.map(location => {
      formattedLocations.push({
        label: `${location.country} (${location.name})`,
        value: location.id,
        over5HoursRate: location.hours_5,
        over10HoursRate: location.hours_10,
        overnightRate: location.overnight,
        additionalAccommodation: location.additional_accommodation,
        additionalOvernightSubsistence: location.additionalOvernightSubsistence,
        additionalOvernightSubsistenceCurrency: location.additionalOvernightSubsistenceCurrency,
        additionalOvernightSubsistenceValue: location.currency_2_id,
        rate: location.rate,
        currencyId: location.local_currency_id,
        currencyCode: location.currency_code,
      });
      return location;
    });
    return formattedLocations;
  }

  calculate() {
    const { durationDays, durationHours, location } = this.state;
    if (location && (durationHours || durationDays )) {
      let subsistenceValue = 0;
      if (durationDays) {
        subsistenceValue += durationDays * location.overnightRate;
      } else {
        subsistenceValue = 0;
      }

      let subsistenceRate = null;
      let subsistenceRateValue = 0;
      let subsistenceLocalRateValue = 0;
      if ( durationHours > 10 ) {
        subsistenceValue += location.over10HoursRate;
        subsistenceLocalRateValue = location.over10HoursRate;
        subsistenceRate = 'between 10 and 24 hours';
        subsistenceRateValue = location.over10HoursRate * location.rate;
      } else if (durationHours > 5) {
        subsistenceValue += location.over5HoursRate;
        subsistenceLocalRateValue = location.over5HoursRate;
        subsistenceRate = 'between 5 and 10 hours';
        subsistenceRateValue = location.over5HoursRate * location.rate;
      }

      this.setState({
        subsistenceValue,
        subsistenceRate,
        subsistenceRateValue,
        subsistenceLocalRateValue
      });
    }
  }

  onLocationChanged(location) {
    this.setState({
      location,
      fields: {
        ...this.state.fields,
        location: location.value,
      },
      errors: {
        ...this.state.errors,
        location: undefined,
      }
    }, () => this.validate());
  }

  onSubsistenceClaimChanged(checked) {
    this.setState({
      ...this.state,
      isActive: checked,
    }, () => {
      this.props.onDataChanged({
          hasErrors: this.validate(),
          isActive: checked,
          fields: this.state.fields,
        }
      )

    });
  }

  onFocusChanged(fieldName) {
    if (this.props.onFocusChanged) {
      this.props.onFocusChanged(fieldName);
    }
  }

  onAdditionalAccommodationChanged(event) {
    const value = event.target.value;
    if (isNaN(value)) {
      this.setState({
        errors: {
          ...this.state.errors,
          additionalAccommodation: 'Only numbers allowed',
        },
      }, () => this.calculate());
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          additionalAccommodation: undefined,
        },
        fields: {
          ...this.state.fields,
          additional_accommodation_value: value,
        },
      }, () => this.calculate());
    }
  }

  validate () {
    const {fields} = this.state;
    let hasErrors = false;
    const errors = {};

    //Location
    if (fields.location){
      errors.location = null;

    } else {
      errors.location = 'This Field is required.';
      hasErrors = true;
    }

    //Start Location
    if (fields.start_location){
      errors.start_location = null;
    } else {
      errors.start_location = 'This Field is required.';
      hasErrors = true;
    }

    //End Location
    if (fields.end_location){
      errors.end_location = null;
    } else {
      errors.end_location = 'This Field is required.';
      hasErrors = true;
    }

    this.setState({
      ...this.state,
      errors,
    }, () =>
      this.props.onDataChanged({
          hasErrors,
          isActive: this.state.isActive,
          fields: this.state.fields,
        }
      )
    );

    return !hasErrors;
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      fields: {...this.state.fields, [name]: value},
    }, () => this.validate());
  }

  render() {

    const {editable, days, hours, isSent} = this.props;
    const {
      locations,
      location,
      subsistenceRateValue,
      isActive,
      errors,
      fields,
      subsistenceBreakdown,
      subsistenceRate,
      subsistenceLocalRateValue,
    } = this.state;

    const additionalOvernightSubsistence = 0;
    const additionalAccommodation = 0;

    const currencyId = location && location.currencyId;
    const currencyCode = location && location.currencyCode;
    const overnightValue = location && location.overnightRate;

    const eurValueOvernight = location ?
      (overnightValue * location.rate).toFixed(2) : 0;

    const eurValueOvernightTotal = eurValueOvernight ?
      (eurValueOvernight * days).toFixed(2) : 0;


    let subsistenceTotalValue;
    subsistenceTotalValue = eurValueOvernightTotal ? parseFloat(eurValueOvernightTotal) : 0;
    subsistenceTotalValue += subsistenceRateValue ? parseFloat(subsistenceRateValue) : 0;
    subsistenceTotalValue += additionalOvernightSubsistence ? parseFloat(additionalOvernightSubsistence) : 0;
    subsistenceTotalValue += additionalAccommodation ? parseFloat(additionalAccommodation) : 0;

    const subsistenceTotal = `€${parseFloat(subsistenceTotalValue).toFixed(2)}`;

    return (
      <div className="card" id="subsitence-claim-panel">
        <div className="card-title">
          Subsistence claim
          <Switch
            disabled={!editable}
            onColor="#5db8f1"
            offColor="#2c3c59"
            checkedIcon={false}
            uncheckedIcon={false}
            width={48}
            height={24}
            onChange={(checked) => this.onSubsistenceClaimChanged(checked)}
            checked={isActive}
            id="normal-switch"
          />
        </div>
        <Collapse isOpened={isActive}>
          <div className="card-body">
            <Flex flexWrap={'wrap'}>
              <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                <FormGroup
                  intent={ isSent && errors.location ? Intent.DANGER : Intent.SUCCESS }
                  label="Location"
                  labelFor="location"
                  labelInfo="*"
                  helperText={ isSent && errors.location && errors.location }
                >
                  <Select
                    isDisabled={!editable}
                    name="location"
                    multi={false}
                    options={locations}
                    onChange={option => this.onLocationChanged(option)}
                    defaultValue={location}
                    value={location}
                    onFocus={() => this.onFocusChanged('location')}
                    onBlur={() => this.onFocusChanged(null)}
                  />
                </FormGroup>
              </Box>
              <Box width={['100%', '50%', '50%']} pl={['0', '5px']}>
                <FormGroup
                  intent={ isSent && errors.additional_accommodation_value ? Intent.DANGER : Intent.SUCCESS }
                  label="Additional Accommodation per day (in €)"
                  labelFor="additionalAccommodation"
                  helperText={ isSent && errors.additional_accommodation_value && errors.additional_accommodation_value }
                  disabled={(location && !location.additionalAccommodation) || !location}
                >
                  <InputGroup
                    disabled={(location && !location.additionalAccommodation) || !location || !editable
                    }
                    large
                    name="additional-accommodation"
                    id="additional-accommodation"
                    value={fields.additional_accommodation_value}
                    onChange={(event) => this.onAdditionalAccommodationChanged(event)}
                    onFocus={(event) => this.onFocusChanged(event)}
                    onBlur={() => this.onFocusChanged(null)}
                  />
                </FormGroup>
              </Box>
            </Flex>
            <Flex flexWrap={'wrap'}>
              <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                <FormGroup
                  intent={ isSent && errors.start_location ? Intent.DANGER : Intent.SUCCESS }
                  label="Where did your trip begin?"
                  labelFor="tripBegin"
                  labelInfo="*"
                  helperText={ isSent && errors.start_location && errors.start_location }
                >
                  <InputGroup
                    disabled={!editable}
                    name="start_location"
                    id="start_location"
                    large
                    onChange={(event) => this.handleUserInput(event)}
                    onFocus={(event) => this.onFocusChanged(event)}
                    onBlur={() => this.onFocusChanged(null)}
                    value={ fields.start_location }
                  />
                </FormGroup>
              </Box>
              <Box width={['100%', '50%', '50%']} pl={['0', '5px']}>
                <FormGroup
                  intent={  isSent && errors.end_location ? Intent.DANGER : Intent.SUCCESS }
                  label="Where did your trip end?"
                  labelFor="tripEnd"
                  labelInfo="*"
                  helperText={  isSent && errors.end_location ? errors.end_location : ''}
                >
                  <InputGroup
                    disabled={!editable}
                    name="end_location"
                    id="end_location"
                    large
                    onChange={(event) => this.handleUserInput(event)}
                    onFocus={(event) => this.onFocusChanged(event)}
                    onBlur={() => this.onFocusChanged(null)}
                    value={ fields.end_location }
                  />
                </FormGroup>
              </Box>
            </Flex>
          </div>
          {location && (days || hours) ?
            <div className="card" id="subsitence-breakdown-panel">
              <div className="card-title">
                Subsistence Calculation Breakdown
                <Switch
                  disabled={!editable}
                  onColor="#5db8f1"
                  offColor="#2c3c59"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  width={32}
                  height={18}
                  onChange={(checked) => this.setState({
                    subsistenceBreakdown: checked
                  })}
                  checked={subsistenceBreakdown}
                  id="normal-switch"
                />
              </div>
              <Collapse isOpened={subsistenceBreakdown}>

                <Flex pl={10} pr={10}>
                  <Box width={['100%', '50%', '50%']}><b>Duration Away From Base</b>
                  </Box>
                  <Box width={['100%', '50%', '50%']}>
                    {days} {days === 1 ? 'Day' : 'Days'}&nbsp;and {hours} Hours
                  </Box>
                </Flex>
                <Flex pl={10} pr={10}>
                  <Box width={['100%', '50%', '50%']}><b>Base Amount</b>
                  </Box>
                  <Box width={['100%', '50%', '50%']}>
                    {eurValueOvernight} EUR {currencyId > 1 && `(${overnightValue} ${currencyCode})`}
                  </Box>
                </Flex>
                <Flex pl={10} pr={10}>
                  <Box width={['100%', '50%', '50%']}><b>Overnight Value</b></Box>
                  <Box width={['100%', '50%', '50%']}>
                    €{eurValueOvernight} x&nbsp;
                    {days} {days === 1 ? 'Day' : 'Days'} =&nbsp;
                    €{eurValueOvernightTotal}
                  </Box>
                </Flex>


                {subsistenceRateValue > 0 ? <Flex pl={10} pr={10}>
                  <Box width={1 / 2}><b>1 x {subsistenceRate} rate</b>
                  </Box>
                  <Box>€{(subsistenceRateValue).toFixed(2)} {currencyId > 1 && `(${subsistenceLocalRateValue} ${currencyCode})`}</Box>
                </Flex> : ''}


                {location.additionalOvernightSubsistence ? <Flex pl={10} pr={10}>
                  <Box width={['100%', '50%', '50%']}>
                    <b>Additional Overnight Subsistence</b>
                  </Box>
                  <Box width={['100%', '50%', '50%']}>
                    €{location.additionalOvernightSubsistenceValue} x&nbsp;
                    {days} {days === 1 ? 'Day' : 'Days'} =&nbsp;
                    €{(location.additionalOvernightSubsistenceValue * days).toFixed(2)}
                  </Box>
                </Flex> : ''}
                {location.additionalAccommodation && additionalAccommodation.length && additionalAccommodation > 0 ?
                  <Flex pl={10} pr={10}>
                    <Box width={['100%', '50%', '50%']}>
                      <b>Additional Subsistence</b>
                    </Box>
                    <Box width={['100%', '50%', '50%']}>
                      €{additionalAccommodation}
                    </Box>
                  </Flex> : ''}
                <Flex pl={10} pr={10}>
                  <Box width={['100%', '50%', '50%']}>
                    <b>Total</b>
                  </Box>
                  <Box width={['100%', '50%', '50%']}>
                    {subsistenceTotal}
                  </Box>
                </Flex>
              </Collapse>
            </div>
            : ''}

        </Collapse>
      </div>
    );
  }
}

export default SubsistenceForm;
