import axios from 'axios';
import {settingsMileageRatesConstants} from './constants';

export function getMileageRates(){
  return function(dispatch){
    dispatch({type:settingsMileageRatesConstants.GET_SETTINGS_MILEAGE_RATE_REQUEST});
    axios.get('/mileage-rates')
      .then((result)=>{
        dispatch({type: settingsMileageRatesConstants.GET_SETTINGS_MILEAGE_RATE_SUCCESS, payload: result.data});
      })
      .catch((err)=>{
        dispatch({type:settingsMileageRatesConstants.GET_SETTINGS_MILEAGE_RATE_FAILURE, error: err});
      })
  }
}


export function toggleMileageRateEditor( record ){
  return function(dispatch){
    dispatch({ type:settingsMileageRatesConstants.TOGGLE_EDITOR, payload: record });
  }
}

export function insertMileageRate( companyId, record, cb ) {
  return function(dispatch){
    dispatch({type:settingsMileageRatesConstants.MILEAGE_RATE_ADD_REQUEST});
    axios.post(`/mileage-rates`, record).then(result => {
      cb('saved');
      dispatch({type:settingsMileageRatesConstants.MILEAGE_RATE_ADD_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:settingsMileageRatesConstants.MILEAGE_RATE_ADD_FAILURE, error});
    });
  }
}

export function updateMileageRate( companyId, id, record, cb ) {
  return function(dispatch){
    dispatch({type:settingsMileageRatesConstants.MILEAGE_RATE_EDIT_REQUEST});
    axios.post(`/mileage-rates/${id}`, record).then(result => {
      cb('saved');
      dispatch({type:settingsMileageRatesConstants.MILEAGE_RATE_EDIT_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:settingsMileageRatesConstants.MILEAGE_RATE_EDIT_FAILURE, error});
    });
  }
}

export function deleteMileageRate( companyId, id, cb ) {
  return function(dispatch){
    dispatch({type:settingsMileageRatesConstants.MILEAGE_RATE_DELETE_REQUEST});
    axios.post(`/mileage-rates/${id}/delete`).then(result => {
      cb('saved');
      dispatch({type:settingsMileageRatesConstants.MILEAGE_RATE_DELETE_SUCCESS, payload: parseInt(id)});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:settingsMileageRatesConstants.MILEAGE_RATE_DELETE_FAILURE, error});
    });
  }
}
