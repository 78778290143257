import React, { useState } from "react";
import { toast } from "../../../../store/toaster/actions";
import {
  Classes,
  FormGroup,
  InputGroup,
  Button,
  Spinner,
  Intent,
  ControlGroup,
} from "@blueprintjs/core";
import {
  insertPortalAccoessRecord,
  togglePortalAccessEditor,
  updatePortalAccessRecord,
  updateRecord,
} from "../../../../store/contractors/actions";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { randomString } from "../../../../utils/random";

const FormSchema = Yup.object().shape({
  u_username: Yup.string().required("This field is required"),
  u_password: Yup.string().required("This field is required"),
});

export default ({ formik }) => {
  const [loading, setLoading] = useState(false);
  const [inputType, setInputType] = useState("password");
  const { record = {} } = useSelector((store) => ({
    record: store.contractors.recordToEdit,
  }));
  const dispatch = useDispatch();

  const onSubmit = (form) => {
    setLoading(true);
    dispatch(
      updateRecord(record.id, form, (status) => {
        if (status === "saved") {
          dispatch(togglePortalAccessEditor());
          dispatch(
            toast({
              message: "Contractor was successfully updated",
              intent: "SUCCESS",
            })
          );
        } else if (status === "error") {
          dispatch(toast({ message: "waiting the api", intent: "DANGER" }));
        }
        setLoading(false);
      })
    );
  };

  return (
    <Formik
      initialValues={{
        u_username: record.u_username || "",
        u_password: record.u_password || "",
      }}
      validateOnChange={false}
      validationSchema={FormSchema}
      onSubmit={onSubmit}
    >
      {(subformik) => {
        const {
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          dirty,
          errors,
          isValid,
          setFieldValue,
        } = formik || subformik;
        return (
          <form onSubmit={handleSubmit}>
            <FormGroup
              label="Username"
              labelFor="u_username"
              intent={errors.u_username && Intent.DANGER}
              helperText={errors.u_username}
            >
              <InputGroup
                name="u_username"
                id="u_username"
                value={values.u_username || ""}
                intent={errors.u_username && Intent.DANGER}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              label="Password"
              labelFor="u_password"
              intent={errors.u_password && Intent.DANGER}
              helperText={errors.u_password}
            >
              <ControlGroup fill>
                <InputGroup
                  name="u_password"
                  id="u_password"
                  type={inputType}
                  autoComplete="new-password"
                  value={values.u_password || ""}
                  intent={errors.u_password && Intent.DANGER}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Button
                  className={Classes.FIXED}
                  icon={inputType === "text" ? "eye-open" : "eye-off"}
                  onClick={() =>
                    setInputType(inputType === "text" ? "password" : "text")
                  }
                />
                <Button
                  className={Classes.FIXED}
                  icon="random"
                  onClick={() => setFieldValue("u_password", randomString(8))}
                />
              </ControlGroup>
            </FormGroup>
            {!formik && <Button large type="submit" icon="floppy-disk" intent="success" text="Save" disabled={!dirty || loading} />}
            {!formik && loading && <Spinner />}
          </form>
        );
      }}
    </Formik>
  );
};
