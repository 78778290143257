import React, {Component} from 'react';
import { Route, Switch, withRouter, Link } from 'react-router-dom';
import BankStatementsUploader from "./BankStatements";
import PaintersHours from "./PainterHours";
import BrightPayUploader from "./BrightPay";

class Uploads extends Component {
  render() {
    const path = this.props.history.location.pathname.split("/");
    const currentPath = `${path[1]}/${path[2]}`;
    return (
      <div className="split">
        <div className="left">
          <ul className="submenu">
            <li className={currentPath === 'uploads/brightpay' ? 'active' : ''}><Link to="/uploads/brightpay">Payroll - Brightpay</Link></li>
            <li className={currentPath === 'uploads/bank-statements' ? 'active' : ''}><Link to="/uploads/bank-statements">Bank Statements</Link></li>
            <li className={currentPath === 'uploads/painter-hours' ? 'active' : ''}><Link to="/uploads/painter-hours">Painter Hours</Link></li>
          </ul>
        </div>
        <div className="right">
          <Switch>
            <Route exact path="/uploads/brightpay" component={BrightPayUploader} />
            <Route exact path="/uploads/bank-statements" component={BankStatementsUploader} />
            <Route exact path="/uploads/painter-hours" component={PaintersHours} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(Uploads);
