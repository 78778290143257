export const settingsMileageRatesConstants = {
  GET_SETTINGS_MILEAGE_RATE_REQUEST: 'SETTINGS_MILEAGE_RATE_GET_REQUEST',
  GET_SETTINGS_MILEAGE_RATE_SUCCESS: 'SETTINGS_MILEAGE_RATE_GET_SUCCESS',
  GET_SETTINGS_MILEAGE_RATE_FAILURE: 'SETTINGS_MILEAGE_RATE_GET_FAILURE',
  MILEAGE_RATE_ADD_REQUEST: 'SETTINGS_MILEAGE_RATE_ADD_REQUEST',
  MILEAGE_RATE_ADD_SUCCESS: 'SETTINGS_MILEAGE_RATE_ADD_SUCCESS',
  MILEAGE_RATE_ADD_FAILURE: 'SETTINGS_MILEAGE_RATE_ADD_FAILURE',
  MILEAGE_RATE_EDIT_REQUEST: 'SETTINGS_MILEAGE_RATE_EDIT_REQUEST',
  MILEAGE_RATE_EDIT_SUCCESS: 'SETTINGS_MILEAGE_RATE_EDIT_SUCCESS',
  MILEAGE_RATE_EDIT_FAILURE: 'SETTINGS_MILEAGE_RATE_EDIT_FAILURE',
  MILEAGE_RATE_DELETE_REQUEST: 'SETTINGS_MILEAGE_RATE_DELETE_REQUEST',
  MILEAGE_RATE_DELETE_SUCCESS: 'SETTINGS_MILEAGE_RATE_DELETE_SUCCESS',
  MILEAGE_RATE_DELETE_FAILURE: 'SETTINGS_MILEAGE_RATE_DELETE_FAILURE',
  TOGGLE_EDITOR: 'TOGGLE_SETTINGS_MILEAGE_RATE_EDITOR',
};
