import React, {Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from "axios";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        email: '',
      },
      error: null,
      errors: null,
      sent: false,
      requested: false,
    };
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Email
    if(!fields["email"]){
      formIsValid = false;
      errors["email"] = "This field is required";
    } else {
      if (typeof fields["email"] !== "undefined") {
        let lastAtPos = fields["email"].lastIndexOf('@');
        let lastDotPos = fields["email"].lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
          formIsValid = false;
          errors["email"] = "Please, enter a valid email address";
        }
      }
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleChange(field, e){
    if (!e.isTrusted) return;
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({fields});
    if (this.state.sent){
      this.handleValidation();
    }
  }

  handleFormSubmit = event => {
    event.preventDefault();
    const email = this.state.fields.email;
    this.setState({sent: true});
    if(this.handleValidation()) {
      const data = {email};
      axios.post(`/auth/forgot-password`, data)
        .then(res => {
          this.setState({
            requested: true,
          })
        })
    }
  }

  render() {
    return (
      <div className="account">
        <div className="left">
          <img onClick={() => { this.props.toast('AMMOTIO is Latin for APPLICATION!'); }} className="branding" alt="ammotio" src="/images/ammotio.png" />
          <div className="apps-wrapper">
            <h2>remember your password?</h2>
            <div className="apps-holder">
              <Link to="/login">Go to login</Link>
            </div>
          </div>
        </div>
        {!this.state.requested ?
          <div className="right">
            <h3>Forgot your Password?</h3>
            <br />
            <div>No worries! Enter your email address and we'll send you a link to reset your password.</div>
            <form ref={form => (this.form = form)} onSubmit={this.handleFormSubmit}>
              <div className="has-error form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="form-control"
                  noValidate
                  onChange={this.handleChange.bind(this, 'email')}
                />
                <label>
                  Email
                </label>
                {this.state.errors && this.state.errors.email &&
                <span className="help-block">{this.state.errors.email}</span>}
              </div>
              {this.state.error && <ul className="errors">
                <li>{this.state.error}</li>
              </ul>}
              <div className="button-area">
                <button type="submit" className="btn btn-primary">Send Request</button>
              </div>
            </form>
          </div> : <div className="right">
            <h3>Password recovery email sent</h3>
            <br />
            We’ve sent you an email containing a password recovery link. You will be able to reset your password by clicking the link within the next 24 hours. Please click on the button in your email.
            <br /><br />If you don’t see the email, be sure to check the spam folder.
          </div>
        }
      </div>
    );
  }
}

export default withRouter(ForgotPassword);
