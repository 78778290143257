export const settingsAgenciesConstants = {
  GET_SETTINGS_AGENCIES_REQUEST: 'SETTINGS_AGENCIES_GET_REQUEST',
  GET_SETTINGS_AGENCIES_SUCCESS: 'SETTINGS_AGENCIES_GET_SUCCESS',
  GET_SETTINGS_AGENCIES_FAILURE: 'SETTINGS_AGENCIES_GET_FAILURE',
  AGENCIES_ADD_REQUEST: 'SETTINGS_AGENCIES_ADD_REQUEST',
  AGENCIES_ADD_SUCCESS: 'SETTINGS_AGENCIES_ADD_SUCCESS',
  AGENCIES_ADD_FAILURE: 'SETTINGS_AGENCIES_ADD_FAILURE',
  AGENCIES_EDIT_REQUEST: 'SETTINGS_AGENCIES_EDIT_REQUEST',
  AGENCIES_EDIT_SUCCESS: 'SETTINGS_AGENCIES_EDIT_SUCCESS',
  AGENCIES_EDIT_FAILURE: 'SETTINGS_AGENCIES_EDIT_FAILURE',
  AGENCIES_DELETE_REQUEST: 'SETTINGS_AGENCIES_DELETE_REQUEST',
  AGENCIES_DELETE_SUCCESS: 'SETTINGS_AGENCIES_DELETE_SUCCESS',
  AGENCIES_DELETE_FAILURE: 'SETTINGS_AGENCIES_DELETE_FAILURE',
  TOGGLE_EDITOR: 'TOGGLE_SETTINGS_AGENCIES_EDITOR',
};
