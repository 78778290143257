import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {FormGroup, InputGroup, Button, Intent, Dialog, Classes, Checkbox, Card} from '@blueprintjs/core';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {toast} from "../../../store/toaster/actions";
import {toggleMileageRateEditor, insertMileageRate, updateMileageRate, deleteMileageRate} from "./store/actions";

import SlidingPanel from '../../../components/slidingpanel';
import {Box, Flex} from "@rebass/grid";
import Icon from "../../../components/icon";
import Select from "react-select";

const StyledEditor = styled(SlidingPanel)`
  .bp3-card {
    margin: 0 5px 16px 0;
    
    h4 {
      font-size: 16px;
      margin: 0 0 10px;
    }
  }
`;


class MileageRateEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      record: {
        name: '',
        band1rate: '',
        band1limit: '',
        band2rate: '',
        band2limit: '',
        band3rate: '',
        band3limit: '',
        band4rate: '',
        band4limit: '',
      },
      deleteDialog: false,
      errors: {},
      sent: false,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.record && nextProps.record.id) {
      this.setState({
        record: {
          id: nextProps.record.id ? nextProps.record.id : '',
          name: nextProps.record.name ? nextProps.record.name : '',
          band1rate: nextProps.record.band1rate ? nextProps.record.band1rate : '',       
          band1limit: nextProps.record.band1limit ? nextProps.record.band1limit : '',       
          band2rate: nextProps.record.band2rate ? nextProps.record.band2rate : '',       
          band2limit: nextProps.record.band2limit ? nextProps.record.band2limit : '',       
          band3rate: nextProps.record.band3rate ? nextProps.record.band3rate : '',       
          band3limit: nextProps.record.band3limit ? nextProps.record.band3limit : '',       
          band4rate: nextProps.record.band4rate ? nextProps.record.band4rate : '',       
          band4limit: nextProps.record.band4limit ? nextProps.record.band4limit : '',       
        }
      });
    } else {
      this.setState({
        record: {
          name: '',
          band1rate: '',
          band1limit: '',
          band2rate: '',
          band2limit: '',
          band3rate: '',
          band3limit: '',
          band4rate: '',
          band4limit: '',       
        }
      });
    }
  }

  handleDelete()
  {
    this.setState({deleteDialog: false});
    this.props.deleteMileageRate(this.props.match.params._id, this.props.record.id, () => {
      this.props.toast({message: 'Mileage Rate was successfully deleted', intent: 'SUCCESS'});
      this.props.toggleMileageRateEditor();
    });
  }

  handleSave(e) {
    e.preventDefault();
    this.setState({sent: true});
    if(this.handleValidation()) {
      const { insertMileageRate, updateMileageRate, toggleMileageRateEditor, toast, record } = this.props;
      if (record.id){
        updateMileageRate(record.company_id, record.id, this.state.record, status => {
          if (status === 'saved'){
            toast({message: 'Mileage Rate was successfully updated', intent: 'SUCCESS'});
            toggleMileageRateEditor();
          } else if (status === 'error') {
            toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      } else {
        insertMileageRate(this.props.match.params._id, this.state.record, status => {
          if (status === 'saved'){
            toast({message: 'Mileage Rate was successfully added', intent: 'SUCCESS'});
            toggleMileageRateEditor();
          } else if (status === 'error') {
            toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      }
    } else {
      console.log('form error');
    }

  }

  handleValidation(){
    let record = this.state.record;
    let errors = {};
    let formIsValid = true;

    if(!record['name']){
      formIsValid = false;
      errors['name'] = "This field is required";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleChange(e){
    let record = this.state.record;
    record[e.currentTarget.name] = e.currentTarget.value;
    this.setState({record});
    if (this.state.sent){
      this.handleValidation();
    }
  }

  handleNotificationChange(type, value) {
    let val = null;
    if (!value) {
      val = null;
    } else if (value.hasOwnProperty('value') && value.value === false){
      val = false;
    } else {
      val = true;
    }
    this.setState({
      ...this.state,
      record: {
        ...this.state.record,
        [type]: val
      },
      values: {
        ...this.state.values,
        [type]: value,
      }
    },() => {
      if (this.state.sent){
        this.handleValidation();
      }
    });
  }

  render() {
    const {isEditorActive, toggleMileageRateEditor} = this.props;
    const {record, values} = this.state;
    return (
      <StyledEditor isOpen={isEditorActive} width="520px" title="Mileage Rate" onRequestClose={() => toggleMileageRateEditor()}>
        <React.Fragment>
          <form onSubmit={(e) => this.handleSave(e)}>
            <FormGroup
              intent={this.state.errors.name ? Intent.DANGER : Intent.SUCCESS}
              label="Name"
              labelFor="name"
              labelInfo="*"
              helperText={this.state.errors.name && this.state.errors.name}
            >
              <InputGroup
                intent={this.state.errors.name ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="name"
                id="name"
                large
                placeholder="Name"
                defaultValue={record.name}
              />
            </FormGroup>
            <FormGroup
              intent={this.state.errors.band1rate ? Intent.DANGER : Intent.SUCCESS}
              label="Band1Rate"
              labelFor="band1rate"
              labelInfo="*"
              helperText={this.state.errors.band1rate && this.state.errors.band1rate}
            >
              <InputGroup
                intent={this.state.errors.band1rate ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="band1rate"
                id="band1rate"
                large
                placeholder="Band1Rate"
                defaultValue={record.band1rate}
              />
            </FormGroup>
            <FormGroup
              intent={this.state.errors.band1limit ? Intent.DANGER : Intent.SUCCESS}
              label="Band1Limit"
              labelFor="band1limit"
              labelInfo="*"
              helperText={this.state.errors.band1limit && this.state.errors.band1limit}
            >
              <InputGroup
                intent={this.state.errors.band1limit ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="band1limit"
                id="band1limit"
                large
                placeholder="Band1Limit"
                defaultValue={record.band1limit}
              />
            </FormGroup>
            <FormGroup
              intent={this.state.errors.band2rate ? Intent.DANGER : Intent.SUCCESS}
              label="Band2Rate"
              labelFor="band2rate"
              labelInfo="*"
              helperText={this.state.errors.band2rate && this.state.errors.band2rate}
            >
              <InputGroup
                intent={this.state.errors.band2rate ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="band2rate"
                id="band2rate"
                large
                placeholder="Band2Rate"
                defaultValue={record.band2rate}
              />
            </FormGroup>
            <FormGroup
              intent={this.state.errors.band2limit ? Intent.DANGER : Intent.SUCCESS}
              label="Band2Limit"
              labelFor="band2limit"
              labelInfo="*"
              helperText={this.state.errors.band2limit && this.state.errors.band2limit}
            >
              <InputGroup
                intent={this.state.errors.band2limit ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="band2limit"
                id="band2limit"
                large
                placeholder="Band2Limit"
                defaultValue={record.band2limit}
              />
            </FormGroup>
            <FormGroup
              intent={this.state.errors.band3rate ? Intent.DANGER : Intent.SUCCESS}
              label="Band3Rate"
              labelFor="band3rate"
              labelInfo="*"
              helperText={this.state.errors.band3rate && this.state.errors.band3rate}
            >
              <InputGroup
                intent={this.state.errors.band3rate ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="band3rate"
                id="band3rate"
                large
                placeholder="Band3Rate"
                defaultValue={record.band3rate}
              />
            </FormGroup>
            <FormGroup
              intent={this.state.errors.band3limit ? Intent.DANGER : Intent.SUCCESS}
              label="Band3Limit"
              labelFor="band3limit"
              labelInfo="*"
              helperText={this.state.errors.band3limit && this.state.errors.band3limit}
            >
              <InputGroup
                intent={this.state.errors.band3limit ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="band3limit"
                id="band3limit"
                large
                placeholder="Band3Limit"
                defaultValue={record.band3limit}
              />
            </FormGroup>
            <FormGroup
              intent={this.state.errors.band4rate ? Intent.DANGER : Intent.SUCCESS}
              label="Band4Rate"
              labelFor="band4rate"
              labelInfo="*"
              helperText={this.state.errors.band4rate && this.state.errors.band4rate}
            >
              <InputGroup
                intent={this.state.errors.band4rate ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="band4rate"
                id="band4rate"
                large
                placeholder="Band4Rate"
                defaultValue={record.band4rate}
              />
            </FormGroup>
            <FormGroup
              intent={this.state.errors.band4limit ? Intent.DANGER : Intent.SUCCESS}
              label="Band4Limit"
              labelFor="band4limit"
              labelInfo="*"
              helperText={this.state.errors.band4limit && this.state.errors.band4limit}
            >
              <InputGroup
                intent={this.state.errors.band4limit ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="band4limit"
                id="band4limit"
                large
                placeholder="Band4Limit"
                defaultValue={record.band4limit}
              />
            </FormGroup>
                     
            <div className="button-bar">
              <Button large type="submit" icon="floppy-disk" intent="success" text="Save" />
              {record && record.id && <Button style={{marginLeft: 10}} large onClick={() => this.setState({deleteDialog: true})} icon={<Icon color="#fff" icon="trash" />} intent="danger" text="Delete" />}
            </div>
          </form>
          <Dialog
            icon="info-sign"
            onClose={() => this.setState({deleteDialog: false})}
            title="MileageRate Delete"
            isOpen={this.state.deleteDialog}>
            <div className={Classes.DIALOG_BODY}>
              <div>Are you sure?</div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => this.setState({deleteDialog: false})}>Cancel</Button>
                <Button intent="DANGER" onClick={() => this.handleDelete()}>Delete</Button>
              </div>
            </div>
          </Dialog>
        </React.Fragment>
      </StyledEditor>
    );
  }
}

function mapStateToProps(state){
  return {
    isEditorActive: state.settingsMileageRates.isEditorActive,
    record: state.settingsMileageRates.recordToEdit,
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    toast,
    toggleMileageRateEditor,
    updateMileageRate,
    insertMileageRate,
    deleteMileageRate,
  }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withRouter(MileageRateEditor));
