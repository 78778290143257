import axios from 'axios';
import {settingsCurrenciesConstants} from './constants';

export function getCurrencies(){
  return function(dispatch){
    dispatch({type:settingsCurrenciesConstants.GET_SETTINGS_CURRENCY_REQUEST});
    axios.get('/currencies')
      .then((result)=>{
        dispatch({type: settingsCurrenciesConstants.GET_SETTINGS_CURRENCY_SUCCESS, payload: result.data});
      })
      .catch((err)=>{
        dispatch({type:settingsCurrenciesConstants.GET_SETTINGS_CURRENCY_FAILURE, error: err});
      })
  }
}


export function toggleCurrencyEditor( record ){
  return function(dispatch){
    dispatch({ type:settingsCurrenciesConstants.TOGGLE_EDITOR, payload: record });
  }
}

export function insertCurrency( companyId, record, cb ) {
  return function(dispatch){
    dispatch({type:settingsCurrenciesConstants.CURRENCY_ADD_REQUEST});
    axios.post(`/currencies`, record).then(result => {
      cb('saved');
      dispatch({type:settingsCurrenciesConstants.CURRENCY_ADD_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:settingsCurrenciesConstants.CURRENCY_ADD_FAILURE, error});
    });
  }
}

export function updateCurrency( companyId, id, record, cb ) {
  return function(dispatch){
    dispatch({type:settingsCurrenciesConstants.CURRENCY_EDIT_REQUEST});
    axios.post(`/currencies/${id}`, record).then(result => {
      cb('saved');
      dispatch({type:settingsCurrenciesConstants.CURRENCY_EDIT_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:settingsCurrenciesConstants.CURRENCY_EDIT_FAILURE, error});
    });
  }
}

export function deleteCurrency( companyId, id, cb ) {
  return function(dispatch){
    dispatch({type:settingsCurrenciesConstants.CURRENCY_DELETE_REQUEST});
    axios.post(`/currencies/${id}/delete`).then(result => {
      cb('saved');
      dispatch({type:settingsCurrenciesConstants.CURRENCY_DELETE_SUCCESS, payload: parseInt(id)});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:settingsCurrenciesConstants.CURRENCY_DELETE_FAILURE, error});
    });
  }
}
