import React, {Component} from 'react';
import {Route, Switch, Link} from "react-router-dom";
import Payslips from "./payslips";
import P60 from "./p60";

class Documents extends Component {
  render() {
    const path = this.props.history.location.pathname.split("/");
    const currentPath = `${path[1]}/${path[2]}`;
    return (
      <div className="split">
        <div className="left">
          <ul className="submenu">
            <li className={currentPath === 'documents/payslips' ? 'active' : ''}><Link to="/documents/payslips">Payslips</Link></li>
            <li className={currentPath === 'documents/p60' ? 'active' : ''}><Link to="/documents/p60">P60</Link></li>
          </ul>
        </div>
        <div className="right">
          <Switch>
            <Route exact path="/documents/payslips" component={Payslips} />
            <Route exact path="/documents/p60" component={P60} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Documents;
