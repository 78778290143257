import React from 'react';
import MyTabs, { TabPane } from 'rc-tabs';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {toggleList} from "../../../store/companies/actions";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import TabContent from "rc-tabs/lib/TabContent";

const StyledTabs = styled.div`
    display: flex;
    height: 64px;
    padding: 0 0;
    border-bottom: 1px solid #edeef0;
    
    button {
      &.back-button {
        border: none;
        border-right: 1px solid #edeef0;
        background-color: #fff;
        padding: 20px;
        margin-left: -15px;
        cursor: pointer;
        
        svg {
          width: 24px;
          height: 24px;
          fill: #7c9192;
        }
      }
    }
    
    li {
      &.tab {
        border: 1px solid #edeef0;
        border-right: none;
      
        &:last-child {
          border-right: 1px solid #edeef0;
        }
      }
    }
    
    .tabs {
      position: absolute;
      top: 0px;
      bottom: 0;
      align-items: flex-end;
      right: 16px;
      left: 16px;
      display: flex;
      
      @media screen and (max-width: 769px) {
        left: 70px;
      }
    }
     
    .tab {
      align-self: flex-end;
      position: relative;
      padding: 15px;

      span {
        display: block;
        position: absolute;
        left: -1px;
        right: -1px;
        bottom: -7px;
        height: 7px;
        background-color: #ebeff0;
        border: 1px solid #e2e6e9;
      }
    }

`;

const tabsList = [{
  key: 'details',
  label: 'Details',
  component: <i></i>,
}, {
  key: 'employees',
  label: 'Employees',
  component: <i></i>,
}, {
  key: 'bank-statements',
  label: 'Bank Statements',
  component: <i></i>,
}, {
  key: 'bank-accounts',
  label: 'Bank Accounts',
  component: <i></i>,
}];

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    const submoduleUrl = props.match.params._view ? props.match.params._view : null;
    this.state = {
      activeKey: submoduleUrl ? submoduleUrl : 'details',
    };
  }

  onChange(key) {
    this.setState({
      activeKey: key,
    })
    this.props.history.push(`/companies/${ this.props.match.params._id }/${ key === 'details' ? '' : key }`);
  }

  render() {
    let activeKey = 'details';
    const { children } = this.props;
    if (children) {
      this.data.forEach((d) => {
        if (d.component.type === children.type) {
          d.component = children;
          activeKey = d.key;
        }
      });
    }
    const tabs = tabsList.map(d => <TabPane key={d.key} tab={d.label}>{d.component}</TabPane>);
    return (
      <StyledTabs>
        <MediaQuery maxWidth={768}>
          <button className="back-button" onClick={this.props.toggleList}>
            <svg className="icon">
              <use xlinkHref="/ammotio-SVG-sprite.svg#options"/>
            </svg>
          </button>
        </MediaQuery>
        <div className="tabs">
          <MyTabs
            activeKey={this.state.activeKey}
            onChange={(key) => this.onChange(key)}
            renderTabBar={() => <ScrollableInkTabBar/>}
            renderTabContent={() => <TabContent/>}>
            {tabs}
          </MyTabs>
        </div>
      </StyledTabs>
    );
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    toggleList,
  }, dispatch);
}

export default connect(null, matchDispatchToProps)(Tabs);
