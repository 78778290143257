export function socket(state = {}, action) {
  switch (action.type) {
    case 'SOCKET':
      console.log(action);
      return {
        event: action.event,
        data: action.data
      };
    default:
      return state
  }
}
