import { userConstants } from '../contants';

const initialState = {
  loggedIn: false,
  data: {},
  modules: [],
}

export function user(state = initialState, action, x) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        data: {},
        modules: [],
      };
    case userConstants.LOGIN_SUCCESS:
      console.log(action.user);
      return {
        loggedIn: true,
        data: action.user,
        modules: action.user.modules,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        error: action.error,
        data: {},
        modules: [],
      };
    case userConstants.CHECK_REQUEST:
      return {
        checkingIn: true,
        data: {},
        modules: [],
      };
    case userConstants.CHECK_SUCCESS:
      return {
        loggedIn: true,
        data: action.user,
        modules: action.modules,
      };
    case userConstants.CHECK_FAILURE:
      return {
        loggedIn: false,
        error: action.error,
        data: {},
        modules: [],
      };
    case userConstants.LOGOUT:
      return {
        loggedIn: false,
        data: {},
        modules: [],
      };
    default:
      return state
  }
}
