import React, {Component} from 'react';
import {Button, Dialog} from '@blueprintjs/core';

class Tags extends Component {
  render() {
    return (
      <div className="tags">
        <Button intent="success" icon="add">Tag</Button>
        <Dialog/>
      </div>
    );
  }
}

export default Tags;
