import React from 'react';
import styled from 'styled-components';

const IconStyled = styled.svg`
  fill: ${props => props.theme.brand};
  width: ${props => props.size + 'px'};
  height: ${props => props.size + 'px'};
  display: inline-block;
  vertical-align: middle;
  fill: ${props => props.color};

  &.iconWrapped {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

const IconWrapper = styled.div`
  width: ${props => (props.wrapSize || props.size * 2) + 'px'};
  height: ${props => (props.wrapSize || props.size * 2) + 'px'};
  position: relative;
  ${props => (props.onClick ? ' cursor: pointer' : '')};
`;

const Icon = ({ className, icon, size, color }) => {
  return (
    <IconStyled
      className={className}
      size={size || 16}
      color={color || '#344563'}
    >
      <use xlinkHref={'/ammotio-SVG-sprite.svg#' + icon} />
    </IconStyled>
  );
};

Icon.Wrapped = props => (
  <IconWrapper {...props}>
    <Icon {...props} className={props.className + ' iconWrapped'} />
  </IconWrapper>
);

export default Icon;
