import { profileConstants } from '../constants';

const initialState = {
  loading: false,
  data: {}
}

export function profile(state = initialState, action) {
  switch (action.type) {
    case profileConstants.GET_PROFILE_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case profileConstants.GET_PROFILE_SUCCESS:
      console.log(action.payload);
      return {
        loading: false,
        data: action.payload,
      };
    case profileConstants.GET_PROFILE_FAILURE:
      return {
        loading: false,
        error: action.error,
        data: {},
      };
    default:
      return state
  }
}
