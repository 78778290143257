import React, {Component} from 'react';
import {connect} from "react-redux";
import { Toaster, Position } from '@blueprintjs/core';

class ToasterComponent extends Component {
  componentWillReceiveProps(nextProps) {
    this.toaster.show(nextProps.toast)
  }

  refHandlers = {
    toaster: (ref) => this.toaster = ref,
  };

  render() {
    return (
      <Toaster position={Position.TOP_RIGHT} ref={this.refHandlers.toaster} canEscapeKeyClear={true}
               autoFocus={true}/>
    );
  }
}

function mapStateToProps(state){

  if (state.socket && state.socket.event === "TOAST"){
    return {
      toast: state.socket.data
    }
  }

  return {
    toast: state.toaster.toast
  }
}

export default connect(mapStateToProps, "")(ToasterComponent);
