import { settingsCurrenciesConstants } from './constants';


const initialState = {
  loading: false,
  data: {},
  recordToEdit: null,
  isEditorActive: false,
}

export function settingsCurrencies(state = initialState, action) {
  switch (action.type) {
    case settingsCurrenciesConstants.GET_SETTINGS_CURRENCY_REQUEST:
      return {
        ...state,
        loading: true,
        data: {},
      };
    case settingsCurrenciesConstants.GET_SETTINGS_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case settingsCurrenciesConstants.GET_SETTINGS_CURRENCY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: {},
      };
    case settingsCurrenciesConstants.GET_SETTINGS_CURRENCY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: {},
      };
    case settingsCurrenciesConstants.CURRENCY_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: [...state.data, action.payload]
      };
    case settingsCurrenciesConstants.CURRENCY_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: state.data.map(record => action.payload.id === record.id ? action.payload : record)
      };
    case settingsCurrenciesConstants.CURRENCY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: state.data.filter(record=>record.id !== action.payload)
      };
    case settingsCurrenciesConstants.TOGGLE_EDITOR:
      console.log(action.payload);

      return {
        ...state,
        isEditorActive: !state.isEditorActive,
        recordToEdit: action.payload
      };
    default:
      return state
  }
}
