import React, {Component} from 'react';
import {Box, Flex} from '@rebass/grid';
import {Spinner, Dialog, Classes, Button} from "@blueprintjs/core";
import { connect } from 'react-redux';

import Icon from '../../../../components/icon';
import {bindActionCreators} from "redux";

import { toggleEditor, getDetails, deleteRecord } from "../../../../store/companies/actions";
import { toast } from "../../../../store/toaster/actions";
import moment from "moment";
import Tags from "../../../../components/tags";

class Details extends Component {

  state = {
    deleteDialog: false,
  }


  componentDidMount() {
    this.props.getDetails(this.props.match.params._id);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { record } = nextProps;
    if (record && record.record && (record.record.id !== parseInt(nextProps.match.params._id))) {
      nextProps.getDetails(nextProps.match.params._id);
    }
  }

  handleDelete() {
    this.props.deleteRecord(this.props.match.params._id, status => {
      if (status === 'saved'){
        this.setState({deleteDialog: false}, () => {
          this.props.toast({message: 'Company was successfully deleted', intent:'SUCCESS', icon: 'trash'});
          this.props.history.push('/companies');
        });

      } else if (status === 'error') {
        this.setState({deleteDialog: false}, () => {
          this.props.toast({message: 'Server Error', intent: 'DANGER', icon: 'error'});
        })
      }
    });
  }

  formatCountries (data) {
    const countries = [];
    if (data) {
      data.map(record => {
        data.push({label: record.name, value: record.id});
      });
    }
    return countries;
  }

  render() {
    const { toggleEditor } = this.props;
    const { tags, suggestions } = this.state;

    const record = this.props.record && this.props.record.record;

    if (record) {
      const address = [record.address_1, record.address_2, record.address_3, record.address_4, record.country];
      return (
        <React.Fragment>
        <Flex flexWrap={'wrap'} p={'16px'}>
          <Box width={['100%','100%','100%','100%','40%']} pr={['0','0','0','0','5px']}>
            <div className="panel">
              <div className="panel-head">
                <div className="tools">
                  <div className="icon" onClick={() => this.setState({deleteDialog: true})}>
                    <Icon icon="trash" color="#fff"/>
                  </div>
                  <div className="icon" onClick={() => toggleEditor(record)}>
                    <Icon icon="inclined-pencil" color="#fff"/>
                  </div>
                </div>
                <h2>{record.name}</h2>
                <div className="data-item-container blue">
                  <div>CRO NUMBER</div>
                  <div>{record.cro_number}</div>
                </div>
                <div className="data-item-container blue">
                  <div>VAT NUMBER</div>
                  <div>{record.vat_number}</div>
                </div>
                <Tags/>
              </div>

              <div className="panel-body">
                <div className="data-item-container white">
                  <div>Financial Year End</div>
                  <div>{moment(record.financial_year_end).isValid() ? moment(record.financial_year_end).format('DD/MM') : ''}</div>
                </div>
                <div className="data-item-container white">
                  <div>Incorporation Date	</div>
                  <div>{moment(record.date_of_incorporation).isValid() ? moment(record.date_of_incorporation).format('DD/MM/YYYY') : ''}</div>
                </div>
              </div>
            </div>
          </Box>
          <Box width={['100%','100%','100%','100%','60%']} pt={['10px', '10px', '10px', '10px', '0']} pl={['0','0','0','0','5px']}>
            <div className="card">
                <h5 className="card-title">Address</h5>
                <div className="card-body">
                  {address.filter(x => typeof x === 'string' && x.length > 0).join(", ")}
                </div>
            </div>
          </Box>
        </Flex>
          <Dialog
            icon="info-sign"
            onClose={() => this.setState({deleteDialog: false})}
            title="Company Delete"
            isOpen={this.state.deleteDialog}
          >
            <div className={Classes.DIALOG_BODY}>
              <div>Are you sure?</div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => this.setState({deleteDialog: false})}>Cancel</Button>
                <Button intent="DANGER" onClick={() => this.handleDelete()}>Delete</Button>
              </div>
            </div>
          </Dialog>
        </React.Fragment>
      );
    } else {
      return (
        <Spinner className="mt50" />
      )
    }
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    toggleEditor,
    getDetails,
    deleteRecord,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    record: state.companies.selectedRecord,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(Details));
