import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import axios from 'axios';
import { connect } from "react-redux";
import Select from 'react-select';
import { Button, FormGroup, InputGroup, Intent, Spinner } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import styled from "styled-components";
import { Box, Flex } from "@rebass/grid";
import moment from "moment";

import Icon from "../../../../components/icon";
import { toast } from "../../../../store/toaster/actions";
import { toggleCompanyEditor, updateRecord } from "../../../../store/contractors/actions";
import { yesNo } from '../../../../utils/yesno';

const StyledEditor = styled.div`
  .bp3-card {
    margin: 0 5px 16px 0;
    
    h4 {
      font-size: 16px;
      margin: 0 0 10px;
    }
  }
`;


class CompanyEditor extends Component {
  state = {
    record: {
      company_id: null,
      date_of_appointment: null,
      date_of_resignation: null,
      proprietary_director: 0,
    },
    errors: {},
    companies: [],
    loading: false,
    selectedCompany: null,
    dateOfAppointment: null,
    dateOfResignation: null,
    proprietaryDirector: { value: 0, label: "No" },
  };

  componentDidMount() {
    this.setState({ loading: true });
    axios.get('companies').then(result => {
      const { record } = this.props;
      const companies = result.data.map(company => { return { value: company.id, label: company.name } })
      const selectedCompany = companies.filter(company => company.value === record.company_id)[0];
      this.setState({
        loading: false,
        companies,
        selectedCompany,
        dateOfAppointment: record.date_of_appointment && moment(record.date_of_appointment, ['DD/MM/YYYY']).toDate(),
        dateOfResignation: record.date_of_resignation && moment(record.date_of_resignation, ['DD/MM/YYYY']).toDate(),
        proprietaryDirector: record.proprietary_director === 1 ? { value: 1, label: "Yes" } : { value: 0, label: "No" },
        record: {
          company_id: record.company_id,
          date_of_appointment: record.date_of_appointment && moment(record.date_of_appointment, ['DD/MM/YYYY']).format('YYYY-MM-DD'),
          date_of_resignation: record.date_of_resignation && moment(record.date_of_resignation, ['DD/MM/YYYY']).format('YYYY-MM-DD'),
          proprietary_director: record.proprietary_director,
          share_holding: record.share_holding,
        }
      }, () => console.log(this.state));
    }).catch(error => {
      this.setState({ loading: false });
    });
  }

  handleSave(event) {
    event.preventDefault();
    this.props.updateRecord(this.props.record.id, this.state.record, (status) => {
      if (status === 'saved') {
        this.props.toast({ message: 'Contractor was successfully updated', intent: 'SUCCESS' });
        this.props.toggleCompanyEditor();
      } else if (status === 'error') {
        this.props.toast({ message: 'Server Error', intent: 'DANGER' });
      }
    });
  }

  handleChange(e) {
    let record = this.state.record;
    record[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ record });
    if (this.state.sent) {
      this.handleValidation();
    }
  }


  handleSetCompany(company) {
    this.setState({
      ...this.state,
      selectedCompany: company,
      record: {
        ...this.state.record,
        company_id: company && company.value ? company.value : null,
      }
    }, () => console.log(this.state));
  }

  handleSetIsDirector(value) {
    this.setState({
      ...this.state,
      proprietaryDirector: value,
      record: {
        ...this.state.record,
        proprietary_director: value && value.value ? value.value : 0,
      }
    })
  }

  render() {
    const { record, loading, companies, selectedCompany, proprietaryDirector } = this.state;
    const { formik } = this.props;
    const handleChange = formik ? formik.handleChange : null;
    const setFieldValue = formik ? formik.setFieldValue : null;

    return (
      <>
        {loading ? <Spinner /> :
          <StyledEditor>
            <form onSubmit={(e) => this.handleSave(e)}>
              <FormGroup
                label="Company"
                labelFor="company_id"
              >
                <Select
                  styles={{ menu: (styles) => Object.assign(styles, { zIndex: 10 }) }}
                  isClearable
                  placeholder="Not Set"
                  id="company_id"
                  options={companies}
                  name="company"
                  onChange={option => {
                    this.handleSetCompany(option);
                    setFieldValue && option && setFieldValue('company_id', option.value);
                  }}
                  value={selectedCompany}
                />
              </FormGroup>
              <Flex flexWrap={'wrap'}>
                <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                  <FormGroup
                    intent={this.state.errors.date_of_appointment ? Intent.DANGER : Intent.SUCCESS}
                    label="Date of Appointment"
                    labelFor="date_of_appointment"
                    helperText={this.state.errors.date_of_appointment && this.state.errors.date_of_appointment}
                  >
                    <DateInput
                      inputProps={{ style: { width: '100%' }, className: 'bp3-large', leftIcon: "calendar" }}
                      id="date_of_appointment"
                      className="bp3-large"
                      minDate={new Date('1900-01-01')}
                      formatDate={date => moment(date).format('DD/MM/YYYY')}
                      parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
                      onChange={(dt) => {
                        const date_of_appointment = dt ? `${moment(dt).format('YYYY-MM-DD')} 00:00:00` : null;
                        this.setState({
                          dateOfAppointment: dt ? new Date(dt) : null,
                          record: {
                            ...this.state.record,
                            date_of_appointment
                          }
                        })
                        setFieldValue && setFieldValue('date_of_appointment', );
                      }}
                      placeholder={"DD/MM/YYY"}
                      showActionsBar
                      intent={this.state.dateOfAppointment ? Intent.DANGER : Intent.NONE}
                      value={this.state.dateOfAppointment}
                    />
                  </FormGroup>
                </Box>
                <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                  <FormGroup
                    intent={this.state.errors.date_of_resignation ? Intent.DANGER : Intent.SUCCESS}
                    label="Date of Resignation"
                    labelFor="date_of_resignation"
                    helperText={this.state.errors.date_of_resignation && this.state.errors.date_of_resignation}
                  >
                    <DateInput
                      inputProps={{ style: { width: '100%' }, className: 'bp3-large', leftIcon: "calendar" }}
                      id="date_of_resignation"
                      className="bp3-large"
                      minDate={new Date('1900-01-01')}
                      formatDate={date => moment(date).format('DD/MM/YYYY')}
                      parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
                      onChange={(dt) => {
                        const date_of_resignation = dt ? `${moment(dt).format('YYYY-MM-DD')} 00:00:00` : null;
                        this.setState({
                        dateOfResignation: dt ? new Date(dt) : null,
                        record: {
                          ...this.state.record,
                          date_of_resignation,
                        }
                      })
                      setFieldValue && setFieldValue('date_of_resignation', date_of_resignation);
                    }}
                      placeholder={"DD/MM/YYY"}
                      showActionsBar
                      intent={this.state.dateOfResignation ? Intent.DANGER : Intent.NONE}
                      value={this.state.dateOfResignation}
                    />
                  </FormGroup>
                </Box>
              </Flex>

              <Flex flexWrap={'wrap'}>
                <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                  <FormGroup
                    intent={this.state.errors.proprietary_director ? Intent.DANGER : Intent.SUCCESS}
                    label="Proprietary Director"
                    labelFor="proprietary_director"
                    helperText={this.state.errors.proprietary_director && this.state.errors.proprietary_director}
                  >
                    <Select
                      isClearable
                      placeholder="Not Set"
                      id="proprietary_director"
                      options={yesNo()}
                      name="proprietary_director"
                      onChange={option => {
                        this.handleSetIsDirector(option);
                        setFieldValue && option && setFieldValue('proprietary_director', option.value);
                      }}
                      value={proprietaryDirector}
                    />
                  </FormGroup>
                </Box>
                <Box width={['100%', '50%', '50%']} pr={['0', '5px']}>
                  <FormGroup
                    intent={this.state.errors.share_holding ? Intent.DANGER : Intent.SUCCESS}
                    label="Share Holding"
                    labelFor="share_holding"
                    helperText={this.state.errors.share_holding && this.state.errors.share_holding}
                  >
                    <InputGroup
                      intent={this.state.errors.share_holding ? Intent.DANGER : Intent.NONE}
                      onChange={(e) => {
                        this.handleChange(e);
                        handleChange && handleChange(e);
                      }}
                      name="share_holding"
                      id="share_holding"
                      large
                      placeholder="Share Holding"
                      defaultValue={this.state.record.share_holding}
                    />
                  </FormGroup>
                </Box>
              </Flex>
              {!formik &&
                <div className="button-bar">
                  <Button large type="submit" icon="floppy-disk" intent="success" text="Save" />
                  {record && record.id &&
                    <Button style={{ marginLeft: 10 }} large onClick={() => this.setState({ deleteDialog: true })}
                      icon={<Icon color="#fff" icon="trash" />} intent="danger" text="Delete" />}
                </div>
              }
            </form>

          </StyledEditor>
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    record: state.contractors.recordToEdit,
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    toggleCompanyEditor,
    updateRecord,
  }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(CompanyEditor);
