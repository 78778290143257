import React, {Component} from 'react';
import axios from 'axios';
import { Callout, H4, H5, H6, Blockquote, Intent, Button, Card } from "@blueprintjs/core";
import {Table, Column, AutoSizer, RowComponent} from 'react-virtualized';
import Scrollbar from 'react-scrollbars-custom';
import Papa from 'papaparse';
import MonthYearPicker from "../../../../components/monthyearpicker";
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {connect} from "react-redux";

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const COLUMNS = [
  'Name','Location','Pass No.','Cheque Total','Back Hours','Back PAY','Car Park Deduction in days','Hours for Month','Rate','Tot Gross Sal',
];

const DBCOLUMNS = [
  'name','location','crew_code','total','back_hours','back_pay','car_park_deduction_in_days','hours_for_month','rate','gross',
];

function CustomRowRendererComponent({
  className,
  columns,
  index,
  style,
  rowData
}) {
  return (
    <div
      className={className}
      key={index}
      style={{...style, backgroundColor: rowData.found ? '' : 'red'}}
    >
        {columns}
    </div>
  );
}

class PaintersHours extends Component {
  state = {
    input: "",
    rows: [],
    currentStep: 1,
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    uploading: false,
  }

  handlePaste(data){
    const parsed = Papa.parse(data, {delimiter: "\t"});
    const records = [];
    parsed.data.map(record => {
      let r = {};
      record.map((column, index) => {
        const val = column.replace("€", "").trim().replace(",", "");
        r[DBCOLUMNS[index]] = val;
      });
      if (r['name'] !== "" || r['crew_code'] !== "") {
        records.push(r);
      }
    });

    this.setState({
      currentStep: 2,
      loading: true,
    }, () => {
      axios.post('contractors/get-by/crew-codes', records).then(result => {
        records.map((record, index) => {
          const data = result.data.find(obj => obj.crew_code == record['crew_code']);
          if (data){
            records[index].found = true;
            records[index].email = data.email;
            records[index].first_name = data.first_name;
            records[index].id = data.id;
          } else {
            record.found = false;
          }
        });
        this.setState({
          crewCodes: result.data.data,
          rows: records,
        })
      });
    })
  }

  handleUpload() {
    const data = {
      year: this.state.year,
      month: this.state.month,
      data: this.state.rows,
    };

    this.setState({
      uploading: true,
    })

    axios.post('/uploads/painter-hours', data).then(res => {
      console.log(res);

      this.setState({
        uploading: false,
        currentStep: 4,
      })

    }).catch(err => {
      console.log(err);

     // this.props.toast({message: err.response, intent: "danger"});
      this.setState({
        uploading: false,
      })
    });
  }
  render() {
    let totalEmails = 0;
    this.state.rows.map(row => {
      console.log(row);
      if (row.email && row.email !== '') {
        totalEmails += 1;
      }
    });


    return (
      <div className="right">
        {this.state.currentStep === 1 &&
          <React.Fragment>
            <div className="tools">
              <H4 className="m0"> Step 1/3 - Copy & Paste your data</H4>
            </div>
            <div className="p15">
              <Blockquote>
                Open your excel file, select all data you want to import and paste it here
              </Blockquote>
              <Callout className="mb10" intent={Intent.PRIMARY} title="Expected columns order is:">
                {COLUMNS.join(" | ")}
              </Callout>
              <textarea placeholder="Paste your selected data here" onChange={(e) => this.handlePaste(e.currentTarget.value)} style={{minWidth: '100%'}} rows="20"></textarea>
            </div>
          </React.Fragment>
        }
        {this.state.currentStep === 2 &&
          <React.Fragment>
            <div className="tools">
              <H4 className="m0"> Step 2/3 - Check your data</H4>
              <div>
                <Button intent={Intent.NONE} onClick={()=>{
                  this.setState({
                    rows: [],
                    currentStep: 1,
                    crewCodes: [],
                    input: null
                  })
                }}>Go Back</Button>
                <Button intent={Intent.PRIMARY} onClick={()=>{
                  this.setState({
                    currentStep: 3,
                  })
                }}>Next</Button>
              </div>
            </div>
            <div className="p15">
            <Blockquote>
              System processed data, please check if is everything correct
            </Blockquote>
            <AutoSizer>
              {({width, height}) => (
                <Table
                  width={width}
                  height={400}
                  headerHeight={60}
                  rowCount={this.state.rows.length}
                  rowGetter={({ index }) => this.state.rows[index]}
                  rowHeight={50}
                  rowRenderer={(props) => <CustomRowRendererComponent crewCodes={this.state.crewCodes} { ...props } />}
                >
                  {COLUMNS.map((col, index) =>
                    <Column
                      key={index}
                      width={200}
                      disableSort
                      label={COLUMNS[index]}
                      dataKey={DBCOLUMNS[index]}
                      flexGrow={1}
                    />
                  )}
                </Table>
              )}
            </AutoSizer>
            </div>
          </React.Fragment>
        }
        {this.state.currentStep === 3 &&
        <React.Fragment>
          <div className="tools">
            <H4 className="m0"> Step 3/3 - Upload & Notify</H4>
          </div>

          <Scrollbar noDefaultStyles>
          <div className="p15">
            <Card  elevation={1}>
              <H5>Which period is this upload for?</H5>
              <MonthYearPicker selectedYear={this.state.year} selectedMonth={this.state.month} minYear={2011} maxYear={2030} onChangeYear={(year) => this.setState({year})} onChangeMonth={(month)=>this.setState({month})}/>
            </Card>
            <Callout className="mb10" intent={Intent.PRIMARY} title={`Email Notifications - ${totalEmails}`}>
              Following emails will be sent with this uploads
            </Callout>
            {this.state.rows.map((row, index) => {
              if (row.email && row.email !== "") {
                return (
                  <Card className="mt10" key={index}>
                    <H6>{row.email}</H6>
                    <p>Dear {row.first_name}, your {months[this.state.month - 1]} Hours have been uploaded to the
                      portal.
                      Login to review</p>
                  </Card>
                )
              }
            })}
            <Button loading={this.state.uploading} className="mt10" large="true" icon="cloud-upload" onClick={() => this.handleUpload()} text="Upload" intent="success"/>
          </div>
          </Scrollbar>
        </React.Fragment>
        }
        {this.state.currentStep === 4 &&
          <React.Fragment>
            <div className="tools">
              <H4 className="m0">Upload Completed</H4>
            </div>
            <div className="p15">
              <Button intent={Intent.PRIMARY} onClick={() => this.setState({
                currentStep: 1,
              })}>Start Again</Button>
            </div>

          </React.Fragment>
        }
      </div>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
  }, dispatch);
}


export default (connect(null, matchDispatchToProps)(PaintersHours));

