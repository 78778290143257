import React from 'react';
import { useSelector } from 'react-redux';
import AppConfig from '../../AppConfig';

const check = (rules, role, action) => {
  const permissions = rules[role];

  if (!permissions) {
    // role is not present in the rules
    return false;
  }
  
  const staticPermissions = permissions.static;
  
  if (staticPermissions && staticPermissions.includes(action)) {
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      return false;
    }

    return permissionCondition(role);
  }
  return false;
};

export default ({ children, action, yes = <></>, no = <></> }) => {
  const { user } = useSelector(({ user }) => ({ user }));

  if(!user) return no || null;

  return check(AppConfig.permissions, user.data.p_group, action)
      ? children 
        ? children : yes
      : no || null;
}