import React, {Component} from 'react';
import moment from "moment";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getBankStatement} from "./store/actions";
import {Button, InputGroup, NonIdealState, Spinner} from "@blueprintjs/core";
import {CSVLink} from "react-csv";
import Icon from "../../../components/icon";
import {AutoSizer, Column, Table} from "react-virtualized";
import styled from "styled-components";

const StyledList = styled.div`
  height: 100%;
  width: 100%;
  padding: 8px 16px;
`;

const FUSE_OPTIONS = {
  threshold: 0.5,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    {name: 'date', weight: 0.4},
    {name: 'details', weight: 0.2},
    {name: 'credit', weight: 0.2},
    {name: 'debit', weight: 0.1},
    {name: 'balance', weight: 0.1},
  ]
};

const EXCEL_HEADERS = [
  { label: 'Date', key: 'date' },
  { label: 'Details', key: 'details' },
  { label: 'Debit', key: 'debit' },
  { label: 'Credit', key: 'credit' },
  { label: 'Balance', key: 'balance' },
];


class Payslips extends Component {

  state = {
    records: [],
  }

  componentDidMount() {
    this.props.getBankStatement();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let formatedRecords = [];

    console.log(nextProps.records);

    nextProps.records.map(record => {
      record = {
        ...record,
        date: moment(record.date).isValid() ? moment(record.date).format('DD/MM/YY') : '',
      };
      return formatedRecords.push(record);
    });

    this.setState({
      records: formatedRecords
    });
  }

  viewFile (payslipId) {
    const { _id } = this.props.match.params;
    const url = `${process.env.REACT_APP_API_URL}/payslips/get-file/${payslipId}?auth=${window.localStorage.getItem('ammotio-token')}&session=${window.localStorage.getItem('ammotio-session')}`;
    window.open(url, "_blank");
  }

  render() {
    const { loading } = this.props;
    const { records } = this.state;

    return (
      <div className="right">
        {loading ? (
          <Spinner className="mt50" />
        ) : (
          <React.Fragment>
            <div className="tools">
              <div></div>
              <div className="flex">
                {records.length > 0 &&
                <CSVLink
                  headers={EXCEL_HEADERS}
                  data={records}
                  filename={"bank_statements.csv"}
                  className="mr5"
                  target="_blank"
                >
                  <Icon icon="csv" size={38}/>
                </CSVLink>
                }
                <InputGroup
                  leftIcon="search"
                  onChange={(e) => {
                    this.setState({
                      search: e.currentTarget.value
                    })
                  }}
                  name="vat_number"
                  id="vat_number"
                  large
                  placeholder="Search"
                />
              </div>
            </div>
            {records.length > 0 ? (
              <StyledList>
                <AutoSizer>
                  {({width, height}) => (
                    <Table
                      ref="Table"
                      headerHeight={30}
                      noRowsRenderer={this._noRowsRenderer}
                      overscanRowCount={10}
                      rowClassName={this._rowClassName}
                      rowHeight={30}
                      rowCount={records.length}
                      rowGetter={({ index }) => records[index]}
                      height={height}
                      width={width}>
                      <Column
                        label="Date"
                        dataKey="date"
                        width={width * 0.1}
                      />
                      <Column
                        width={width * 0.3}
                        disableSort
                        label="Details"
                        dataKey="details"
                        flexGrow={1}
                      />
                      <Column
                        width={width * 0.1}
                        disableSort
                        label="Debit"
                        dataKey="debit"
                        flexGrow={1}
                      />
                      <Column
                        width={width * 0.1}
                        disableSort
                        label="Credit"
                        dataKey="credit"
                        flexGrow={1}
                      />
                      <Column
                        width={width * 0.1}
                        disableSort
                        label="Balance"
                        dataKey="balance"
                        flexGrow={1}
                      />
                    </Table>
                  )}
                </AutoSizer>
              </StyledList>
            ) : (
              <NonIdealState
                icon="search"
                title="No records found"
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    getBankStatement
  }, dispatch);
}

function mapStateToProps(state){
  return {
    loading: state.bankStatement.loading,
    records: state.bankStatement.records,
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(Payslips);
