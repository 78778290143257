import React, { useState } from 'react';
import {Spinner, Button, Dialog, Classes} from '@blueprintjs/core';

export default ({
  onViewClick,
  upload,
  canDelete,
  onDeleteClick
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);


  return (
    <React.Fragment>
      <tr onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}>
        <td>
          <Button
            icon='eye'
            minimal
            onClick={() => onViewClick(upload)}>
          </Button>
        </td>
        <td>{upload && upload.name}</td>
        <td className="r">
          {upload && upload.uploaded
            ? <React.Fragment>
              {
                !canDelete
                && <Button
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShow(true)
                    }}
                    icon='trash'
                    minimal>
                  </Button>
                }
                </React.Fragment>
              : <Spinner size={16} /> }
        </td>
      </tr>
      <Dialog
        icon="info-sign"
        onClose={() => setShow(false)}
        title="Delete Expense Receipts"
        enforceFocus={true}
        lazy={true}
        isOpen={show}>
        <div className={Classes.DIALOG_BODY}>
          <div>Are you sure?</div>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setShow(false);
              }}>
                Cancel
            </Button>
            <Button
              intent="DANGER"
              loading={loading}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                const res = onDeleteClick(upload.id);

                if (res && res.finally) {
                  setLoading(true);
                  res.finally(() => {
                    setLoading(false);
                    setShow(false);
                  });
                }
              }}>
                Delete
              </Button>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
