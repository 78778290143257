import React, {Component} from 'react';
import BusinessExpenseForm from "./BusinessExpenseForm";

class BusinessEditor extends Component {
  render() {
    return (
      <div className="split w-60-40">
        <div className="left">
          <BusinessExpenseForm/>
        </div>
        <div className="right">

        </div>
      </div>
    );
  }
}

export default BusinessEditor;
