import React, {Component} from 'react';
import TravelExpenseForm from "./TravelExpenseForm";

class TravelEditor extends Component {
  render() {
    return (
      <div className="split w-60-40">
        <div className="left">
          <TravelExpenseForm/>
        </div>
        <div className="right">

        </div>
      </div>
    );
  }
}

export default TravelEditor;
