import { settingsAgenciesConstants } from './constants';


const initialState = {
  loading: false,
  data: {},
  recordToEdit: null,
  isEditorActive: false,
}

export function settingsAgencies(state = initialState, action) {
  switch (action.type) {
    case settingsAgenciesConstants.GET_SETTINGS_AGENCIES_REQUEST:
      return {
        ...state,
        loading: true,
        data: {},
      };
    case settingsAgenciesConstants.GET_SETTINGS_AGENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case settingsAgenciesConstants.GET_SETTINGS_AGENCIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: {},
      };
    case settingsAgenciesConstants.GET_SETTINGS_AGENCIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: {},
      };
    case settingsAgenciesConstants.AGENCIES_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: [...state.data, action.payload]
      };
    case settingsAgenciesConstants.AGENCIES_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: state.data.map(record => action.payload.id === record.id ? action.payload : record)
      };
    case settingsAgenciesConstants.AGENCIES_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: state.data.filter(record=>record.id !== action.payload)
      };
    case settingsAgenciesConstants.TOGGLE_EDITOR:
      console.log(action.payload);

      return {
        ...state,
        isEditorActive: !state.isEditorActive,
        recordToEdit: action.payload
      };
    default:
      return state
  }
}
