import React, {Component} from 'react';
import { Link, withRouter } from 'react-router-dom';
import {bindActionCreators} from "redux";
import axios from "axios";
import {toast} from "../../store/toaster/actions";
import {connect} from "react-redux";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: {
        password: 'aaaaaa',
        password2: 'aaaaaa',
      },
      error: null,
      errors: null,
      sent: false,
    };
  }

  handleValidation(){
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Email
    if (fields.password.length === 0) {
      errors.password = 'This fields is required';
      formIsValid = false;
    } else {
      if (fields.password.length < 6) {
        errors.password = 'Your password must contain at least 6 characters';
        formIsValid = false;
      } else {
        errors.password = undefined;
      }
    }

    if (fields.password !== fields.password2) {
      errors.password2 = 'Fields mismatch';
      formIsValid = false;
    } else {
      errors.password2 = undefined;
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleChange(event){
    let fields = this.state.fields;
    fields[event.currentTarget.name] = event.currentTarget.value;
    this.setState({fields});
    if (this.state.sent){
      this.handleValidation();
    }
  }

  handleFormSubmit = event => {
    event.preventDefault();
    // const { history } = this.props;
    const email = this.state.fields.email;
    this.setState({sent: true});

    if(this.handleValidation()) {
      const data = {email};

      axios.post(`/auth/reset-password/${this.props.match.params._activationToken}`, data)
        .then(res => {
          this.props.toast({message: 'Your password has been changed', intent: 'success'});
          this.props.history.push('/login');
        })
        .catch((error) => {
          this.props.toast({message: error.response.data.message, intent: 'danger'});
        });
    }
  }

  render() {
    return (
      <div className="account">
        <div className="left">
          <img className="branding" alt="ammotio" src="/images/ammotio.png" />
          <div className="apps-wrapper">
            <h2>remember your password?</h2>
            <div className="apps-holder">
              <Link to="/login">Go to login</Link>
            </div>
          </div>
        </div>
        <div className="right">
          <h3>Change password</h3>
          <br />
          <div>Provide a new password for your account</div>
          <form ref={form => (this.form = form)} onSubmit={this.handleFormSubmit}>
            <div className="has-error form-group">
              <input
                type="password"
                name="password"
                placeholder="New password"
                className="form-control"
                noValidate
                value={this.state.fields.password}
                onChange={(event) => this.handleChange(event)}
              />
              <label>
                New password
              </label>
              {this.state.errors && this.state.errors.password && <span className="help-block">{this.state.errors.password}</span>}
            </div>
            <div className="form-group">
              <input
                type="password"
                name="password2"
                placeholder="Confirm Password"
                className="form-control"
                noValidate
                value={this.state.fields.password2}
                onChange={(event) => this.handleChange(event)}
              />
              <label>
                Confirm password
              </label>
              {this.state.errors && this.state.errors.password2 && <span className="help-block">{this.state.errors.password2}</span>}
            </div>
            {this.state.error && <ul className="errors"><li>{this.state.error}</li></ul>}
            <div className="button-area">
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
  }, dispatch);
}

export default withRouter((connect(null, matchDispatchToProps)(ResetPassword)));
