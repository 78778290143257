export const Enums = {
  UserActions: {
    EDIT_CONTRACTORS: 1,
    EDIT_CONTRACTOR_ROLE: 2
  },
  Role: {
    ADMINISTRATOR: '1',
    GENERAL: '2',
    ONE_MC: '3',
    SOLE_TRADER: '4'
  }
}

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

function invert(object) {
  const result = {}
  Object.keys(object).forEach(key => {
    let value = object[key]
    if (value != null && typeof value.toString !== 'function') {
      value = toString.call(value)
    }
    result[value] = new String(key)
                          .split('_')
                          .map(s => s.toLowerCase().capitalize())
                          .join(' ');
  })
  return result
}
export const LabelForEnum = Object.keys(Enums).reduce((acc, key) => {
  acc[key] = invert(Enums[key]);
  return acc;
}, {});

export default {
  permissions: {
    [Enums.Role.ADMINISTRATOR]: {
      static: [
        Enums.UserActions.EDIT_CONTRACTORS,
        Enums.UserActions.EDIT_CONTRACTOR_ROLE
      ],
      dynamic: {}
    },
    [Enums.Role.GENERAL]: {
      static: [
        Enums.UserActions.EDIT_CONTRACTORS
      ],
      dynamic: {}
    },
    [Enums.Role.ONE_MC]: {
      static: [
        Enums.UserActions.EDIT_CONTRACTORS
      ],
      dynamic: {
        // ['example']: () => true
      }
    } 
  }
}