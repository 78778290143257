import axios from 'axios';
import {expensesReportsConstants} from './constants';


export function getSummary({company, month, year}){
  return function(dispatch){
    dispatch({type:expensesReportsConstants.EXPENSES_SUMMARY_REQUEST});
    axios.get(`/reports/expenses/businessExpenses?year=${year}&month=${month}&company=${company}`).then(result => {
      dispatch({type:expensesReportsConstants.EXPENSES_SUMMARY_SUCCESS, payload: result});
    }).catch(error => {
      dispatch({type:expensesReportsConstants.EXPENSES_SUMMARY_FAILURE, error});
    });
  }
}
