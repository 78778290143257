export const contractorsConstants = {
  CONTRACTORS_REQUEST: 'GET_CONTRACTORS_REQUEST',
  CONTRACTORS_SUCCESS: 'GET_CONTRACTORS_SUCCESS',
  CONTRACTORS_FAILURE: 'GET_CONTRACTORS_FAILURE',

  CONTRACTOR_REQUEST: 'GET_CONTRACTOR_REQUEST',
  CONTRACTOR_SUCCESS: 'GET_CONTRACTOR_SUCCESS',
  CONTRACTOR_FAILURE: 'GET_CONTRACTOR_FAILURE',

  CONTRACTOR_ADD_REQUEST: 'CONTRACTOR_ADD_REQUEST',
  CONTRACTOR_ADD_SUCCESS: 'CONTRACTOR_ADD_SUCCESS',
  CONTRACTOR_ADD_FAILURE: 'CONTRACTOR_ADD_FAILURE',

  CONTRACTOR_EDIT_REQUEST: 'CONTRACTOR_EDIT_REQUEST',
  CONTRACTOR_EDIT_SUCCESS: 'CONTRACTOR_EDIT_SUCCESS',
  CONTRACTOR_EDIT_FAILURE: 'CONTRACTOR_EDIT_FAILURE',

  CONTRACTOR_DELETE_REQUEST: 'CONTRACTOR_DELETE_REQUEST',
  CONTRACTOR_DELETE_SUCCESS: 'CONTRACTOR_DELETE_SUCCESS',
  CONTRACTOR_DELETE_FAILURE: 'CONTRACTOR_DELETE_FAILURE',

  //PAYSLIPS
  PAYSLIPS_REQUEST: 'CONTRACTOR_PAYSLIPS_REQUEST',
  PAYSLIPS_SUCCESS: 'CONTRACTOR_PAYSLIPS_SUCCESS',
  PAYSLIPS_FAILURE: 'CONTRACTOR_PAYSLIPS_FAILURE',
  
  PAYSLIP_DELETE_REQUEST: 'CONTRACTOR_PAYSLIP_DELETE_REQUEST',
  PAYSLIP_DELETE_SUCCESS: 'CONTRACTOR_PAYSLIP_DELETE_SUCCESS',
  PAYSLIP_DELETE_FAILURE: 'CONTRACTOR_PAYSLIP_DELETE_FAILURE',

  //INVOICES
  INVOICES_REQUEST: 'CONTRACTOR_INVOICES_REQUEST',
  INVOICES_SUCCESS: 'CONTRACTOR_INVOICES_SUCCESS',
  INVOICES_FAILURE: 'CONTRACTOR_INVOICES_FAILURE',

  //BANK STATEMENTS
  BANK_STATEMENTS_ADD_REQUEST: 'CONTRACTOR_BANK_STATEMENTS_ADD_REQUEST',
  BANK_STATEMENTS_ADD_SUCCESS: 'CONTRACTOR_BANK_STATEMENTS_ADD_SUCCESS',
  BANK_STATEMENTS_ADD_FAILURE: 'CONTRACTOR_BANK_STATEMENTS_ADD_FAILURE',

  BANK_STATEMENTS_REQUEST: 'CONTRACTOR_BANK_STATEMENTS_REQUEST',
  BANK_STATEMENTS_SUCCESS: 'CONTRACTOR_BANK_STATEMENTS_SUCCESS',
  BANK_STATEMENTS_FAILURE: 'CONTRACTOR_BANK_STATEMENTS_FAILURE',
  
  BANK_STATEMENTS_EDIT_REQUEST: 'CONTRACTOR_BANK_STATEMENTS_EDIT_REQUEST',
  BANK_STATEMENTS_EDIT_SUCCESS: 'CONTRACTOR_BANK_STATEMENTS_EDIT_SUCCESS',
  BANK_STATEMENTS_EDIT_FAILURE: 'CONTRACTOR_BANK_STATEMENTS_EDIT_FAILURE',
  
  BANK_STATEMENTS_DELETE_REQUEST: 'CONTRACTOR_BANK_STATEMENTS_DELETE_REQUEST',
  BANK_STATEMENTS_DELETE_SUCCESS: 'CONTRACTOR_BANK_STATEMENTS_DELETE_SUCCESS',
  BANK_STATEMENTS_DELETE_FAILURE: 'CONTRACTOR_BANK_STATEMENTS_DELETE_FAILURE',
  
  //BANK ACCOUNTS
  BANK_ACCOUNTS_REQUEST: 'CONTRACTOR_BANK_ACCOUNTS_REQUEST',
  BANK_ACCOUNTS_SUCCESS: 'CONTRACTOR_BANK_ACCOUNTS_SUCCESS',
  BANK_ACCOUNTS_FAILURE: 'CONTRACTOR_BANK_ACCOUNTS_FAILURE',

  BANK_ACCOUNTS_ADD_REQUEST: 'CONTRACTOR_BANK_ACCOUNTS_ADD_REQUEST',
  BANK_ACCOUNTS_ADD_SUCCESS: 'CONTRACTOR_BANK_ACCOUNTS_ADD_SUCCESS',
  BANK_ACCOUNTS_ADD_FAILURE: 'CONTRACTOR_BANK_ACCOUNTS_ADD_FAILURE',

  BANK_ACCOUNTS_EDIT_REQUEST: 'CONTRACTOR_BANK_ACCOUNTS_EDIT_REQUEST',
  BANK_ACCOUNTS_EDIT_SUCCESS: 'CONTRACTOR_BANK_ACCOUNTS_EDIT_SUCCESS',
  BANK_ACCOUNTS_EDIT_FAILURE: 'CONTRACTOR_BANK_ACCOUNTS_EDIT_FAILURE',
  
  BANK_ACCOUNTS_DELETE_REQUEST: 'CONTRACTOR_BANK_ACCOUNTS_DELETE_REQUEST',
  BANK_ACCOUNTS_DELETE_SUCCESS: 'CONTRACTOR_BANK_ACCOUNTS_DELETE_SUCCESS',
  BANK_ACCOUNTS_DELETE_FAILURE: 'CONTRACTOR_BANK_ACCOUNTS_DELETE_FAILURE',


  //WORKING HOURS
  WORKING_HOURS_REQUEST: 'CONTRACTOR_WORKING_HOURS_REQUEST',
  WORKING_HOURS_SUCCESS: 'CONTRACTOR_WORKING_HOURS_SUCCESS',
  WORKING_HOURS_FAILURE: 'CONTRACTOR_WORKING_HOURS_FAILURE',

  //EXPENSES
  EXPENSES_SUMMARY_REQUEST: 'CONTRACTOR_EXPENSES_SUMMARY_REQUEST',
  EXPENSES_SUMMARY_SUCCESS: 'CONTRACTOR_EXPENSES_SUMMARY_SUCCESS',
  EXPENSES_SUMMARY_FAILURE: 'CONTRACTOR_EXPENSES_SUMMARY_FAILURE',

  EXPENSES_REQUEST: 'CONTRACTOR_EXPENSES_REQUEST',
  EXPENSES_SUCCESS: 'CONTRACTOR_EXPENSES_SUCCESS',
  EXPENSES_FAILURE: 'CONTRACTOR_EXPENSES_FAILURE',

  CONTRACTORS_TOGGLE_EDITOR: 'CONTRACTORS_TOGGLE_EDITOR',
  CONTRACTORS_TOGGLE_PORTAL_ACCESS_EDITOR: 'CONTRACTORS_TOGGLE_PORTAL_ACCESS_EDITOR',
  CONTRACTORS_TOGGLE_ADDRESS_EDITOR: 'CONTRACTORS_TOGGLE_ADDRESS_EDITOR',
  CONTRACTORS_TOGGLE_SPOUSE_EDITOR: 'CONTRACTORS_TOGGLE_SPOUSE_EDITOR',
  TOGGLE_BANK_ACCOUNT_EDITOR: 'CONTRACTORS_TOGGLE_BANK_ACCOUNT_EDITOR',
  TOGGLE_BANK_STATEMENT_EDITOR: 'CONTRACTORS_TOGGLE_BANK_STATEMENT_EDITOR',
  MARITAL_STATUS_AND_SPOUSE_DETAILS_EDITOR: 'MARITAL_STATUS_AND_SPOUSE_DETAILS_EDITOR',
  TOGGLE_NOTIFICATIONS_EDITOR: 'CONTRACTORS_TOGGLE_NOTIFICATIONS_EDITOR',
  TOGGLE_COMPANY_EDITOR: 'CONTRACTORS_TOGGLE_COMPANY_EDITOR',
  CONTRACTORS_TOGGLE_LIST: 'CONTRACTORS_TOGGLE_LIST',

  SET_FILTER: 'CONTRACTORS_SET_FILTER',
  REMOVE_FILTERS: 'CONTRACTORS_REMOVE_FILTERS',
  SEARCH: 'CONTRACTORS_SEARCH',

  CONTRACTORS_SELECT_RECORD: 'CONTRACTORS_SELECT_RECORD'
};
