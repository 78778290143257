import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {getBankStatements, toggleBankStatementEditor} from "../../../../store/contractors/actions";
import {connect} from "react-redux";
import { AutoSizer, Table, Column } from 'react-virtualized';
import styled from "styled-components";
import { CSVLink } from "react-csv";
import SlidingPanel from "../../../../components/slidingpanel";
import BankStatementEditor from "./BankStatementEditor";

import 'react-virtualized/styles.css';
import {Button, InputGroup, NonIdealState, Spinner} from "@blueprintjs/core";
import Fuse from "fuse.js";
import Icon from "../../../../components/icon";

const StyledList = styled.div`
  height: 100%;
  width: 100%;
  padding: 8px 16px;
`;

const FUSE_OPTIONS = {
  threshold: 0.5,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    {name: 'date', weight: 0.4},
    {name: 'details', weight: 0.2},
    {name: 'credit', weight: 0.2},
    {name: 'debit', weight: 0.1},
    {name: 'balance', weight: 0.1},
  ]
};

const EXCEL_HEADERS = [
  { label: 'Date', key: 'date' },
  { label: 'Details', key: 'details' },
  { label: 'Debit', key: 'debit' },
  { label: 'Credit', key: 'credit' },
  { label: 'Balance', key: 'balance' },
];

class BankStatements extends Component {

  state = {
    search: '',
    record: parseInt(this.props.match.params._id)
  }

  componentDidMount() {
    this.props.getBankStatements(this.props.match.params._id, r => {});
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.record !== parseInt(nextProps.match.params._id)){
      this.setState({
        record: parseInt(nextProps.match.params._id),
        records: nextProps.bankStatements,
      }, () => {
        this.props.getBankStatements(this.props.match.params._id, r => {});
      });
    }
  }

  render() {
    console.log(this.props.match.params._id)
    const { bankStatements, bankStatementsLoading, toggleBankStatementEditor, isBankStatementEditorActive} = this.props;
    const fuse = new Fuse(bankStatements, FUSE_OPTIONS);
    const records = this.state.search.length ? fuse.search(this.state.search) : bankStatements;
    return (
      <React.Fragment>
        {bankStatementsLoading ? (
          <Spinner className="mt50" />
        ) : (
          <React.Fragment>
            <div className="tools">
              <Button large onClick={() => toggleBankStatementEditor({user_id: this.props.match.params._id})} icon="add" intent="success" text="Add New" />
              <div className="flex">
                {records.length > 0 &&
                  <CSVLink
                    headers={EXCEL_HEADERS}
                    data={records}
                    filename={"bank_statement.csv"}
                    className="mr5"
                    target="_blank"
                  >
                    <Icon icon="csv" size={38}/>
                  </CSVLink>
                }
                <InputGroup
                  leftIcon="search"
                  onChange={(e) => {
                    this.setState({
                      search: e.currentTarget.value
                    })
                  }}
                  large
                  placeholder="Search"
                />
              </div>
            </div>
            {records.length ? (
              <StyledList>
              <AutoSizer>
                {({width, height}) => (
                  <Table
                    ref="Table"
                    headerHeight={30}
                    noRowsRenderer={this._noRowsRenderer}
                    overscanRowCount={10}
                    rowClassName={this._rowClassName}
                    rowHeight={30}
                    rowCount={records.length}
                    rowGetter={({ index }) => records[index]}
                    onRowClick={(event) => toggleBankStatementEditor(event.rowData)}
                    height={height}
                    width={width}>
                    <Column
                      label="Date"
                      dataKey="date"
                      width={width * 0.1}
                    />
                    <Column
                      width={width * 0.3}
                      disableSort
                      label="Details"
                      dataKey="details"
                      flexGrow={1}
                    />
                    <Column
                      width={width * 0.1}
                      disableSort
                      label="Debit"
                      dataKey="debit"
                      flexGrow={1}
                    />
                    <Column
                      width={width * 0.1}
                      disableSort
                      label="Credit"
                      dataKey="credit"
                      flexGrow={1}
                    />
                    <Column
                      width={width * 0.1}
                      disableSort
                      label="Balance"
                      dataKey="balance"
                      flexGrow={1}
                    />
                  </Table>
                )}
              </AutoSizer>
            </StyledList>
            ) : (
              <NonIdealState
                icon="search"
                title="No bank statements found for this contractor"
              />
            )}
          </React.Fragment>
        )}
        <SlidingPanel width="520px" isOpen={isBankStatementEditorActive} title="Bank Statement" onRequestClose={() => toggleBankStatementEditor()}>
          <BankStatementEditor/>
        </SlidingPanel>
      </React.Fragment>
    );
  }
}


function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
    getBankStatements,
    toggleBankStatementEditor,
  }, dispatch);
}

function mapStateToProps(state) {
  return {
    bankStatementsLoading: state.contractors.bankStatementsLoading,
    bankStatements: state.contractors.bankStatements,
    isBankStatementEditorActive: state.contractors.isBankStatementEditorActive,
  }
}

export default (connect(mapStateToProps, matchDispatchToProps)(BankStatements));
