import React, {Component} from 'react';
import SlidingPanel from "../../../components/slidingpanel";
import {Button, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import {DateInput} from "@blueprintjs/datetime";
import axios from "axios";
import Select from "react-select";
import moment from "moment";

class Editor extends Component {
  state = {
    countries: [],
    genders: [],
    positions: [],
    marital_statuses: [],
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    nationality: '',
    date_of_birth: null,
    dob: null,
    gender: null,
    base: '',
    position: null,
    errors: {},
    saving: false,
    marital_status: null,
  }

  formatGenders(genders){
    return genders.map((gender) => ({value: gender.id, label: gender.name}));
  }

  formatCountries(countries){
    return countries.map((country) => ({value: country.id, label: country.name}));
  }

  formatPositions(positions){
    return positions.map((position) => ({value: position.id, label: position.name}));
  }

  formatMaritalStatuses(maritalStatuses){
    return maritalStatuses.map((maritalStatus) => ({value: maritalStatus.id, label: maritalStatus.name}));
  }



  componentWillReceiveProps(nextProps, nextContext) {
    const {record} = nextProps;
    if (record) {
      const genders = this.formatGenders(record.genders);
      const countries = this.formatCountries(record.countries);
      const positions = this.formatPositions(record.positions);
      const date_of_birth = record.date_of_birth && moment(record.date_of_birth).toDate();
      const marital_statuses = this.formatMaritalStatuses(record.marital_statuses);

      this.setState({
        genders,
        countries,
        positions,
        marital_statuses,
        gender: genders.find(gender => {
          return gender.value === record.gender_id && gender;
        }),
        nationality: countries.find(nationality => {
          return nationality.value === record.nationality_id && nationality;
        }),
        position: positions.find(position => {
          return position.value === record.position_id && position;
        }),
        first_name: record.first_name,
        last_name: record.last_name,
        email: record.email,
        phone: record.phone,
        dob: record.date_of_birth,
        date_of_birth,
        base: record.base ? record.base : '',
        marital_status: marital_statuses.find(marital_status => {
          return marital_status.value === record.marital_status_id && marital_status;
        }),
      });
    }
  }

  handleChange(event){
    const {name, value} = event.currentTarget;
    this.setState({
      ...this.state,
      [name]: value,
    });

  }

  handleCountryChange(nationality){
    this.setState({
      nationality
    })
  }

  handlePositionChange(position){
    this.setState({
      position
    })
  }

  handleGenderChange(gender){
    this.setState({
      gender
    })
  }

  handleMaritalStatusChange(marital_status){
    this.setState({
      marital_status
    })
  }

  handleSubmit(event){
    event.preventDefault();

    this.setState({
      saving: true,
    })

    const data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      phone: this.state.phone,
      date_of_birth: this.state.dob,
      nationality_id: this.state.nationality && this.state.nationality.value,
      gender_id: this.state.gender && this.state.gender.value,
      base: this.state.base,
      position_id: this.state.position && this.state.position.value,
      marital_status_id: this.state.marital_status && this.state.marital_status.value,
    }

    axios.post('profile', data).then(result => {
      this.setState({
        saving: false,
      }, () => {
        this.props.onUpdate();
        this.props.onClose();
      });
    }).catch(error => {
      this.setState({
        saving: false,
      })
    });
  }


  render() {
    const {isOpen, onClose} = this.props;
    return (
      <SlidingPanel width="520px" isOpen={isOpen} title='Personal Details' onRequestClose={() => onClose()}>
        <form onSubmit={(event) => this.handleSubmit(event)}>
          <FormGroup
            intent={this.state.errors.first_name ? Intent.DANGER : Intent.NONE}
            className="mb5"
            label="First Name"
            labelFor="first_name"
            labelInfo="*"
            helperText={this.state.errors.first_name && this.state.errors.first_name}
          >
            <InputGroup
              name="first_name"
              id="first_name"
              large
              onChange={(event) => this.handleChange(event)}
              value={this.state.first_name}
            />
          </FormGroup>
          <FormGroup
            className="mb5"
            label="Last Name"
            labelFor="last_name"
            labelInfo="*"
            helperText={this.state.errors.last_name && this.state.errors.last_name}
          >
            <InputGroup
              name="last_name"
              id="last_name"
              large
              onChange={(event) => this.handleChange(event)}
              value={this.state.last_name}
            />
          </FormGroup>
          <FormGroup
            className="mb5"
            label="Email"
            labelFor="email"
            labelInfo="*"
            helperText={this.state.errors.email && this.state.errors.email}
          >
            <InputGroup
              name="email"
              id="email"
              large
              onChange={(event) => this.handleChange(event)}
              value={this.state.email}
            />
          </FormGroup>
          <FormGroup
            className="mb5"
            label="Phone"
            labelFor="phone"
            labelInfo="*"
            helperText={this.state.errors.phone && this.state.errors.phone}
          >
            <InputGroup
              name="phone"
              id="phone"
              large
              onChange={(event) => this.handleChange(event)}
              value={this.state.phone}
            />
          </FormGroup>
          <FormGroup
            className="mb5"
            label="Date of Birth"
            labelFor="date_of_birth"
            labelInfo="*"
            helperText={this.state.errors.date_of_birth && this.state.errors.date_of_birth}
          >
            <DateInput
              inputProps={{style: {width: '100%'}, className: 'bp3-large', leftIcon: "calendar"}}
              id="date_of_birth"
              className="bp3-large"
              minDate={new Date('1900-01-01')}
              formatDate={date => moment(date).format('DD/MM/YYYY')}
              parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
              onChange={(dt) => this.setState({
                date_of_birth: dt ? new Date(dt) : null,
                dob: dt ? moment(dt).format('YYYY-MM-DD') : null,
              })}
              placeholder={"DD/MM/YYYY"}
              showActionsBar
              intent={this.state.date_of_birth ? Intent.DANGER : Intent.NONE}
              value={this.state.date_of_birth}
            />
          </FormGroup>
          <FormGroup
            className="mb5"
            label="Nationality"
            labelFor="nationality"
            labelInfo="*"
            helperText={this.state.errors.nationality && this.state.errors.nationality}
          >
            <Select
              id="nationality"
              options={this.state.countries}
              onChange={option => this.handleCountryChange(option)}
              value={this.state.nationality}
            />
          </FormGroup>
          <FormGroup
            className="mb5"
            label="Gender"
            labelFor="gender"
            labelInfo="*"
            helperText={this.state.errors.gender && this.state.errors.gender}
          >
            <Select
              id="gender"
              options={this.state.genders}
              onChange={option => this.handleGenderChange(option)}
              value={this.state.gender}
            />
          </FormGroup>
          <FormGroup
            className="mb5"
            label="Marital Status"
            labelFor="marital_status"
            labelInfo="*"
            helperText={this.state.errors.gender && this.state.errors.gender}
          >
            <Select
              id="marital_status"
              options={this.state.marital_statuses}
              onChange={option => this.handleMaritalStatusChange(option)}
              value={this.state.marital_status}
            />
          </FormGroup>
          <FormGroup
            className="mb5"
            label="Base"
            labelFor="base"
            helperText={this.state.errors.base && this.state.errors.base}
          >
            <InputGroup
              name="base"
              id="base"
              large
              onChange={(event) => this.handleChange(event)}
              value={this.state.base}
            />
          </FormGroup>
          <FormGroup
            className="mb5"
            label="Position"
            labelFor="position"
            helperText={this.state.errors.dob && this.state.errors.dob}
          >
            <Select
              id="position"
              options={this.state.positions}
              onChange={option => this.handlePositionChange(option)}
              value={this.state.position}
            />
          </FormGroup>
          <Button loading={this.state.saving} large className="mt20" type="submit" intent="SUCCESS" icon="floppy-disk">Save</Button>

        </form>
      </SlidingPanel>
    );
  }
}

export default Editor;
