import { contractorsConstants } from '../constants';
const initialState = {
  loading: false,
  records: {},
  isEditorActive: false,
  isAddressEditorActive: false,
  isBankAccountEditorActive: false,
  isBankStatementEditorActive: false,
  isNotificationsEditorActive: false,
  isCompanyEditorActive: false,
  isMaritalStatusAndSpouseDetailsEditorActive: false,
  isPortalAccessEditorActive: false,
  isListActive: false,
  selectedRecord: null,
  recordToEdit: null,
  recordSaving: false,
  payslipsLoading: false,
  payslips: [],
  invoicesLoading: false,
  invoices: [],
  bankAccountsLoading: false,
  bankAccounts: [],
  bankStatementsLoading: false,
  bankStatements: [],
  workingHours: [],
  workingHoursLoading: false,
  expensesLoading: false,
  expenses: [],
  searchPhrase: localStorage.getItem('ammotio-contractors-search'),
  filters: localStorage.getItem('ammotio-contractors-filter') ? JSON.parse(localStorage.getItem('ammotio-contractors-filter')) : []
}

export function contractors(state = initialState, action, x) {
  switch (action.type) {
    case contractorsConstants.CONTRACTORS_REQUEST:
      return {
        ...state,
        loading: true,
        records: {}
      };
    case contractorsConstants.CONTRACTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        records: action.payload,
        genders: action.payload.genders,
        nationalities: action.payload.countries,
      };
    case contractorsConstants.CONTRACTORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        records: {}
      };
    case contractorsConstants.CONTRACTOR_REQUEST:
      return {
        ...state,
        recordLoading: true,
        selectedRecord: null
      };
    case contractorsConstants.CONTRACTOR_SUCCESS:
      return {
        ...state,
        recordLoading: false,
        selectedRecord: action.payload
      };
    case contractorsConstants.CONTRACTOR_ADD_REQUEST:
      return {
        ...state,
        recordSaving: true,
      };
    case contractorsConstants.CONTRACTOR_ADD_SUCCESS:
      return {
        ...state,
        recordSaving: false,
        records: [...state.records, action.payload]
      };
    case contractorsConstants.CONTRACTOR_EDIT_REQUEST:
      return {
        ...state,
        recordSaving: true,
      };
    case contractorsConstants.CONTRACTOR_EDIT_SUCCESS:

      const editedRecord = action.payload;

      /*
      if (editedRecord.company && editedRecord.company.name) {
        editedRecord.company = editedRecord.company.name;
      }
      */

      const updatedRecords = state.records.map(record => {
        return parseInt(editedRecord.id) === parseInt(record.id) ? editedRecord : record;
      });
      return {
        ...state,
        recordSaving: false,
        records: updatedRecords,
        selectedRecord: action.payload
      };
    case contractorsConstants.CONTRACTOR_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        recordDeleting: true,
      };
    case contractorsConstants.CONTRACTOR_DELETE_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        recordDeleting: false,
        records: state.records.filter(record => action.payload !== record.id),
      };

    case contractorsConstants.EMPLOYEES_REQUEST:
      return {
        ...state,
        employeesLoading: true,
        employees: []
      };
    case contractorsConstants.EMPLOYEES_SUCCESS:
      return {
        ...state,
        employeesLoading: false,
        employees: action.payload
      };
    case contractorsConstants.EMPLOYEES_FAILURE:
      return {
        ...state,
        error: action.error,
        employeesLoading: false,
        employees: []
      };

    case contractorsConstants.PAYSLIPS_REQUEST:
      return {
        ...state,
        payslipsLoading: true,
        payslips: []
      };
    case contractorsConstants.PAYSLIPS_SUCCESS:
      return {
        ...state,
        payslipsLoading: false,
        payslips: action.payload
      };
    case contractorsConstants.PAYSLIPS_FAILURE:
      return {
        ...state,
        error: action.error,
        payslipsLoading: false,
        payslips: []
      };
    case contractorsConstants.PAYSLIP_DELETE_REQUEST:
      return {
        ...state,
      };
    case contractorsConstants.PAYSLIP_DELETE_SUCCESS:
      console.log(action);

      return {
        ...state,
        payslips: state.payslips.filter(account=>account.id !== action.payload)
      };
    case contractorsConstants.PAYSLIP_DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    

    case contractorsConstants.INVOICES_REQUEST:
      return {
        ...state,
        invoicesLoading: true,
        invoices: []
      };
    case contractorsConstants.INVOICES_SUCCESS:
      return {
        ...state,
        invoicesLoading: false,
        invoices: action.payload
      };
    case contractorsConstants.INVOICES_FAILURE:
      return {
        ...state,
        error: action.error,
        invoicesLoading: false,
        invoices: []
      };


    case contractorsConstants.BANK_ACCOUNTS_REQUEST:
      return {
        ...state,
        bankAccountsLoading: true,
        bankAccounts: []
      };
    case contractorsConstants.BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        bankAccountsLoading: false,
        bankAccounts: action.payload
      };
    case contractorsConstants.BANK_ACCOUNTS_FAILURE:
      return {
        ...state,
        error: action.error,
        bankAccountsLoading: false,
        bankAccounts: []
      };


    case contractorsConstants.BANK_ACCOUNTS_ADD_REQUEST:
      return {
        ...state,
      };
    case contractorsConstants.BANK_ACCOUNTS_ADD_SUCCESS:
      console.log(action);
      return {
        ...state,
        bankAccounts: [...state.bankAccounts, action.payload]
      };
    case contractorsConstants.BANK_ACCOUNTS_ADD_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case contractorsConstants.BANK_ACCOUNTS_EDIT_REQUEST:
      return {
        ...state,
      };
    case contractorsConstants.BANK_ACCOUNTS_EDIT_SUCCESS:

      console.log(action.payload);

      return {
        ...state,
        bankAccounts: state.bankAccounts.map(record => action.payload.id === record.id ? action.payload : record)
      };
    case contractorsConstants.BANK_ACCOUNTS_EDIT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case contractorsConstants.BANK_ACCOUNTS_DELETE_REQUEST:
      return {
        ...state,
      };
    case contractorsConstants.BANK_ACCOUNTS_DELETE_SUCCESS:
      console.log(action);

      return {
        ...state,
        bankAccounts: state.bankAccounts.filter(account=>account.id !== action.payload)
      };
    case contractorsConstants.BANK_ACCOUNTS_DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };    
    
      case contractorsConstants.BANK_STATEMENTS_ADD_REQUEST:
        return {
          ...state,
        };
      case contractorsConstants.BANK_STATEMENTS_ADD_SUCCESS:
        console.log(action);
        return {
          ...state,
          bankStatements: [...state.bankStatements, action.payload]
        };
      case contractorsConstants.BANK_STATEMENTS_ADD_FAILURE:
        return {
          ...state,
          error: action.error,
        };

    case contractorsConstants.BANK_STATEMENTS_REQUEST:
      return {
        ...state,
        bankStatementsLoading: true,
        bankStatements: []
      };
    case contractorsConstants.BANK_STATEMENTS_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        bankStatementsLoading: false,
        bankStatements: action.payload
      };
    case contractorsConstants.BANK_STATEMENTS_FAILURE:
      return {
        ...state,
        bankStatementsLoading: false,
        error: action.error,
        bankStatements: []
      };
    case contractorsConstants.BANK_STATEMENTS_EDIT_REQUEST:
      return {
        ...state,
      };
    case contractorsConstants.BANK_STATEMENTS_EDIT_SUCCESS:

      console.log(action.payload);

      return {
        ...state,
        bankStatements: state.bankStatements.map(record => action.payload.id === record.id ? action.payload : record)
      };
    case contractorsConstants.BANK_STATEMENTS_EDIT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case contractorsConstants.BANK_STATEMENTS_DELETE_REQUEST:
      return {
        ...state,
      };    
    case contractorsConstants.BANK_STATEMENTS_DELETE_SUCCESS:
      console.log(action);

      return {
        ...state,
        bankStatements: state.bankStatements.filter(account=>account.id !== action.payload)
      };
    case contractorsConstants.BANK_STATEMENTS_DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case contractorsConstants.WORKING_HOURS_REQUEST:
      return {
        ...state,
        workingHoursLoading: true,
      };
    case contractorsConstants.WORKING_HOURS_SUCCESS:

      const workingHours = action.payload.length ? action.payload : [];

      console.log(workingHours);

      return {
        ...state,
        workingHoursLoading: false,
        workingHours,
      };
    case contractorsConstants.WORKING_HOURS_FAILURE:

      console.log(action.error);

      return {
        ...state,
        workingHoursLoading: false,
        error: action.error,
        workingHours: []
      };

    case contractorsConstants.EXPENSES_SUMMARY_REQUEST:
      return {
        ...state,
        expensesSummaryLoading: true,
        expensesSummary: []
      };
    case contractorsConstants.EXPENSES_SUMMARY_SUCCESS:
      return {
        ...state,
        expensesSummaryLoading: false,
        expensesSummary: action.payload
      };
    case contractorsConstants.EXPENSES_SUMMARY_FAILURE:
      return {
        ...state,
        expensesSummaryLoading: false,
        error: action.error,
        expensesSummary: []
      };

    case contractorsConstants.EXPENSES_REQUEST:
      return {
        ...state,
        expensesLoading: true,
        expenses: []
      };
    case contractorsConstants.EXPENSES_SUCCESS:
      return {
        ...state,
        expensesLoading: false,
        expenses: action.payload
      };
    case contractorsConstants.EXPENSES_FAILURE:
      return {
        ...state,
        expensesLoading: false,
        error: action.error,
        expenses: []
      };


    case contractorsConstants.CONTRACTORS_TOGGLE_EDITOR:
      return {
        ...state,
        isEditorActive: !state.isEditorActive,
        recordToEdit: action.payload
      };

    case contractorsConstants.CONTRACTORS_TOGGLE_ADDRESS_EDITOR:
      return {
        ...state,
        isAddressEditorActive: !state.isAddressEditorActive,
        recordToEdit: action.payload
      };

    case contractorsConstants.MARITAL_STATUS_AND_SPOUSE_DETAILS_EDITOR:
      return {
        ...state,
        isMaritalStatusAndSpouseDetailsEditorActive: !state.isMaritalStatusAndSpouseDetailsEditorActive,
        recordToEdit: action.payload
      };

    case contractorsConstants.CONTRACTORS_TOGGLE_PORTAL_ACCESS_EDITOR:
      return {
        ...state,
        isPortalAccessEditorActive: !state.isPortalAccessEditorActive,
        recordToEdit: action.payload
      };

    case contractorsConstants.TOGGLE_BANK_ACCOUNT_EDITOR:
      return {
        ...state,
        isBankAccountEditorActive: !state.isBankAccountEditorActive,
        recordToEdit: action.payload
      };

    case contractorsConstants.TOGGLE_BANK_STATEMENT_EDITOR:
      return {
        ...state,
        isBankStatementEditorActive: !state.isBankStatementEditorActive,
        recordToEdit: action.payload
      };

    case contractorsConstants.TOGGLE_NOTIFICATIONS_EDITOR:
      return {
        ...state,
        isNotificationsEditorActive: !state.isNotificationsEditorActive,
        recordToEdit: action.payload
      };

    case contractorsConstants.TOGGLE_COMPANY_EDITOR:
      return {
        ...state,
        isCompanyEditorActive: !state.isCompanyEditorActive,
        recordToEdit: action.payload
      };

    case contractorsConstants.CONTRACTORS_TOGGLE_LIST:
      return {
        ...state,
        isListActive: !state.isListActive
      };

    case contractorsConstants.SET_FILTER:
      if (action.value){
        localStorage.setItem('ammotio-contractors-filter', JSON.stringify([
          ...state.filters,
          {name: action.name, operator: action.operator, value: action.value}
        ]));
        return {
          ...state,
          filters: [
            ...state.filters,
            {name: action.name, operator: action.operator, value: action.value}
          ]
        };
      } else {
        localStorage.setItem('ammotio-contractors-filter', JSON.stringify(
          state.filters.filter(filter=>filter.name !== action.name)
        ));
        return {
          ...state,
          filters: state.filters.filter(filter=>filter.name !== action.name)
        };
      }
    case contractorsConstants.SEARCH:
      localStorage.setItem('ammotio-contractors-search', action.phrase);
      return {
        ...state,
        searchPhrase: action.phrase,
      };
    default:
      return state
  }
}
