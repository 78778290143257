import { expensesConstants } from './contants';
const initialState = {
  expensesSummaryLoading: false,
  expensesSummary: [],
  expensesLoading: false,
  expenses: [],
  loading: false,
  records: [],
}

export function expenses(state = initialState, action) {
  switch (action.type) {
    case expensesConstants.GET_EXPENSES_REQUEST:
      return {
        ...state,
        expensesSummaryLoading: true,
        expensesSummary: []
      };
    case expensesConstants.GET_EXPENSES_SUCCESS:
      return {
        ...state,
        expensesSummaryLoading: false,
        expensesSummary: action.payload
      };
    case expensesConstants.GET_EXPENSES_FAILURE:
      return {
        ...state,
        expensesSummaryLoading: false,
        error: action.error,
        expensesSummary: []
      };
    case expensesConstants.GET_EXPENSES_LIST_REQUEST:
      return {
        ...state,
        expensesLoading: true,
        expenses: []
      };
    case expensesConstants.GET_EXPENSES_LIST_SUCCESS:
      return {
        ...state,
        expensesLoading: false,
        expenses: action.payload
      };
    case expensesConstants.GET_EXPENSES_LIST_FAILURE:
      return {
        ...state,
        expensesLoading: false,
        error: action.error,
        expenses: []
      };
    default:
      return state
  }
}
