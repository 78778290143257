export const settingsCurrenciesConstants = {
  GET_SETTINGS_CURRENCY_REQUEST: 'SETTINGS_CURRENCY_GET_REQUEST',
  GET_SETTINGS_CURRENCY_SUCCESS: 'SETTINGS_CURRENCY_GET_SUCCESS',
  GET_SETTINGS_CURRENCY_FAILURE: 'SETTINGS_CURRENCY_GET_FAILURE',
  CURRENCY_ADD_REQUEST: 'SETTINGS_CURRENCY_ADD_REQUEST',
  CURRENCY_ADD_SUCCESS: 'SETTINGS_CURRENCY_ADD_SUCCESS',
  CURRENCY_ADD_FAILURE: 'SETTINGS_CURRENCY_ADD_FAILURE',
  CURRENCY_EDIT_REQUEST: 'SETTINGS_CURRENCY_EDIT_REQUEST',
  CURRENCY_EDIT_SUCCESS: 'SETTINGS_CURRENCY_EDIT_SUCCESS',
  CURRENCY_EDIT_FAILURE: 'SETTINGS_CURRENCY_EDIT_FAILURE',
  CURRENCY_DELETE_REQUEST: 'SETTINGS_CURRENCY_DELETE_REQUEST',
  CURRENCY_DELETE_SUCCESS: 'SETTINGS_CURRENCY_DELETE_SUCCESS',
  CURRENCY_DELETE_FAILURE: 'SETTINGS_CURRENCY_DELETE_FAILURE',
  TOGGLE_EDITOR: 'TOGGLE_SETTINGS_CURRENCY_EDITOR',
};
