import React, {Component} from 'react';
import axios from 'axios';
import {FormGroup, InputGroup, Button, Intent} from '@blueprintjs/core';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import Select from 'react-select';
import {toast} from "../../../../store/toaster/actions";
import {getCompany, toggleEditor, insertRecord, updateRecord} from "../../../../store/companies/actions";

import {DateInput} from "@blueprintjs/datetime";
import moment from "moment";
import {Box, Flex} from "@rebass/grid";

class CompanyEditor extends Component {

  constructor(props) {
    super(props);

    this.state = {
      countries: [],
      record: {
        name: '',
        cro_number: null,
        vat_number: null,
        financial_year_end: null,
        date_of_incorporation: null,
        country_id: null,
      },
      selectedCountry: null,
      errors: {},
      sent: false,
    };
  }

  componentDidMount() {
    this.loadRecord()
  }

  formatCountries(data) {
    const countries = [];

    data.map(record => {
      countries.push({label: record.name, value: record.id});
    })

    return countries;
  }

  loadRecord() {
    this.setState({
      loading: true
    });

    const companyId = this.props.record.id ? this.props.record.id : 0;

    axios.get(`companies/${companyId}`).then(result => {
      const countries = this.formatCountries(result.data.countries);
      const dbCountry = this.props.record.country_id && this.props.record.country_id;
      const selectedCountry = countries.find(country => country.value === dbCountry);
      const financial_year_end = result.data.record && result.data.record.financial_year_end && moment(result.data.record.financial_year_end).toDate();
      const date_of_incorporation = result.data.record && result.data.record.date_of_incorporation && moment(result.data.record.date_of_incorporation).toDate();

      const record = result.data.record ? result.data.record : {
        name: '',
          cro_number: null,
          vat_number: null,
          financial_year_end: null,
          date_of_incorporation: null,
          country_id: null,
      };

      this.setState({
        selectedCountry,
        loading: false,
        record,
        countries,
        financial_year_end,
        date_of_incorporation
      });
    }).catch(error => {
      console.log(error);
      this.setState({
        loading: false,
      }, () => {
       // this.props.toast({message: "Server Error", intent: "Danger"});
      });
    });

    /*
    if(this.props.record.id) {

      const dbCountry = this.props.record.country_id;
      const selectedCountry = this.props.countries.filter(country => country.value === dbCountry)[0];

      const financial_year_end = this.props.record.financial_year_end;
      console.log(financial_year_end);


      //console.log(new Date(new Date(this.props.record.financial_year_end.setHours(0,0,0,0)))


      this.setState({
        selectedCountry,
        record: {
          name: this.props.record.name,
          cro_number: this.props.record.cro_number,
          vat_number: this.props.record.vat_number,
          financial_year_end: this.props.record.financial_year_end,
          date_of_incorporation: this.props.record.date_of_incorporation,
          address_1: this.props.record.address_1,
          address_2: this.props.record.address_2,
          address_3: this.props.record.address_3,
          address_4: this.props.record.address_4,
          country_id: this.props.record.country_id,
        },
        financial_year_end: this.props.record.financial_year_end && moment(this.props.record.financial_year_end).toDate(),
        date_of_incorporation: this.props.record.date_of_incorporation && moment(this.props.record.date_of_incorporation).toDate()
      })
    }
    */
  }

  handleSave(e) {
    e.preventDefault();
    this.setState({sent: true});
    if(this.handleValidation()) {
      if (this.props.record.id){
        this.props.updateRecord(this.props.record.id, this.state.record, status => {
          if (status === 'saved'){
            this.props.toast({message: 'Company was successfully updated', intent: 'SUCCESS'});
            this.props.toggleEditor();
          } else if (status === 'error') {
            this.props.toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      } else {
        this.props.insertRecord(this.state.record, status => {
          if (status === 'saved'){
            this.props.toast({message: 'Company was successfully added', intent: 'SUCCESS'});
            this.props.toggleEditor();
          } else if (status === 'error') {
            this.props.toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      }
    } else {
      console.log('form error');
    }

  }

  handleValidation(){
    let record = this.state.record;
    let errors = {};
    let formIsValid = true;

    if(!record['name']){
      formIsValid = false;
      errors['name'] = "This field is required";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleChange(e){
    let record = this.state.record;
    record[e.currentTarget.name] = e.currentTarget.value;
    this.setState({record});
    if (this.state.sent){
      this.handleValidation();
    }
  }

  handleCountryChange(value)
  {
    this.setState({
      selectedCountry: value,
      record: {
        ...this.state.record,
        country_id: parseInt(value.value),
      }
    }, () => {
      console.log(this.state.record);
    })
  }

  render() {
    const {name} = this.state.record;

    return (
      <form onSubmit={(e) => this.handleSave(e)}>
        <FormGroup
          intent={this.state.errors.name ? Intent.DANGER : Intent.SUCCESS}
          label="Name"
          labelFor="name"
          labelInfo="*"
          helperText={this.state.errors.name && this.state.errors.name}
        >
          <InputGroup
            intent={this.state.errors.name ? Intent.DANGER : Intent.NONE}
            onChange={(e) => this.handleChange(e)}
            name="name"
            id="name"
            large
            placeholder="Company name"
            defaultValue={this.state.record.name}
          />
        </FormGroup>
        <FormGroup
          intent={this.state.errors.cro_number ? Intent.DANGER : Intent.SUCCESS}
          label="CRO Number"
          labelFor="cro_number"
          helperText={this.state.errors.cro_number && this.state.errors.cro_number}
        >
          <InputGroup
            intent={this.state.errors.cro_number ? Intent.DANGER : Intent.NONE}
            onChange={(e) => this.handleChange(e)}
            name="cro_number"
            id="cro_number"
            large
            placeholder="CRO Number"
            defaultValue={this.state.record.cro_number}
          />
        </FormGroup>
          <FormGroup
            intent={this.state.errors.vat_number ? Intent.DANGER : Intent.SUCCESS}
            label="VAT Number"
            labelFor="vat_number"
            helperText={this.state.errors.vat_number && this.state.errors.vat_number}
          >
            <InputGroup
              intent={this.state.errors.vat_number ? Intent.DANGER : Intent.NONE}
              onChange={(e) => this.handleChange(e)}
              name="vat_number"
              id="vat_number"
              large
              placeholder="VAT Number"
              defaultValue={this.state.record.vat_number}
            />
        </FormGroup>
        <Flex flexWrap={'wrap'}>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={this.state.errors.financial_year_end ? Intent.DANGER : Intent.SUCCESS}
              label="Financial Year End"
              labelFor="financial_year_end"
              helperText={this.state.errors.financial_year_end && this.state.errors.financial_year_end}
            >
              <DateInput
                inputProps={{style: {width: '100%'}, className: 'bp3-large', leftIcon: "calendar"}}
                id="financial_year_end"
                className="bp3-large"
                formatDate={date => moment(date).format('DD/MM')}
                parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
                onChange={(dt) => {
                  this.setState({
                  financial_year_end: dt ? new Date(dt) : null,
                  record: {
                    ...this.state.record,
                    financial_year_end: dt ? `${moment(dt).format('YYYY-MM-DD')} 00:00:00` : null,
                  }
                }, () => {console.log(this.state.record.financial_year_end)})}}
                placeholder={"DD/MM"}
                showActionsBar
                intent={this.state.financial_year_end ? Intent.DANGER : Intent.NONE}
                value={this.state.financial_year_end}
              />
            </FormGroup>
          </Box>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={this.state.errors.date_of_incorporation ? Intent.DANGER : Intent.SUCCESS}
              label="Date Of Incorporation"
              labelFor="date_of_incorporation"
              helperText={this.state.errors.date_of_incorporation && this.state.errors.date_of_incorporation}
            >
              <DateInput
                inputProps={{style: {width: '100%'}, className: 'bp3-large', leftIcon: "calendar"}}
                id="date_of_incorporation"
                className="bp3-large"
                formatDate={date => moment(date).format('DD/MM/YYYY')}
                parseDate={str => moment(str, 'DD/MM/YYYY').toDate()}
                onChange={(dt) => this.setState({
                  date_of_incorporation: dt ? new Date(dt) : null,
                  record: {
                    ...this.state.record,
                    date_of_incorporation: dt ? `${moment(dt).format('YYYY-MM-DD')} 00:00:00` : null,
                  }
                }, () => {console.log(this.state.record.date_of_incorporation)})}
                placeholder={"DD/MM/YYY"}
                showActionsBar
                intent={this.state.date_of_incorporation ? Intent.DANGER : Intent.NONE}
                value={this.state.date_of_incorporation}
              />
            </FormGroup>
          </Box>
        </Flex>
        <Flex flexWrap={'wrap'}>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={this.state.errors.address_1 ? Intent.DANGER : Intent.SUCCESS}
              label="Address 1"
              labelFor="address_1"
              helperText={this.state.errors.address_1 && this.state.errors.address_1}
            >
              <InputGroup
                intent={this.state.errors.address_1 ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="address_1"
                id="address_1"
                large
                placeholder="Address 1"
                defaultValue={this.state.record.address_1}
              />
            </FormGroup>
          </Box>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={this.state.errors.address_2 ? Intent.DANGER : Intent.SUCCESS}
              label="Address 2"
              labelFor="address_2"
              helperText={this.state.errors.address_2 && this.state.errors.address_2}
            >
              <InputGroup
                intent={this.state.errors.address_2 ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="address_2"
                id="address_2"
                large
                placeholder="Address 2"
                defaultValue={this.state.record.address_2}
              />
            </FormGroup>
          </Box>
        </Flex>
        <Flex flexWrap={'wrap'}>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={this.state.errors.address_3 ? Intent.DANGER : Intent.SUCCESS}
              label="Address 3"
              labelFor="address_3"
              helperText={this.state.errors.address_3 && this.state.errors.address_3}
            >
              <InputGroup
                intent={this.state.errors.cro_number ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="address_3"
                id="address_3"
                large
                placeholder="Address 3"
                defaultValue={this.state.record.address_3}
              />
            </FormGroup>
          </Box>
          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={this.state.errors.address_4 ? Intent.DANGER : Intent.SUCCESS}
              label="Address 4"
              labelFor="address_4"
              helperText={this.state.errors.address_4 && this.state.errors.address_4}
            >
              <InputGroup
                intent={this.state.errors.address_4 ? Intent.DANGER : Intent.NONE}
                onChange={(e) => this.handleChange(e)}
                name="address_4"
                id="address_4"
                large
                placeholder="Address 4"
                defaultValue={this.state.record.address_4}
              />
            </FormGroup>
          </Box>

          <Box width={['100%','50%','50%']} pr={['0', '5px']}>
            <FormGroup
              intent={this.state.errors.address_4 ? Intent.DANGER : Intent.SUCCESS}
              label="Address 4"
              labelFor="address_4"
              helperText={this.state.errors.address_4 && this.state.errors.address_4}
            >
              <Select
                options={this.state.countries}
                onChange={option => this.handleCountryChange(option)}
                value={this.state.selectedCountry}
              />
            </FormGroup>
          </Box>
        </Flex>
        <Button large type="submit" icon="tick" intent="success" text="Save" />
      </form>
    );
  }
}

function mapStateToProps(state){
  return {
    record: state.companies.recordToEdit,
    countries: state.general.countries,
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    getCompany,
    toast,
    toggleEditor,
    updateRecord,
    insertRecord,
  }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(CompanyEditor);
