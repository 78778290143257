import React, {Component} from 'react';
import moment from "moment";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getInvoices} from "./store/actions";
import {Button, InputGroup, NonIdealState, Spinner} from "@blueprintjs/core";
import {CSVLink} from "react-csv";
import Icon from "../../../components/icon";
import {AutoSizer, Column, Table} from "react-virtualized";
import styled from "styled-components";

const StyledList = styled.div`
  height: 100%;
  width: 100%;
  padding: 8px 16px;
`;

const FUSE_OPTIONS = {
  threshold: 0.5,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: [
    {name: 'name', weight: 0.4},
    {name: 'iban', weight: 0.2},
    {name: 'account_number', weight: 0.2},
    {name: 'sort_code', weight: 0.2},
  ]
};

const EXCEL_HEADERS = [
  { label: 'Date', key: 'date' },
  { label: 'Description', key: 'description' },
  { label: 'Net', key: 'net' },
  { label: 'Vat Rate', key: 'vat_rate' },
  { label: 'Vat', key: 'vat_amount' },
  { label: 'Gross', key: 'gross' },
];

class Invoices extends Component {

  state = {
    records: [],
  }

  componentDidMount() {
    this.props.getInvoices();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let formatedRecords = [];
    nextProps.records.map(record => {
      record = {
        ...record,
        net: record.net.toFixed(2),
        vat_rate: `${record.vat_rate}%`,
        date: moment(record.date).isValid() ? moment(record.date).format('DD/MM/YYYY') : ''
      };
      formatedRecords.push(record);
    });

    this.setState({
        records: formatedRecords
      });
  }

  viewFile (invoiceId) {
    const { _id } = this.props.match.params;
    const url = `${process.env.REACT_APP_API_URL}/sales-invoices/get-file/${invoiceId}?auth=${window.localStorage.getItem('ammotio-token')}&session=${window.localStorage.getItem('ammotio-session')}`;
    window.open(url, "_blank");
  }

  render() {
    const { loading } = this.props;
    const { records } = this.state;

    return (
      <div className="right">
          {loading ? (
            <Spinner className="mt50" />
          ) : (
            <React.Fragment>
              <div className="tools">
                <div></div>
                <div className="flex">
                  {records.length > 0 &&
                  <CSVLink
                    headers={EXCEL_HEADERS}
                    data={records}
                    filename={"sales_invoices.csv"}
                    className="mr5"
                    target="_blank"
                  >
                    <Icon icon="csv" size={38}/>
                  </CSVLink>
                  }
                  <InputGroup
                    leftIcon="search"
                    onChange={(e) => {
                      this.setState({
                        search: e.currentTarget.value
                      })
                    }}
                    name="vat_number"
                    id="vat_number"
                    large
                    placeholder="Search"
                  />
                </div>
              </div>
              {records.length > 0 ? (
                <StyledList>
                  <AutoSizer>
                    {({width, height}) => (
                      <Table
                        ref="Table"
                        headerHeight={30}
                        noRowsRenderer={this._noRowsRenderer}
                        overscanRowCount={10}
                        rowClassName={this._rowClassName}
                        rowHeight={30}
                        rowCount={records.length}
                        rowGetter={({ index }) => records[index]}
                        height={height}
                        onRowClick={(event) => console.log(event.rowData)}
                        width={width}>
                        <Column
                          label="Date"
                          dataKey="date"
                          width={width * 0.2}
                        />
                        <Column
                          width={width * 0.5}
                          disableSort
                          label="Description"
                          dataKey="description"
                          flexGrow={1}
                        />
                        <Column
                          width={width * 0.2}
                          disableSort
                          label="Net"
                          dataKey="net"
                          flexGrow={1}
                        />
                        <Column
                          width={width * 0.1}
                          disableSort
                          style={{textAlign: 'center'}}
                          label=""
                          dataKey="id"
                          cellRenderer={({cellData}) => (<Button onClick={() => {this.viewFile(cellData)}} small>View</Button>)}
                        />
                      </Table>
                    )}
                  </AutoSizer>
                </StyledList>
              ) : (
                <NonIdealState
                  icon="search"
                  title="No invoices found"
                />
              )}
            </React.Fragment>
          )}
      </div>
    );
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    getInvoices
  }, dispatch);
}

function mapStateToProps(state){
  return {
    loading: state.invoices.loading,
    records: state.invoices.records,
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(Invoices);
