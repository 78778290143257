import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {FormGroup, InputGroup, Button, Intent, Dialog, Classes, Spinner} from '@blueprintjs/core';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {toast} from "../../../../store/toaster/actions";
import {toggleBankStatementEditor, insertBankStatement, updateBankStatement, deleteBankStatement} from "../../../../store/contractors/actions";
import moment from 'moment';
import { DateInput } from "@blueprintjs/datetime";
import {Box, Flex} from "@rebass/grid";
import Icon from "../../../../components/icon";
import Select from 'react-select';
import axios from 'axios';

class BankStatementEditor extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      record: {
        date: null,
        debit: null,
        credit: null,
        balance: null,
        details: null,
        user_id: null,
        account_id: null,
        company_id: null,
      },
      accountDate: [],
      deleteDialog: false,
      errors: {},
      sent: false,
    };
  }

  componentDidMount() {
    this.loadRecord();
  }
  
  loadRecord() {
    console.log(this.props.record);
    if(this.props.record.id) { 
      let [d, M, y] = this.props.record.date.match(/\d+/g);     
      this.setState({
        record: {
          date: new Date(y, M-1, d),
          debit: this.props.record.debit,
          credit: this.props.record.credit,
          balance: this.props.record.balance,
          details: this.props.record.details,
          user_id: this.props.record.user_id,
          account_id: this.props.record.account_id,
        },
      })
    } else {
      this.setState({
        record: {
          user_id: this.props.record.user_id,
        },
      })
    }
    axios.get(`/contractors/${this.props.record.user_id}/bank-accounts`)
      .then(result => { 
        let data = [];
        result.data.map(d => {
          data.push({value: d.id, label: d.name});
        })
        this.setState({
          loading: false,
          accountDate: data,
      }); 
    });
  }

  handleDelete()
  {
    this.setState({deleteDialog: false});
    this.props.deleteBankStatement(this.props.match.params._id, this.props.record.id, () => {
      this.props.toast({message: 'Bank Statement was successfully deleted', intent: 'SUCCESS'});
      this.props.toggleBankStatementEditor();
    });
  }

  handleSave(e) {
    e.preventDefault();
    this.setState({sent: true});
    if(this.handleValidation()) {
      const { insertBankStatement, updateBankStatement, toggleBankStatementEditor, toast, record } = this.props;
      if (record.id){
        let data = {
          date: moment(this.state.record.date).format('YYYY-MM-DD'),
          debit: this.state.record.debit,
          credit: this.state.record.credit,
          balance: this.state.record.balance,
          details: this.state.record.details,
          user_id: this.state.record.user_id,
          account_id: this.state.record.account_id,
          company_id: this.state.record.company_id,
        }
        updateBankStatement(record.user_id, record.id, data, status => {
          if (status === 'saved'){
            toast({message: 'Bank Statement was successfully updated', intent: 'SUCCESS'});
            toggleBankStatementEditor();
          } else if (status === 'error') {
            toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      } else {
        let data = {
          date: moment(this.state.record.date).format('YYYY-MM-DD'),
          debit: this.state.record.debit,
          credit: this.state.record.credit,
          balance: this.state.record.balance,
          details: this.state.record.details,
          user_id: this.state.record.user_id,
          account_id: this.state.record.account_id,
          company_id: this.state.record.company_id,
        }
        insertBankStatement(this.props.match.params._id, data, status => {
          if (status === 'saved'){
            toast({message: 'Bank Statement was successfully added', intent: 'SUCCESS'});
            toggleBankStatementEditor();
          } else if (status === 'error') {
            toast({message: 'Server Error', intent: 'DANGER'});
          }
        });
      }
    } else {
      console.log('form error');
    }

  }

  handleValidation(){
    let record = this.state.record;
    let errors = {};
    let formIsValid = true;

    if(!record['date']){
      formIsValid = false;
      errors['date'] = "This field is required";
    }

    if(!record['details']){
      formIsValid = false;
      errors['details'] = "This field is required";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleChange(e){
    let record = this.state.record;
    record[e.currentTarget.name] = e.currentTarget.value;
    this.setState({record});
    if (this.state.sent){
      this.handleValidation();
    }
  }

  render() {
    const {record} = this.props;
    if(this.state.loading){
      return <Spinner className="mt50" />;
    }
    return (
      <React.Fragment>
        <form onSubmit={(e) => this.handleSave(e)}>
        <Flex flexWrap={'wrap'}>
            <Box width={['100%','50%','50%']} pr={['0', '5px']}>
              <FormGroup
                intent={this.state.errors.sort_code ? Intent.DANGER : Intent.SUCCESS}
                label="Date"
                labelFor="date"
                helperText={this.state.errors.date && this.state.errors.date}
              >
                <DateInput
                  inputProps={{style: {width: '100%'}, className: 'bp3-large'}}
                  formatDate={date => moment(date).format('DD/MM/YYYY')}
                  parseDate={str => moment(str, 'DD/MM/YYYY').toLocaleString()}
                  placeholder={"DD/MM/YYYY"}
                  showActionsBar
                  id="date"
                  intent={this.state.errors.date ? Intent.DANGER : Intent.NONE}
                  value={this.state.record.date && this.state.record.date}
                  // disabled={isDisabled}
                  onChange={
                    date => {
                      this.handleChange({
                        currentTarget: {
                          name: 'date',
                          value: moment(date).isValid() ? date : null,
                        },
                      });
                    }
                  }
                />
              </FormGroup>
            </Box>
            <Box width={['100%','50%','50%']} pr={['0', '5px']}>
              <FormGroup
                intent={this.state.errors.debit ? Intent.DANGER : Intent.SUCCESS}
                label="Debit"
                labelFor="debit"
                helperText={this.state.errors.debit && this.state.errors.debit}
              >
                <InputGroup
                  intent={this.state.errors.debit ? Intent.DANGER : Intent.NONE}
                  onChange={(e) => this.handleChange(e)}
                  name="debit"
                  id="debit"
                  large
                  placeholder="Debit Balance"
                  defaultValue={this.state.record.debit}
                />
              </FormGroup>
            </Box>
          </Flex>
          <Flex flexWrap={'wrap'}>
            <Box width={['100%','50%','50%']} pr={['0', '5px']}>
              <FormGroup
                intent={this.state.errors.credit ? Intent.DANGER : Intent.SUCCESS}
                label="Credit"
                labelFor="credit"
                helperText={this.state.errors.credit && this.state.errors.credit}
              >
                <InputGroup
                  intent={this.state.errors.credit ? Intent.DANGER : Intent.NONE}
                  onChange={(e) => this.handleChange(e)}
                  name="credit"
                  id="credit"
                  large
                  placeholder="Credit Balance"
                  defaultValue={this.state.record.credit}
                />
              </FormGroup>
            </Box>
            <Box width={['100%','50%','50%']} pr={['0', '5px']}>
              <FormGroup
                intent={this.state.errors.balance ? Intent.DANGER : Intent.SUCCESS}
                label="Balance"
                labelFor="balance"
                helperText={this.state.errors.balance && this.state.errors.balance}
              >
                <InputGroup
                  intent={this.state.errors.balance ? Intent.DANGER : Intent.NONE}
                  onChange={(e) => this.handleChange(e)}
                  name="balance"
                  id="balance"
                  large
                  placeholder="Balance Account"
                  defaultValue={this.state.record.balance}
                />
              </FormGroup>
            </Box>
          </Flex>          
          <FormGroup
            intent={this.state.errors.account_id ? Intent.DANGER : Intent.SUCCESS}
            label="Bank Account"
            labelFor="account_id"
            helperText={this.state.errors.account_id && this.state.errors.account_id}
          >
            <Select
              options={this.state.accountDate}
              id="account_id"
              name="account_id"
              className="select"
              multi={false}
              // isDisabled={!canEdit}
              value={ 
                this.state.record.account_id
                  ? this.state.accountDate.find(account => account.value === this.state.record.account_id)
                  : this.state.accountDate[0]
              }
              onChange={e => this.handleChange({
                currentTarget: {
                  name: 'account_id',
                  value: e.value,
                },
              })}
            />
          </FormGroup> 
          <FormGroup
            intent={this.state.errors.details ? Intent.DANGER : Intent.SUCCESS}
            label="Details"
            labelFor="details"
            labelInfo="*"
            helperText={this.state.errors.details && this.state.errors.details}
          >
            <InputGroup
              intent={this.state.errors.details ? Intent.DANGER : Intent.NONE}
              onChange={(e) => this.handleChange(e)}
              name="details"
              id="details"
              large
              placeholder="Details"
              defaultValue={this.state.record.details}
            />
          </FormGroup>         
          <div className="button-bar">
            <Button large type="submit" icon="floppy-disk" intent="success" text="Save" />
            {record && record.id && <Button style={{marginLeft: 10}} large onClick={() => this.setState({deleteDialog: true})} icon={<Icon color="#fff" icon="trash" />} intent="danger" text="Delete" />}
          </div>
        </form>
        <Dialog
          icon="info-sign"
          onClose={() => this.setState({deleteDialog: false})}
          title="Bank Statement Delete"
          isOpen={this.state.deleteDialog}>
            <div className={Classes.DIALOG_BODY}>
              <div>Are you sure?</div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => this.setState({deleteDialog: false})}>Cancel</Button>
                <Button intent="DANGER" onClick={() => this.handleDelete()}>Delete</Button>
              </div>
            </div>
      </Dialog>
    </React.Fragment>
    );
  }
}

function mapStateToProps(state){
  return {
    record: state.contractors.recordToEdit,
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    toast,
    insertBankStatement,
    toggleBankStatementEditor,
    updateBankStatement,
    deleteBankStatement,
  }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withRouter(BankStatementEditor));
