import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button, NonIdealState, Spinner, Intent} from "@blueprintjs/core";
import {bindActionCreators} from "redux";
import {getP60} from "./store/actions";
import styled from "styled-components";

const StyledList = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  
  button {
    margin: 10px 16px;
  }
  
`;


class P60 extends Component {

  state = {
    records: [],
  }

  componentDidMount() {
    this.props.getP60();
  }

  componentWillReceiveProps(nextProps, nextContext) {

  }

  viewFile (year) {
    const { _id } = this.props.match.params;
    const url = `${process.env.REACT_APP_API_URL}/p60/get-file/${year}?auth=${window.localStorage.getItem('ammotio-token')}&session=${window.localStorage.getItem('ammotio-session')}`;
    window.open(url, "_blank");
  }

  render() {
    const { loading, records } = this.props;

    return (
      <div className="right">
        {loading ? (
          <Spinner className="mt50" />
        ) : (
          <React.Fragment>
            {records.length > 0 ? (
              <StyledList>
                {records.map(record => (
                  <Button large icon="cloud-download" intent={Intent.PRIMARY} onClick={() => this.viewFile(record)}>{record}</Button>
                ))}
              </StyledList>
            ) : (
              <NonIdealState
                icon="search"
                title="No records found"
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    getP60
  }, dispatch);
}

function mapStateToProps(state){
  return {
    loading: state.p60.loading,
    records: state.p60.records,
  }
}

export default connect(mapStateToProps, matchDispatchToProps)(P60);
