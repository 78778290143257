import { payslipsConstants } from '../constants';

const initialState = {
  loading: false,
  records: []
}

export function payslips(state = initialState, action) {
  switch (action.type) {
    case payslipsConstants.GET_REQUEST:
      return {
        loading: true,
        records: [],
      };
    case payslipsConstants.GET_SUCCESS:
      console.log(action.payload);
      return {
        loading: false,
        records: action.payload,
      };
    case payslipsConstants.GET_FAILURE:
      return {
        loading: false,
        error: action.error,
        records: [],
      };
    default:
      return state
  }
}
