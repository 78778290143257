import axios from 'axios';
import {settingsAgenciesConstants} from './constants';

export function getAgencies(){
  return function(dispatch){
    dispatch({type:settingsAgenciesConstants.GET_SETTINGS_AGENCIES_REQUEST});
    axios.get('/agencies')
      .then((result)=>{
        dispatch({type: settingsAgenciesConstants.GET_SETTINGS_AGENCIES_SUCCESS, payload: result.data});
      })
      .catch((err)=>{
        dispatch({type:settingsAgenciesConstants.GET_SETTINGS_AGENCIES_FAILURE, error: err});
      })
  }
}


export function toggleAgencyEditor( record ){
  return function(dispatch){
    dispatch({ type:settingsAgenciesConstants.TOGGLE_EDITOR, payload: record });
  }
}

export function insertAgency( companyId, record, cb ) {
  return function(dispatch){
    dispatch({type:settingsAgenciesConstants.AGENCIES_ADD_REQUEST});
    axios.post(`/agencies`, record).then(result => {
      cb('saved');
      dispatch({type:settingsAgenciesConstants.AGENCIES_ADD_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:settingsAgenciesConstants.AGENCIES_ADD_FAILURE, error});
    });
  }
}

export function updateAgency( companyId, id, record, cb ) {
  return function(dispatch){
    dispatch({type:settingsAgenciesConstants.AGENCIES_EDIT_REQUEST});
    axios.post(`/agencies/${id}`, record).then(result => {
      cb('saved');
      dispatch({type:settingsAgenciesConstants.AGENCIES_EDIT_SUCCESS, payload: result.data});
    }).catch(error => {
      cb('error');
      dispatch({type:settingsAgenciesConstants.AGENCIES_EDIT_FAILURE, error});
    });
  }
}

export function deleteAgency( companyId, id, cb ) {
  return function(dispatch){
    dispatch({type:settingsAgenciesConstants.AGENCIES_DELETE_REQUEST});
    axios.delete(`/agencies/${id}`).then(result => {
      cb('saved');
      dispatch({type:settingsAgenciesConstants.AGENCIES_DELETE_SUCCESS, payload: parseInt(id)});
    }).catch(error => {
      cb && cb('error');
      dispatch({type:settingsAgenciesConstants.AGENCIES_DELETE_FAILURE, error});
    });
  }
}
