import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import Scrollbar from 'react-scrollbars-custom';
import { DateInput, TimePrecision } from "@blueprintjs/datetime";
import moment from "moment";
import {FormGroup, Intent, Button, Spinner, Dialog, Classes} from '@blueprintjs/core';
import { Flex, Box } from '@rebass/grid';
import axios from 'axios';
import styled from "styled-components";
import {toast} from "../../../../store/toaster/actions";
import {connect} from "react-redux";
import SubsistenceForm from "../../../../components/expenses/SubsistenceForm";
import MileageForm from "../../../../components/expenses/MileageForm";

const StyledForm = styled.form`
  display: flex;
  flex-flow: column;
  min-height: 100%;
  
  .content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: ${props => props.hideHeader ? '0' : '72px'};
    
    .form-wrapper {
      padding: 10px;
    }
  }
  
  .card {
    margin-bottom: 10px;
    border-radius: 4px;
    
    .card-title {
      border-radius: 4px 4px 0 0;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #e4e5e9;
    }
    
    .card-body {
      padding: 10px 10px 0;
      border: 1px solid #e4e5e9;
    }
  }
  
  .status {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #edeef0;
    margin-bottom: 10px;
    padding: 5px 0;
    
    span {
      &.value {
        font-weight: bold;
      }
    }
  }
`;

const statuses = {
  status_1: 'Awaiting Approval',
  status_2: 'Approved',
  status_3: 'Rejected',
  status_4: 'Deleted',
  status_5: 'Paid',
};

class TravelExpenseForm extends Component {
  constructor(props) {
    super(props);

    this.subsistenceComponent = React.createRef();
    this.mileageComponent = React.createRef();

    const { expense } = this.props;
    this.state = {
      loading: true,
      statusName: props.record && props.record.status_id ? statuses[`status_${props.record.status_id}`] : '',
      isSent: false,
      startDate: expense && expense.start_date ? expense.start_date : null,
      endDate: expense && expense.endDate ? expense.endDate : null,
      durationDays: 0,
      durationHours: 0,
      subsistence: {},
      mileage: {},
      locations: [],
      engineSizes: [],
      statusId: null,
      recordId: null,
      fields: {
        start_time: '',
        end_time: '',
      },
      errors: {},
    };
  }

  componentDidMount() {
    const { expense } = this.props;
    axios.get(`/expenses/travel/${expense ? expense.id : 0}`)
      .then(result => {
        const { record, locations, engineSizes } = result.data;

        const startDate = record && record.start_time ? moment(record.start_time).toDate() : null;
        const endDate = record && record.end_time ? moment(record.end_time).toDate() : null;

        this.setState({
          loading: false,
          statusName: record && record.status_id ? statuses[`status_${record.status_id}`] : '',
          locations,
          engineSizes,
          startDate,
          endDate,
          statusId: record && record.status_id,
          recordId: record && record.id,
          fields: {
            ...record,
            start_time: null,
            end_time: null,
          },
        }, () => {
          if (record) {
            this.getDuration();
            this.recalculate()
          }
        });

      })
      .catch((error)=>{
        console.log(error);
      });
  }

  isClaim() {
    if (this.state.isSent) {
      if (!this.state.subsistenceClaim && !this.state.carUsed) {
        this.setState({
          generalError: 'Select At least one option bellow'
        });
      } else {
        this.setState({
          generalError: ''
        });
      }
    }
  }

  recalculate() {

  }

  getDuration() {
    // calculates duration between two dates
    // returns number of days and hours

    // get total seconds between the times
    const startDate = this.state.startDate ? this.state.startDate.getTime() : null;
    const endDate = this.state.endDate ? this.state.endDate.getTime() : null;

    if (startDate && endDate) {
      // get total seconds between the times
      let delta = Math.abs(endDate - startDate) / 1000;

      // calculate (and subtract) whole days
      const days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      const hours = Math.floor(delta / 3600) % 24;

      this.setState({
        durationDays: days,
        durationHours: hours,
      }, () => this.recalculate());
    }
  }

  onStartChanged(e) {
    const startDate = e;
    if ( !startDate ){
      this.setState({
        startDate: null,
        fields: { ...this.state.fields, start_time: null},
        errors: { ...this.state.errors, start_time: 'This Field is required' }
      }, () => {
        this.getDuration();
      });
    } else {
      this.setState({
        startDate,
        fields: { ...this.state.fields, start_time: moment(startDate).format('YYYY-MM-DD HH:mm:ss')},
        errors: { ...this.state.errors, start_time: undefined }
      }, () => this.getDuration());
    }
  }

  onEndChanged(e) {
    const endDate = e;
    if ( !endDate ){
      this.setState({
        endDate: null,
        fields: { ...this.state.fields, end_time: null},
        errors: { ...this.state.errors, end_time: 'This Field is required' }
      }, () => this.getDuration());
    } else {
      this.setState({
        endDate,
        fields: { ...this.state.fields, end_time: moment(endDate).format('YYYY-MM-DD HH:mm:ss')},
        errors: { ...this.state.errors, end_time: undefined }
      }, () => this.getDuration());
    }
  }

  onFocusChanged(fieldName) {
    if (this.props.onFocusChanged) {
      this.props.onFocusChanged(fieldName);
    }
  }

  submitForm(event) {
    event.preventDefault();
    this.setState({
      isSent: true,
    }, () => {this.handleSubmit();});
  }

  validate() {
    const {startDate, endDate, subsistence, mileage}  = this.state;

    let hasErrors = false;
    const errors = {
      start_time: null,
      end_time: null,
      general: null,
    };

    //Start Date
    if (moment(startDate).isValid()){
      errors.start_time = null;
    } else {
      errors.start_time = 'This Field is required.';
      hasErrors = true;
    }

    //End Date
    if (moment(endDate).isValid()){
      errors.end_time = null;
    } else {
      errors.end_time = 'This Field is required.';
      hasErrors = true;
    }

    //General
    if ( !subsistence.isActive && !mileage.isActive ) {
      errors.general = 'Select At least one option bellow.';
      hasErrors = true;
    } else {
      errors.general = null;
    }

    if (subsistence.hasErrors){
      //this.subsistenceComponent.showErrors();
      hasErrors = true;
    }

    this.setState({
      ...this.state,
      errors,
    });

    return !hasErrors;
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    //merge data from
    console.log();

    const data = {...this.state.subsistence.fields, ...this.state.fields }
    console.log(data);



    /*

    if (this.validate()){
      const existingRecord = this.props.expense && this.expense._id;
      if (existingRecord) {
        axios.post(`/expenses/travel/${this.state.recordId}`, this.state.fields).then(result => {
          console.log(result);
        }).catch(error => {
          console.log(error.result);
        });
      } else {
        axios.post(`/expenses/travel`, this.state.fields).then(result => {
          const {month, year} = result.data;
          this.props.history.push(`/expenses/${parseInt(year)}/${parseInt(month)}`);
        }).catch(error => {
          console.log(error.result);
        });
      }
    }

     */
  }

  handleDelete () {
    return axios.delete(`/expenses/travel/${this.state.recordId}`).then(response => {
      this.setState({
        deleteDialog: false,
      }, () => {
        this.props.onTravelEdited();
        this.props.toast({message: 'Travel Expense deleted', intent: 'success'});
      })
    }).catch(error => {

    });
  }

  setSubsistence (subsistence) {
    console.log(subsistence);

    this.setState({
      subsistence
    }, () => this.validate())
  }

  setMileage (mileage) {
    this.setState({
      mileage
    }, () => this.validate())
  }

  render() {
    const {
      loading,
      statusName,
      locations,
      engineSizes,
      durationDays,
      durationHours,
      isSent,
      errors
    } = this.state;

    const { expense } = this.props;

    const canEdit = expense && expense.id ? false : true;

    return (
      <StyledForm hideHeader={this.props.hideHeader} noValidate onSubmit={ event => this.submitForm(event) }>
        {loading ? <Spinner /> : <React.Fragment>
          {!this.props.hideHeader ?
            <div className="tools">
              <Button large type="submit" icon="add" intent="success" text="Add New" />
            </div> : ''}
            <div className="content">
              <Scrollbar noDefaultStyles>
              <div className="content-wrapper">
                {statusName &&
                <Flex className="row">
                  <Box width={1/1}>
                    <div className="status">
                      <span>Status</span>
                      <span className="value">{statusName}</span>
                    </div>
                  </Box>
                </Flex>
                }
                <Flex flexWrap={'wrap'}>
                  <Box width={['100%','50%','50%']} pr={['0', '5px']}>
                    <FormGroup
                      style={{width: '100%'}}
                      intent={errors.start_time ? Intent.DANGER : Intent.SUCCESS}
                      label="Travel start date and time"
                      labelFor="start_time"
                      labelInfo="*"
                      helperText={isSent && errors.start_time && errors.start_time}
                    >
                      <DateInput
                        disabled={!canEdit}
                        inputProps={{style: {width: '100%'}, className: 'bp3-large'}}
                        name="start_time"
                        id="start_time"
                        formatDate={date => moment(date).locale('ie').format('DD/MM/YYYY HH:mm')}
                        parseDate={str => moment(str, 'DD/MM/YYYY HH:mm').locale('ie').toDate()}
                        onChange={(dt) => this.onStartChanged(dt)}
                        placeholder={"DD/MM/YYYY HH:MM"}
                        value={this.state.startDate}
                        timePrecision={TimePrecision.MINUTE}
                        showActionsBar
                        intent={isSent && errors.start_time ? Intent.DANGER : Intent.NONE}
                      />
                    </FormGroup>
                  </Box>
                  <Box width={['100%','50%','50%']} pl={['0', '5px']}>
                    <FormGroup
                      intent={errors.end_time ? Intent.DANGER : Intent.SUCCESS}
                      label="Travel end date and time"
                      labelFor="end_time"
                      labelInfo="*"
                      helperText={isSent && errors.end_time && errors.end_time}
                    >
                      <DateInput
                        disabled={!canEdit}
                        inputProps={{style: {width: '100%'}, className: 'bp3-large'}}
                        id="end_time"
                        name="end_time"
                        className="bp3-large"
                        formatDate={date => moment(date).locale('ie').format('DD/MM/YYYY HH:mm')}
                        parseDate={str => moment(str, 'DD/MM/YYYY HH:mm').locale('ie').toDate()}
                        onChange={(dt) => this.onEndChanged(dt)}
                        placeholder={"DD/MM/YYYY HH:MM"}
                        value={this.state.endDate}
                        timePrecision={TimePrecision.MINUTE}
                        showActionsBar
                        intent={isSent && errors.end_time ? Intent.DANGER : Intent.NONE}
                      />
                    </FormGroup>
                  </Box>
                </Flex>
                {errors.general ?
                  <div className="row bp3-form-group bp3-intent-danger">
                    <div className="bp3-form-helper-text">{errors.general}</div></div> : ''}
                <Flex>
                  <Box width={'100%'} pl={'0'}>
                     <SubsistenceForm
                       ref={this.subsistenceComponent}
                       isSent={isSent}
                       days={durationDays}
                       hours={durationHours}
                       editable={canEdit}
                       expense={expense}
                       onFocusChanged={(field) => this.onFocusChanged(field)}
                       locations={locations}
                       onDataChanged={(subsistence) => this.setSubsistence(subsistence)}
                     />
                  </Box>
                </Flex>
                <Flex>
                  <Box width={'100%'}>
                    <MileageForm
                      ref={this.mileageComponent}
                      isSent={isSent}
                      engineSizes={engineSizes}
                      editable={canEdit}
                      expense={expense}
                      onFocusChanged={(field) => this.onFocusChanged(field)}
                      dataChanged={(mileage) => this.setMileage(mileage)}
                    />
                  </Box>
                </Flex>
                {this.state.recordId && this.state.statusId === 1 &&
                <Button large onClick={(event) => this.setState({deleteDialog: true})} icon="trash" intent="danger"
                        text={'Delete'}/>}
              </div>
            </Scrollbar>
          </div>
          <Dialog
            icon="info-sign"
            onClose={() => this.setState({deleteDialog: false})}
            title="Delete Travel Expense"
            isOpen={this.state.deleteDialog}>
            <div className={Classes.DIALOG_BODY}>
              Are you sure?
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={() => this.setState({deleteDialog: false})}>Cancel</Button>
                <Button intent="DANGER" onClick={() => this.handleDelete()}>Delete</Button>
              </div>
            </div>
          </Dialog>
        </React.Fragment>}
      </StyledForm>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    toast,
  }, dispatch);
}

export default (connect(null, matchDispatchToProps)(withRouter(TravelExpenseForm)));
