import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import Overview from "./Overview";
import BusinessEditor from "./BusinessExpenseEditor";
import TravelExpenseEditor from './TravelExpenseEditor'

class Expenses extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/expenses/add/business" component={BusinessEditor} />
        <Route exact path="/expenses/add/travel" component={TravelExpenseEditor} />
        <Route exact path="/expenses/:_year?/:_month?" component={Overview} />
      </Switch>
    );
  }
}

export default withRouter(Expenses);
