import React, {Component} from 'react';
import { Route, Switch, withRouter, Link } from 'react-router-dom';
import Expenses from './expenses';
import BusinessExpenses from './business-expenses';
import TravelExpenses from './travel-expenses';

class Reports extends Component {
  render() {
    const path = this.props.history.location.pathname.split("/");
    const currentPath = `${path[1]}/${path[2]}`;
    return (
      <div className="split">
        <div className="left">
          <ul className="submenu">
            {/* <li className={currentPath === 'reports/expenses' ? 'active' : ''}>
              <Link to="/reports/expenses">Expenses</Link>
            </li> */}
            <li className={currentPath === 'reports/monthly-business-expenses' ? 'active' : ''}>
              <Link to="/reports/monthly-business-expenses">Monthly Business Expenses</Link>
            </li>
            <li className={currentPath === 'reports/monthly-travel-expenses' ? 'active' : ''}>
              <Link to="/reports/monthly-travel-expenses">Monthly Travel Expenses</Link>
            </li>
          </ul>
        </div>
        <div className="right">
          <Switch>
            {/* <Route exact path="/reports/expenses" component={Expenses} /> */}
            <Route exact path="/reports/monthly-business-expenses" component={BusinessExpenses} />
            <Route exact path="/reports/monthly-travel-expenses" component={TravelExpenses} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(Reports);
