import React, {useState} from 'react';
import styled from 'styled-components';
import Icon from "../../../../../components/icon";

const StyledCheckbox = styled.div`
  position: relative;
  display: inline-flex;
  border-radius: 4px;
  background-color: #edeef0;
  border-color: #edeef0;
  padding: 10px;
  color: #2e3c58;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  margin: 4px 20px 4px 0;
  
  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
    width: 20px;
    height: 20px;
    border: 1px solid #ced1d8;
    border-radius: 4px;
    
    .check-mark {
      height: 10px;
      width: 5px;
      margin-bottom: 1px;
      border: solid #2e3c58;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: ${props => props.isActive ? 0.9 : 0}; 
    }
  }
  
  .type {
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: ${props => props.color};
  }
  

`

export default ({color, icon, onChange, total}) => {
  const [checked, setChecked] = useState(false);

  const setVal = () => {
    setChecked(!checked);
    onChange(!checked);
  };

  return (
    <StyledCheckbox isActive={checked} onClick={setVal} color={color}>
      <div className="type">{icon && <Icon color="#fff" icon={icon}/>}</div>
      {total}
      <div className="checkbox"><div className="check-mark"></div></div>
    </StyledCheckbox>
  );
}
